import React, {useState} from 'react' ;
import {Box} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import RoutedButton from "../../components/RoutedButton";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
// import {userHasNecessaryRole} from "../../services/auth/service";
import moment from "moment";
import {NA, SessionStatusColor, UserEngagement} from "../../lib/constants";
import {StatusCell} from "../../components/table/StatusCell";
// import {DeleteButton} from "../../components/DeleteButton";
import {AvatarCell} from "../../components/table/AvatarCell";

// const dateFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};

export const DELETE_CLIENT = gql`
    mutation($id: Int!){
        delete_session(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

// TODO: Add assigned to provider column if the logged in provider is ProviderManager
const columns = [
    {
        id: 'user.first_name',
        Header: 'Client Name',
        primary: true,
        Cell: ({row: {original: {id, user: {first_name, last_name}, sessions}}}) => (
          <AvatarCell user_id={id} id={sessions?.[0]?.id} last_name={last_name} first_name={first_name} path={`clients/${id}`} />
        ),
    },
    {
        id: 'user.date_of_birth',
        accessor: ({user: {date_of_birth}}) => date_of_birth ? moment().diff(date_of_birth, 'years', false) : NA,
        Header: 'Age'
    },
    {
        id: 'user.gender_id',
        accessor: ({user: {gender}}) => gender ? gender.name : NA,
        Header: 'Gender'
    },
    {
        id: 'phone_number',
        accessor: ({phone_number}) => phone_number ? phone_number : NA,
        Header: 'Phone'
    },
    {
        id: 'user.status_id',
        Cell: ({row: {original: {user: {status: {id, name}, engaged}}}}) =>
            id === 1 ? // If it is active, show engagement status
                <StatusCell id={engaged ? 1 : 0} name={engaged ? 'Engaged' : 'Not Engaged'} map={UserEngagement}/> :
                <StatusCell id={id} name={name} map={SessionStatusColor}/>,
        Header: 'Status'
    },
    {
        id: 'next_appointment',
        Cell: ({row: {original: {sessions}}}) => {
            const appointment = sessions?.[0].meeting_participants?.[0]?.meeting;
            if (appointment && moment(appointment.start_at).isSameOrAfter()) {
                return moment(appointment.start_at).format('MMM DD, YYYY, h:mm a');
            }
            return NA;
        },
        Header: 'Next Appointment'
    },
    {
        id: 'sessions.provider_session.provider.id',
        accessor: ({sessions}) => sessions?.[0]?.provider_session?.provider?.user ? `${sessions[0].provider_session.provider.user.first_name} ${sessions[0].provider_session.provider.user.last_name}` : NA,
        Header: 'Clinician'
    }
    // {
    //     id: 'treatment_status',
    //     Cell: ({row: {original: {sessions}}}) => {
    //         const hasActiveSession = sessions?.[0].status_id === SESSION_STATUS.ACTIVE;
    //         return hasActiveSession ? 'In Treatment' : 'Discharged'
    //     },
    //     Header: 'Treatment Status'
    // },
    // {
    //     id: 'provider_session.provider.user.first_name',
    //     requiredRole: MANAGER,
    //     accessor: ({provider_session}) => provider_session ? `${provider_session.provider.user.first_name} ${provider_session.provider.user.last_name}` : NA,
    //     Header: 'Staff Assigned'
    // },

    // {
    //     id: "actions",
    //     sortable: false,
    //     Header: 'Actions',
    //     Cell: ({row: {original: {id}}}) => (
    //         <DeleteButton
    //             query={DELETE_CLIENT}
    //             variables={{id: parseInt(id)}}
    //             queriesToRefetch={['fetchDataClients']}
    //         />
    //     )
    // },
];

const FETCH_DATA = gql`
    query fetchDataClients($limit: Int! $offset: Int! $orderBy: [client_order_by!] $filter: client_bool_exp) {
        data: client(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            recovery_date
            phone_number
            user {
                id
                first_name
                last_name
                created_at
                email
                date_of_birth
                engaged
                status_id
                status {
                    id
                    name
                }
                gender_id
                gender {
                    id
                    name
                }
            }
            sessions(order_by: {created_at: desc} limit: 1) {
                id
                created_at
                status_id
                provider_session {
                    provider {
                        id
                        user {
                            first_name
                            last_name
                        }
                    }
                }
                meeting_participants (order_by: {meeting: {start_at: desc}} limit: 1) {
                    meeting {
                        id
                        start_at
                    }
                }
            }
        }
        data_aggregate: client_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const removeObjectProp = (obj, prop) => {
    let {[prop]: omit, ...result} = obj;
    return result;
};

const ClientsPage = () => {
    const [filter, setFilter] = useState({});
    // const [all, setAll] = useState(true);

    const header = () => (
        <Box direction="row" gap='small'>
            {/*{userHasNecessaryRole(MANAGER) && <CheckBox*/}
            {/*    checked={all}*/}
            {/*    label="All"*/}
            {/*    onChange={(event) => {*/}
            {/*        setAll(event.target.checked);*/}
            {/*        if (event.target.checked) {*/}
            {/*            setFilter(removeObjectProp(filter, 'provider_sessions'));*/}
            {/*        } else {*/}
            {/*            setFilter({...filter, provider_sessions: {}})*/}
            {/*        };*/}
            {/*    }*/}
            {/*    }*/}
            {/*/>}*/}
            <SearchField onSubmit={(term) => !term ? setFilter(removeObjectProp(filter, '_or')) :
                setFilter({
                    ...filter,
                    _or: [
                        {user: {first_name: {_ilike: `%${term}%`}}},
                        {user: {last_name: {_ilike: `%${term}%`}}}
                    ]
                })
            }/>
            <RoutedButton path='/clients/create' label='New Client' primary/>
        </Box>
    );
    return (
        <Page title="Clients" header={header()}>
            <DataTable defaultSort={{user: {first_name: 'asc'}}} query={FETCH_DATA} applyFilter={filter} columns={columns}/>
        </Page>
    );
};

export default ClientsPage;