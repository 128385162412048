import React, {useState} from 'react';
import {
    BAM_TEMPLATE_ID,
    PHQ9_TEMPLATE_ID,
    STATUS_ID_COMPLETED
} from "./constants";
import {Box} from "grommet";
import PHQ9DataTable from "./PHQ9DataTable";
import BasicTemplateDataTable from "./BasicTemplateDataTable";
import BAMDataTable from "./BAMDataTable";
import {SessionInfoHeader} from "./SessionInfoHeader";
import {FormDown, FormUp} from "grommet-icons";
import {Button} from "grommet";

const SessionResultTableCard = ({template_id, session, color, index, latestResultOn, severity}) => {

    const [collapsed, setCollapsed] = useState(true);

    const getDataTableForCurrentTemplate = (session_id) => {
        switch (template_id) {
            case PHQ9_TEMPLATE_ID:
                return <PHQ9DataTable session_id={session_id}
                                      status_id={STATUS_ID_COMPLETED}
                />;
            case BAM_TEMPLATE_ID:
                return <BAMDataTable session_id={session_id}
                                     status_id={STATUS_ID_COMPLETED}
                />;
            default:
                return <BasicTemplateDataTable template_id={template_id}
                                               session_id={session_id}
                                               status_id={STATUS_ID_COMPLETED}
                />
        }
    };

    return (
        <Box background='white' flex='grow'
             round='xsmall' pad='small' elevation='xsmall' gap='medium'
             border={{
                 "color": color,
                 "size": "medium",
                 "style": "solid",
                 "side": "left"
             }}>
            <Box direction='row' justify='between' fill='horizontal' gap='medium' pad={{horizontal:'small'}}>
                <SessionInfoHeader session={session} severity={severity}
                                   latestResultOn={latestResultOn}
                                   linkToIndividualReport template_id={template_id}/>
                {collapsed ?
                    <Box align='end'  pad={{top: '20px'}}><Button><FormDown onClick={() => setCollapsed(false)}/></Button></Box> :
                    <Box align='end' pad={{top: '20px'}}><Button><FormUp onClick={() => setCollapsed(true)}/></Button></Box>
                }
            </Box>
            {collapsed || getDataTableForCurrentTemplate(session.id)}
        </Box>
    );
};

export default (SessionResultTableCard);
