import React from 'react'
import {Box, Button, Grid, Text, ResponsiveContext} from "grommet";
import Avatar from "../Avatar";
import RoutedButton from "../RoutedButton";
import moment from "moment";
import {Print} from "grommet-icons";
import ReactToPrint from "react-to-print";

export const IndividualReportActionBar = ({survey, printRef, canMessage = false}) => (
    <ResponsiveContext.Consumer>
        {(deviceSize) => (
            <Grid
                align="start"
                columns={deviceSize === 'small' ? 'auto' : ["1/3", "2/3"]}
            >
                <Box
                    gap="small"
                    justify="center"
                    border={deviceSize !== "small" ? [{side: "left"}, {side: "horizontal"}] : [{side: "top"}, {side: "vertical"}]}
                    fill
                    round={{size: "xsmall", corner: deviceSize === "small" ? "top" : "left"}}
                    pad={{horizontal: "medium", vertical: "medium"}}
                >
                    <Text weight="bold">Survey</Text>
                    <Text color="sidebarInactive" truncate>{survey.template.title}</Text>
                </Box>
                <Box
                    pad={{horizontal: "medium", vertical: "large"}}
                    round={{size: "xsmall", corner: deviceSize === "small" ? "bottom" : "right"}}
                    background="white"
                    direction="row"
                    justify="between"
                    align="center"
                    gap="small"
                    border
                >
                    <Box direction="row" gap="medium" align="center">
                        <Avatar id={survey.session.client.user.id}/>
                        <Box gap="xsmall">
                            <Text
                                weight='bold'>{`${survey.session.client.user.first_name} ${survey.session.client.user.last_name}`}</Text>
                            {canMessage &&
                            <RoutedButton path={`/messenger/${survey.session.provider_session.twilio_channel_sid}`}
                                          plain
                                          label={<Text
                                              color='brand'>{`Message ${survey.session.client.user.first_name}`}</Text>}
                            />}
                        </Box>
                    </Box>
                    <Box gap="xsmall">
                        <Text weight='bold'>Completed On</Text>
                        <Text>{moment(survey.updated_at).format('MMM D, YYYY, h:mm A')}</Text>
                    </Box>
                    <Box gap="xsmall">
                        <Text weight='bold'>Duration</Text>
                        <Text>{moment.duration(moment(survey.updated_at).diff(survey.created_at, 'hours'), 'hours').humanize()}</Text>
                    </Box>
                    <ReactToPrint trigger={() => <Button plain icon={<Print/>}/>} content={() => printRef.current}/>
                </Box>
            </Grid>
        )}
    </ResponsiveContext.Consumer>
);
