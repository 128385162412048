import React, {useCallback, useState} from 'react';
import {Box, Button, Text} from "grommet";
import {useDropzone} from 'react-dropzone'
import WindowUpload from "./icons/WindowUpload";

export const ExcelImport = ({setFile, error}) => {

    const [fileName, setFileName] = useState(undefined);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            setFile(file);
            setFileName(file.name)
        });

    }, [setFile]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <Box align='center' pad='large' fill justify='center' {...getRootProps()}>
            <Box gap='xsmall' align='center' pad='medium'>
                {fileName ?
                    <Text color='gray' weight='bold' margin={{top: 'small'}}>{fileName}</Text>
                    :
                    <React.Fragment>
                        <WindowUpload active style={{width: '37px', height: '38px'}}/>
                        <Text weight='bold' size="14px">Start Importing</Text>
                        <Text color='gray' margin={{top: 'small'}}>Please upload an Excel file to get started</Text>
                        <Text color='gray'>{isDragActive ? 'Drop here' : 'Drag & drop or click the Browse button'}</Text>
                        <Button fill='horizontal' margin={{top: "medium"}} label="Browse" primary/>
                    </React.Fragment>
                }
                { error && <Text color="lightred">{error}</Text> }
            </Box>
            <input
                type="file"
                accept=".xls,.xlsx"
                {...getInputProps()}
            />
        </Box>
    )
};
