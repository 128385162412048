import React, {useState} from 'react';
import {Box, Button, Text} from "grommet";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import gql from 'graphql-tag';
import {Edit} from "grommet-icons";
import {SessionDiagnosis} from "./SessionDiagnosis";
import {EditSessionDiagnosesField} from "./EditSessionDiagnosesField";

const FETCH_DATA = gql`
query fetchSessionDiagnosis($sessionId: Int!) {
    diagnoses: diagnosis(where: {session_diagnoses: {session_id: {_eq: $sessionId}}}) {
        id
        name
        icd10_code
    }
}
`;

export const SessionDiagnosesField = ({sessionId, canEdit = true}) => {
    const [editMode, setEditMode] = useState(false);
    const {data, loading, error} = useQuery(FETCH_DATA, {
        variables: {sessionId}
    });

    if (loading) {
        return <Spinner full/>
    }

    if (error) {
        console.log(error)
        return <Text color="red">Can't load diagnoses for this client</Text>
    }

    if (editMode) {
        return <EditSessionDiagnosesField
            currentDiagnoses={data.diagnoses.map(diagnosis => diagnosis.id)}
            sessionId={sessionId}
            onCancel={() => setEditMode(false)}
            onAccept={() => setEditMode(false)}
            refetchQueries={[{query: FETCH_DATA, variables: {sessionId}}]}
        />
    }

    return (
        <Box direction="row" alignContent='start'>
            <Box direction="column">
                {data && data.diagnoses.length > 0 ? data.diagnoses.map(diagnosis =>
                    <SessionDiagnosis key={diagnosis.id} diagnosis={diagnosis}/>) : <Text color='gray'>Not specified</Text>}
            </Box>
            {canEdit &&
            <Button alignSelf='start'
                    icon={<Edit/>}
                    plain
                    margin={{horizontal: '12px'}}
                    onClick={() => {
                        setEditMode(true)
                    }}/>
            }
        </Box>
    );
};
