import React, {useState} from 'react';
import {useInterval} from "../lib/useInterval";
import {Text} from "grommet";

export const LoadingDots = () => {
    const [dots, setDots] = useState(1);
    useInterval(() => {
        setDots(d => d >=3 ? 1 : d + 1)
    }, 400);
    return <Text>{'.'.repeat(dots)}</Text>
};