import React from 'react';
import {Box, Grid, Heading, Select} from "grommet";
import {useFormikContext} from "formik";
import {AutosaveFormField} from "./AutosaveFormField";
import {useClientSession} from "../../hooks/useClientSession";

export const SexIdentityInformation = ({data}) => {
    const { setFieldValue } = useFormikContext();
    const { active } = useClientSession();
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Sexual Identity</Heading>
            <Grid
                gap='medium'
                columns={{count: 'fill', size: 'small'}}
            >
                <AutosaveFormField name='session.client.user.birth_sex_id' label='Birth Sex'>
                    <Select
                        options={data?.birth_sex || []}
                        valueKey={{key: 'id', reduce: true}}
                        labelKey='name'
                        disabled={!active}
                        onChange={({value}) => setFieldValue('session.client.user.birth_sex_id', value)}
                    />
                </AutosaveFormField>
                <AutosaveFormField name='session.client.user.sexual_orientation_id' label='Sexual Orientation'>
                    <Select
                        options={data?.sexual_orientation || []}
                        valueKey={{key: 'id', reduce: true}}
                        labelKey='name'
                        disabled={!active}
                        onChange={({value}) => setFieldValue('session.client.user.sexual_orientation_id', value)}
                    />
                </AutosaveFormField>
                <AutosaveFormField name='session.client.user.gender_identity_id' label='Gender Identity'>
                    <Select
                        options={data?.gender_identity || []}
                        valueKey={{key: 'id', reduce: true}}
                        labelKey='name'
                        disabled={!active}
                        onChange={({value}) => setFieldValue('session.client.user.gender_identity_id', value)}
                    />
                </AutosaveFormField>
            </Grid>
        </Box>
    )
};