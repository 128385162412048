import * as React from "react"

function Unmute(props) {
    return (
        <svg width='1em' height='1em' viewBox="0 0 17 24" {...props}>
            <path
                d="M15.75 9a.75.75 0 01.743.648l.007.102V12A8.25 8.25 0 019 20.216v3.034a.75.75 0 01-1.493.102L7.5 23.25v-3.034a8.251 8.251 0 01-7.496-7.964L0 12V9.75a.75.75 0 011.493-.102l.007.102V12a6.75 6.75 0 0013.496.237L15 12V9.75a.75.75 0 01.75-.75zm-6-9a3.75 3.75 0 013.75 3.75v9.75a3.75 3.75 0 01-3.75 3.75h-3A3.75 3.75 0 013 13.5V3.75A3.75 3.75 0 016.75 0zm0 1.5h-3A2.25 2.25 0 004.5 3.75v9.75a2.25 2.25 0 002.25 2.25h3A2.25 2.25 0 0012 13.5V3.75A2.25 2.25 0 009.75 1.5z"
                fill="#FFFFFF"
                fillRule="nonzero"
            />
        </svg>
    )
}

export default Unmute
