import React from 'react';
import Page from "../../components/Page";
import {Form, Formik} from "formik";
import {Box, FormField, Grid, Notification, Text, TextInput} from "grommet";
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spinner from "../../components/Spinner";
import {AddressFields} from "../Join/AddressFields";
import {PROFESSIONAL_PLAN} from "../../lib/constants";
import {LoadingButton} from "../../components/LoadingButton";
import RoutedButton from "../../components/RoutedButton";
import {CompanyImageBranding} from "./CompanyImageBranding";
import {MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS, useImageUpload} from "../../services/image";
import {Editor} from "react-draft-wysiwyg";
import {convertToRaw, EditorState, ContentState} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const htmlToState = (html) => {
    const contentBlock = htmlToDraft(html || '');
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty()
}

const CompanyDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    phone: Yup.string(),
    address: Yup.object().shape({
        address: Yup.string(),
        state: Yup.string(),
        city: Yup.string(),
        zip: Yup.string().test('len', 'Invalid', val => val ? val.length === 5 : true),
    }),
    // consent_for_treatment: Yup.string(),
    brand: Yup.mixed()
        .test('fileSize', "File is too large", value => !value.name || value.size <= MAX_FILE_SIZE)
        .test('fileType', "Unsupported file format", value => !value.name || SUPPORTED_IMAGE_FORMATS.includes(value.type)),
});

const FETCH_DATA = gql`
    query ($companyId: Int!){
        companyID @client @export(as: "companyId")
        company: company_by_pk(id: $companyId) {
            id
            name
            address
            state
            city
            zip
            plan_id
            consent_for_treatment
        }
    }
`;

const UPDATE_COMPANY = gql`
    mutation ($data: company_set_input! $companyId: Int!) {
        update_company(_set: $data where: {id: {_eq: $companyId}}) {
            affected_rows
            returning {
                id
                name
                address
                state
                city
                zip
                plan_id
                consent_for_treatment
            }
        }
    }
`;

const INVALIDATE_SESSION_CONSENT_AGREEMENTS = gql`
    mutation ($companyId: Int!) {
        update_session(_set: {consent_for_treatment_accepted_at: null} where: {_and: [{company_id: {_eq: $companyId}}, {discharge_date: {_is_null: true}}]}) {
            affected_rows
        }
    }
`;

const CompanyDetailsPage = () => {
    const {data, loading} = useQuery(FETCH_DATA);
    const [update] = useMutation(UPDATE_COMPANY);
    const [invalidateSessionConsents] = useMutation(INVALIDATE_SESSION_CONSENT_AGREEMENTS);
    const {uploadBrand} = useImageUpload();

    return (
        <Page title='Company Details'>
            {loading ? <Spinner fill/> : <Formik
                initialValues={{
                    name: data.company.name,
                    address: {
                        address: data.company.address || '',
                        city: data.company.city || '',
                        state: data.company.state || '',
                        zip: data.company.zip || '',
                    },
                    consent_for_treatment: htmlToState(data.company.consent_for_treatment),
                    brand: {}
                }}
                validationSchema={CompanyDetailsSchema}
                onSubmit={async ({name, address, brand, consent_for_treatment: consentState}, actions) => {
                    const consent_for_treatment = draftToHtml(convertToRaw(consentState.getCurrentContent()))
                    console.log(consent_for_treatment === data.company.consent_for_treatment, consent_for_treatment, data.company.consent_for_treatment);
                    try {
                        await update({variables: {data: {consent_for_treatment, name, ...address}, companyId: data.company.id}});
                        if (consent_for_treatment && consent_for_treatment !== data.company.consent_for_treatment) {
                            await invalidateSessionConsents({variables: {companyId: data.company.id}});
                        }
                        if (brand.name) {
                            await uploadBrand(brand);
                        }
                        actions.setStatus({type: 'normal', message: 'Update was successful!'})
                    } catch (e) {
                        console.log('e', e);
                        actions.setStatus({type: 'critical', message: 'Data could not be saved!'})
                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
            >
                {(props) => (
                    <Form>
                        <Grid
                            gap='medium'
                            columns={{count: 'fit', size: '500px'}}
                        >
                            <Box background='white' round="xsmall" elevation="xsmall" pad='medium' gap='medium'>
                                <Text>Company Information</Text>
                                <FormField label="Company Name" error={props.errors.name}>
                                    <TextInput
                                        name='name'
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                    />
                                </FormField>
                            </Box>
                            {data.company.plan_id === PROFESSIONAL_PLAN &&
                            <Box background='white' round="xsmall" elevation="xsmall" pad='medium' gap='medium'>
                                <Text>Branding</Text>
                                <Box gap='small'>
                                    <Box direction="row-responsive" justify='between' flex fill>
                                        <Text color='lightgray'>Upload your logo below</Text>
                                        <Text style={{fontStyle: 'italic'}} color='lightgray'>
                                            Recommended size 180 x 30 pixels
                                        </Text>
                                    </Box>
                                    <CompanyImageBranding onUpload={(file) => {
                                        props.setFieldValue('brand', file)
                                    }}/>
                                </Box>
                            </Box>
                            }
                            <Box background='white' round="xsmall" elevation="xsmall" pad='medium' gap='medium' flex={false}>
                                <Text>Consent for treatment</Text>
                                <Editor
                                    editorClassName='wysiwyg-editor'
                                    toolbarClassName='wysiwyg-toolbar'
                                    editorStyle={{maxHeight: '200px', height: 'auto'}}
                                    editorState={props.values.consent_for_treatment}
                                    onEditorStateChange={(data) => props.setFieldValue('consent_for_treatment', data)}
                                />
                            </Box>
                            <Box background='white' round="xsmall" elevation="xsmall" pad='medium' gap='medium'>
                                <Text>Company Address</Text>
                                <AddressFields
                                    heading={false}
                                    handleChange={props.handleChange}
                                    handleBlur={props.handleBlur}
                                    setFieldValue={props.setFieldValue}
                                    errors={props.errors.address || {}}
                                    values={props.values.address}
                                    field='address'
                                />
                            </Box>
                        </Grid>
                        <Box margin={{top: 'medium'}} direction='row' gap='small'>
                            <LoadingButton loading={props.isSubmitting} type='submit' primary label='Update'/>
                            <RoutedButton primary label='Cancel' color='lightgray' path='/'/>
                        </Box>
                        {props.status && <Notification
                            title={props.status.message}
                            onClose={() => props.setStatus(undefined)}
                            status={props.status.type}
                            toast={{autoClose: true, position: 'bottom-right'}}
                        />}
                    </Form>
                )}
            </Formik>}
        </Page>
    );
};

export default CompanyDetailsPage;
