import React from 'react';
import {Box, Button, Text} from "grommet";
import {Form} from "formik";
import {MailOption} from "grommet-icons";
import {TextField} from "./TextField";

export const RequestPasswordResetForm = ({ values, errors, status, handleChange, touched, isSubmitting }) => (
    <Form>
        <TextField name='email'
                   type='email'
                   icon={MailOption}
                   value={values.email}
                   handleChange={handleChange}
                   placeholder='Email'
                   error={errors.email}
        />

        {status && <Box margin={{bottom: "medium"}} width='small'>
            <Text color="lightgray">{status.message}</Text>
        </Box>}

        <Box direction='row-responsive'>
            <Button type="submit" fill primary label="Reset Password" disabled={isSubmitting}/>
        </Box>
    </Form>
);
