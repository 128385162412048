import React from 'react'
import {colors} from "../../theme";

const Trash = ({active, ...rest}) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M11.875 2.125l.17.007c.672.054.672.054 1.158.54.547.547.547.547.547 1.328v.625h4.375l.099.007c.192.03.192.03.34.179.186.185.186.185.186.439s0 .254-.186.44c-.185.185-.185.185-.439.185H16.25V16.5l-.007.17c-.054.672-.054.672-.54 1.158-.547.547-.547.547-1.328.547h-8.75l-.17-.007c-.672-.054-.672-.054-1.158-.54-.547-.547-.547-.547-.547-1.328V5.875H1.875l-.099-.007c-.192-.03-.192-.03-.34-.179-.186-.185-.186-.185-.186-.439s0-.254.186-.44c.185-.185.185-.185.439-.185H6.25V4l.007-.17c.054-.672.054-.672.54-1.158.547-.547.547-.547 1.328-.547h3.75zM15 5.875H5V16.5l.007.099c.03.192.03.192.179.34.185.186.185.186.439.186h8.75c.254 0 .254 0 .44-.186.185-.185.185-.185.185-.439V5.875zm-6.875 2.5c.254 0 .254 0 .44.186.185.185.185.185.185.439v5l-.007.099c-.03.192-.03.192-.179.34-.185.186-.185.186-.439.186s-.254 0-.44-.186C7.5 14.254 7.5 14.254 7.5 14V9l.007-.099c.03-.192.03-.192.179-.34.185-.186.185-.186.439-.186zm3.75 0c.254 0 .254 0 .44.186.185.185.185.185.185.439v5l-.007.099c-.03.192-.03.192-.179.34-.185.186-.185.186-.439.186s-.254 0-.44-.186c-.185-.185-.185-.185-.185-.439V9l.007-.099c.03-.192.03-.192.179-.34.185-.186.185-.186.439-.186zm0-5h-3.75l-.099.007c-.192.03-.192.03-.34.179C7.5 3.746 7.5 3.746 7.5 4v.625h5V4l-.007-.099c-.03-.192-.03-.192-.179-.34-.185-.186-.185-.186-.439-.186z"
            fill={active ? colors.lightred : colors.sidebarInactive}
            fillRule="nonzero"
        />
    </svg>
);

export default Trash
