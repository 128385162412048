import React from 'react';
import {Box, Text} from "grommet";
import {questionIcons} from "../../Questions";

export const QuestionTitle = ({title, number, type}) => {
    const Icon = questionIcons[type];
    return (
    <Box direction='row' justify='between' pad={{right: 'small'}}>
        <Text color="lightgray">{`${number}. ${title}`}</Text>
        <Icon/>
    </Box>
)};
