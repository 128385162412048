import * as React from "react"
import {colors} from "../../theme";

function Phone({active, ...rest}) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 38 38" {...rest}>
            <title>Phone</title>
            <path
                d="M6.709.01a4.392 4.392 0 013.155 1.055l.25.234 6.041 6.03a4.394 4.394 0 01.262 5.93l-.262.286-3.107 3.107a7.326 7.326 0 01-8.382 1.408l-.322-.169.208.457c1.263 2.681 3.26 5.416 5.947 8.18l.482.489c2.93 2.93 5.827 5.092 8.665 6.43l.455.207-.072-.13a7.324 7.324 0 01-.824-2.954l-.013-.446c0-1.793.657-3.519 1.838-4.854l.307-.325 3.104-3.107a4.394 4.394 0 015.93-.262l.286.262 6.035 6.035a4.394 4.394 0 01-.448 6.642c-8.572 6.31-18.126 3.785-27.335-5.427C-.302 19.874-2.828 10.321 3.493 1.741A4.394 4.394 0 016.71.009zm20.979 23.634c-.305 0-.599.094-.842.265l-.172.143L23.637 27a4.108 4.108 0 00-1.26 2.948c0 .983.359 1.93 1.004 2.678l.256.27 1.732 1.68.054.007c2.986.227 5.878-.552 8.641-2.408l.414-.287a1.36 1.36 0 00.272-1.95l-.139-.153-5.91-5.734a1.455 1.455 0 00-1.013-.408zM7.126 3.381a1.37 1.37 0 00-1.006.55c-1.946 2.723-2.835 5.573-2.735 8.516l.035.61L5.1 14.785a4.08 4.08 0 005.66.228l.239-.228 2.95-3.043a1.465 1.465 0 00.143-1.856l-.142-.172-5.743-5.91a1.368 1.368 0 00-1.08-.423z"
                fill={active ? colors.green : colors.white}
                fillRule="evenodd"
            />
        </svg>
    )
}

export default Phone
