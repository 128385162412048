import React from 'react'
import {colors} from "../../theme";

const Staff = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 38 26" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M15.535 25.611a1.07 1.07 0 01-.21-.019 1.762 1.762 0 01-.21-.057 1.156 1.156 0 01-.686-.61 1.24 1.24 0 01-.076-.952 12.69 12.69 0 011.791-3.316 12.306 12.306 0 012.65-2.554 11.942 11.942 0 013.296-1.638 11.51 11.51 0 013.735-.61c1.296 0 2.54.203 3.735.61 1.194.38 2.293.927 3.297 1.638a12.306 12.306 0 012.648 2.554 12.69 12.69 0 011.792 3.316c.05.152.07.31.057.476a1.981 1.981 0 01-.095.476c-.077.153-.178.28-.305.382a1.35 1.35 0 01-.42.228.677.677 0 01-.19.057 1.198 1.198 0 01-.915-.21 1.43 1.43 0 01-.457-.57 9.556 9.556 0 00-3.05-4.365 9.445 9.445 0 00-4.877-2.077v6.022c0 .33-.121.616-.363.857a1.172 1.172 0 01-.857.362c-.33 0-.616-.12-.858-.362a1.172 1.172 0 01-.362-.857V18.37a9.445 9.445 0 00-4.878 2.077 9.556 9.556 0 00-3.049 4.364c-.076.229-.222.42-.438.572a1.197 1.197 0 01-.705.228zm10.29-9.756a7.873 7.873 0 01-3.087-.61 8.328 8.328 0 01-2.516-1.715 8.328 8.328 0 01-1.715-2.516 7.873 7.873 0 01-.61-3.087c0-.686.09-1.359.267-2.02.178-.66.432-1.295.763-1.905l.057-.057c.012-.013.019-.032.019-.058a7.862 7.862 0 012.896-2.839A7.768 7.768 0 0125.825 0c1.93 0 3.646.642 5.145 1.925 1.5 1.283 2.388 2.877 2.668 4.783.025.05.038.101.038.152v.115c.025.177.045.343.057.495.013.152.02.305.02.457a7.873 7.873 0 01-.61 3.087 8.328 8.328 0 01-1.716 2.516 8.328 8.328 0 01-2.515 1.715 7.873 7.873 0 01-3.087.61zm-5.298-9.3a3.327 3.327 0 00-.152.686 6.208 6.208 0 00-.038.686c0 1.525.533 2.82 1.6 3.888 1.068 1.067 2.363 1.6 3.888 1.6 1.372 0 2.572-.45 3.602-1.352 1.029-.902 1.632-2.014 1.81-3.335-.508.127-1.023.228-1.544.305-.52.076-1.048.114-1.581.114a12.36 12.36 0 01-4.04-.667 11.997 11.997 0 01-3.545-1.925zm1.144-2.21a9.71 9.71 0 002.991 1.753 9.886 9.886 0 006.384.152c-.355-1.118-1.016-2.032-1.982-2.744a5.33 5.33 0 00-3.24-1.067 5.32 5.32 0 00-2.305.515 5.588 5.588 0 00-1.848 1.39z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <path
                d="M8.103 18.294c.914 0 1.804.146 2.668.438a8.39 8.39 0 012.363 1.239c.152.101.266.222.343.362.076.14.127.286.152.438.025.153.019.311-.02.477a1.08 1.08 0 01-.209.438 1.484 1.484 0 01-.438.362c-.165.089-.35.133-.553.133a1.02 1.02 0 01-.38-.076 1.491 1.491 0 01-.344-.19 5.81 5.81 0 00-1.696-.877 6.044 6.044 0 00-1.886-.305c-1.296 0-2.471.375-3.526 1.124a5.783 5.783 0 00-2.191 2.954 1.43 1.43 0 01-.458.572 1.173 1.173 0 01-.914.21.677.677 0 01-.19-.058 1.35 1.35 0 01-.42-.228.805.805 0 01-.267-.382 1.275 1.275 0 01-.133-.476 1.2 1.2 0 01.057-.476c.61-1.677 1.652-3.043 3.125-4.097a8.254 8.254 0 014.917-1.582zm0-12.196c1.677 0 3.112.597 4.306 1.791 1.195 1.194 1.792 2.63 1.792 4.307 0 1.677-.597 3.112-1.792 4.307-1.194 1.194-2.63 1.79-4.306 1.79-1.703 0-3.145-.596-4.326-1.79-1.182-1.195-1.772-2.63-1.772-4.307 0-1.677.59-3.113 1.772-4.307C4.958 6.695 6.4 6.098 8.103 6.098zm0 2.44c-1.017 0-1.88.355-2.592 1.066-.711.712-1.067 1.576-1.067 2.592s.356 1.88 1.067 2.592c.711.711 1.575 1.067 2.592 1.067.99 0 1.848-.356 2.572-1.067a3.497 3.497 0 001.086-2.592c0-1.016-.362-1.88-1.086-2.592-.724-.711-1.581-1.067-2.572-1.067z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
        </g>
    </svg>
)

export default Staff
