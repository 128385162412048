 import {useMemo} from 'react';
 import {useParams} from "react-router-dom";
 import {useQuery} from "@apollo/react-hooks";
 import gql from "graphql-tag";

 const FETCH_SESSION = gql`
     query ($id: Int!) {
         id @client
         session: session_by_pk(id: $id) {
             id
             discharge_date
             provider_session {
                 provider_id
             }
         }
     }
 `;

export const useClientSession = () => {
    const params = useParams();
    const {data} = useQuery(FETCH_SESSION, {variables: {id: params.session_id}})
    return useMemo(() => ({
        session_id: data?.session?.id,
        active: !data?.session?.discharge_date,
        is_from_caseload: data?.id === data?.session?.provider_session?.provider_id,
    }), [data])
}