import {array, lazy, number, object, string} from "yup";
import {ACTION_TYPE, EVENT_TYPE} from "./enums";

export const CampaignValidationSchema = object().shape({
    name: string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
    audiences: array().of(object().shape({
        id: string(),
        name: string()
    })).required('Required'),
    actions: array().of(object().shape({
            type: object().shape({
                id: string().required('Required'),
                name: string().required('Required')
            }),
            subject: string()
                .max(255, 'Too Long!')
                .when('type', (type, schema) => {
                    if (parseInt(type.id) === ACTION_TYPE.SEND_EMAIL) {
                        return schema.required('Required').min(2, 'Too Short!');
                    }
                    return schema;
                }),
            body: string()
                .when('type', (type, schema) => {
                    if (parseInt(type.id) === ACTION_TYPE.SEND_EMAIL) {
                        return schema.required('Required').min(2, 'Too Short!');
                    }
                    return schema;
                }),
            survey: object().when('type', (type, schema) => {
                if (parseInt(type.id) === ACTION_TYPE.SEND_SURVEY || parseInt(type.id) === ACTION_TYPE.SEND_MEASUREMENT_SURVEY) {
                    return object().shape({
                        id: string().required('Required'),
                        name: string()
                    });
                }
                return schema;
            }),
            events: lazy(events => {
                if (parseInt(events.type_id) === EVENT_TYPE.CHANGE_OF_FIELD) {
                    return object().shape({
                        id: string(),
                        name: string(),
                        fieldName: object().shape({
                            id: string().required('Required'),
                            name: string(),
                        }),
                        operator: object().shape({
                            id: string().required('Required'),
                            name: string(),
                        }),
                        value: string().required('Required')
                    })
                } else {
                    return object().shape({
                        id: string(),
                        name: string()
                    })
                }
            }),
            time: number()
                .typeError('Time delay must be a number')
                .integer('Time delay must be an integer')
                .moreThan(-1, 'Time delay must be positive or 0'),
            unit: string(),

        }
    ))
});
