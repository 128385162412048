import React from 'react';
import {Box, FormField, Paragraph, Text, TextArea, TextInput} from "grommet";
import * as Yup from "yup";
import {Form, Formik} from "formik";

export const TextAreaInputField = ({title, placeholder, id, handleChange, value, error, disabled, preview}) => {
    return (
        <Box gap='xsmall' fill>
            <Text color='gray'>{title}</Text>
            {preview ? <Box fill pad='xsmall' border round='xsmall'><Paragraph fill>{value}</Paragraph></Box> : <TextArea disabled={disabled} placeholder={placeholder} fill value={value} name={id} onChange={handleChange}/>}
        </Box>
    )
};
// FormField creates an inner div whose style cannot be edited
// export const TextAreaInputField = ({title, placeholder, id, handleChange, value, error, disabled}) => {
//     return (
//         <FormField label={title} error={error}>
//             <TextArea disabled={disabled} placeholder={placeholder} fill value={value} name={id} onChange={handleChange}/>
//         </FormField>
//     )
// };

TextAreaInputField.defaultSize = {
    w: 2,
    h: 2,
    minW: 2,
    minH: 2
};

TextAreaInputField.title = 'Text Area';

const TextAreaInputValidation = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
    placeholder: Yup.string()
});

export const TextAreaInputFieldProperties = ({title, placeholder, updateField, id, item}) => {
    return (

        <Formik
            // same as onChange. Formik calls both validate and validationSchema.
            validate={(values) => {
                updateField(id, values)
            }}
            initialValues={{title, placeholder}}
            validationSchema={TextAreaInputValidation}
            enableReinitialize
            onSubmit={() => {
            }}
        >
            {({errors, values, handleChange}) => (
                <Form>
                    <FormField label="Title" error={errors.title}>
                        <TextInput
                            name="title"
                            value={values.title || ""}
                            onChange={handleChange}
                        />
                    </FormField>

                    <FormField label="Placeholder" error={errors.placeholder}>
                        <TextInput
                            name="placeholder"
                            value={values.placeholder || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                </Form>
            )
            }
        </Formik>
    )}