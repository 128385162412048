import React from 'react';
import {withRouter} from "react-router-dom";
import gql from 'graphql-tag';
import {useQuery} from "react-apollo";
import Spinner from "../../components/Spinner";
import SurveyTemplatePage from "./SurveyTemplatePage"; // TODO: Use hooks here

const TEMPLATE = gql`
    query ($id: Int!) {
        survey_template_by_pk(id: $id) {
            created_at
            id
            questions
            subtitle
            title
            updated_at
        }
    }
`;

const EditSurveyTemplatePage = ({match}) => {
    const id = match.params?.id;
    const {data, loading} = useQuery(TEMPLATE, {variables: {id}});
    if (loading) {
        return <Spinner fill/>;
    }
    return <SurveyTemplatePage pageTitle='Edit Template' template={data.survey_template_by_pk} />
};

export default withRouter(EditSurveyTemplatePage);
