import React from 'react'
import {colors} from "../../theme";

const Bell = ({active = false, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...rest}>
        <title>{'bell'}</title>
        <path
            d="M9.508 24c-.228 0-.456-.031-.685-.094a2.9 2.9 0 01-.64-.258 2.484 2.484 0 01-.845-.703 2.682 2.682 0 01-.48-.984.718.718 0 01.057-.574c.1-.18.24-.301.423-.364.03 0 .065-.003.103-.011A.517.517 0 017.544 21c.167 0 .316.05.445.152.13.102.217.23.263.387.046.172.122.328.228.469.107.14.236.25.389.328.106.047.213.086.32.117.106.031.213.047.32.047a1.435 1.435 0 00.365-.047c.213-.062.396-.176.548-.34.153-.164.26-.355.32-.574a.725.725 0 01.274-.387.718.718 0 01.434-.152c.03 0 .065.004.103.012a.517.517 0 00.103.011.747.747 0 01.445.364c.1.18.118.37.058.574-.138.469-.37.871-.697 1.207-.328.336-.72.574-1.177.715-.137.047-.27.078-.4.094-.13.015-.255.023-.377.023zM.736 19.5a.69.69 0 01-.4-.117.773.773 0 01-.263-.305.716.716 0 01-.068-.398.956.956 0 01.137-.399c.015 0 .24-.566.674-1.699.434-1.133.65-3.16.65-6.082 0-1.078.183-2.094.549-3.047a8.511 8.511 0 011.554-2.531 8.238 8.238 0 012.307-1.805 8.33 8.33 0 012.901-.844V.75c0-.203.069-.379.206-.527A.683.683 0 019.508 0c.198 0 .37.074.514.223a.73.73 0 01.218.527v1.523c1.02.11 1.98.391 2.878.844a8.238 8.238 0 012.308 1.805 8.511 8.511 0 011.553 2.531c.38.953.571 1.969.571 3.047 0 3.156.202 5.227.606 6.21.403.985.605 1.485.605 1.5a.703.703 0 01.217.364.654.654 0 01-.034.434.635.635 0 01-.263.363.714.714 0 01-.4.129H.736zM17.025 18c-.244-.61-.461-1.508-.651-2.695-.19-1.188-.286-2.79-.286-4.805 0-.938-.175-1.812-.525-2.625a7.138 7.138 0 00-1.417-2.145 6.384 6.384 0 00-2.079-1.44 6.221 6.221 0 00-2.559-.54c-.913 0-1.766.18-2.558.54a6.32 6.32 0 00-2.102 1.44A7.138 7.138 0 003.43 7.875 6.817 6.817 0 002.93 10.5c0 1.984-.114 3.578-.343 4.781-.228 1.203-.457 2.11-.685 2.719h15.124z"
            fill={active ? colors.brand : colors.sidebarInactive}
            fillRule="nonzero"
        />
    </svg>
)

export default Bell
