import React, {useState} from 'react';
import Page from "../../components/Page";
import {Text, Box, CheckBox, TextArea, Button} from "grommet";
import {Form, Formik} from "formik";
import {LoadingButton} from "../../components/LoadingButton";
import {Link, withRouter} from "react-router-dom";
import {useChat} from "../../lib/useChat";
import MessengerErrorPage from "./MessengerErrorPage";
import Spinner from "../../components/Spinner";
import {CaseloadClientsSelect} from "./components/CaseloadClientsSelect";
import API from "../../services/api";

const SESSION_FIELDS = `{
        id
        client {
            id
            user {
                id
                first_name
                last_name
            }
        }
        provider_session {
            twilio_channel_sid
        }
    }`;

const initialValues = {
    chat: true,
    email: false,
    sms: false,
    body: ''
};

// TODO: Currently if you are a manager you can see all company sessions but you can only send messages to sessions that are assigned to you
const BroadcastMessagePage = ({history}) => {
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [sending, setSending] = useState(false);
    const {loading, error, initialize, channels} = useChat(); // TODO: Causes a lot of rerenders. This needs to be optimized
    if (error) {
        return (<MessengerErrorPage initialize={initialize}/>);
    }

    const sendMessage = async (session, body, email, sms) => {
        if (!session.provider_session) {return;}
        const channel = channels[session.provider_session.twilio_channel_sid];
        if (!channel) {return;}
        const index = await channel.sendMessage(body);
        await channel.advanceLastConsumedMessageIndex(index);
        try {
            await API.post('/session/send-message', {
                channelSid: session.provider_session.twilio_channel_sid,
                message: body
            }, {params: {email, sms}});
        } catch (e) {
            // TODO: Show clients that did not receive email or sms
        }
    };

    return (
        <Page title="Broadcast Message">
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    setSending(true);
                    await Promise.all(selectedSessions.map((s) => sendMessage(s, values.body, values.email, values.sms)));
                    setSending(false);
                    history.push('/messenger');
                }}
            >
                {({setFieldValue, values, handleChange, handleSubmit}) => (
                    <Box gap="small">
                        <Box elevation='xsmall' background='white' round="xsmall" pad='medium' gap="medium" flex="grow"
                             height={{min: "small"}}>
                            {loading ? <Spinner fill/> : <Form>
                                <Text>Message Type</Text>
                                <Box direction="row" gap="large" margin={{top: "small"}}>
                                    <CheckBox
                                        checked={values.chat}
                                        label="Chat"
                                        name="chat"
                                        readOnly
                                    />
                                    <CheckBox
                                        checked={values.sms}
                                        label="SMS"
                                        name="sms"
                                        onChange={(event) => {
                                            setFieldValue('sms', event.target.checked)
                                        }}
                                    />
                                    <CheckBox
                                        checked={values.email}
                                        label="Email"
                                        name="email"
                                        onChange={(event) => {
                                            setFieldValue('email', event.target.checked)
                                        }}
                                    />
                                </Box>
                                <Box margin={{top: "medium"}} gap="small">
                                    <Text>Message Body</Text>
                                    <TextArea
                                        placeholder="Write your message here..."
                                        name="body"
                                        value={values.body}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Form>}
                        </Box>
                        <Text weight="bold">Audience</Text>
                        <CaseloadClientsSelect
                            onChange={setSelectedSessions}
                            fields={SESSION_FIELDS}
                        />
                        <Box flex={false} pad={{bottom: "small"}} direction="row" gap="small">
                            <LoadingButton label="Send" type="submit" onClick={handleSubmit} primary loading={sending}/>
                            <Link to='/messenger'><Button primary label='Cancel' color='lightgray'/></Link>
                        </Box>
                    </Box>
                )}
            </Formik>
        </Page>
    )
};

export default withRouter(BroadcastMessagePage);
