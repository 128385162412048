import React from 'react'

const GeneralError = props => (
    <svg width="100%" height="100%" viewBox="0 0 316 316" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <circle fill="#E4E8EB" cx={158} cy={158} r={158} />
                <path
                    stroke="#819DB6"
                    strokeWidth={5}
                    fill="#EEF0F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M121.5 28.808v-9.616M126 24h-9"
                />
                <path
                    stroke="#C7D2DB"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M85.1 90.4l6.8-6.8M91.682 90.182l-6.364-6.364M255.1 80.4l6.8-6.8M261.682 80.182l-6.364-6.364M44.1 216.4l6.8-6.8M50.682 216.182l-6.364-6.364M83.5 272.808v-9.616M88 268h-9"
                />
                <path
                    stroke="#819DB6"
                    strokeWidth={5}
                    fill="#EEF0F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M29 147.654v-17.308M37.5 139.25h-17M212.5 52.808v-9.616M217 48h-9M292.5 162.808v-9.616M297 158h-9M242.5 262.808v-9.616M247 258h-9"
                />
                <path
                    stroke="#C7D2DB"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M183.5 292.808v-9.616M188 288h-9"
                />
            </g>
            <path
                stroke="#819DB6"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M97 162H69M96 189l-14 14M96 134l-14-14M220 162h28M221 189l14 14M221 134l14-14"
            />
            <path
                d="M124 134.706V93.412c.061-18.98 15.696-34.352 35-34.412 19.307.055 34.944 15.43 35 34.412v48.176c-.039 13.667-8.272 26.026-21 31.521-4.411 1.912-12 2.891-14 2.891-23.333 0-35-13.765-35-41.294z"
                fill="#EEF0F4"
            />
            <path
                d="M194 182.375v41.25c-.051 18.964-15.691 34.325-35 34.375-19.307-.055-34.944-15.413-35-34.375v-41.25c.061-18.96 8-34.375 35-34.375 23.333 0 35 11.458 35 34.375z"
                fill="#EEF0F4"
            />
            <path
                d="M124 134.706V93.412c.061-18.98 15.696-34.352 35-34.412h0c19.307.055 34.944 15.43 35 34.412v48.176c-.039 13.667-8.272 26.026-21 31.521A35.124 35.124 0 01159 176"
                stroke="#819DB6"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M194 182.375v41.25c-.051 18.964-15.691 34.325-35 34.375h0c-19.307-.055-34.944-15.413-35-34.375v-41.25c.061-18.96 15.696-34.315 35-34.375"
                stroke="#819DB6"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
)

export default GeneralError
