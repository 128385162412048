import React from 'react';
import {Form} from "formik";
import {Box, Button, Text} from "grommet";
import {Lock, User} from "grommet-icons";
import {TextField} from "./TextField";
// import {LoadingButton} from "../../../components/LoadingButton";

export const LoginForm = ({values, errors, handleChange, status, touched, isSubmitting, loading}) => (
    <Form>
        <TextField name='email'
                   type='email'
                   icon={User}
                   value={values.email}
                   handleChange={handleChange}
                   placeholder='Email'
                   error={errors.email}
        />

        <TextField name='password'
                   type='password'
                   icon={Lock}
                   value={values.password}
                   handleChange={handleChange}
                   placeholder='Password'
                   error={errors.password}
        />

        {status && <Box margin={{bottom: "medium"}} width='small'>
            <Text color="lightgray">{status.message}</Text>
        </Box>}

        <Box direction='row-responsive'>
            {/*<LoadingButton type="submit" fill primary loading={loading} label="Login"/>*/}
            <Button type="submit" fill primary label="Login" disabled={isSubmitting}/>
        </Box>
    </Form>
);
