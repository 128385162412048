import React, {useEffect, useState} from 'react';
import {Box, Button, Layer, Text} from "grommet";
import {useInterval} from "../lib/useInterval";
import LogoutIcon from "../components/icons/LogoutIcon";

export const INACTIVE_MINUTES_TIMEOUT = 30;
const SECONDS_TIMEOUT = 60;

export const UserInactiveModal = ({onClose = () => {}, onTimeout = () => {}}) => {
    const [secondsCounter, setSecondsCounter] = useState(SECONDS_TIMEOUT);

    useEffect(() => {
        secondsCounter === 0 && onTimeout();
    }, [onTimeout, secondsCounter]);

    useInterval(() => {
        setSecondsCounter(counter => counter - 1);
    }, 1000);

    return (
        <Layer position="center" modal onClickOutside={onClose} onEsc={onClose}>
            <Box pad="medium" gap="small" width="medium" align="center">
                <LogoutIcon/>
                <Text weight="bold">Are you there?</Text>
                <Text>You will soon be logged out due to inactivity.</Text>
                <Box
                    as="footer"
                    direction="row"
                    pad="medium"
                    fill
                    align="center"
                >
                    <Button
                        fill
                        label={
                            <Text weight="bold" color="white">Stay Logged In</Text>
                        }
                        onClick={onClose}
                        primary
                    />
                </Box>
            </Box>
        </Layer>
    )
}
