import React, {useState} from 'react';
import {Box} from "grommet";
import {ClientsList} from "./ClientsList";
import {SearchField} from "../../../components/SearchField";
import {useChat} from "../../../lib/useChat";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

const FETCH_ACTIVE_CLIENT_SESSIONS = gql`
    query ($provider_id: Int!) {
        id @client @export(as: "provider_id")
        provider_session (where: {_and: [{provider_id: {_eq: $provider_id}}, {session: {status_id: {_eq: 1}}}]}) {
            twilio_channel_sid
        }
    }
`;

export const ClientsSidebar = ({activeChannelSid, onChannelSelect = () => {}}) => {
    const [activeChannels, setActiveChannels] = useState([]);
    const {loading: sessionsLoading} = useQuery(FETCH_ACTIVE_CLIENT_SESSIONS, {
        onCompleted: data => setActiveChannels(data.provider_session.map(ps => ps.twilio_channel_sid))
    });
    const {channels, order, lastMessages, loading} = useChat();
    const [filter, setFilter] = useState('');
    return (
        <Box elevation='xsmall' background='white' round="xsmall" fill>
            <SearchField containerProps={{pad: 'small', border: {side: 'bottom'}}}
                         placeholder='SEARCH CLIENTS'
                         onChange={(searchTerm) => setFilter(searchTerm.toLowerCase())}
                         textInputProps={{size: 'large'}}
            />
            <ClientsList
                channels={order
                    .filter(sid => activeChannels.includes(sid))
                    .map(sid => channels[sid])
                    .filter(channel =>
                        channel.friendlyName &&
                        channel.friendlyName.toLowerCase().split(' - ')[1].includes(filter))
                }
                activeChannelSid={activeChannelSid}
                lastMessages={lastMessages}
                onChannelSelect={onChannelSelect}
                loading={loading || sessionsLoading}
            />
        </Box>
    );
};
