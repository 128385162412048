import React, {useState} from 'react';
import Page from "../../components/Page";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {Formik} from "formik";
import TreatmentPlanForm from "./components/TreatmentPlanForm";
import {Box, FormField, Select, TextInput} from "grommet";
// import TxPlanTagSelector from "./components/TxPlanTagSelector";
import Spinner from "../../components/Spinner";
import {FETCH_OPTIONS_DATA} from "./CreateTreatmentPlanPage";
import useTxPlanUpdater from "./utils/useTxPlanUpdater";
import {Notification} from "../../components/Notification";
import RequiredSignaturesSelector from "./components/RequiredSignaturesSelector";
import ConfirmationButton from "../../components/ConfirmationButton";
import {useParams} from "react-router-dom";


const FETCH_DATA = gql`
query fetchTxPlanById($txPlanId: Int!) {
  tx_plan: tx_plan_by_pk(id: $txPlanId) {
    id
    created_at
    name
    status {
      id
      name
    }
    tx_plan_tags: tags {
      tag: tx_plan_tag {
        id
        name
      }
    }
    signatures: tx_signatures(order_by: {order: asc}) {
      id
      provider_id
      order
      datetime
      sign_path_data
      ip
      provider {
        id
        user {
          id
          first_name
          last_name
        }
      }
    }
    problems(order_by: {order: asc}) {
      id
      name
      goals
      created_at
      order
      related_to_and_evidenced_by
      objectives(order_by: {order: asc}) {
        id
        description
        created_at
        order
        interventions(order_by: {order: asc}) {
          id
          created_at
          description
          order
          target_date
          resolved_date
          status {
            id
            name
          }
          measurements(order_by: {measurement_survey_template: {abbreviation: asc}}) {
            measurement_survey_template {
              id
              name: abbreviation
            }
          }
        }
      }
    }
  }
}
`

const transformSignatures = (signatures) => {
    return signatures.map(signature => {

        return {
            id: signature.provider_id,
            name: `${signature.provider.user.first_name} ${signature.provider.user.last_name}`,
            fullName: `${signature.provider.user.first_name} ${signature.provider.user.last_name}`,
        }

    })
}

const EditTreatmentPlansPage = () => {
    const {treatment_plan_id: txPlanId} = useParams();
    const {data: optionsData, loading: optionDataLoading} = useQuery(FETCH_OPTIONS_DATA)
    const {data, loading} = useQuery(FETCH_DATA, {variables: {txPlanId}, fetchPolicy: 'no-cache'});
    const [updateTxPlan, updateTxPlanLoading] = useTxPlanUpdater()

    const [notification, setNotification] = useState(undefined)

    if (loading || optionDataLoading || updateTxPlanLoading) {
        return <Spinner/>
    }

    const {tx_plan} = data;

    const initialValues = {
        "id": tx_plan.id,
        "name": tx_plan.name,
        "created_at": tx_plan.created_at,
        "tags": tx_plan.tx_plan_tags.map(({tag: {id, name}}) => {
            return {id, name}
        }),
        "status": tx_plan.status || undefined,
        "signatures": transformSignatures(tx_plan.signatures),
        "problems": tx_plan.problems.map(problem => {
            return {
                "id": problem.id,
                "name": problem.name,
                "related_to_and_evidenced_by": problem.related_to_and_evidenced_by,
                "goals": problem.goals,
                "created_at": problem.created_at,
                "order": problem.order,
                "objectives": problem.objectives.map(objective => {
                    return {
                        "id": objective.id,
                        "description": objective.description,
                        "created_at": objective.created_at,
                        "order": objective.order,
                        "interventions": objective.interventions.map(intervention => {
                            return {
                                "id": intervention.id,
                                "description": intervention.description,
                                "created_at": intervention.created_at,
                                "order": intervention.order,
                                "target_date": intervention.target_date || undefined,
                                "resolved_date": intervention.resolved_date || undefined,
                                "status": intervention.status || undefined,
                                "measurements": intervention.measurements.map(({measurement_survey_template: {id, name}}) => {
                                    return {id, name}
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    return (
        <Page>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    await updateTxPlan(values)
                    setNotification({
                        message: "The TX Plan was successfully updated",
                        color: 'green'
                    })
                }}>
                {(props) =>

                        <Box fill pad={{right: 'medium'}} flex='grow' gap='medium'>
                            <Box direction='row' gap='medium' flex={false}>
                                    <Box background='white'
                                         elevation='xsmall'
                                         fill='horizontal'
                                         round="xsmall"
                                         pad='20px 15px'
                                    >
                                                <FormField label='Document Name'>
                                                    <TextInput name={'name'} value={props.values.name}
                                                               onChange={props.handleChange}/>
                                                </FormField>

                                                <FormField label='Status'>
                                                    {/*<TextInput name={'status'} value={props.values.status}*/}
                                                    {/*           onChange={props.handleChange}/>*/}

                                                    <Select
                                                        valueKey="id"
                                                        labelKey="name"
                                                        options={optionsData.txPlanStatuses || []}
                                                        value={props.values.status}
                                                        onChange={({option}) => props.setFieldValue(`status`, option)}
                                                    />
                                                </FormField>
                                    </Box>

                                    <Box background='white'
                                         elevation='xsmall'
                                         fill='horizontal'
                                         round="xsmall"
                                         pad='20px 15px'
                                         direction='column'
                                         gap='small'>
                                        <RequiredSignaturesSelector
                                            value={props.values.signatures}
                                            onChange={(signatures) => props.setFieldValue('signatures', signatures)}
                                        />
                                    </Box>
                            </Box>
                            <Box fill='horizontal' height='fit-content' pad={{bottom: 'medium'}}>
                                    <TreatmentPlanForm {...props}
                                                       interventionStatusOptions={optionsData.interventionStatuses}
                                                       interventionMeasurementsOptions={optionsData.measurementOptions}
                                    />

                                <Box align="start" pad={{left: "small"}} flex={false} direction='row' gap='small'>
                                    <ConfirmationButton label='Save Tx Plan'
                                                        onAccept={props.handleSubmit}
                                                        text="Are you sure you want to update the TX Plan? All signatures would be reset!"
                                                        acceptLabel="Save"
                                                        acceptButtonColor='brand'
                                                        primary
                                    />
                                </Box>
                            </Box>
                        </Box>

                }
            </Formik>

            <Notification content={notification?.message}
                          color={notification?.color}
                          isVisible={notification}
                          position='bottom'
            />
        </Page>
    )
}

export default EditTreatmentPlansPage
