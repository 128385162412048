import React from 'react';
import {Box, DataTable, Text} from "grommet";
import {Mastercard, Visa} from "grommet-icons";
import RoutedButton from "../../components/RoutedButton";
import {DeleteButton} from "../../components/DeleteButton";
import gql from "graphql-tag";

export const cardBrandIcons = {
    'Visa': Visa,
    'MasterCard': Mastercard
};

const DELETE_PAYMENT_METHOD = gql`
    mutation delete_payment_method($id: String!) {
        delete_payment_method(id: $id) {
            Result
            Error
        }
    }
`;

const columns = [
    {
        header: 'Name',
        property: 'Name',
    },
    {
        header: 'Credit/Debit Card',
        property: 'MaskedNumber',
        render: datum => {
            const CardIcon = cardBrandIcons[datum?.CardType || 'Visa'];
            return (<Box direction='row'><CardIcon/><Text size='16px'>{datum.MaskedNumber}</Text></Box>)
        }
    },
    {
        header: 'Expiration Date',
        property: 'Exp',
        render: datum => datum.Exp.slice(0, 2) + '/' + datum.Exp.slice(2)
    }
];

export const PaymentMethods = ({paymentMethods}) => {
    return (
        <Box background='white' round="xsmall" elevation="xsmall" pad='medium' justify='between' gap='medium'>
            <Text>Payment Methods</Text>
            <DataTable
                data={paymentMethods}
                primaryKey="PaymentMethodId"
                columns={paymentMethods.length > 0 ? columns.concat({
                    property: 'Delete',
                    header: null,
                    render: datum => <DeleteButton
                        text='Are you sure you want to delete this payment method?'
                        query={DELETE_PAYMENT_METHOD}
                        queriesToRefetch={['fetchPaymentData']}
                        variables={{id: datum.PaymentMethodId}}
                        iconProps={{width: '16px', height: '16px', active: true}}
                        buttonProps={{plain: true}}
                    />
                }) : columns}
            />
            <RoutedButton alignSelf='start' label='Add Payment Method' path={`/billing/payment-method/create`}/>
        </Box>
    );
}