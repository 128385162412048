import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Box, Text} from "grommet";
import RoutedAnchor from "../../components/RoutedAnchor";
import {SignUpForm} from "./components/SignUpForm";
import {requestSignUp} from "../../services/auth/service";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import {useMutation} from "@apollo/react-hooks";
import {LOGIN} from "./LoginPage";
import {Layout} from "./Layout";
import {useHistory} from "react-router-dom";
import YupPassword from "yup-password";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

YupPassword(Yup)

const SignUpSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required').matches(phoneRegExp, 'Phone number is not valid'),
    password: Yup.string()
        .minNumbers(1, 'Must contain at least 1 number')
        .minLowercase(1, 'Must contain at least 1 lowercase character')
        .minUppercase(1, 'Must contain at least 1 uppercase character')
        .minSymbols(1, 'Must contain at least 1 special symbol')
        .min(8, 'Must contain at least 8 characters')
        .max(64, 'Must be shorter than 64 characters')
        .required('Required'),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required('Password repeat is required'),
    acceptTerms: Yup.boolean()
        .oneOf([true], 'Please accept the terms of use')
        .required()
});

const SignUpPage = ({match}) => {
    const {email, token} = match.params;
    const history = useHistory();
    const [localLogin] = useMutation(LOGIN);

    return (
        <Layout header='Sign up for an account'>
            <Box align="center">
                <Box
                    height={{min: '600px'}}
                    background="white"
                    round="small"
                    border
                    elevation='xsmall'
                    pad="34px"
                >
                    <Formik
                        initialValues={{
                            email: email,
                            password: '',
                            passwordRepeat: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            acceptTerms: false
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={async (values, actions) => {
                            try {
                                // const message = await requestSignUp(token, values);
                                await requestSignUp(token, values);
                                await localLogin({variables: {email, password: values.password}});
                                history.push('/');
                                // actions.resetForm();
                                // actions.setStatus(message);
                            } catch (e) {
                                SagaErrorHandler.handle(e.networkError || e, actions);
                            }
                        }}
                        render={props => <SignUpForm {...props} />}
                    />
                </Box>
                <Text margin={{top: "medium"}} color="lightgray">
                    Already have an account?
                    <RoutedAnchor path='/login' label=' Log in here.'/>
                </Text>
            </Box>
        </Layout>
    );
};

export default SignUpPage;
