import React from 'react';
import {Box, FormField, Select, Text, TextInput} from "grommet";
import {CheckBox, TextArea} from "grommet";
import {safeGet} from "../../../utils";

export const EmailTypeFields = ({values, errors, handleChange, index, disabled}) => (

    <Box gap='medium' >
        <Box direction='row'>
            <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight':'12px'}}>SUBJECT</Text>
            <Text color='darkgray' size='22px' weight='bold' style={{'lineHeight':'0', 'padding':'0 5px 0 5px'}}> . </Text>
            <Text color='darkgray' size='14px' style={{'lineHeight':'12px'}}>Enter the subject of the email</Text>
        </Box>
        <FormField error={safeGet(() => errors.actions[index].subject)}>
            <TextInput name={'actions[' + index + '].subject'}
                       value={values.actions[index].subject}
                       onChange={handleChange}
                       disabled={disabled}
            />
        </FormField>
        <Box direction='row'>
            <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight':'12px'}}>BODY</Text>
            <Text color='darkgray' size='22px' weight='bold' style={{'lineHeight':'0', 'padding':'0 5px 0 5px'}}> . </Text>
            <Text color='darkgray' size='14px' style={{'lineHeight':'12px'}}>Enter the body of the email</Text>
        </Box>
        <FormField error={safeGet(() => errors.actions[index].body)}>
            <TextArea placeholder="Enter your message"
                      name={'actions[' + index + '].body'}
                      value={values.actions[index].body}
                      onChange={handleChange}
                      disabled={disabled}
            />
        </FormField>
    </Box>

);

export const NotificationTypeFields = ({values, errors, handleChange, index, disabled=false}) => (

    <Box gap='medium'>
        <Box direction='row'>
            <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight':'12px'}}>MESSAGE</Text>
            <Text color='darkgray' size='22px' weight='bold' style={{'lineHeight':'0', 'padding':'0 5px 0 5px'}}> . </Text>
            <Text color='darkgray' size='14px' style={{'lineHeight':'12px'}}>Send a message</Text>
        </Box>
        <Box direction='row' gap='small'>
        <CheckBox label='SMS'
                  name={'actions[' + index + '].sms'} c
                  hecked={values.actions[index].sms}
                  onChange={handleChange}
                  disabled={disabled}
        />
        <CheckBox label='Chat'
                  name={'actions[' + index + '].chat'}
                  checked={values.actions[index].chat}
                  onChange={handleChange}
                  disabled={disabled}
        />
        </Box>
        <FormField error={safeGet(() => errors.actions[index].message)}>
            <TextArea placeholder="Enter your message"
                      name={'actions[' + index + '].message'}
                      value={values.actions[index].message}
                      onChange={handleChange}
                      disabled={disabled}
            />

        </FormField>
    </Box>

);

export const SurveyTypeFields = ({values, errors, handleChange, index, options, setFieldValue, disabled}) => (
    <Box gap='medium' justify='start'>
        <Box direction='row'>
            <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight':'12px'}}>SURVEY</Text>
            <Text color='darkgray' size='22px' weight='bold' style={{'lineHeight':'0', 'padding':'0 5px 0 5px'}}> . </Text>
            <Text color='darkgray' size='14px' style={{'lineHeight':'12px'}}>Select the survey you want to send</Text>
        </Box>
        <Box justify='start'>
            <FormField error={safeGet(() => errors.actions[index].survey.id)}>
                <Select
                    name={'actions[' + index + '].survey'}
                    labelKey="title"
                    valueKey="id"
                    options={options}
                    value={values.actions[index].survey}
                    onChange={({option}) => {
                        setFieldValue('actions[' + index + '].survey', option)
                    }}
                    disabled={disabled}
                />
            </FormField>
        </Box>
    </Box>

);
