import React from "react";
import {Avatar as AvatarImage} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "./Spinner";

const FETCH_AVATAR = gql`
    query ($id: Int){
        avatar(id: $id) {
            id
            url
        }
    }
`;

const Avatar = ({id, usePlaceholder = false, ...rest}) => {
    const {data, loading} = useQuery(FETCH_AVATAR, {variables: {id}, skip: usePlaceholder});
    if (loading) return <Spinner/>;
    if (data) return <AvatarImage src={data.avatar?.url} {...rest}/>;
    return <AvatarImage src={process.env.REACT_APP_DEFAULT_AVATAR} {...rest}/>;
};

export default Avatar;
