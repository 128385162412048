import React, {useState, useEffect} from "react";
import {FormClose} from "grommet-icons";

import {Box, Button, Layer, Text} from "grommet";

export const Notification = ({content, color, isVisible = false, position='bottom-right', textColor='white'}) => {
    const [open, setOpen] = useState(isVisible);
    useEffect(() => setOpen(isVisible), [isVisible]);

    const onClose = () => setOpen(false);
    return (
        <React.Fragment>
            {open && (
                <Layer
                    position={position}
                    modal={false}
                    margin={{vertical: "xsmall", horizontal: "small"}}
                    onEsc={onClose}
                    responsive={false}
                    plain
                >
                    <Box
                        align="center"
                        direction="row"
                        gap="small"
                        justify="between"
                        round="small"
                        elevation="medium"
                        pad={{vertical: "small", horizontal: "small"}}
                        background={color}
                    >
                        <Text color={textColor}>{content}</Text>
                        <Button icon={<FormClose/>} onClick={onClose} plain/>
                    </Box>
                </Layer>
            )}
        </React.Fragment>
    );
};
