import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, FormField, Text} from "grommet";
import {useFormikContext} from "formik";
import dot from "dot-object";
import Spinner from "../../../../components/Spinner";
import {Close, FormCheckmark} from "grommet-icons";

export const AutosaveFormField = ({name, label, children, value}) => {
    const { values, submitForm, errors, handleBlur, isValid, dirty } = useFormikContext();
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const fieldValue = useMemo(() => value !== undefined ? value : dot.pick(name, values) || '', [value, values, name]);
    const submit = useCallback(async () => {
        setLoading(true);
        try {
            await submitForm();
            setLoading(false);
            setStatus(true);
        } catch (e) {
            setLoading(false);
            setStatus(false);
        }
    }, [submitForm]);

    useEffect(
        () => {
            if (status === null) {
                return;
            }
            let timer = setTimeout(() => setStatus(null), 1000);
            return () => {
                clearTimeout(timer);
            };
        }, [status]);

    const heading = useMemo(() => (
        <Box direction='row' justify='between'>
            <Text>{label}</Text>
            {loading && <Spinner height='16px'/>}
            {status !== null && (status ? <FormCheckmark color='green'/> : <Close color='red'/>)}
        </Box>
    ), [label, loading, status])

    return (
        <FormField label={heading} error={dot.pick(name, errors)}>
            {React.cloneElement(React.Children.only(children), {
                name,
                onBlur: async (event) => {
                    handleBlur(event);
                    if (dirty && isValid) {
                        await submit();
                    }
                },
                value: fieldValue,

            })}
        </FormField>
    );
}