import React from 'react';
import moment from "moment";
import {Form, Formik} from "formik";
import {CREATE_MEETING, initialValues, MeetingValidationSchema} from "../../Communication/CreateMeetingPage";
import {useHistory, useParams} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {MeetingDetailsForm} from "../../Communication/components/MeetingDetailsForm";
import {LoadingButton} from "../../../components/LoadingButton";
import {MeetingTypeLabels} from "../../../lib/constants";

export const CreateMeeting = ({closeModal, session}) => {
    const {session_id, id} = useParams();
    const history = useHistory();
    const [createMeeting, {loading, error}] = useMutation(CREATE_MEETING);
    return (
        <Formik
            initialValues={{
                ...initialValues,
                participant: session_id,
                name: `${MeetingTypeLabels[initialValues.type_id]}-${session.client.user.first_name} ${session.client.user.last_name.at(0)}.`,
        }}
            validationSchema={MeetingValidationSchema}
            onSubmit={async (values, actions) => {
                const {date, participant, start_at, end_at, duration, billing_code_id, ...data} = values;
                const dateString = moment(values.date).format('l');
                const startTime = moment(`${dateString} ${start_at}`, 'l h:mm a');
                const endTime = moment(startTime).add(duration, 'minutes');
                const meeting = {
                    ...data,
                    billing_code_id: billing_code_id || null,
                    start_at: startTime.format(),
                    end_at: endTime.format(),
                    meeting_participants: {
                        data: [{session_id: values.participant}]
                    }
                };
                try {
                    await createMeeting({variables: {meeting}, refetchQueries: ['fetchAppointments']});
                    history.replace(`/clients/${id}/${session_id}/appointments`);
                    closeModal();
                } catch (e) {
                    actions.setSubmitting(false);
                }
            }}
        >
            {(props) => <Form>
                <MeetingDetailsForm {...props} error={error}/>
                <LoadingButton margin={{left: 'medium', bottom: 'medium'}} alignSelf='start' primary label="Save" type="submit" loading={loading || props.isSubmitting}/>
            </Form>}
        </Formik>
    );
}