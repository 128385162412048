import React, {forwardRef, useCallback} from 'react';
import {Box, Heading, Text} from "grommet";
import {PrintPage} from "../../PrintPage";
import {InformationRibbon} from "./InformationRibbon";
import {Field} from "../../Settings/DocumentBuilder/components/Field";
import _ from "lodash";
import {Responsive, WidthProvider} from "react-grid-layout";
import moment from "moment";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const defaultSettings = {
    className: "layout",
    rowHeight: 50,
    isDraggable: false,
    isResizable: false,
    // cols: {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
};


export const DocumentPrintPage = forwardRef(({data, fields, fieldsData, formik}, ref) => {
    const generateDOM = useCallback(() => {
        return _.map(fields, (l, i) => {
            // console.log('field', l, fieldsData[l.i]);
            return (
                <Box
                    key={l.i}
                    data-grid={l}
                    round='xsmall'
                    flex={false}
                >
                    <Box pad='small' fill>
                        <Field {...fieldsData[l.i]} id={l.i} formik={formik} preview/>
                    </Box>
                </Box>
            );
        });
    }, [fieldsData, fields, formik]);
    return (
        <Box style={{ overflow: 'hidden', height: '0'}}>
            <PrintPage ref={ref} gap='small'>
                <Box>
                    <Heading level={3} margin={{vertical: 'none'}}>{data?.document?.title}</Heading>
                    <Text>
                        Created
                        on {moment(data.document?.created_at).format('DD MMM YYYY [at] HH:mm')} by {data.document.provider.user.first_name} {data.document.provider.user.last_name}
                    </Text>
                </Box>
                <InformationRibbon document={data.document}/>
                <ResponsiveReactGridLayout
                    {...defaultSettings}
                    layouts={{lg: fields}}
                    breakpoints={{lg: 996, md: 800, sm: 700, xs: 600, xxs: 500}}
                    cols={{lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
                    isDraggable={false}
                    isResizable={false}
                    measureBeforeMount={false}
                    compactType='horizontal'
                >
                    {generateDOM()}
                </ResponsiveReactGridLayout>
            </PrintPage>
        </Box>
    )
});