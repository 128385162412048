import React from 'react';
import {Box, Grid, Text, ResponsiveContext} from "grommet";
import {Print} from 'grommet-icons';
import {Button} from "grommet";
import ReactToPrint from "react-to-print";
import {SessionInfoHeader} from "./SessionInfoHeader";

const pageTitle = document.title;

export const IndividualMeasurementReportHeader = ({reportName, session, printRef, ...rest}) => (
        <ResponsiveContext.Consumer>
            {(deviceSize) => (
                <Grid
                    align="start"
                    columns={deviceSize === 'small' ? 'auto' : ["1/3", "2/3"]}
                >
                    <Box
                        gap="small"
                        justify="center"
                        border={deviceSize !== "small" ? [{side: "left"}, {side: "horizontal"}] : [{side: "top"}, {side: "vertical"}]}
                        fill
                        round={{size: "xsmall", corner: deviceSize === "small" ? "top" : "left"}}
                        pad={{horizontal: "medium", vertical: "medium"}}
                        {...rest}>
                        <Text weight='bold' color='dark'>Measurement</Text>
                        <Text color='dark'>{reportName}</Text>
                    </Box>
                    <Box direction='row'
                         border
                         background='white'
                         fill
                         pad={{horizontal: "medium", vertical: "large"}}
                         round={{size: "xsmall", corner: deviceSize === "small" ? "bottom" : "right"}}
                         justify="between"
                         align="center"
                         gap="small"
                    >
                        <SessionInfoHeader session={session} {...rest}/>
                        <ReactToPrint trigger={() => <Button plain icon={<Print/>}/>}
                                      content={() => printRef.current}
                                      onBeforePrint={() => {
                                          let date = new Date().toLocaleDateString('us-US');
                                          let lastNameInitial = session.client.user.last_name.charAt(0);
                                          document.title = `${date}_${session.client.user.first_name}_${lastNameInitial}`
                                      }}
                                      onAfterPrint={() => {
                                          document.title = pageTitle;
                                      }}
                        />
                        {/*<Download color='gray' size='20px'/>*/}
                    </Box>
                </Grid>
            )}
        </ResponsiveContext.Consumer>
    )
;
