import React from 'react';
import {Box, Meter, Text} from "grommet";
import {Filter} from "grommet-icons";

export const AnswerRow = ({title, value}) => (
    <Box border round='xxsmall' pad='small' direction='row' justify='between'>
        <Text size='16px' color='gray' truncate>{title}</Text>
        <Box direction='row' gap='medium'>
            {!!value && <Meter values={[{value: value, color: 'brightgreen'}]} round thickness='small' alignSelf='center'/>}
            {!!value && <Text size='16px' color='gray'>{`${Math.round(value)} %`}</Text>}
            <Filter color='gray'/>
        </Box>
    </Box>
);
