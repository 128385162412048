import React from 'react'

const NoMatch = props => (
    <svg width="100%" height="100%" viewBox="0 0 316 334" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <circle fill="#E4E8EB" cx={158} cy={158} r={158} />
                <path
                    stroke="#819DB6"
                    strokeWidth={5}
                    fill="#EEF0F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M49 147.654v-17.308M57.5 139.25h-17M121.5 28.808v-9.616M126 24h-9"
                />
                <path
                    stroke="#C7D2DB"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M85.1 90.4l6.8-6.8M91.682 90.182l-6.364-6.364M225.1 50.4l6.8-6.8M231.682 50.182l-6.364-6.364M44.1 216.4l6.8-6.8M50.682 216.182l-6.364-6.364"
                />
                <path
                    stroke="#819DB6"
                    strokeWidth={5}
                    fill="#EEF0F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M182.5 62.808v-9.616M187 58h-9"
                />
                <path
                    stroke="#C7D2DB"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M233.5 272.808v-9.616M238 268h-9"
                />
            </g>
            <g
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#819DB6"
                strokeWidth={5}
            >
                <path
                    d="M179 246.4c0-31.149-25.296-56.4-56.5-56.4S66 215.251 66 246.4v75.2c0 2.493.992 4.884 2.758 6.647A9.425 9.425 0 0075.417 331h94.166c5.201 0 9.417-4.209 9.417-9.4v-75.2zM150.5 99v28M137 113.5h28M249.5 191v28M236 205.5h28"
                    fill="#EEF0F4"
                />
                <path d="M249.5 99c-.124 15.413-12.812 27.878-28.5 28 15.688.122 28.376 12.587 28.5 28 .124-15.413 12.812-27.878 28.5-28-15.688-.122-28.376-12.587-28.5-28M167 165.732l16.781-21.726a5.192 5.192 0 013.764-1.995 5.209 5.209 0 014 1.482l20.945 20.68a5.084 5.084 0 011.498 3.953 5.1 5.1 0 01-2.017 3.721L191.93 187" />
                <path d="M207 243v-10.207c-.458-25.883-13.675-49.861-35.298-64.035A77.908 77.908 0 0099 162" />
                <path fill="#EEF0F4" d="M66 240.5h113" />
                <path
                    d="M109 329v-21c0-7.732 6.268-14 14-14h0c7.732 0 14 6.268 14 14v21"
                    fill="#C7D2DB"
                />
            </g>
        </g>
    </svg>
)

export default NoMatch
