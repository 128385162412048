import React, {useState} from 'react';
import MultiSelect from "../../../components/input/MultiSelect";
import {Box, Button, Text} from "grommet";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import Spinner from "../../../components/Spinner";
import {Checkmark, Close} from "grommet-icons";

const FETCH_DATA = gql`
    query {
        tags: tag {
            id
            name
        }
    }
`;

const SAVE_SESSION_TAGS = gql`
    mutation ($sessionId: Int! $tags: [session_tag_insert_input!]!) {
        delete_session_tag(where: {session_id: {_eq: $sessionId}}) {
            affected_rows
        }
        insert_session_tag(objects: $tags) {
            affected_rows
        }
    }
`;

export const EditSessionTagsField = ({sessionId, preselectedOptions: preselectedTagIds, onCancel = () => {}, onAccept = () => {}, refetchQueries = []}) => {
    const [tags, setTags] = useState([]);
    const {data = {tags: []}, loading, error} = useQuery(FETCH_DATA, {fetchPolicy: 'no-cache'});
    const [saveSessionTags, {loading: mutationLoading, error: mutationError}] = useMutation(SAVE_SESSION_TAGS, {
        refetchQueries, onCompleted: onAccept
    });
    if (loading || mutationLoading) {
        return <Spinner full/>
    }
    if (error) {
        return <Text color='red'>Error encountered when trying to get all tags.</Text>
    }

    return (
        <Box>
            <Box direction='row' gap='medium'>
                <MultiSelect options={data.tags} handleChange={setTags}
                             preselectedOptions={data.tags.filter(tag => preselectedTagIds.includes(tag.id))}/>
                <Box direction='row' gap='small'>
                    <Button plain icon={<Checkmark/>} onClick={() => saveSessionTags({
                        variables: {
                            sessionId, tags: tags.map(tag => ({
                                session_id: sessionId,
                                tag_id: tag.id
                            }))
                        }
                    })
                    }/>
                    <Button plain icon={<Close/>} onClick={onCancel}/>
                </Box>
            </Box>
            {mutationError && <Text color='red'>Could not save the changes.</Text>}
        </Box>
    )
};
