import React from 'react';
import {Box, Button} from "grommet";
import {LoadingButton} from "../../../components/LoadingButton";
import {Link} from "react-router-dom";
import {Form} from "formik";
import {SelectParticipant} from "./SelectParticipant";
import {MeetingDetailsForm} from "./MeetingDetailsForm";
import {MeetingTypeLabels} from "../../../lib/constants";

export const MeetingForm = ({errors, values, handleChange, setFieldValue, loading, isSubmitting, error}) =>
    <Form>
        <Box gap='medium'>
            <Box background='white' round="xsmall" elevation="xsmall" direction='row'>
                <MeetingDetailsForm
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange} error={error}
                />
            </Box>
            <SelectParticipant value={values.participant}
                               selectError={errors.participant}
                               onChange={({option}) => {
                                   setFieldValue('participant', option.id);
                                   setFieldValue('name', `${MeetingTypeLabels[values.type_id]}-${option.name}`);
                               }}
                               name='participant'
            />
            <Box margin={{top: "small"}} direction="row" gap="small">
                <LoadingButton primary label="Save & Send" type="submit" loading={loading || isSubmitting}/>
                <Link to='/meetings'><Button primary label='Cancel' color='lightgray'/></Link>
            </Box>
        </Box>
    </Form>;