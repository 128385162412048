import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    FormField,
    Select, Text,
} from "grommet";

const Dropdown = ({id, title, placeholder, options, value, error, setFieldValue, disabled, ...rest}) => (
    <Box gap="small" {...rest}>
        <Text color="lightgray">{title}</Text>
        <FormField error={error} className="surveyFormInput">
            <Select
                name={id}
                options={options}
                disabled={disabled}
                value={value || ""}
                labelKey="content"
                placeholder={placeholder}
                onChange={event => setFieldValue(id, event.value)}
            >
            </Select>
        </FormField>
    </Box>
);

Dropdown.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    })),
    placeholder: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,

};
export default Dropdown;
