import React from 'react';
import {Box, Button, FormField, Layer, Text, TextInput} from "grommet";
import {Close} from "grommet-icons";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation} from "react-apollo";
import {CreateOptionSelect} from "./CreateOptionSelect";

const VALIDATION_SCHEMA = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Please enter a valid email'),
    phone_number: Yup.string(),
    relationship_id: Yup.number()
});

const CREATE_RELATIONSHIP_MUTATION = gql`
    mutation ($name: String!) {
        inserted_option: insert_contact_relationship_one(object: {name: $name}) {
            id
            name
        }
    }
`;

const CREATE_CONTACT_MUTATION = gql`
    mutation($data: [session_contact_insert_input!]!) {
        insert_session_contact(objects: $data) {
            returning {
                id
            }
        }
    }
`;

const FETCH_RELATIONSHIPS = gql`
    query {
        options: contact_relationship {
            id
            name
        }
    }
`;

export const CreateContactModal = ({sessionId, refetchQueries}) => {
    const [show, setShow] = React.useState(false);
    const [createContact] = useMutation(CREATE_CONTACT_MUTATION, {refetchQueries});
    return (
        <React.Fragment>
            <Button label="Add Contact" margin={{top: 'small'}} alignSelf='start' onClick={() => setShow(true)}/>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Button selfAlign='end' icon={<Close/>} onClick={() => setShow(false)}/>
                    <Box pad={{horizontal: 'large', vertical: 'small'}}>
                        <Text weight='bold'>Add Contact</Text>
                        <Formik
                            validationSchema={VALIDATION_SCHEMA}
                            initialValues={{
                                first_name: '',
                                last_name: '',
                                email: '',
                                phone_number: '',
                                relationship_id: undefined
                            }}
                            onSubmit={async (values) => {
                                const response = await createContact({variables: {data: {...values, session_id: sessionId}}});
                                console.log(response);
                                setShow(false);
                            }}
                        >
                            {({values, errors, handleChange, setFieldValue}) => (
                                <Form>
                                    <FormField label='First Name' error={errors.first_name}>
                                        <TextInput
                                            name='first_name'
                                            value={values.first_name}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Last Name' error={errors.last_name}>
                                        <TextInput
                                            name='last_name'
                                            value={values.last_name}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Email' error={errors.email}>
                                        <TextInput
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Phone' error={errors.phone_number}>
                                        <TextInput
                                            name='phone_number'
                                            value={values.phone_number}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Relationship' error={errors.relationship_id}>
                                        <CreateOptionSelect
                                            name='relationship_id'
                                            value={values.relationship_id}
                                            onChange={(option) => setFieldValue('relationship_id', option)}
                                            getOptionsQuery={FETCH_RELATIONSHIPS}
                                            createOptionMutation={CREATE_RELATIONSHIP_MUTATION}
                                            mutateProperty='name'
                                        />
                                    </FormField>
                                    <Button label="Create" type='submit' alignSelf='stretch'/>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Layer>
            )}
        </React.Fragment>
    )
}