import React, {useEffect, useRef} from 'react';
import {Avatar, Box, Text} from "grommet";
import moment from "moment";
import FilledInSurvey from "./icons/FilledInSurvey";
import Gears from "./icons/Gears";
import useVisibilitySensor from "@rooks/use-visibility-sensor"

const IconMap = {
    1: FilledInSurvey,
    2: FilledInSurvey,
    3: Gears,
    4: Gears,
};

export const NotificationRow = ({id, seen_at, created_at, title, body, notification_type, onRead }) => {
    const ref = useRef(null);
    const { isVisible } = useVisibilitySensor(ref, {
        intervalCheck: false,
        scrollCheck: true,
        containment: document.getElementById("notifications")
    });
    useEffect(() => {
        if (!seen_at && isVisible) {
            onRead();
        }
    }, [isVisible, seen_at, onRead]);
    const Icon = IconMap[notification_type.id];
    return (
        <Box ref={ref} pad='small' gap='small' direction='row' align='center' border={{side: "bottom"}}>
            <Avatar border><Icon height='28px' width='28px'/></Avatar>
            <Box gap='xsmall'>
                <Box>
                    <Text truncate>{title || notification_type.name}</Text>
                    <Text weight={seen_at ? 'normal' : 'bold'} truncate>{body}</Text>
                </Box>
                <Text>{moment(created_at).fromNow()}</Text>
            </Box>
        </Box>
    )
};