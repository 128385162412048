import React, {useState, useEffect, useCallback} from 'react' ;

import {useQuery} from "@apollo/react-hooks";
import dot from 'dot-object';
import {Table} from "./table/Table";
import GeneralError from "./icons/GeneralError";
// import merge from 'deepmerge';

/**
 * You can pass defaultSort of the datatable as follows: {field_name: "asc"}
 */
export const DataTable = ({query, applyFilter = null, columns, tableProps, defaultSort=[], defaultPageSize=10}) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [filter, setFilter] = useState(applyFilter);
    const [sort, setSort] = useState(defaultSort);
    const {data, loading, error} = useQuery(query, { // TODO: Use lazyQuery and call it in fetchData
        variables: {
            limit: pageSize,
            offset: page * pageSize,
            orderBy: sort,
            filter
        },
        fetchPolicy: 'no-cache'
    });
    useEffect(() => setFilter(applyFilter), [applyFilter]); // Set page to 0?
    const fetchData = useCallback(({pageIndex, pageSize: pageSizeValue, sortBy}) => {
        setPage(pageIndex);
        setPageSize(pageSizeValue);
        // Uncomment this one if you want to support multiple fields (i.e. 'user.first_name,user.last_name' in the cells)
        // setSort(sorted.map(a => merge.all(a.id.split(',').map(b => dot.str(b, a.desc ? 'desc' : 'asc', {})))));
        if(sortBy.length > 0){
            setSort(sortBy.map(attr => dot.str(attr.id, attr.desc ? 'desc' : 'asc', {})));
        }
    }, []);

    if (error) {
        return <GeneralError/>
    }

    return (
        <Table loading={loading}
               fetchData={fetchData}
               columns={columns}
               data={(data && data.data) || []}
               defaultPageSize={pageSize}
               pageCount={!data || loading ? 0 : Math.ceil(data.data_aggregate.aggregate.count / pageSize)}
               {...tableProps}
        />
    );
};
