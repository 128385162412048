import gql from "graphql-tag";

export default gql`
query ($limit: Int! $offset: Int! $orderBy: [measurement_survey_result_order_by!] $filter: measurement_survey_result_bool_exp) {
    data: measurement_survey_result(limit: $limit, offset: $offset, order_by: $orderBy, where: $filter) {
        id
        created_at
        updated_at
        survey_template: measurement_survey_template {
            id
            title
            metadata
        }
        template_id
        score
        provider {
            id
            user {
                first_name
                last_name
            }
        }
    }
    data_aggregate: measurement_survey_result_aggregate(where: $filter) {
        aggregate {
            count
        }
    }
}
`;
