import React, {forwardRef, useCallback, useEffect, useState} from "react";
import {Box, Button, Text} from "grommet";
import SignaturePad from "react-signature-pad-wrapper";
import {ClearOption} from "grommet-icons";
import moment from "moment";


const SignaturePadBox = forwardRef(({disabled=false, signature, onChange = () => {}}, ref2) => {
    const [signaturePad, setSignaturePad] = useState();
    const signaturePadRef = useCallback((signaturePad) => setSignaturePad(signaturePad), []);
    const clearSignature = () => {
        if (signaturePad) {
            signaturePad.instance.clear();
            onChange(null);
        }
    }
    useEffect(() => {
        const onStrokeEnd = () => onChange(signaturePad.toData());
        if (signaturePad) {
            if (disabled) {
                signaturePad.off()
            } else {
                signaturePad.on()
            }
            signaturePad.instance.addEventListener('endStroke', onStrokeEnd);
            if (signature.sign_path_data) {
                signaturePad.fromData(signature.sign_path_data)
            }
            return () => signaturePad.instance.removeEventListener('endStroke', onStrokeEnd);
        }
    }, [disabled, signature, signaturePad, onChange])

    return (
        <Box gap='small'>
            <Text weight='bold'> {signature.user.first_name} {signature.user.last_name}</Text>
            <Box border={{color: 'divider', size: 'xsmall'}}
                // height="220px"
                 width="350px"
                 round='xsmall'
            >
                <SignaturePad redrawOnResize={true} ref={signaturePadRef}/>
                {!disabled &&
                    <Button alignSelf='start' icon={<ClearOption/>} onClick={clearSignature} margin={{top: '-35px'}}/>}
            </Box>
            {
                disabled &&
                <Box>
                    <Text>Digitally signed by
                        <Text weight='bold'> {signature.user.first_name} {signature.user.last_name}</Text> <br/>
                        from <Text weight='bold'> {signature.ip} </Text> on
                        <Text weight='bold'> {moment(signature.created_at).format('L')} </Text>
                    </Text>
                </Box>
            }
        </Box>
    )
})

export default SignaturePadBox
