import React from 'react';
import {Button, Spinner} from "grommet";
import {useLazyQuery} from "@apollo/react-hooks";
import {Download} from "grommet-icons";
import gql from "graphql-tag";

const DOWNLOAD_CORRESPONDENCE = gql`
    query downloadCorrespondence ($id: Int!) {
        download_correspondence(id: $id)
    }
`;

export const DownloadCorrespondenceButton = ({id}) => {
    const [download, {loading}] = useLazyQuery(DOWNLOAD_CORRESPONDENCE, {
        variables: {id}, onCompleted: (data) => {
            window.location.href = data.download_correspondence;
        }
    });
    return (
        <Button
            icon={loading ? <Spinner/> : <Download/>}
            disabled={loading}
            plain
            onClick={() => download()}
        />
    );
}