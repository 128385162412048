import React from 'react';
import Page from "../../components/Page";
import {Formik} from "formik";
import {withRouter} from "react-router-dom";
import * as Yup from "yup";
import {MeetingForm} from "./components/MeetingForm";
import moment from "moment";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";

export const MeetingValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    description: Yup.string()
        .min(2, 'Too Short!')
        .max(4000, 'Too Long!'),
    date: Yup.date()
        .required('Required'),
    start_at: Yup.string()
        .required('Required')
        .test('in_future', 'Start time should be in the future', function (start_at) {
            if (!this.options.parent.date) return true;
            const now = moment();
            if (moment(this.options.parent.date).isAfter(now, 'day')) return true;
            const startTime = moment(start_at, "h:mm a");
            return startTime.isAfter(now);
        }),
    sms_notification: Yup.bool(),
    email_notification: Yup.bool(),
    message_notification: Yup.bool(),
    // participants: Yup.array().of(Yup.number())
    //     .min(1, 'Select a single client')
    //     .max(1, 'Select a single client')
    //     .required('Required')
    participant: Yup.number()
        .typeError('Required')
        .required('Required'),
    type_id: Yup.number().required('Required'),
    service_type_id: Yup.number().required('Required'),
    duration: Yup.number(),
    billing_code_id: Yup.number(),
});

export const initialValues = {
    name: '',
    description: '',
    date: moment().format(),
    start_at: '',
    sms_notification: false,
    email_notification: false,
    message_notification: false,
    type_id: 2,
    duration: 0,
    service_type_id: '',
    billing_code_id: '',
    // participants: [],
    participant: null
};

export const CREATE_MEETING = gql`
    mutation ($meeting: [meeting_insert_input!]!) {
        insert_meeting(objects: $meeting) {
            returning {
                id
                name
                description
                twilio_room_name
                status
                service_type_id
                start_at
                provider {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
                meeting_participants {
                    session {
                        id
                        client {
                            id
                            user {
                                id 
                                first_name
                                last_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const CreateMeetingPage = ({history}) => {
    const [createMeeting, {loading, error}] = useMutation(CREATE_MEETING);
    return (
    <Page title="New Meeting">
        <Formik
            initialValues={initialValues}
            validationSchema={MeetingValidationSchema}
            onSubmit={async (values, actions) => {
                const {date, participant, start_at, end_at, duration, billing_code_id, ...data} = values;
                const dateString = moment(values.date).format('l');
                const startTime = moment(`${dateString} ${start_at}`, 'l h:mm a');
                const endTime = moment(startTime).add(duration, 'minutes');
                const meeting = {
                    ...data,
                    billing_code_id: billing_code_id || null,
                    start_at: startTime.format(),
                    end_at: endTime.format(),
                    meeting_participants: {
                        data: [{session_id: values.participant}]
                    }
                };
                try {
                    await createMeeting({variables: {meeting}});
                    history.replace('/meetings');
                } catch (e) {
                    actions.setSubmitting(false);
                }
            }}
        >
            {(props) => <MeetingForm {...props} loading={loading} error={error}/>}
        </Formik>
    </Page>
)};

export default withRouter(CreateMeetingPage);