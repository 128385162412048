import React, {Fragment} from 'react';
import {useRouteMatch} from "react-router-dom";
import {Document} from "../Document";
import {PrivateRoute} from "../../../../routes";
import {DocumentsTable} from "../DocumentsTable";

export const DocumentsTab = () => {
    const { path } = useRouteMatch();
    return (
        <Fragment>
            <PrivateRoute path={`${path}/:item_id`} component={Document} />
            <PrivateRoute exact path={path} component={DocumentsTable} />
        </Fragment>
    )
}