import React, {useCallback, useEffect, useReducer} from 'react';
import Chat from "twilio-chat";
import API from "../services/api";
import {arrayToObject} from "../lib/helpers";
import {mapMessage} from "./useChat";

export const ChatContext = React.createContext();

let twilioClient = null;

const initClient = async () => {
    // Reuse client on the client-side
    const token = await getToken();
    twilioClient = await Chat.create(token, {
        logLevel: process.env.NODE_ENV === 'development' ? 'warn' : 'silent'
    });
    return twilioClient
}

async function getToken() {
    // TODO: Cache in local state and load from there when possible
    const response = await API.get('/site/renew-chat-access-token');
    return response.data.chatToken;
    // return loadState(CHAT_TOKEN_KEY);
}

const chatReducer = (state, [type, payload]) => {
    switch (type) {
        case "loadChannels":
            return {
                ...state,
                channels: arrayToObject(payload),
                order: payload.sort(({lastMessage: a}, {lastMessage: b}) => // Orders channels by last message timestamp
                    !a && !b ? 0 : a && !b ? 1 : !a && b ? -1 : a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
                ).map(channel => channel.sid)
            };
        case "addChannel":
            return {
                ...state,
                channels: {...state.channels, [payload.sid]: payload},
                order: [payload.sid, ...state.order]
            };
        case "removeChannel":
            const {[payload.sid]: channel, ...channels} = state.channels;
            return {
                ...state,
                channels,
                order: state.order.filter(sid => sid !== payload.sid)
            };
        case "setLastMessage":
            return {
                ...state,
                lastMessages: {
                    ...state.lastMessages,
                    [payload.channel.sid]: mapMessage(payload)
                },
                order: [payload.channel.sid, ...state.order.filter(sid => sid !== payload.channel.sid)]
            };
        case "setActiveChannel":
            return {
                ...state,
                activeChannel: payload
            };
        case "setError":
            return {
                ...state,
                error: payload
            };
        case "setConnection":
            return {
                ...state,
                connection: payload
            };
        case "setLoading":
            return {
                ...state,
                loading: payload
            };
        default: {
            return state;
        }
    }
};


const initialState = {
    channels: {},
    activeChannel: undefined,
    order: [],
    lastMessages: {},
    error: undefined,
    connection: undefined,
    loading: false
};

export const ChatProvider = ({children}) => {
    const [state, dispatch] = useReducer(chatReducer, initialState);

    const loadChannelsWithLastMessages = async (chatClient) => {
        const subscribedChannels = (await chatClient.getSubscribedChannels()).items;
        dispatch(['loadChannels', subscribedChannels]);

        const messages = await Promise.all(subscribedChannels.map(async (channel) => {
            const lastPage = (await channel.getMessages(1));
            if (lastPage.items.length > 0) {
                return lastPage.items[0];
            }
            return undefined;
        }));
        messages.forEach(message => message && dispatch(['setLastMessage', message]));
    };

    const registerChatEventListeners = (chatClient) => {
        chatClient.on('messageAdded', message => dispatch(['setLastMessage', message]));
        chatClient.on('tokenAboutToExpire', async () => chatClient.updateToken(await getToken()));
        chatClient.on('tokenExpired', async () => chatClient.updateToken(await getToken()));
        chatClient.on('connectionStateChanged', conn => dispatch(['setConnection', conn]));
        chatClient.on('connectionError', error => dispatch(['setError', error]));
        chatClient.on('channelLeft', channel => dispatch(['removeChannel', channel]));
        chatClient.on('channelJoined', channel => dispatch(['addChannel', channel]));
    };

    const initialize = useCallback(() => {(async () => {
        dispatch(['setError', undefined]);
        dispatch(['setLoading', true]);
        try {
            const chatClient = await initClient();
            // console.log(chatClient, 'client');
            await loadChannelsWithLastMessages(chatClient);
            registerChatEventListeners(chatClient);
            dispatch(['setConnection', chatClient.connectionState]);
        } catch (e) {
            console.error('Cannot initialize chat client', e);
            dispatch(['setError', 'Chat System could not be initialized.']);
            if (twilioClient) await twilioClient.shutdown();
        }
        dispatch(['setLoading', false]);
    })()}, [dispatch]);

    useEffect(() => {
        let shutdown = false;
        (async () => await initialize())();
        if (shutdown) {
            (async () => {if (twilioClient) await twilioClient.shutdown()})();
        }
        return () => {
            console.log('unmount chat provider');
            shutdown = true;
        }
    }, [initialize]);
    return (
        <ChatContext.Provider value={[state, {dispatch, initialize}]}>
            {children}
        </ChatContext.Provider>
    )
};
