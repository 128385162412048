export const PHQ9_TEMPLATE_ID = 3;
export const GAD2_TEMPLATE_ID = 2; //not used for now
export const GAD7_TEMPLATE_ID = 1; //not used for now
export const BAM_TEMPLATE_ID = 5;
export const STATUS_ID_COMPLETED = 3;
export const MAX_CLIENTS_FOR_COMPARISON = 5;
export const COMPARISON_COLORS = ['#51DA5D', '#4DABF2','#4843E9', '#D0021B', '#FFA33F'];
export const BAM_COLORS = ['#51DA5D', '#D0021B', '#FFA33F'];
export const timeFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};
export const dateFormat = {day: 'numeric', month: 'short', year: 'numeric'};

