import React, {forwardRef, useState} from 'react';
import {Box, Image, Text, Avatar} from "grommet";
import {useQuery} from "@apollo/react-hooks";
import {FETCH_BRAND} from "../services/image";

const getPageMargins = () => {
    return `@page { margin: 0 20px 50px 20px !important; }`;
};

export const PrintPage = forwardRef(({children, ...rest}, ref) => {

const [preview, setPreview] = useState(null);
    useQuery(FETCH_BRAND, {onCompleted: (data) => {setPreview(data.brand.url)}});
    return (
        <Box ref={ref} pad='medium' {...rest}>
            <style>{getPageMargins()}</style>
            <Box height='xsmall' direction='row' justify='between'>
                <Box>
                    {/*<Text>Company Logo</Text>*/}
                </Box>
                <Box gap='xsmall' align='end'>
                    <Text weight='bold' color='gray'>Powered by</Text>
                    {preview ? <Avatar round='none' src={preview}/> : <Image src="/LogoHorizontal.svg"/>}
                </Box>
            </Box>
            {children}
        </Box>
    );
});
