import React from 'react';
import {CheckBox, FormField, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";

export const CheckboxInputField = ({title, label, id, value, error, setFieldValue, disabled}) => {
    return (
        <FormField label={title} error={error}>
            <CheckBox disabled={disabled} name={id} checked={!!value}  label={label} onChange={(event) => setFieldValue(id, event.target.checked)}/>
        </FormField>
    )
};

CheckboxInputField.defaultSize = {
    w: 1,
    h: 1,
    minW: 1,
    minH: 1
};

CheckboxInputField.title = 'Checkbox';

const CheckboxInputSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
    label: Yup.string()
});

export const CheckboxInputFieldProperties = ({title, label, updateField, id, item}) => {
    console.log('prop', title, label, id, item);
    return (

    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => updateField(id, values)}
        initialValues={{title, label}}
        validationSchema={CheckboxInputSchema}
        enableReinitialize
        onSubmit={() => {
        }}
    >
        {({errors, values, handleChange}) => (
            <Form>
                <FormField label="Title" error={errors.title}>
                    <TextInput
                        name="title"
                        value={values.title || ""}
                        onChange={handleChange}
                    />
                </FormField>

                <FormField label="Label" error={errors.label}>
                    <TextInput
                        name="label"
                        value={values.label || ""}
                        onChange={handleChange}
                    />
                </FormField>
            </Form>
        )
        }
    </Formik>
)}