import React from 'react';
import {Box, CheckBox, FormField, Grid, ResponsiveContext, Text, TextArea, TextInput} from "grommet";
import {DateInputPicker} from "./DateInputPicker";
import {EnumSelect} from "../../../components/EnumSelect";
import {TimeInputPicker} from "./TimeInputPicker";
import gql from "graphql-tag";

const FETCH_TYPES = gql`
    query {
        options: meeting_type {
            id
            name
        }
    }
`;

const FETCH_SERVICE_TYPES = gql`
    query serviceTypes{
        options: provider_service_type {
            duration
            service_type_id
            service_type {
                id
                name
            }
        }
    }
`;

const FETCH_BILLING_CODES = gql`
    query billingCodes{
        options: billing_code {
            id
            name
        }
    }
`;

export const MeetingDetailsForm = ({errors, values, handleChange, setFieldValue, error}) => (
    <ResponsiveContext.Consumer>
        {(deviceSize) => (
            <Grid
                align="start"
                fill
                columns={deviceSize === 'small' ? 'auto' : ["1/2", "1/2"]}
            >
                <Box
                    pad={deviceSize === 'small' ? 'medium' : {
                        top: 'medium',
                        bottom: 'medium',
                        right: 'small',
                        left: 'medium'
                    }}
                    gap='xsmall'>
                    <Text>Meeting Details</Text>
                    <FormField margin={{top: "small"}} label='Name' error={errors.name}>
                        <TextInput
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label='Description' error={errors.description}>
                        <TextArea
                            rows={5}
                            name='description'
                            resize={false}
                            value={values.description}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label="Billing Code" error={errors.billing_code_id}>
                        <EnumSelect
                            clear
                            name="billing_code_id"
                            query={FETCH_BILLING_CODES}
                            value={values.billing_code_id}
                            onChange={({value}) => setFieldValue("billing_code_id", value)}
                        />
                    </FormField>
                    {error && <Text color='red'>Something went wrong. Please try again later</Text>}
                </Box>
                <Box
                    pad={deviceSize === 'small' ? 'medium' : {
                        top: 'medium',
                        bottom: 'medium',
                        right: 'medium',
                        left: 'small'
                    }}
                    gap='xsmall'>
                    <Text>Meeting Time</Text>
                    <FormField margin={{top: "small"}} label='Date' error={errors.date}>
                        <DateInputPicker
                            value={values.date}
                            onSelect={selectedDate => {
                                setFieldValue('date', selectedDate);
                            }}
                        />
                    </FormField>
                    <FormField label='Service Type' error={errors.service_type_id}>
                        <EnumSelect
                            name="service_type_id"
                            options={(data) =>
                                data.options.map((item) => ({
                                    id: item.service_type_id,
                                    name: item.service_type.name,
                                    duration: item.duration
                                }))
                            }
                            query={FETCH_SERVICE_TYPES}
                            value={values.service_type_id}
                            onChange={({value, option}) => {
                                setFieldValue("service_type_id", value);
                                setFieldValue("duration", option.duration);
                            }}
                        />
                    </FormField>
                    <Box direction='row' gap="medium">
                        <FormField label="Type" error={errors.type_id}>
                            <EnumSelect
                                name="type_id"
                                query={FETCH_TYPES}
                                value={values.type_id}
                                onChange={({value}) => setFieldValue("type_id", value)}
                            />
                        </FormField>
                        <FormField label='Starts at' error={errors.start_at}>
                            <TimeInputPicker
                                value={values.start_at}
                                name='start_at'
                                onChange={handleChange}
                            />
                        </FormField>
                        {values.duration ? <FormField label='Service Time' error={errors.duration}>
                            <Text size="large" margin={{top: `small`}}>{values.duration} minutes</Text>
                        </FormField> : null}
                    </Box>
                    <Box>
                        <Text>Notify by</Text>
                        <Box direction="row" gap={deviceSize === "large" ? "xlarge" : "medium"} margin={{top: "small"}}>
                            <CheckBox
                                checked={values.sms_notification}
                                label="SMS"
                                name="sms_notification"
                                onChange={(event) => {
                                    setFieldValue('sms_notification', event.target.checked)
                                }}
                            />
                            <CheckBox
                                checked={values.message_notification}
                                label="Chat Message"
                                name="message_notification"
                                onChange={(event) => {
                                    setFieldValue('message_notification', event.target.checked)
                                }}
                            />
                            <CheckBox
                                checked={values.email_notification}
                                label="Email"
                                name="email_notification"
                                onChange={(event) => {
                                    setFieldValue('email_notification', event.target.checked)
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )}
    </ResponsiveContext.Consumer>
)