import Clients from "../components/icons/Clients";
import {COMPANY_OWNER, MANAGER} from "../lib/constants";
import Scheduling from "../components/icons/Scheduling";
import Mail from "../components/icons/Mail";
import Pen from "../components/icons/Pen";
import Measurement from "../components/icons/Measurement";
import Settings from "../components/icons/Settings";
// import Dashboard from "../components/icons/Dashboard";

export const menu = [
    // {
    //     label: "Dashboard",
    //     path: "/",
    //     exact: true,
    //     icon: Dashboard,
    // },
    {
        label: "Clients",
        icon: Clients,
        submenu: [
            {
                label: "Charts",
                path: "/clients",
                // exact: true,
            },
            {
                label: "Caseloads",
                path: "/assign-clients",
                requiredRole: MANAGER,
                exact: true,
            },
            {
                label: "Risk Factors",
                path: "/tags",
                //exact: true,
            },
        ]
    },
    {
        label: "Scheduling",
        icon: Scheduling,
        submenu: [
            {
                label: "Calendar",
                path: "/scheduling",
            },
            {
                label: "Meetings",
                path: "/meetings",
            },
            {
                label: "Work Schedule",
                path: "/work-schedule",
            }
        ]
    },
    {
        label: "Communication",
        icon: Mail,
        submenu: [
            {
                label: "Messenger",
                path: "/messenger",
            },
            {
                label: "Broadcast Messages",
                path: "/messenger-broadcast",
            },
            {
                label: "Campaigns",
                path: "/campaigns"
            },
            {
                label: "Audiences",
                path: "/audiences",
                exact: true,
            },
        ]
    },
    {
        label: "Survey Reports",
        icon: Pen,
        submenu: [
            {
                label: "Individual Reports",
                path: "/survey/results",
            },
            {
                label: "Aggregate Reports",
                path: "/survey/report/aggregate",
            },
        ]
    },
    {
        label: "Empirical Notes",
        icon: Measurement,
        submenu: [
            {
                label: "Measurement Tools",
                path: "/measurement/tools",
            },
            {
                label: "Individual Reports",
                path: "/measurement/results",
            },
            {
                label: "Comparison Reports",
                path: "/measurement/report/comparison",
                exact: true,
            }
        ]
    },
    {
        label: "Settings",
        requiredRole: MANAGER,
        icon: Settings,
        submenu: [
            {
                label: "Survey Templates",
                path: "/survey/templates",
            },
            {
                label: "Document Templates",
                path: "/document-template-library"
            },
            {
                label: "Staff",
                path: "/staff",
            },
            {
                label: "Billing Codes",
                path: "/billing-codes",
            },
            {
                label: "Company Details",
                path: "/company-details",
                requiredRole: COMPANY_OWNER,
            },
            {
                label: "Subscription",
                path: "/billing",
                requiredRole: COMPANY_OWNER,
            },
        ]
    },
];