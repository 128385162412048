import React, {useMemo} from 'react';
import Page from "../../../components/Page";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {FETCH_WORK_DAYS, UPDATE_WORK_DAYS} from "./WorkDays";
import Spinner from "../../../components/Spinner";
import {Box, Button, CheckBoxGroup, List, Tag, Text, FormField} from "grommet";
import moment from "moment";
import {Form, Formik} from "formik";
import {TimeInputPicker} from "../../Communication/components/TimeInputPicker";
import * as Yup from "yup";
import gql from "graphql-tag";

const UPDATE_BREAKS = gql`
    mutation ($provider_id: Int! $day: String! $breaks: jsonb!) {
        update_provider_work_schedule_by_pk(pk_columns: {provider_id: $provider_id, week_day: $day}, _set: {breaks: $breaks}) {
            breaks
        }
    }
`;

const validationSchema = Yup.object().shape({
    days: Yup.array().of(Yup.string()).required('Please select at least one day'),
    start_hour: Yup.string().required('Please select a start hour'),
    end_hour: Yup.string().required('Please select an end hour'),
});

const initialValues = {
    days: [],
    start_hour: '',
    end_hour: '',
}

export const Breaks = () => {
    const {data, loading} = useQuery(FETCH_WORK_DAYS);
    const [updateWorkDays] = useMutation(UPDATE_WORK_DAYS, {refetchQueries: ['fetchWorkDays']});
    const [updateBreaks] = useMutation(UPDATE_BREAKS, {refetchQueries: ['fetchWorkDays']});
    const workDays = useMemo(() => data?.provider_work_schedule.map(({week_day}) => week_day), [data]);

    if (loading) return <Spinner/>;
    console.log(data?.provider_work_schedule);
    return (
        <Page title='Breaks'>
            <Box background="white" elevation="xsmall" round="xsmall">
                <List
                    primaryKey={(item) => item}
                    secondaryKey={(item) =>
                        <Box key={item} direction="row" gap="xsmall" wrap>
                            {data.provider_work_schedule
                                .find(({week_day}) => week_day === item)
                                ?.breaks
                                ?.map(({start_hour, end_hour}) => <Tag key={`${start_hour} - ${end_hour}`} value={`${start_hour} - ${end_hour}`} onRemove={async () => {
                                    const breaks = data.provider_work_schedule
                                        .find(({week_day}) => week_day === item)
                                        ?.breaks
                                        .filter((workBreak) => workBreak?.start_hour !== start_hour && workBreak?.end_hour !== end_hour);
                                    await updateBreaks({variables: {provider_id: data.provider_id, day: item, breaks}});
                                }} />)}
                        </Box>}
                    data={workDays}
                    />
            </Box>
            <Text weight={600} margin={{vertical: "small"}}>Add break</Text>
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                // const outOfWorkingHours = data?.provider_work_schedule.filter(({week_day}) => values.days.includes(week_day)).some((workDay) => values.start_hour >= workDay.end_hour || values.end_hour <= workDay.start_hour);
                // if (outOfWorkingHours) {
                //     actions.setStatus({error: 'Please select a timeframe that is within your working hours for all selected days'});
                //     return;
                // }
                const workDays = data?.provider_work_schedule?.map((day) => {
                    const {__typename, ...rest} = day;
                    const workDay = {...rest, provider_id: data.provider_id};
                    if (!values.days.includes(workDay.week_day)) return workDay;
                    const dayBreak = workDay.breaks?.find(({start_hour, end_hour}) =>
                        values.start_hour === moment(start_hour, 'HH:mm').format('hh:mm a') &&
                        values.end_hour === moment(end_hour, 'HH:mm').format('hh:mm a')
                    );
                    if (dayBreak) {
                        return workDay;
                    }
                    return {
                        ...workDay,
                        breaks: [
                            ...(workDay?.breaks ?? []),
                            {
                                start_hour: moment(values.start_hour, 'hh:mm a').format('HH:mm'),
                                end_hour: moment(values.end_hour, 'hh:mm a').format('HH:mm')
                            }
                        ]
                    };
                });
                await updateWorkDays({variables: {providerId: data.provider_id, workDays}});
                actions.resetForm();
            }}>
                {({values, handleChange, setFieldValue, errors, status}) => (
                    <Form>
                        <Box background="white" elevation="xsmall" round="xsmall" pad="medium">
                            <FormField label="Days" error={errors.days}>
                                <CheckBoxGroup
                                    name='days'
                                    value={values.days}
                                    onChange={({value}) => setFieldValue('days', value)}
                                    direction="row"
                                    wrap
                                    options={workDays}
                                />
                            </FormField>
                            <Box direction="row" gap="small">
                                <FormField name="start_hour" margin="none" error={errors.start_hour}>
                                    <TimeInputPicker
                                        name="start_hour"
                                        placeholder="Start hour"
                                        onChange={handleChange}
                                        value={values.start_hour}
                                    />
                                </FormField>
                                <FormField name="end_hour" margin="none" error={errors.end_hour}>
                                    <TimeInputPicker
                                        name="end_hour"
                                        placeholder="End hour"
                                        onChange={handleChange}
                                        value={values.end_hour}
                                    />
                                </FormField>
                            </Box>
                            {status?.error && <Text color="status-error" margin={{top: 'small'}}>{status.error}</Text>}
                        </Box>
                        <Button primary type="submit" label="Add" margin={{top: 'small'}}/>
                    </Form>
                )}
            </Formik>
        </Page>
    );
}