import React from 'react';
import {Box, Text} from "grommet";
import Avatar from "../../../components/Avatar";
import moment from "moment";
import {Link} from "react-router-dom";
import Spinner from "../../../components/Spinner";

export const ClientsList = ({loading, lastMessages, channels, activeChannelSid,
                             onChannelClick = () => {}, containerProps = {}}) => {
    const now = moment();
    const getLastMessageDate = (timestamp, now) => {
        const messageTime = moment(timestamp);
        return now.diff(messageTime, 'days') > 1 ? messageTime.format('Do MMM, kk:mm') : messageTime.fromNow();
    };
    return (
    <Box flex overflow='auto' {...containerProps}>
        {loading ? <Spinner margin={{top: "medium"}}/> :
            channels.length > 0 ? channels.map(({friendlyName, sid, lastMessage, lastConsumedMessageIndex, attributes}) => {
            const isMessageConsumed = lastMessages[sid] && lastMessages[sid].index <= lastConsumedMessageIndex;
            const clientName = friendlyName.split(' - ')[1];
            return (
                <Link key={sid} to={`/messenger/${sid}`} onClick={onChannelClick}>
                <Box
                    pad='small'
                    background={activeChannelSid === sid ? 'lightdirtywhite' : 'white'}
                    direction='row'
                    gap='small'
                    style={{cursor: 'pointer'}}
                    border={{side: 'bottom'}}
                >
                    <Box flex={false}><Avatar id={attributes.clientId} usePlaceholder={!attributes.clientId}/></Box>
                    <Box justify='evenly' fill>
                        <Box direction='row' justify='between'>
                            <Text>{clientName}</Text>

                            <Text>{lastMessage && getLastMessageDate(lastMessage.timestamp, now)}</Text>
                        </Box>
                        <Text weight={activeChannelSid === sid || isMessageConsumed ? 'normal' : 'bold'} truncate>
                            {(lastMessages[sid] && lastMessages[sid].body) || 'No Messages'}
                        </Text>
                    </Box>
                </Box>
                </Link>
            )
        }) : <Box pad='small'><Text>You don't have any assigned clients.</Text></Box>}
    </Box>
)};
