import gql from "graphql-tag";
import {AUTH_TOKEN_KEY, loadState} from "../localStorage";
import jwtDecode from "jwt-decode";
import {
    login as loginRequest,
    authenticateDevice as authenticateDeviceRequest,
    resendDeviceAuthenticationToken
} from "./auth/service";
import {refreshSubscription} from "../index";

export const typeDefs = gql`
  extend type Query {
    isAuthenticated: Boolean!
    id: Int
    name: String
    email: String
    chatToken: String
    role: String
    roles: [String]!
    companyID: Int
  }
  extend type Mutation {
    login(email: String! password: String!): null
  }
`;

export const initialState = {
    isAuthenticated: false,
    id: null,
    name: null,
    email: null,
    chatToken: null,
    role: null,
    roles: [],
    companyID: null
};

export const getClaimField = (field, jwt) => {
    const token = jwt || loadState(AUTH_TOKEN_KEY);
    if (!token) {
        return null
    }
    const data = jwtDecode(token);
    return data['https://hasura.io/jwt/claims'][field]
};

export const getField = (field, jwt) => {
    const token = jwt || loadState(AUTH_TOKEN_KEY);
    if (!token) {
        return null
    }
    const data = jwtDecode(token);
    return data[field];
};

export const resolvers = {
    Query: {
        isAuthenticated: () => !!loadState(AUTH_TOKEN_KEY),
        id: () => parseInt(getClaimField('x-hasura-user-id')),
        companyID: () => parseInt(getClaimField('x-hasura-company-id')),
        role: () => getClaimField('x-hasura-role'),
        roles: () => getClaimField('x-hasura-allowed-roles'),
        name: () => getField('name'),
        planId: () => parseInt(getClaimField('x-hasura-plan-id')),
        chatToken: () => null,
        email: () => null,
    },
    Mutation: {
        // For an explanation why we need this method see LoginPage
        login: async (_, {email, password}, {cache}) => {
            const {authToken: jwt} = await loginRequest(email, password);
            refreshSubscription();
            // TODO: Optimize by decoding jwt here and passing values directly below
            cache.writeData({
                data: {
                    isAuthenticated: true,
                    id: parseInt(getClaimField('x-hasura-user-id', jwt)),
                    companyID: parseInt(getClaimField('x-hasura-company-id', jwt)),
                    role: getClaimField('x-hasura-role', jwt),
                    roles: getClaimField('x-hasura-allowed-roles', jwt),
                    name: getField('name', jwt),
                    planId: parseInt(getClaimField('x-hasura-plan-id', jwt)),
                    chatToken: null,
                    email: null,
                }
            });
        },
        resendDeviceToken: async (_, {id}) => {
            await resendDeviceAuthenticationToken(id);
        },
        authenticateDevice: async (_, {token, id}, {cache}) => {
            const {authToken: jwt} = await authenticateDeviceRequest(token, id);
            refreshSubscription();
            // TODO: Optimize by decoding jwt here and passing values directly below
            cache.writeData({
                data: {
                    isAuthenticated: true,
                    id: parseInt(getClaimField('x-hasura-user-id', jwt)),
                    companyID: parseInt(getClaimField('x-hasura-company-id', jwt)),
                    role: getClaimField('x-hasura-role', jwt),
                    roles: getClaimField('x-hasura-allowed-roles', jwt),
                    name: getField('name', jwt),
                    planId: parseInt(getClaimField('x-hasura-plan-id', jwt)),
                    chatToken: null,
                    email: null,
                }
            });
        }
    }
};
