import React, {useState} from 'react';
import gql from 'graphql-tag';

import {Box, Text} from "grommet";
import {SearchField} from "../../components/SearchField";
import RoutedButton from "../../components/RoutedButton";
import Page from "../../components/Page";
import {DataTable} from "../../components/DataTable";
import {SessionsSearchList} from "./components/SessionsSearchList";

const FETCH_DATA = gql`
query tags($limit: Int! $offset: Int! $orderBy: [tag_order_by!] $filter: tag_bool_exp) {
    data: tag(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
        id
        created_at
        updated_at
        provider_id
        name
        description
    }
    data_aggregate: tag_aggregate(where: $filter) {
        aggregate {
            count
        }
    }
}
`;

const columns = [
    {
        accessor: 'name',
        Header: 'Risk Factor',
    },
    {
        accessor: 'description',
        Header: 'Description',
        Cell: ({row: {original: {description}}}) => (
            <Text truncate={true}> {description}</Text>
        )
    },
];

const TagsPage = () => {
    const [filter, setFilter] = useState({});
    const [selectedRow, setSelectedRow] = useState();
    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({
                    _or: [
                        {name: {_ilike: `%${term}%`}},
                        {description: {_ilike: `%${term}%`}}
                    ]
                })
            }/>
            <RoutedButton path='/tags/create' label='New Risk Factor' primary/>
        </Box>
    );
    return (
        <Page title="Risk Factors" header={header()}>
            <Box direction='row' gap='small'>
            <DataTable query={FETCH_DATA}
                       applyFilter={filter}
                       columns={columns}
                       defaultSort={{updated_at:'desc'}}
                       tableProps={{
                           boxProps: {fill: true},
                           getRowProps: (row) => ({
                               onClick: () => {
                                   setSelectedRow(sRow =>
                                       sRow && sRow.original.id === row.original.id ? undefined : row
                                   )
                               },
                               style: {cursor: 'pointer'},
                               background: selectedRow && row.original.id === selectedRow.original.id ? 'dirtywhite' : undefined
                           })
                       }}
            />
            {selectedRow && <SessionsSearchList author={selectedRow.original.provider_id} tagName={selectedRow.original.name} tag={selectedRow.original.id} onRemove={() => setSelectedRow(undefined)}/>}
            </Box>
        </Page>
    );
};

export default TagsPage;
