import React from "react";

import {Box, Text} from "grommet";
import {Toast} from "grommet-icons";

export const DisplayError = (error) => (
    <Box>
        <Box background='white' margin="medium" pad="medium" align='center' alignContent='center' round>
            <Toast color='brand' size='xlarge'/>
            <Text size="large" color="brand">Oh no!</Text>
            <Text>{error.message || ''}</Text>
        </Box>
    </Box>
);
