import React from 'react';
import {FormField, Text, TextInput, TextArea, FileInput, Box} from "grommet";
import gql from "graphql-tag";
import {useHistory, useParams} from "react-router-dom";
import {Form, Formik} from "formik";
import API from "../../../services/api";
import {print} from "graphql";
import {graphqlFetchOptions} from "../../../services/image";
import {LoadingButton} from "../../../components/LoadingButton";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").max(255, 'Too Long!'),
    description: Yup.string(),
    file: Yup.mixed().required("Required")
});

export const UPLOAD_CORRESPONDENCE = gql`
    mutation ($file: Upload! $session_id: Int! $name: String! $description: String) {
        upload_correspondence(file: $file session_id: $session_id name: $name description: $description)
    }
`;

export const CreateCorrespondence = ({closeModal}) => {
    const {session_id, id} = useParams();
    const history = useHistory();
    return (
        <Box pad="small">
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    file: null,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                    console.log({...values, session_id: parseInt(session_id)});
                    const response = await API.request(graphqlFetchOptions({
                        query: print(UPLOAD_CORRESPONDENCE),
                        variables: {...values, session_id: parseInt(session_id)},
                    }));
                    if (response.data.errors) {
                        actions.setStatus({type: 'error', message: response.data.errors[0].message});
                        throw response.data.errors[0];
                    }
                    closeModal();
                    history.push(`/clients/${id}/${session_id}/correspondence`);
                }}>
                {({status, values, isSubmitting, errors, handleChange, setFieldValue}) => (
                    <Form>
                        <FormField error={errors.name} label='Name'>
                            <TextInput name="name" value={values.name} onChange={handleChange}/>
                        </FormField>
                        <FormField error={errors.description} label='Description'>
                            <TextArea name="description" value={values.description} onChange={handleChange}/>
                        </FormField>
                        <FormField error={errors.file}>
                            <FileInput
                                name="file"
                                onChange={(event) => setFieldValue('file', event.target.files[0])}
                            />
                        </FormField>
                        {status && <Text size='small' color='status-error'>{status.message}</Text>}
                        <LoadingButton primary type='submit' loading={isSubmitting}
                                       label='Save'/>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}