import React, {useCallback, useEffect, useState} from 'react';
import {Avatar, Box, Text} from "grommet";
import {useDropzone} from 'react-dropzone'
import {useQuery} from "@apollo/react-hooks";
import Spinner from "./Spinner";
import {FETCH_AVATAR} from "../services/image";

export const ImageUpload = ({setFieldValue, id, usePlaceholder = false}) => {
    const {data, loading} = useQuery(FETCH_AVATAR, {variables: {id}, skip: usePlaceholder});
    const [preview, setPreview] = useState(process.env.REACT_APP_DEFAULT_AVATAR);

    useEffect(() => {
        data?.avatar?.url && setPreview(data.avatar.url);
    }, [data]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            setFieldValue('avatar', file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreview(reader.result)
            };
        });

    }, [setFieldValue]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    return (
    <Box align='center' pad='large' fill justify='center' {...getRootProps()}>
        {loading ? <Spinner/> : <Avatar size='xlarge' src={preview}/>}
        <Box gap='xsmall' align='center'>
            <Text color='gray' weight='bold' margin={{top: 'small'}}>PROFILE IMAGE</Text>
            <Text color='gray' weight='bold'>{isDragActive ? 'Drop here' : 'Click image or drag & drop photo'}</Text>
        </Box>
        <input
            type="file"
            name="avatar"
            {...getInputProps()}
        />
    </Box>
)};
