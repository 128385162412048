import React from 'react';
import {Box, Text} from "grommet";
import RoutedButton from "../components/RoutedButton";
import NoPermissions from "../components/icons/NoPermissions";

export const NoPermissionsPage = () => (
    <Box fill align="center" justify='center' gap='small'>
        <Box height='medium'>
            <NoPermissions/>
        </Box>
        <Text>You have no permission to view this page.</Text>
        <Text color="sidebarInactive">Go back to the Dashboard.</Text>
        <RoutedButton margin={{top: "small"}} label="Go to Dashboard" path='/'/>
    </Box>
);
