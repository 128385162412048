import React from 'react';
import {Link} from "react-router-dom";
import {Box, Image, Text} from "grommet";

export const Layout = ({children, header}) => (
    <Box justify="center" direction="column" align="center" background="dirtywhite" fill overflow='auto'>
        <Link to='/'>
            <Box justify="center" direction="column" align="center" margin={{top: "small"}}>
                <Box height="small">
                    <Image
                        fit="contain"
                        src="/LogoVertical.svg"
                    />
                </Box>
            </Box>
        </Link>
        {header && <Text color="lightgray" size="medium" margin={{bottom: "medium"}}>{header}</Text>}
        {children}
    </Box>
);