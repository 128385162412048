import React from 'react';
import * as Yup from "yup";
import {Box, FormField, Grid, MaskedInput, Paragraph, Text, TextInput} from "grommet";
import {PasswordInput} from "../Profile/PasswordInput";
import Staff from "../../components/icons/Staff";
import {PHONE_NUMBER_MASK} from "../../lib/constants";
import YupPassword from "yup-password";

YupPassword(Yup)

const Step1 = ({handleChange, handleBlur, errors, values}) => (
    <Grid
        gap='small'
        areas={[
            {name: 'firstName', start: [0, 0], end: [1, 0]},
            {name: 'lastName', start: [1, 0], end: [2, 0]},
            {name: 'email', start: [0, 1], end: [2, 1]},
            {name: 'phone', start: [2, 1], end: [2, 1]},
            {name: 'password', start: [0, 2], end: [0, 2]},
            {name: 'repeatPassword', start: [0, 3], end: [0, 3]},
        ]}
        columns={['auto', 'auto', 'auto']}
        rows={['auto', 'auto', 'auto', 'auto']}
    >
        <Box gridArea='firstName'>
            <FormField label="First Name" error={errors.first_name}>
                <TextInput
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Box>
            <Box gridArea='lastName'>
            <FormField label="Last Name" error={errors.last_name}>
                <TextInput
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Box>
        <Box gridArea='email'>
            <FormField label="Email" error={errors.email}>
                <TextInput
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Box>
        <Box gridArea='phone'>
            <FormField label="Phone" error={errors.phone_number}>
                <MaskedInput
                    name='phone_number'
                    mask={PHONE_NUMBER_MASK}
                    value={values.phone_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Box>
        <Box gridArea='password'>
            <FormField label="Password" error={errors.password}>
                <PasswordInput
                    name="password"
                    onBlur={handleBlur}
                    value={values.password}
                    onChange={handleChange}
                />
            </FormField>
        </Box>
        <Box gridArea='repeatPassword'>
            <FormField label="Repeat Password" error={errors.repeat_password}>
                <PasswordInput
                    name="repeat_password"
                    onBlur={handleBlur}
                    value={values.repeat_password}
                    onChange={handleChange}
                />
            </FormField>
        </Box>
    </Grid>
);

Step1.label = 'Account Details';
Step1.validationSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    phone_number: Yup.string().required('Required')
        .test('len', 'Phone number is not valid', value => !value || value.length === 14),
    password: Yup.string()
            .minNumbers(1, 'Must contain at least 1 number')
            .minLowercase(1, 'Must contain at least 1 lowercase character')
            .minUppercase(1, 'Must contain at least 1 uppercase character')
            .minSymbols(1, 'Must contain at least 1 special symbol')
            .min(8, 'Must contain at least 8 characters')
            .max(64, 'Must be shorter than 64 characters')
            .required('Required'),
    repeat_password: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required('Password repeat is required'),
});
Step1.initialValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    password: '',
    repeat_password: ''
};
Step1.url = 'account-details';
Step1.sidebar = (
    <Box gap='medium'>
        <Staff active style={{width: '50px', height: '50px'}}/>
        <Box>
            <Text color='blueblack' size='large'>Enhanced patient-provider experience</Text>
            <Paragraph color='lightgray'>
                We bridge the gap in communication between patients and healthcare providers by
                innovating easy of contact with phone, chat and email.
            </Paragraph>
        </Box>
    </Box>
);

export default Step1;
