import React from 'react';
import {Box, FormField, Text, TextInput} from "grommet";
import {ServiceTypeSelect} from "./ServiceTypeSelect";
import {Form, Formik} from "formik";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {LoadingButton} from "../../../components/LoadingButton";

const FETCH_QUERY = gql`
    query providerServiceTypeSettings{
        provider_service_type {
            id
            duration
            service_type {
                id
                name
            }
        }
    }
`;

const UPDATE_QUERY = gql`
    mutation($id: Int!, $objects: [provider_service_type_insert_input!]!) {
        id @client @export(as: "id")
        delete_provider_service_type(where: {provider_id: {_eq: $id}}) {
            affected_rows
        }
        insert_provider_service_type(objects: $objects) {
            affected_rows
        }
    }
`;

export const ServiceTypes = () => {
    const [serviceTypes, setServiceTypes] = React.useState([]);
    const [duration, setDuration] = React.useState({});
    useQuery(FETCH_QUERY, {
        onCompleted: (data) => {
            setServiceTypes(data.provider_service_type.map(item => item.service_type));
            setDuration(data.provider_service_type.reduce((acc, item) => {
                acc[item.service_type.id] = item.duration;
                return acc;
            }, {}));
        }
    });
    const [update, {loading}] = useMutation(UPDATE_QUERY, {refetchQueries: ['serviceTypes', 'providerServiceTypeSettings']});

    return (
        <Box gap="small" pad="small">
            <Text weight="bold">Service Types</Text>
            <Formik initialValues={{}} onSubmit={() => {
            }}>
                {() => (
                    <Form>
                        <Box background="white" elevation="xsmall" round="xsmall" gap="small"
                             alignSelf="start" pad="small">
                            <FormField label="Supported Service Types">
                                <ServiceTypeSelect
                                    value={serviceTypes.map(service => service.id)}
                                    onChange={(value) => setServiceTypes(value)}
                                />
                            </FormField>
                            <Box>
                                {serviceTypes.map((serviceType) => (
                                    <FormField key={serviceType.id} label={`${serviceType.name} duration (minutes)`}>
                                        <TextInput type="number" value={duration[serviceType.id] || ''}
                                                   onChange={(event) => {
                                                       const value = event.target?.value;
                                                       setDuration(prevState => ({...prevState, [serviceType.id]: value}));
                                                   }}/>
                                    </FormField>
                                ))}
                            </Box>

                        </Box>
                        <LoadingButton
                            margin={{top: 'small'}}
                            loading={loading}
                            primary
                            label="Save"
                            alignSelf="start"
                            onClick={async () => {
                                await update({
                                    variables: {
                                        objects: serviceTypes.map(({id}) => ({
                                            service_type_id: id,
                                            duration: duration[id]
                                        }))
                                    }
                                });
                            }}/>
                    </Form>
                )}
            </Formik>

        </Box>
    )
}