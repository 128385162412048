import React, {useCallback, useContext} from 'react';
import {Box, Button, FormField, Select, MaskedInput, Text, TextInput, Grid, ResponsiveContext} from "grommet";
import {ImageUpload} from "../../../components/ImageUpload";
import {LoadingButton} from "../../../components/LoadingButton";
import {Link} from "react-router-dom";
import MultiSelect from "../../../components/input/MultiSelect";
import {StaffCaseload} from "./StaffCaseload";
import {EMAIL_MASK, MANAGER, PHONE_NUMBER_MASK, PROVIDER} from "../../../lib/constants";

export const ROLE_IDS = {
    [MANAGER]: 1,
    [PROVIDER]: 2
};

export const EditStaffForm = ({currentRoles, values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, isValidating, handleBlur, loading}) => {

    const rolesOptions = [
        {role: "ProviderManager", id: ROLE_IDS[MANAGER], name: 'Clinical Administrator'},
        {role: "Provider", id: ROLE_IDS[PROVIDER], name: 'Clinician'}
    ];

    const deviceSize = useContext(ResponsiveContext);
    const smallScreenAreas = [
        ["box1", "box1"],
        ["box2", "box2"],
        ["box3", "box3"]
    ];
    const largeScreensAreas = [
        ["box1", "box2"],
        ["box3", "box3"]
    ];

    return (
        <Box gap='xsmall' flex={false}>
            <Box direction='row' justify='evenly' gap='medium'>
                <Grid
                    fill
                    gap='medium'
                    rows={["auto", "auto", "auto"]}
                    columns={deviceSize === 'small' ? ['auto'] : ['auto', '1/4']}
                    areas={deviceSize === 'small' ? smallScreenAreas : largeScreensAreas }
                >
                    <Box gridArea='box1' background='white' fill pad='medium' elevation='xsmall' gap='small'>
                        <Text>Profile Information</Text>
                        <Box direction='row' gap='medium'>
                            <FormField label='First Name' error={errors.first_name}>
                                <TextInput
                                    name="first_name"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField label="Last Name" error={errors.last_name}>
                                <TextInput
                                    name="last_name"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField label="Status" error={errors.status}>
                                <Select
                                    name="status"
                                    options={values.statuses}
                                    valueKey='id'
                                    value={values.status}
                                    labelKey="name"
                                    onChange={({option}) => setFieldValue("status", option)}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                        </Box>
                        <Box direction='row' gap='medium'>
                            <FormField label="Email" error={errors.email}>
                                <MaskedInput
                                    name="email"
                                    mask={EMAIL_MASK}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField label="Phone" error={errors.phone_number}>
                                <MaskedInput
                                    name='phone_number'
                                    mask={PHONE_NUMBER_MASK}
                                    value={values.phone_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField label="Role" error={errors.roles}>
                                <MultiSelect options={rolesOptions}
                                             preselectedOptions={currentRoles}
                                             handleChange={(selected) => setFieldValue('roles', [...selected])}
                                />
                            </FormField>
                        </Box>
                    </Box>
                    <Box gridArea='box2' border background='white' elevation='xsmall' round='xsmall'
                         pad='medium'
                         gap='medium'
                    >
                        <Text>Photo</Text>
                        <ImageUpload id={values.id} setFieldValue={setFieldValue}/>
                    {errors.avatar && <Text color='red'>{errors.avatar}</Text>}
                    </Box>

                    <Box gridArea='box3' fill gap="small">
                        <Text weight="bold">Caseload</Text>
                        <StaffCaseload
                            providerId={values.id}
                            onChange={useCallback((selected) => {
                                setFieldValue('assignedSessions', selected)
                            }, [setFieldValue])}
                        />
                    </Box>
                </Grid>
            </Box>
            <Box flex={false} pad={{bottom: "small"}} direction="row" gap="small">
                <LoadingButton type='submit' label='Save' onClick={handleSubmit} primary
                               loading={loading || isSubmitting || isValidating}/>
                <Link to='/staff'><Button primary label='Cancel' color='lightgray'/></Link>
            </Box>
        </Box>
    );
};
