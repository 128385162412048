import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {useQuery} from "react-apollo";
import {Box, List, Stack, Text} from "grommet";
import {SearchField} from "../../../components/SearchField";
import Spinner from "../../../components/Spinner";

const LIMIT = 20;

export const CreateSurveyTab = ({fetchQuery, createMutation, refetchQuery, closeModal}) => {
    const {session_id: sessionId, id: clientId} = useParams();
    const [offset, setOffset] = useState(0);
    const [surveys, setSurveys] = useState([]);
    const [filter, setFilter] = useState({});
    const history = useHistory();
    const [createSurvey, {loading}] = useMutation(createMutation, {refetchQueries: [refetchQuery]});
    const {loading: fetchLoading} = useQuery(fetchQuery, {
        variables: {limit: LIMIT, offset, filter},
        fetchPolicy: 'no-cache',
        onCompleted: data => setSurveys(d => d.concat(data?.data || []))
    })
    // Reinitialize state on tab change
    useEffect(() => {
        setSurveys([]);
        setOffset(0);
        setFilter({});
    }, [fetchQuery]);

    return (
        <Stack>
            <Box gap="small">
                <SearchField onSubmit={(term) => {
                    setSurveys([]);
                    setOffset(0);
                    setFilter({title: {_ilike: `%${term}%`}});
                }} textInputProps={{focusIndicator: false}} containerProps={{pad: {horizontal: 'small'}}}/>

                <Box overflow='auto' height={{max: 'medium'}}>
                    {surveys.length > 0 ? <List
                        primaryKey="title"
                        data={surveys}
                        onClickItem={async ({item}) => {
                            await createSurvey({
                                variables: {
                                    data: {
                                        session_id: sessionId,
                                        template_id: item.id
                                    }
                                }
                            });
                            history.push(`/clients/${clientId}/${sessionId}/surveys`);
                            closeModal();
                        }}
                        onMore={() => {
                            setOffset(o => o + LIMIT);
                        }}
                    /> : fetchLoading ? <Spinner/> :
                        <Text margin={{left: 'small', bottom: 'small'}}>No results found</Text>}

                </Box>
            </Box>
            {loading && <Box round='xsmall' fill background={{color: 'white', opacity: 'strong'}}><Spinner fill/></Box>}
        </Stack>
    )
};