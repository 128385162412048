import React from 'react';
import {Box, Button} from "grommet";
import {questionComponentMap} from "../../../../components/survey/Questions";
import {Add, Subtract, Up, Down} from "grommet-icons";

export const QuestionPreviewWrapper = ({question, onActive, onRemove, isActive, onClone, onUp, onDown, showUp, showDown, ...rest}) => (
    <Box direction="column" gap="small">
        <Box onClick={() => {onActive(question.id)}}>
        {questionComponentMap[question.type]({
            ...question,
            ...rest,
            pad: "xsmall",
            border:getActiveBorder(isActive)
        })}
        </Box>
        <Box direction='row' justify="end" gap="small">
            {showUp ? <Button plain icon={<Up/>} onClick={() => {onUp(question.id)}}/> : ''}
            {showDown ? <Button plain icon={<Down/>} onClick={() => {onDown(question.id)}}/> : ''}
            <Button plain icon={<Add/>} onClick={() => {onClone(question.id)}}/>
            <Button plain icon={<Subtract/>} onClick={() => {onRemove(question.id)}}/>
        </Box>
    </Box>
);

const getActiveBorder = (isActive) => {
    return {side: "bottom", color: isActive ? "brand" : "divider"};
};
