import React, {useState} from 'react';
import {Box, Button} from "grommet";
import {Modal} from "./Modal";
import {useMutation} from "@apollo/react-hooks";
import Trash from "./icons/Trash";

export const DeleteButton = ({queriesToRefetch = [], query, variables, text, onRemove = () => {}, buttonProps = {}, iconProps = {}}) => {
    const [removeModalVisible, setRemoveModalVisible] = useState(false);
    const [deleteMutation] = useMutation(query, {
        refetchQueries: queriesToRefetch
    }); // More optimal way to do it would be to delete the current object from the Apollo cache.

    return (
        <Box direction="row">
            <Modal
                visible={removeModalVisible}
                onClose={() => setRemoveModalVisible(false)}
                text={text || 'Are you sure you want to delete this record?'}
                acceptLabel='Remove'
                accept={async (doneLoading) => {
                    await deleteMutation({variables});
                    doneLoading();
                    setRemoveModalVisible(false);
                    onRemove();
                }}
            />
            <Button onClick={() => setRemoveModalVisible(true)} icon={<Trash width='21px' height='21px' {...iconProps}/>} {...buttonProps}/>
        </Box>
    );
};