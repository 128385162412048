import React from 'react';
import {Box, Text} from "grommet";
import {MeetingTypeTags} from "../../../lib/constants";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";

const FETCH_TYPES = gql`
    query {
        types: meeting_type {
            id
            name
        }
    }
`;

export const Legend = () => {
    const {data} = useQuery(FETCH_TYPES);

    return (
        <Box direction='row' gap='small' alignSelf='end'>
            <Text weight='bold'>Meeting Type</Text>
            {data?.types.map((type) => (
                <Box direction='row' gap='xsmall' key={type.id}>
                    <Box width='20px' height='20px' round='full' background={MeetingTypeTags[type.id].color}/>
                    <Text>{type.name}</Text>
                </Box>
            ))}
        </Box>
    );
};