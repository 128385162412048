import React from "react";
import browserHistory from "./browserHistory";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // Note: Upon writing this code Sentry automatically logs this error
        console.log('⚠️', error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            browserHistory.push('/error')
        }

        return this.props.children;
    }
}
