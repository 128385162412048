import React from 'react';
import {FormField, Paragraph, TextArea} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";

export const ParagraphElement = ({text}) => {
    return (
        <Paragraph margin='none'>{text}</Paragraph>
    )
};

ParagraphElement.defaultSize = {
    w: 2,
    h: 2,
    minW: 1,
    minH: 1,
};

const ParagraphSchema = Yup.object().shape({
    text: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('This field is required'),
});

export const ParagraphFieldProperties = ({text = '', updateField, id, item}) => {
    return (

    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => {
            const dimensions = {};
            // if (item.h < Math.sqrt(values.title.length * (6 - values.level))) {
            //     dimensions['h'] = Math.sqrt(values.title.length * (6 - values.level));
            // }
            updateField(id, {...values, dimensions})
        }}
        initialValues={{text}}
        validationSchema={ParagraphSchema}
        enableReinitialize
        onSubmit={() => {
        }}
    >
        {({errors, values, handleChange, setFieldValue}) => (
            <Form>
                <FormField label="Text" error={errors.text}>
                    <TextArea
                        name="text"
                        value={values.text}
                        onChange={handleChange}
                    />
                </FormField>
            </Form>
        )
        }
    </Formik>
)}