import React from "react";
import {withRouter} from "react-router-dom";
import {Anchor} from "grommet";

const RoutedAnchor = ({path, method = 'push', onClick = () => {}, history, ...rest}) =>
    <Anchor
        {...rest}
        href={path}
        onClick={(event, ...args) => {
            const modifierKey = event.ctrlKey || event.metaKey;
            // if the user right-clicked in the Anchor we should let it go
            if (modifierKey) {
                return;
            }
            event.preventDefault();
            history[method](path);
            onClick(event, ...args);
        }}
    />;

export default withRouter(RoutedAnchor);
