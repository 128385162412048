import React, {useMemo, useState} from "react";
import {Box, Button, Text} from "grommet";
import SignaturePad from "react-signature-pad-wrapper";
import axios from "axios";


const emptyFunction = () => {}
// TODO: Consume already created digital signatures
const SignaturePadBox = ({ signeeData, disabled=false, onSign=emptyFunction, onClear=emptyFunction}) => {
    const [digitalSignature, setDigitalSignature] = useState(undefined)
    const [signaturePad, setSignaturePad] = useState(undefined);

    const {name: label, fullName} = signeeData

    const clearSignature = () => {
        if (signaturePad) {
            signaturePad.instance.clear();
        }
        setDigitalSignature(undefined)
        onClear()
    }

    useMemo(() => {
        if (signaturePad){
            console.log('disabled=',disabled)
            if (disabled) {
                console.log('SPB: turning off')
                signaturePad.off()
            } else {
                console.log('SPB: turning on')
                signaturePad.on()
            }

            if(signeeData.signature){
                signaturePad.fromData(signeeData.signature)
                setDigitalSignature(signeeData)
            }
        }
    }, [disabled, signaturePad, signeeData])

    const getUserIpAddress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        return res.data.IPv4
    }

    function getCurrentDatetime() {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: "numeric",
            minute: '2-digit',
            hour12: true
        };

        return new Date().toLocaleString("en-US", options);
    }

    const signDigitally = async () => {
        if (signaturePad.isEmpty()) {
            // eslint-disable-next-line no-alert
            alert('Please provide a signature first.');
        } else {
            // TODO: get the user's name
            const datetime = getCurrentDatetime();
            const ip = await getUserIpAddress()

            const signature = {
                "signature": signaturePad.instance.toData(),
                'ip': ip,
                'datetime': datetime,
            }
            setDigitalSignature(signature)
            onSign(signature)
            console.log(signature)
        }
    }

    return (
        <Box direction='column' gap='small'>
            <Text size='16px'>{label}</Text>
            <Box border={{color: 'divider', size: 'xsmall'}}
                 height="220px"
                 width="350px"
                 round='5px'
            >
                <SignaturePad redrawOnResize={true} ref={(ref) => {

                    setSignaturePad(ref)
                }}/>
            </Box>
            <Box direction='row' justify='between'>
                <Button onClick={clearSignature} disabled={disabled}>Clear</Button>
                <Button onClick={signDigitally} disabled={disabled}>Save</Button>
            </Box>
            {
                digitalSignature &&
                <Box>
                    <Text>Digitally signed by
                        <Text weight='bold'> {fullName}</Text> <br/>
                        from  <Text weight='bold'> {digitalSignature.ip} </Text> on
                        <Text weight='bold'> {digitalSignature.datetime} </Text>
                    </Text>
                </Box>
            }
        </Box>
    )
}

export default SignaturePadBox
