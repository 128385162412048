import React from 'react'
import {colors} from "../../theme";

const Measurement = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 25 23" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M18.011 10.877c0-.671.233-1.244.697-1.715a2.282 2.282 0 011.499-.7l.196-.006h1.528c.663 0 1.23.236 1.694.706.418.424.648.93.69 1.517l.007.198v8.802c0 .67-.233 1.244-.697 1.714a2.282 2.282 0 01-1.498.7l-.196.007h-1.528c-.664 0-1.23-.236-1.695-.707a2.335 2.335 0 01-.69-1.516l-.007-.198v-8.802zm1.728 0v8.802c0 .183.065.339.198.473.08.081.167.137.259.168l.094.023.113.01h1.528a.63.63 0 00.465-.2c.08-.081.136-.17.167-.264l.023-.095.008-.115v-8.802a.649.649 0 00-.198-.474.666.666 0 00-.258-.168l-.094-.023-.113-.009h-1.528a.63.63 0 00-.466.2.649.649 0 00-.198.474zm-18.839 4c0-.671.233-1.244.697-1.715a2.282 2.282 0 011.499-.7l.196-.006h1.527c.664 0 1.23.236 1.695.706.418.424.648.93.69 1.517l.007.198v4.802c0 .67-.233 1.244-.697 1.714a2.282 2.282 0 01-1.498.7l-.197.007H3.292c-.664 0-1.23-.236-1.695-.707a2.335 2.335 0 01-.69-1.516L.9 19.679v-4.802zm1.728 0v4.802c0 .183.065.339.198.473a.635.635 0 00.36.192l.106.008h1.527a.63.63 0 00.466-.2.651.651 0 00.19-.367l.008-.106v-4.802a.649.649 0 00-.198-.474.635.635 0 00-.36-.192l-.106-.008H3.292a.63.63 0 00-.466.2.649.649 0 00-.198.474z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <path
                d="M22.613.101a.958.958 0 01.954 1.659l-.097.056-5.75 2.875a.958.958 0 01-.739.05l-.118-.05-5.24-2.62-9.115 6.381c-.4.28-.94.212-1.26-.141l-.075-.095a.959.959 0 01.142-1.26l.094-.074L10.992.173a.958.958 0 01.858-.122l.12.05 5.322 2.66 5.321-2.66z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
            <path
                d="M8.9 8.444c0-.646.256-1.198.763-1.648a2.63 2.63 0 011.601-.665l.236-.009h1.667c.718 0 1.332.225 1.837.674.456.405.709.893.755 1.457l.008.191v11.334c0 .646-.256 1.198-.763 1.649a2.63 2.63 0 01-1.602.665l-.235.008H11.5c-.719 0-1.333-.225-1.837-.673-.457-.406-.71-.894-.755-1.458l-.008-.191V8.444zm1.867 0v11.334c0 .171.07.318.213.446.118.104.251.166.403.187l.117.008h1.667a.753.753 0 00.52-.195.594.594 0 00.205-.346l.008-.1V8.444a.576.576 0 00-.214-.446.743.743 0 00-.402-.187l-.117-.007H11.5a.753.753 0 00-.52.194.576.576 0 00-.213.446z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
        </g>
    </svg>
);

export default Measurement
