import React from 'react';
import Page from "../../components/Page";
import {WorkDays} from "./components/WorkDays";
import {Breaks} from "./components/Breaks";
import {Box} from "grommet";
import {Timeslots} from "./components/Timeslots";
import {userHasNecessaryRole} from "../../services/auth/service";
import {PROVIDER} from "../../lib/constants";
import {ServiceTypes} from "./components/ServiceTypes";

export const WorkSchedulePage = () => {
    return (
        <Page title='Work Schedule' boxProps={{overflow: 'scroll', flex: false}}>
            <Box direction="row-responsive" gap="small" flex={false}>
                <WorkDays/>
                <Breaks/>
            </Box>
            <Box flex={false} direction="row-responsive" gap="small">
                <Timeslots/>
                {userHasNecessaryRole(PROVIDER) && <ServiceTypes/>}
            </Box>
        </Page>
    );
}