import React, {useEffect, useState} from 'react';
import Chart from 'chart.js';
import {Box} from "grommet";
import {dateFormat} from "./constants";

export const LineChart = ({
                              data, labels, colors, legend, width, height,
                              minTick, maxTick, stepSize, categoricalYAxis,
                              multipleLines, comparison
                          }) => {
    let chartRef = React.createRef();
    const [lineChart, setLineChart] = useState();

    useEffect(() => {

        if (lineChart) {
            lineChart.destroy();
        }

        let ticks = {
            min: minTick,
            max: maxTick,
            stepSize: stepSize,
            fontColor: '#4C6377'
        };

        if (categoricalYAxis) {
            ticks = Object.assign({}, ticks, {
                callback: function (value, index, values) {
                    switch (value) {
                        case 2.5:
                            return 'MINIMAL OR NONE';
                        case 7.5:
                            return 'MILD';
                        case 12.5:
                            return 'MODERATE';
                        case 17.5:
                            return 'MODERATELY SEVERE';
                        case 22.5:
                            return 'SEVERE';
                        default:
                            return '';
                    }
                }
            });
        }

        let datasets = [];
        if (multipleLines) {
            Object.keys(data[0].score).forEach((key, index) => {
                datasets.push({
                    data: data.map(d => {
                        return {
                            x: d.updated_at,
                            y: d.score[key]
                        }
                    }),
                    label: labels[index],
                    borderColor: colors[index],
                    cubicInterpolationMode: 'monotone',
                    fill: false
                });
            });
        } else if (comparison) {
            const session_ids = [...new Set(data.map(item => item.session_id))];
            session_ids.forEach((session_id, index) => {
                datasets.push({
                    data: data.filter(d => d.session_id === session_id)
                        .map(d => {
                            return {
                                x: d.updated_at,
                                y: d.score
                            }
                        }),
                    label: labels[session_id],
                    borderColor: colors[session_id],
                    cubicInterpolationMode: 'monotone',
                    fill: false
                });
            })

        } else {
            datasets.push({
                data: data.map(d => {
                    return {
                        x: d.updated_at,
                        y: d.score
                    }
                }),
                label: 'Score',
                borderColor: colors[0],
                cubicInterpolationMode: 'monotone',
                fill: false
            });
        }

        const maxLengthOfDatasets = Math.max(...datasets.map(dataset => dataset.data.length));

        const chart = new Chart(chartRef.current, {
            type: 'line',
            options: {
                tooltips: {
                    mode: 'nearest',
                    intersect: false,
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return '';
                        },
                        label: function(tooltipItem, data) {
                            let measurementDate = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x || '';
                            measurementDate = new Date(measurementDate).toLocaleDateString("en-US", dateFormat);

                            let client = data.datasets[tooltipItem.datasetIndex].label;
                            let score = tooltipItem.yLabel;

                            return client + ", " + measurementDate + ': ' + score
                        }
                    },
                },
                legend: {
                    display: legend
                },
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: ticks
                    }],
                    xAxes: [{
                        distribution: 'series',
                        ticks: {
                            display: false
                        }
                    }]
                }
            },
            data: {
                labels: [...Array(maxLengthOfDatasets).keys()], // Generate the x-axis to be range of numbers from 0 to maxLengthOfDatasets
                datasets: datasets
            }
        });
        setLineChart(chart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Box width={width}
             round='xsmall'
             background='white'
             pad='small'
             elevation='xsmall'>
            <canvas ref={chartRef} height={height}/>
        </Box>
    )
};

export default LineChart;
