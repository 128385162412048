import {Box, Button, Meter, Text} from "grommet";
import ClientIcon from "../../../components/icons/Client";
import React from "react";
import {colors} from "../../../theme";
import RoutedButton from "../../../components/RoutedButton";

export const StartImportView = ({totalClients, importClients, backToPreviousStage}) => {

    const renderStartImportButton = () => {
        return (
            <React.Fragment>
                <Text color='gray' margin={{top: 'small'}}>To review the clients please see the table below</Text>
                <Text color='gray'>To Import them please click Start Import</Text>
                <Button label="Start Import"
                        onClick={importClients}
                        fill='horizontal'
                        margin={{top: "medium"}} primary
                />
            </React.Fragment>
        );
    };

    const renderBackToFileUpload = () => {
        return (
            <React.Fragment>
                <Text color='gray' margin={{top: 'small'}}>No clients are found in this file</Text>
                <Text color='gray'>To Import a new file please click Import a New File</Text>
                <Button label="Import a New File"
                        onClick={backToPreviousStage}
                        fill='horizontal'
                        margin={{top: "medium"}} primary
                />
            </React.Fragment>
        );
    };

    return (
        <Box align='center' pad='large' fill justify='center'>
            <Box gap='xsmall' align='center' pad='medium'>
                <ClientIcon active style={{width: '37px', height: '38px'}}/>
                <Text weight='bold' size="14px">{totalClients} Clients Found</Text>
                {totalClients > 0 ? renderStartImportButton() : renderBackToFileUpload()}
            </Box>
        </Box>
    );
};


const ProgressBar = ({values, ...rest}) => {
    return <Meter type="bar"
                  thickness="small"
                  round
                  background="light-2"
                  values={values}
                  {...rest}
    />
};

export const ImportProgress = ({currClientNumber, totalClients}) => {

    const progressInPercents = (currClientNumber / totalClients) * 100;

    return (
        <Box align='center' pad='large' fill justify='start' gap="medium">
            <ProgressBar values={[{
                "value": progressInPercents,
                "color": colors.green,
                "highlight": false,
                "label": "import-progress"
            }]}
            />
            <Text weight="bold">Please Wait</Text>
            <Text weight="bold">Importing {currClientNumber} out of {totalClients} Clients</Text>
        </Box>
    );
};

export const ImportResultsView = ({failedClients, totalClients}) => {

    const failedClientsCount = failedClients.length;
    const importedClientsCount = totalClients - failedClientsCount;

    const failedClientsSummary = failedClients
        .map(client => `${client.firstName} ${client.lastName} (row ${client.rowNumber})`)
        .join(', ');

    return (
        <Box pad='large' fill gap='medium'>
            <Box align='center' justify='start' gap='medium'>
                <ProgressBar values={[{
                    "value": 100,
                    "color": colors.green,
                    "highlight": false,
                    "label": "import-progress"
                }]}
                />
                <Text weight="bold">Finished!</Text>
                <Text weight="bold">{importedClientsCount} out of {totalClients} Clients are Imported</Text>
                <RoutedButton path='/clients' label='View Clients Page' primary margin='small'/>
            </Box>

            {
                failedClientsCount > 0 &&
                <Box align="start" gap="small" margin={{top: "small"}}>
                    <Text>{failedClientsCount} clients could not get imported</Text>
                    <Text color='grey'>These are</Text>
                    <Box fill
                         border={{"color": "border", "size": "xsmall"}}
                         height={{max: "100px"}}
                         overflow="scroll"
                    >
                        <Text color='grey'>{failedClientsSummary}</Text>
                    </Box>
                </Box>
            }

        </Box>
    )
};
