import React from 'react';
import * as Yup from "yup";
import {Box, FormField, Grid, MaskedInput, TextInput} from "grommet";
import Step1 from "./Step1";
import {AddressFields} from "./AddressFields";
import {PHONE_NUMBER_MASK} from "../../lib/constants";

const Step2 = ({handleChange, handleBlur, errors, values, setFieldValue}) => {
    return (
        <Grid
            gap='small'
            areas={
                [
                {name: 'name', start: [0, 0], end: [1, 0]},
                {name: 'phone', start: [2, 0], end: [2, 0]},
                {name: 'address', start: [0, 1], end: [2, 1]},
            ]}
            columns={['auto', 'auto', 'auto']}
            rows={['auto', 'auto', 'auto']}
        >
            <Box gridArea='name'>
                <FormField label="Company Name" error={errors.company_name}>
                    <TextInput
                        name="company_name"
                        value={values.company_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </Box>
            <Box gridArea='phone'>
                <FormField label="Company Phone" error={errors.company_phone_number}>
                    <MaskedInput
                        name='company_phone_number'
                        mask={PHONE_NUMBER_MASK}
                        value={values.company_phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </Box>
            <AddressFields
                heading={false}
                gridArea='address'
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                errors={errors.company_address || {}}
                values={values.company_address}
                field='company_address'
            />
        </Grid>
    )
};

Step2.label = 'Company Details';
Step2.validationSchema = Yup.object().shape({
    company_name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    company_phone_number: Yup.string()
        .test('len', 'Phone number is not valid', value => !value || value.length === 14),
    company_address: Yup.object().shape({
        address: Yup.string(),
        state: Yup.string(),
        city: Yup.string(),
        zip: Yup.string().test('len', 'Invalid', val => val?.length === 5),
    }),
});
Step2.initialValues = {
    company_name: '',
    company_phone_number: '',
    company_address: {
        address: '',
        state: '',
        city: '',
        zip: ''
    },
};
Step2.url = 'company-details';
Step2.sidebar = Step1.sidebar;


export default Step2;
