import React, {Fragment, useState} from 'react';
import {Tab, Tabs} from "grommet";
import gql from "graphql-tag";
import {CreateSurveyTab} from "./CreateSurveyTab";

const FETCH_MEASUREMENT_SURVEYS = gql`
    query ($limit: Int! $offset: Int! $filter: measurement_survey_template_bool_exp) {
        id @client
        data: measurement_survey_template(limit: $limit offset: $offset where: $filter) {
            id
            title
            subtitle
        }
    }
`;

const CREATE_MEASUREMENT_SURVEY = gql`
    mutation ($data: measurement_survey_result_insert_input!) {
        insert_measurement_survey_result_one(object: $data) {
            id
        }
    }
`;

const FETCH_SURVEYS = gql`
    query ($limit: Int! $offset: Int! $filter: survey_template_bool_exp) {
        id @client
        data: survey_template(limit: $limit offset: $offset where: $filter) {
            id
            title
            subtitle
        }
    }
`;

const CREATE_SURVEY = gql`
    mutation ($data: survey_result_insert_input!) {
        insert_survey_result_one(object: $data) {
            id
        }
    }
`;

export const CreateSurvey = ({closeModal}) => {
    const [tab, setTab] = useState(0);

    return (
        <Fragment>
            <Tabs activeIndex={tab} onActive={(index) => setTab(index)}>
                <Tab title="Surveys"/>
                <Tab title="Measurement Surveys"/>
            </Tabs>
            <CreateSurveyTab
                closeModal={closeModal}
                refetchQuery='fetchDataClient'
                fetchQuery={tab === 0 ? FETCH_SURVEYS : FETCH_MEASUREMENT_SURVEYS}
                createMutation={tab === 0 ? CREATE_SURVEY : CREATE_MEASUREMENT_SURVEY}
            />
        </Fragment>
    )
}