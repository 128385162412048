import React from 'react';
import {Box, Text} from "grommet";
import {Badge} from "../../../components/Badge";

export const Legend = ({data}) => (
    <Box round='xsmall' border='all'>
        <Box direction='row' gap='xsmall' pad='small'>
            <Box width='100px'>
                <Text color='gray'>Score</Text>
            </Box>
            <Box width='200px'>
                <Text color='gray'>Severity</Text>
            </Box>
            <Box>
                <Text color='gray'>Comments</Text>
            </Box>
        </Box>
        {data.map((row,index) => (
            <Box key={index} align='center' pad='small' border='top' direction='row' gap='xsmall'>
                <Box width='100px'>
                    <Text color='gray'>{row.min_score} - {row.max_score}</Text>
                </Box>
                <Box width='200px'>
                    <Badge text={row.severity} color={row.color}/>
                </Box>
                <Box>
                    <Text color='gray'>{row.comment}</Text>
                </Box>
            </Box>
        ))}
    </Box>
);
