import React, {useState} from 'react';
import {EnumSelect} from "../../../components/EnumSelect";
import gql from "graphql-tag";
import {Box, Text} from "grommet";

const QUERY = gql`
    query {
        options: service_type {
            id
            name
        }
    }
`;

export const ServiceTypeSelect = ({onChange = () => {}, value}) => {
    const [selected, setSelected] = useState([]);
    return (
        <EnumSelect
            query={QUERY}
            value={value}
            onChange={({value, option}) =>
                setSelected(oldSelected => {
                    let result = [...oldSelected, option]
                    if (oldSelected.map(item => item.id).includes(option.id)) {
                        result = oldSelected.filter(oldOption => oldOption.id !== option.id);
                    }
                    onChange(result);
                    return result;
                })
            }
            multiple
            valueLabel={selected.length > 0 ? (
                    <Box pad="small"><Text>{selected.map(item => item.name).join(', ')}</Text></Box>) :
                undefined
            }
            placeholder="Select Service Type"
        />
    )
}