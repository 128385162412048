import React, {useEffect, useState} from 'react';
import {Box, DropButton, Stack} from "grommet";
import RoutedAnchor from "./RoutedAnchor";
import {useChat} from "../lib/useChat";
import {ClientsList} from "../scenes/Communication/components/ClientsList";
import Spinner from "./Spinner";
import {Refresh} from "grommet-icons";
import Chat from "./icons/Chat";
import {GreenDot} from "./GreenDot";

export const ChatNotifications = () => {
    const [visible, setVisible] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const {channels, order, lastMessages, loading, error, activeChannel, initialize} = useChat();
    const lastConsumedMessageIndexes = order.reduce((obj, sid) => { // TODO: Store this in context? It does not rerender on lastConsumedMessageIndex update
        if (activeChannel !== sid)
            obj[sid] = channels[sid].lastConsumedMessageIndex;
        return obj;
    }, {});

    useEffect(() => {
        if (loading || error) return;
        setUnreadMessages(Object.keys(lastMessages).reduce((acc, sid) =>
            acc + (activeChannel !== sid && lastMessages[sid] ?
            lastMessages[sid].index - lastConsumedMessageIndexes[sid] : 0), 0));
    }, [lastMessages, loading, error, lastConsumedMessageIndexes, activeChannel]);

    const renderList = () => (
        <Box round='medium' height={{max: 'medium'}} width={{max: 'medium'}}>
            <ClientsList
                channels={order.map(sid => channels[sid])}
                onChannelClick={() => setVisible(false)}
                lastMessages={lastMessages}
            />
            <Box pad='small' justify="center">
                <RoutedAnchor path='/messenger' label='View all' onClick={() => setVisible(false)}/>
            </Box>
        </Box>
    );

    return (
        <Stack anchor="top-right">
            <DropButton
                label={<Chat style={{width: '28px', height: '28px'}} active={!!unreadMessages || visible}/>}
                focusIndicator={false}
                plain
                disabled={loading}
                onOpen={() => !error && setVisible(true)}
                onClick={() => !!error && initialize()}
                open={visible}
                onClose={() => setVisible(false)}
                dropContent={renderList()}
                dropProps={{align: {top: "bottom"}}}
            />
            {error ? <Refresh color='brand'/> : loading ? <Spinner width="18px" height="18px"/> : !!unreadMessages &&
                <GreenDot/>
            }
        </Stack>
    );
};
