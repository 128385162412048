import React, {useState} from 'react';
import Page from "../../components/Page";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Formik} from "formik";
import TreatmentPlanForm from "./components/TreatmentPlanForm";
import {Box} from "grommet";
import Spinner from "../../components/Spinner";
import {FETCH_OPTIONS_DATA} from "./CreateTreatmentPlanPage";
import SignaturePadBox from "./components/SignaturePadBox";
import RoutedButton from "../../components/RoutedButton";
import {DeleteTxPlanButton} from "./components/DeleteTxPlanButton";
import {useHistory, useParams} from "react-router-dom";
import {LoadingButton} from "../../components/LoadingButton";


const FETCH_DATA = gql`
    query fetchTxPlanById($txPlanId: Int!) {
        id @client
        tx_plan: tx_plan_by_pk(id: $txPlanId) {
            id
            created_at
            name
            status {
                id
                name
            }
            tx_plan_tags: tags {
                tag: tx_plan_tag {
                    id
                    name
                }
            }
            signatures: tx_signatures(order_by: {order: asc}) {
                id
                provider_id
                order
                datetime
                sign_path_data
                ip
                provider {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
            problems(order_by: {order: asc}) {
                id
                name
                goals
                created_at
                order
                related_to_and_evidenced_by
                objectives(order_by: {order: asc}) {
                    id
                    description
                    created_at
                    order
                    interventions(order_by: {order: asc}) {
                        id
                        created_at
                        description
                        order
                        target_date
                        resolved_date
                        status {
                            id
                            name
                        }
                        measurements(order_by: {measurement_survey_template: {abbreviation: asc}}) {
                            measurement_survey_template {
                                id
                                name: abbreviation
                            }
                        }
                    }
                }
            }
        }
    }
`

const DUPLICATE_PLAN = gql`
    mutation duplicateTxPlan($plan: tx_plan_insert_input!) {
        tx_plan: insert_tx_plan_one(object: $plan) {
            id,
            name
            problems {
                name
                order
                goals
                objectives{
                    description
                    interventions{
                        description
                    }
                }
            }
        }
    }`;

const buildDuplicatePlan = (txPlan, session_id) => ({
    session_id,
    name: txPlan.name + ' (copy)',
    problems: {
        data: txPlan.problems.map(problem => ({
            name: problem.name,
            related_to_and_evidenced_by: problem.related_to_and_evidenced_by,
            goals: problem.goals,
            order: problem.order,
            objectives: {
                data: problem.objectives.map(objective => ({
                    description: objective.description,
                    order: objective.order,
                    interventions: {
                        data: objective.interventions.map(intervention => ({
                            description: intervention.description,
                            order: intervention.order,
                            target_date: intervention.target_date,
                            resolved_date: intervention.resolved_date,
                            status: intervention.status,
                            measurements: {
                                data: intervention.measurements.map(measurement => ({
                                    measurement_survey_template_id: measurement.measurement_survey_template.id
                                }))
                            }
                        }))
                    },
                })),
            }
        }))
    }
})

const UPDATE_SIGNATURE = gql`
    mutation($id: Int!, $datetime: String, $ip: String, $signature: jsonb) {
        update_tx_signature_by_pk(_set: {datetime: $datetime, ip: $ip, sign_path_data: $signature}, pk_columns: {id: $id}) {
            id
        }
    }`

const transformSignatures = (signatures) => {
    return signatures.map(signature => {

        return {
            id: signature.id,
            provider_id: signature.provider_id,
            name: `${signature.provider.user.first_name} ${signature.provider.user.last_name}`,
            fullName: `${signature.provider.user.first_name} ${signature.provider.user.last_name}`,
            signature: signature.sign_path_data,
            datetime: signature.datetime,
            ip: signature.ip
        }
    })
}

const ViewTreatmentPlanPage = () => {
    const {treatment_plan_id: txPlanId, id, session_id} = useParams();
    // const txPlanId = (match.params && match.params.id) || 0;
    const history = useHistory();
    const {data: optionsData, loading: optionDataLoading} = useQuery(FETCH_OPTIONS_DATA)
    const {data, loading} = useQuery(FETCH_DATA, {variables: {txPlanId}, fetchPolicy: 'no-cache'});

    /* eslint-disable */
    const [updateSignature, {loading: updateSignatureLoading}] = useMutation(UPDATE_SIGNATURE)
    const [duplicateTxPlan, {loading: duplicateTxPlanLoading}] = useMutation(DUPLICATE_PLAN);
    const [notification, setNotification] = useState(undefined)
    /* eslint-enable */

    if (loading || optionDataLoading) {
        return <Spinner/>
    }

    const {tx_plan, id: currentProviderId} = data;

    const initialValues = {
        "id": tx_plan.id,
        "name": tx_plan.name,
        "created_at": tx_plan.created_at,
        "tags": tx_plan.tx_plan_tags.map(({tag: {id, name}}) => {
            return {id, name}
        }),
        "status": tx_plan.status || undefined,
        "signatures": transformSignatures(tx_plan.signatures),
        "problems": tx_plan.problems.map(problem => {
            return {
                "id": problem.id,
                "name": problem.name,
                "related_to_and_evidenced_by": problem.related_to_and_evidenced_by,
                "goals": problem.goals,
                "created_at": problem.created_at,
                "order": problem.order,
                "objectives": problem.objectives.map(objective => {
                    return {
                        "id": objective.id,
                        "description": objective.description,
                        "created_at": objective.created_at,
                        "order": objective.order,
                        "interventions": objective.interventions.map(intervention => {
                            return {
                                "id": intervention.id,
                                "description": intervention.description,
                                "created_at": intervention.created_at,
                                "order": intervention.order,
                                "target_date": intervention.target_date || undefined,
                                "resolved_date": intervention.resolved_date || undefined,
                                "status": intervention.status || undefined,
                                "measurements": intervention.measurements.map(({
                                                                                   measurement_survey_template: {
                                                                                       id,
                                                                                       name
                                                                                   }
                                                                               }) => {
                                    return {id, name}
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    return (
        <Page>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    // await updateTxPlan(values)
                    // setNotification({
                    //     message: "The TX Plan was successfully updated",
                    //     color: 'green'
                    // })
                }}>
                {(props) =>
                    <Box direction='column'>
                        <Box direction='row' fill pad={{right: 'medium'}} flex='grow'>
                            <Box fill='horizontal' height='fit-content' pad={{bottom: 'medium'}}>
                                <TreatmentPlanForm {...props}
                                                   interventionStatusOptions={optionsData.interventionStatuses}
                                                   interventionMeasurementsOptions={optionsData.measurementOptions}
                                                   disabled={true}
                                />
                                <Page title="Signatures" boxProps={{overflow: 'none'}}>
                                    <Box direction='row'
                                         align='start'
                                         elevation='xsmall'
                                         round='xsmall'
                                         background='white'
                                         pad={{vertical: '15px', horizontal: '25px'}}
                                         wrap
                                         gap='medium'
                                    >
                                        {props.values.signatures.map((item, idx) => {
                                            return <SignaturePadBox key={item.id}
                                                                    signeeData={item}
                                                                    label={item.name}
                                                                    personName={item.fullName}
                                                                    disabled={currentProviderId !== item.provider_id}
                                                                    onSign={async (signatureData) => {
                                                                        const payload = {
                                                                            "id": item.id,
                                                                            "datetime": signatureData.datetime,
                                                                            "ip": signatureData.ip,
                                                                            "signature": signatureData.signature
                                                                        }
                                                                        await updateSignature({variables: payload})
                                                                    }}
                                                                    onClear={async () => {
                                                                        const payload = {
                                                                            "id": item.id,
                                                                            "datetime": null,
                                                                            "ip": null,
                                                                            "signature": null,
                                                                        }
                                                                        await updateSignature({variables: payload})
                                                                    }}
                                            />
                                        })}
                                    </Box>

                                </Page>

                                <Box align="start" pad={{left: 'small'}} direction='row' gap='small'>
                                    <RoutedButton
                                        label='Edit TX Plan'
                                        path={`/clients/${id}/${session_id}/treatment-plans/edit/${txPlanId}`}
                                        primary
                                    />
                                    <DeleteTxPlanButton
                                        audienceId={txPlanId}
                                        queriesToRefetch={['getTxPlans']}
                                        onRemove={() => history.goBack()}
                                    />
                                    <LoadingButton
                                        loading={duplicateTxPlanLoading}
                                        label='Duplicate Plan'
                                        onClick={async () => {
                                            const result = await duplicateTxPlan({
                                                variables: {
                                                    plan: buildDuplicatePlan(initialValues, session_id)
                                                }
                                            })
                                            history.push(`/clients/${id}/${session_id}/treatment-plans/edit/${result.data.tx_plan.id}`);
                                        }
                                        }/>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                }
            </Formik>
        </Page>
    )
}

export default ViewTreatmentPlanPage
