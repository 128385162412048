import React from 'react'
import {colors} from "../../theme";

const Scheduling = ({active = false, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...rest}>
        <g fillRule="evenodd" clipRule="evenodd">
            <path
                d="M10.344 9.375a.96.96 0 00-.694.298.922.922 0 00-.275.67v3.345H6.031a.96.96 0 00-.693.297.922.922 0 00-.276.671v4.313a1 1 0 00.267.684l.009.01.01.008a1 1 0 00.683.267H18.97c.26 0 .488-.093.67-.276a.96.96 0 00.299-.693v-8.625a.888.888 0 00-.298-.671.888.888 0 00-.671-.298h-8.625zm.969 4.313v-2.376h2.374v2.376h-2.374zm4.312 0v-2.376H18v2.376h-2.375zM7 18v-2.375h2.375V18H7zm4.313 0v-2.375h2.374V18h-2.374zm4.312 0v-2.375H18V18h-2.375z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
            <path
                d="M7.469.75a.96.96 0 00-.694.298.922.922 0 00-.275.67v1.907H3.156a2.33 2.33 0 00-1.704.702A2.33 2.33 0 00.75 6.031v15.813c0 .664.235 1.237.702 1.704.467.467 1.04.702 1.704.702h18.688c.664 0 1.237-.235 1.704-.702.467-.467.702-1.04.702-1.704V6.03c0-.664-.235-1.237-.702-1.704a2.329 2.329 0 00-1.704-.702H18.5V1.719a.888.888 0 00-.298-.671.888.888 0 00-.67-.298.96.96 0 00-.694.298.922.922 0 00-.276.67v1.907H8.438V1.719a.888.888 0 00-.297-.671.888.888 0 00-.671-.298zM2.816 5.714a.461.461 0 01.34-.152H6.5V6.75a1 1 0 00.266.684l.01.01.009.008a1 1 0 00.684.267c.26 0 .488-.093.67-.276a.96.96 0 00.299-.693V5.562h8.124V6.75a1 1 0 00.267.684l.009.01.01.008a1 1 0 00.683.267c.26 0 .488-.093.671-.276a.96.96 0 00.298-.693V5.562h3.344a.39.39 0 01.308.142l.009.01.01.009c.099.09.142.187.142.308v15.813a.461.461 0 01-.152.34.423.423 0 01-.317.128H3.156a.497.497 0 01-.34-.129.497.497 0 01-.128-.34V6.032c0-.13.042-.23.128-.317z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
        </g>
    </svg>
)

export default Scheduling
