import React, {Component} from 'react'
import {Box, Text} from "grommet";
import PropTypes from 'prop-types';

class Page extends Component {
    render() {

        const {title, header, children, boxProps = {}} = this.props;

        return (
            <Box fill
                 flex direction='column'
                 pad="small"
                 // height={{min: '500px'}}
                overflow="auto"
                 {...boxProps}
            >
                <Box flex={false} direction="row" justify="between" align="center" margin={{bottom: "small"}}>
                    <Text weight={600}>{title}</Text>
                    {header}
                </Box>
                {children}
            </Box>
        );
    }
}

Page.propTypes = {
    title: PropTypes.string,
    header: PropTypes.element,
};

export default Page
