import {grommet} from "grommet";
import {deepMerge} from "grommet/utils";

export const colors = {
    lightactive: "#09ACF8",
    active: "#4b3cf2",
    purple: "#2A3A9A",
    white: "#FFFFFF",
    green: "#28CC8B",
    brand: "#3F75FF",
    lightgray: "#6B7680",
    // darkgray: "#4C6377",
    statusPending: "#5537ba",
    caption: "#607386",
    sidebarInactive: "#819DB6",
    border: "#E4E8EB",
    divider: "#E4E8EB",
    placeholder: "#6B7680",
    dark: "#091C27",
    text: "#091C27",

    darkgray: '#232323',
    gray: '#4C6377',
    sharpgray: '#919191',
    dirtywhite: '#F9F9F9',
    lightdirtywhite: '#FAFAFA',
    lightborder: '#EFEFEF',
    shadow: 'lightgray',
    whiteblueish: '#F9FCFE',
    blue: '#3F75FF',
    darkblue: '#3964F9',
    blueblack: '#061E65',
    red: '#D0021B',
    lightred: '#FF3C37',
    brightgreen: '#51DA5D',
    yellow: '#FAC200',
    orange: '#FFA33F'

};

export const text = {
    // medium: {
    //     size: "14px",
    //     height: "18px"
    // },
    medium: {
        size: "14px",
        height: "18px"
    },
    mediumLarge: {
        size: "16px",
        height: "22px"
    },
    large: {
        size: "20px",
        height: "24px"
    },
    logo: {
        size: "24px",
        height: "25px",
    },
    logoLarge: {
        size: "35px",
        height: "35px",
    },
};

export const theme = deepMerge(grommet, {
    global: {
        colors: {
            ...colors,
            control: {
                light: colors.brand
            }
        },
        elevation: {
            light: {
                xsmall: "0 2px 12px 0 rgba(119,151,178,0.16)",
                small: "0 4px 14px 0 rgba(127,143,164,0.16)",
                //small: "0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)",
                medium: "0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)",
                large: "0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)",
                xlarge: "0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)"
            }
        },
        font: {
            size: "16px",
            height: "20px",
            family: "Source Sans Pro"
        },
        edgeSize: {
            none: "0px",
            hair: "1px",
            xxsmall: "3px",
            xsmall: "6px",
            small: "12px",
            medium: "24px",
            large: "34px",
            xlarge: "96px",
            responsiveBreakpoint: "small"
        },
        input: {
            weight: 500
        },
        size: {
            avatar: "50px",
            avatarSmall: "24px",
            sidebar: "60px"
        },
        drop: {
            border: {radius: "4px"}
        }
    },

    button: {
        primary: {
            color: {
                dark: colors.lightactive,
                light: colors.brand
            },
        },
        color: {
            light: colors.brand,
            dark: "white"
        },
        border: {
            radius: "4px",
            color: {
                dark: colors.lightactive,
                light: colors.brand
            },
        },
        padding: {
            vertical: '7px',
        },
        extend: {
            fontWeight: 600,
        }
    },
    icon: {
        size: {
            medium: "18px",
            sidebar: "24.5px"
        }
    },
    paragraph: {
        medium: {
            size: "16px",
            height: "20px"
        },
        large: {
            size: "20px",
            height: "24px"
        }
    },
    avatar: {
        size: {
            large: "88px",
            xlarge: "120px"
        }
    },
    text: {
        ...text,
    },
    anchor: {
        fontWeight: 'medium'
    },
    formField: {
        border: false,
        margin: {bottom: 'medium'},
        label: {
            margin: {
                horizontal: 'none'
            },
            color: colors.gray
        }
    },
    textInput: {
        disabled: {
            opacity: 0.8
        },
        extend: {
            background: 'white',
            text: {
                size: "14px",
                height: "18px"
            },
            fontWeight: "normal",
            border: {
                color: colors.brand
            },
            active: {
                border: {
                    color: colors.brand
                }
            }
        }
    },
    maskedInput: {
        disabled: {
            opacity: 0.8
        }
    },
    select: {
        background: 'white'
    },
    checkBox: {
        hover: {
            border: {
                color: {
                    light: 'brand'
                }
            }
        },
        size: '20px',
        extend: {
            fontSize: text.medium.size,
            lineHeight: text.medium.height
        }
    },
    menu: {
        extend: {}
    },
    tabs: {
        header: {
            extend: {
                margin: {
                    horizontal: undefined
                },
            }
        }
    },
    tab: {
        active: {
            color: colors.brand
        },
        border: {
            size: "small",
            color: "divider",
            active: {
                color: 'brand'
            },
            hover: {
                color: 'brand'
            }
        },
        margin: {
            horizontal: undefined
        },
        pad: {
            horizontal: 'small',
            bottom: 'small'
        },
        color: colors.darkgray,

        extend: {
            fontWeight: "bold",
        }
    },
    dataTable: {
        header: {
            pad: {horizontal: '18px', vertical: '15px'}
        },
    },
    table: {
        extend: (props) => `height: auto;`,
        // body: {
        //     extend: `
        //         overflow: auto;
        //         display: block;
        //         width: 100%;
        //         max-height: 200px;
        //     `
        // }
    }
});
