import React from 'react';
import {Box, FormField, Text, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {TimeInputPicker} from "../../../Communication/components/TimeInputPicker";
import moment from "moment";
import {NA} from "../../../../lib/constants";

export const TimeInputField = ({id, title, value, error, disabled, handleChange, preview}) => {
    return (
        <FormField label={title} error={error}>
            {preview ? <Box pad='small' border round='xsmall'><Text>{value ? moment(value).format('HH:mm') : NA}</Text></Box> : <TimeInputPicker
                value={value || ''}
                onChange={handleChange}
                disabled={disabled}
                name={id}
            />}
        </FormField>
    )
};

TimeInputField.defaultSize = {
    w: 2,
    h: 2,
    minW: 2,
    minH: 2,
    maxH: 2
};

TimeInputField.title = 'Time';

const TimeInputSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
});

export const TimeInputFieldProperties = ({title, updateField, id, item}) => {
    return (

        <Formik
            // same as onChange. Formik calls both validate and validationSchema.
            validate={(values) => {
                updateField(id, values)
            }}
            initialValues={{title}}
            validationSchema={TimeInputSchema}
            enableReinitialize
            onSubmit={() => {
            }}
        >
            {({errors, values, handleChange}) => (
                <Form>
                    <FormField label="Title" error={errors.title}>
                        <TextInput
                            name="title"
                            value={values.title || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                </Form>
            )
            }
        </Formik>
    )}