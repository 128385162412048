import * as React from 'react';
import {Box} from "grommet";
import {TemplateHeader} from "../../../components/survey/TemplateHeader";
import {QuestionWrapper} from "../../../components/survey/QuestionWrapper";

const SurveyReport = ({template, answers, surveysCount}) => {
    const {title, subtitle, questions} = template;
    return (
        <Box background="white" round="xsmall" fill overflow='auto' gap='medium'>
            <TemplateHeader title={title} subtitle={subtitle} />
            <Box pad={{horizontal: "large", bottom: 'medium'}}>
                <Box flex={false} gap='small'>
                    {questions.map((question, index) => {
                        return (
                            <Box key={question.id}>
                                <QuestionWrapper
                                    isReport
                                    question={question}
                                    answers={answers[question.id].stats}
                                    number={index+1}
                                    surveysCount={surveysCount}
                                />
                            </Box>)
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default SurveyReport;
