import React, {useState} from 'react';
import {Box, DropButton} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import RoutedAnchor from "../../components/RoutedAnchor";
import {DeleteButton} from "../../components/DeleteButton";
import PlusButtonItem from "../Clients/components/PlusButtonItem";
import {ASSESSMENT, CONSENT, DISCHARGE, MEDICAL, NOTE} from "../../lib/constants";
import {PlusButton} from "../../components/PlusButton";
import {CopyButton} from "./DocumentBuilder/CopyButton";


const dateFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};

const DELETE_MUTATION = gql`
    mutation($id:Int!) {
        delete_document_template(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

const FETCH_DATA = gql`
    query fetch_document_templates($limit: Int! $offset: Int! $orderBy: [document_template_order_by!] $filter: document_template_bool_exp) {
        data: document_template(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            title
            updated_at
            created_at
            company_id
            type_id
            document_type {
                id
                name
            }
        }
        data_aggregate: document_template_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const columns = [
    {
        accessor: 'title',
        Header: 'Title',
        primary: true,
        Cell: ({row: {original: {id, title, type_id}}}) => (
            <Box direction='row' align='center' gap='small'>
                <RoutedAnchor
                    path={`/document-builder/${type_id}/${id}`}
                    label={title}
                    color="black"/>
            </Box>
        ),

    },
    {
        id: 'document_type.id',
        accessor: ({document_type: {name}}) => name,
        Header: 'Type',
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => new Date(created_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Created On',
    },
    {
        id: 'updated_at',
        accessor: ({updated_at}) => new Date(updated_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Last Modified',
    },
    {
        id: "actions",
        sortable: false,
        Header: 'Actions',
        Cell: ({row: {original: {id, company_id}}}) => (
            <Box direction="row">
                <DeleteButton
                    buttonProps={{disabled: !company_id}}
                    query={DELETE_MUTATION}
                    text='Are you sure you want to delete this template?'
                    variables={{id}}
                    queriesToRefetch={['fetch_document_templates']}
                    />
                <CopyButton id={id}/>
            </Box>
        )
    },
];

const DocumentTemplatesPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small' align='center'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({title: {_ilike: `%${term}%`}})
            }/>
            {/*<RoutedButton path='/document-builder' label='New Template' primary/>*/}
            <DropButton
                alignSelf="center"
                dropProps={{align: {top: 'bottom'}}}
                dropContent={
                    <Box background="white" pad="medium" gap='small'>
                        <PlusButtonItem label="New Assessment"
                                        link={`/document-builder/${ASSESSMENT}`}
                        />
                        <PlusButtonItem label="New Consent"
                                        link={`/document-builder/${CONSENT}`}
                        />
                        <PlusButtonItem label="New Medical"
                                        link={`/document-builder/${MEDICAL}`}
                        />
                        <PlusButtonItem label="New Discharge"
                                        link={`/document-builder/${DISCHARGE}`}
                        />
                        <PlusButtonItem label="New Note"
                                        link={`/document-builder/${NOTE}`}
                        />
                    </Box>
                }
            >
                <PlusButton/>
            </DropButton>
        </Box>
    );
    return (
        <Page title="Document Templates" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns}
                       defaultSort={{updated_at: 'desc'}}
            />
        </Page>
    );
};

export default DocumentTemplatesPage
