import React from 'react' ;
// import {Box, Grid, Text} from "grommet";
// import Page from "../../components/Page";
// import {ActivityBox} from "../../components/ActivityBox";
// import {ActivityList} from "../../components/ActivityList";
// import {ActivitySurveyRow} from "../../components/ActivitySurveyRow";
// import {ActivityClientSessionRow} from "../../components/ActivityClientSessionRow";
// import Clients from "../../components/icons/Clients";
// import Survey from "../../components/icons/Survey";
// import Messages from "../../components/icons/Messages";
// import {useQuery} from "@apollo/react-hooks";
// import FETCH_DATA from './DashboardQuery';
import {Redirect} from "react-router-dom";

// const DashboardPage = () => {
//     const {data, loading} = useQuery(FETCH_DATA); // TODO: Handle error
//     const {myClients, mySurveyResults, mySurveyTemplates, lastSurveyResults, lastCreatedSessions} = data || {};
//     return (
//         <Page title='Dashboard'>
//             <Grid
//                 align="start"
//                 columns={{count: "fit", size: "small"}}
//                 gap="medium"
//             >
//                 <ActivityBox color='brand' header={myClients && myClients.aggregate.count} text='Active Clients'/>
//                 <ActivityBox color='green' header={mySurveyResults && mySurveyResults.aggregate.count}
//                              text='Surveys Completed'/>
//                 <ActivityBox color='red' header={mySurveyTemplates && mySurveyTemplates.aggregate.count}
//                              text='Templates Created'/>
//                 <ActivityBox color='active' header='43%' text='Survey Result'/>
//             </Grid>
//             <Box flex={false} pad={{vertical: 'small'}}><Text weight="bold">Recent Activity</Text></Box>
//             <Grid
//                 align="start"
//                 columns={{count: "fit", size: "300px"}}
//                 gap="medium"
//             >
//                 <ActivityList
//                     icon={<Survey active style={{width: '50px', height: '50px'}}/>}
//                     isLoading={loading}
//                     text='Latest Surveys'
//                     data={lastSurveyResults}
//                     rowComponent={ActivitySurveyRow}
//                     buttons={[
//                         {path: '/survey/results', label: 'View Survey Results', primary: true},
//                         {path: '/survey/templates/create', label: 'New Template'}
//                     ]}
//                 />
//                 <ActivityList
//                     icon={<Messages active style={{width: '50px', height: '50px'}}/>}
//                     text='Latest Messages'
//                     buttons={[{path: '/messages', label: 'Coming soon', primary: true, disabled: true}]}
//                 />
//                 <ActivityList
//                     icon={<Clients active style={{width: '50px', height: '50px'}}/>}
//                     isLoading={loading}
//                     text='Latest Registered Clients'
//                     data={lastCreatedSessions}
//                     rowComponent={ActivityClientSessionRow}
//                     buttons={[
//                         {path: '/clients', label: 'View All Clients', primary: true},
//                         {path: '/clients/create', label: 'New Client'}
//                     ]}
//                 />
//             </Grid>
//         </Page>
//     )
// };

// TODO: Make clients the dashboard page until we figure out what to put here.
const DashboardPage = () => {
    return (
        <Redirect to='/clients'/>
    )
};

export default DashboardPage;
