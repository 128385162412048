import React, {useState} from 'react';
import {Box} from "grommet";
import RoutedButton from "../../components/RoutedButton";
import Page from "../../components/Page";
import {Send} from "grommet-icons";
import {SearchField} from "../../components/SearchField";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import RoutedAnchor from "../../components/RoutedAnchor";

const columns = [
    {
        accessor: 'title',
        Header: 'Title',
        Cell: ({row: {original: {id, title}}}) => (
            <Box direction="row">
                <RoutedAnchor
                    path={`/measurement/tools/${id}`}
                    label={title}
                    color="black"
                />
            </Box>
        )
    },
    {
        id: 'questions',
        accessor: ({questions}) => questions.length.toString(),
        Header: 'Number of Questions',
    },
    {
        id: "actions",
        sortable: false,
        Header: 'Actions',
        Cell: ({row: {original: {id}}}) => (
            <Box direction="row">
                <RoutedButton
                    icon={<Send color="sidebarInactive"/>}
                    path={`/survey/assignment/${id}/${true}`}/>
            </Box>
        )
    },
];

const FETCH_DATA = gql`
    query ($limit: Int! $offset: Int! $orderBy: [measurement_survey_template_order_by!] $filter: measurement_survey_template_bool_exp) {
        data: measurement_survey_template(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            title
            questions
        }
        data_aggregate: measurement_survey_template_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const MeasurementSurveyTemplatesPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({title: {_ilike: `%${term}%`}})
            }/>
        </Box>
    );
    return (
        <Page title="Measurement Surveys" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns}/>
        </Page>
    );
};

export default MeasurementSurveyTemplatesPage