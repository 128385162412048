import axios from 'axios';
import {AUTH_TOKEN_KEY, loadState} from '../localStorage'
import ErrorFactory from "../errors/ErrorFactory";

const API_BASE_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;

const API = axios.create({
    baseURL: API_BASE_URL,
    timeout: 30000,
    headers: {'Content-Type': 'application/json'},
});

/*
    The authorization header is added only if the request is to our API to prevent sending credential to third parties.
    This is run on each request so if the token changes (for example as a result of a token refresh) then the next
    request picks up the new token. Also a check for existing authorization value in the request is done to allow
    overriding of the header from the call site if that happened to be necessary for whatever reason.

    Reference: https://github.com/axios/axios/issues/1383#issuecomment-377870814
*/
API.interceptors.request.use(
    config => {
        if (config.baseURL === API_BASE_URL && !config.headers.Authorization) {
            const token = loadState(AUTH_TOKEN_KEY);
            if (token) config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

/**
 * Intercepts api responses that have failed and creates an instance of Error according to the status code.
 *
 * The instance can be accessed via da 'instance' property of the error object, eg:
 *
 * try {
 *     //make some failing request...
 * }
 * catch (error){
 *     throw error.instance;
 * }
 */
API.interceptors.response.use(null,
    error => {
        error = ErrorFactory.makeError(error);
        return Promise.reject(error);
    }
);

export default API
