import {useContext, useEffect, useState} from 'react';
import {ChatContext} from "./ChatProvider";

export const useChat = () => {
    const [state, {initialize}] = useContext(ChatContext);
    return {...state, initialize};
};

const PAGE_SIZE = 20;

export const useChannel = (sid) => {
    const [ready, setReady] = useState(false);
    const [messages, setMessages] = useState([]);
    const [prevPage, setPrevPage] = useState(() => () => {});
    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const [state, {dispatch}] = useContext(ChatContext);
    const channel = state.channels[sid];
    const client = {
        id: channel?.attributes.clientId || null,
        name: (channel && channel.friendlyName.split(' - ')[1]) || '' // TODO: This needs to be handled in a better way
    };

    const loadMessages = (paginator, concat = true) => {
        setHasMore(paginator.hasPrevPage);
        if (paginator.hasPrevPage) {
            setPrevPage(() => paginator.prevPage)
        }
        const newMessages = paginator.items.map(message => mapMessage(message));
        if (concat) {
            setMessages(allMessages => newMessages.concat(allMessages));
        } else {
            setMessages(newMessages);
        }
    };

    const loadMore = () => {
        setLoading(true);
        prevPage().then(p => {
            loadMessages(p);
            setLoading(false);
        });
    };

    const typing = () => {
        channel.typing();
    };

    const sendMessage = (text, params = {}) => {
        const attributes = {sms: false, email: false, ...params};
        channel.sendMessage(text, attributes);
    };

    useEffect(() => {
        if (!channel) return;
        const setTypingStarted = () => setIsTyping(true);
        const setTypingEnded = () => setIsTyping(false);
        const addNewMessage = async (message) => {
            setMessages(allMessages => [...allMessages, mapMessage(message)]);
            await channel.advanceLastConsumedMessageIndex(message.index);
        };
        channel.setAllMessagesConsumed();
        channel.on('typingStarted', setTypingStarted);
        channel.on('typingEnded', setTypingEnded);
        channel.on('messageAdded', addNewMessage);
        return () => {
            channel.removeListener('typingStarted', setTypingStarted);
            channel.removeListener('typingEnded', setTypingEnded);
            channel.removeListener('messageAdded', addNewMessage);
        }
    }, [channel]);

    useEffect(() => {
        if (!channel) return;
        dispatch(['setActiveChannel', sid]);
        channel.getMessages(PAGE_SIZE).then(p => {
            loadMessages(p, false);
            setReady(true);
        });
        return () => {
            dispatch(['setActiveChannel', undefined]);
            setPrevPage(() => () => {});
            setMessages([]);
            setHasMore(false);
            setIsTyping(false);
            setLoading(false);
            setReady(false);
        }
    }, [channel, dispatch, sid]);
    return {messages, loadMore, loading, isTyping, sendMessage, typing, hasMore, client, ready};
};

export const mapMessage = (message) => ({
    // sid: message.sid,
    index: message.index,
    body: message.body,
    author: message.author, // TODO: store first letter only? (for performance benefit as this is not displayed anywhere)
    timestamp: message.timestamp,
    sms: message.attributes.sms,
    email: message.attributes.email
});
