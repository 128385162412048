import React from 'react';
import moment from "moment";
import {Form, Formik} from "formik";
import {initialValues} from "../../Communication/CreateMeetingPage";
import {useHistory} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MeetingDetailsForm} from "../../Communication/components/MeetingDetailsForm";
import {LoadingButton} from "../../../components/LoadingButton";
import gql from "graphql-tag";
import {Box, Button, Layer, Text, Tip} from "grommet";
import {Close, Info} from "grommet-icons";
import * as Yup from "yup";

export const MeetingValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    description: Yup.string()
        .min(2, 'Too Short!')
        .max(4000, 'Too Long!'),
    date: Yup.date()
        .required('Required'),
    start_at: Yup.string()
        .required('Required'),
    sms_notification: Yup.bool(),
    email_notification: Yup.bool(),
    message_notification: Yup.bool(),
    service_type_id: Yup.number().required('Required'),
    // participants: Yup.array().of(Yup.number())
    //     .min(1, 'Select a single client')
    //     .max(1, 'Select a single client')
    //     .required('Required')
    participant: Yup.number()
        .typeError('Required')
        .required('Required'),
    type_id: Yup.number().required('Required'),
    billing_code_id: Yup.number()
});

const FETCH_DATA = gql`
    query fetchMeeting($provider_id: Int!, $id: Int!) {
        id @client @export(as: "provider_id")
        meeting: meeting_by_pk(id: $id) {
            id
            name
            description
            approved
            twilio_room_name
            status
            type_id
            service_type_id
            service_type {
                id
                provider_service_types(where: {provider_id: {_eq: $provider_id}}) {
                    duration
                }
            }
            sms_notification
            email_notification
            message_notification
            approved
            start_at
            end_at
            billing_code_id
            billing_code {
                id
                amount
            }
            meeting_type {
                id
                name
            }
            start_at
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            meeting_participants {
                session {
                    id
                    client {
                        id
                        user {
                            id
                            first_name
                            last_name
                        }
                    }
                }
            }
        }
    }
`;

export const UPDATE_MEETING = gql`
    mutation ($id: Int!, $meeting: meeting_set_input!) {
        update_meeting_by_pk(pk_columns: {id: $id} _set: $meeting) {
            id
            name
            description
            service_type_id
            twilio_room_name
            status
            start_at
            billing_code_id
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            meeting_participants {
                session {
                    id
                    client {
                        id
                        user {
                            id
                            first_name
                            last_name
                        }
                    }
                }
            }

        }
    }
`;

const CREATE_BILL = gql`
    mutation createBill($bill: bill_insert_input!) {
        insert_bill_one(object: $bill) {
            id
        }
    }
`;

export const EditMeeting = ({closeModal, meetingId, sessionId}) => {
    const history = useHistory();
    const {data, loading, refetch} = useQuery(FETCH_DATA, {variables: {id: meetingId}});
    const [updateMeeting, {error}] = useMutation(UPDATE_MEETING);
    const [createBill] = useMutation(CREATE_BILL);

    if (loading) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                name: data?.meeting.name || '',
                description: data?.meeting.description || '',
                date: moment(data?.meeting.start_at).format(),
                service_type_id: data?.meeting.service_type_id || '',
                duration: data?.meeting.service_type?.provider_service_types[0]?.duration || '',
                start_at: moment(data?.meeting.start_at).format('hh:mm a'),
                end_at: moment(data?.meeting.end_at).format('hh:mm a'),
                type_id: data?.meeting.type_id,
                sms_notification: data?.meeting.sms_notification,
                email_notification: data?.meeting.email_notification,
                message_notification: data?.meeting.message_notification,
                billing_code_id: data?.meeting.billing_code_id || undefined,
                participant: sessionId,
            }}
            validationSchema={MeetingValidationSchema}
            onSubmit={async (values, actions) => {
                const {date, participant, start_at, end_at, duration, billing_code_id, ...data} = values;
                const dateString = moment(values.date).format('l');
                const startTime = moment(`${dateString} ${start_at}`, 'l h:mm a');
                const endTime = moment(startTime).add(duration, 'minutes');
                const meeting = {
                    ...data,
                    billing_code_id: billing_code_id || null,
                    start_at: startTime.format(),
                    end_at: endTime.format(),
                };
                try {
                    await updateMeeting({variables: {id: meetingId, meeting}, refetchQueries: ['fetchAppointments']});
                    await refetch();
                    history.replace(`/clients/${meetingId}/${sessionId}/appointments`);
                    closeModal();
                } catch (e) {
                    actions.setSubmitting(false);
                }
            }}
        >
            {(props) =>
                <Layer
                    position="center"
                    onEsc={closeModal}
                    onClickOutside={closeModal}
                >
                    <Box width={{min: 'medium'}} gap='small'>
                        <Box direction='row' justify='between' align='center'>
                            <Text weight='bold' margin={{left: 'small'}}>Edit Appointment</Text>
                            <Button icon={<Close/>} onClick={closeModal}/>
                        </Box>
                        <Form>
                            <MeetingDetailsForm {...props} error={error}/>
                            <Box direction="row" gap="small">
                                <LoadingButton margin={{left: 'medium', bottom: 'medium'}} alignSelf='start' primary
                                               label="Save"
                                               type="submit" loading={loading || props.isSubmitting}/>
                                <Button label={<Text color='white'>Approve Meeting</Text>} alignSelf="start" primary
                                        color='status-ok' disabled={data?.meeting?.approved} onClick={async () => {
                                    await updateMeeting({
                                        variables: {id: meetingId, meeting: {approved: true}},
                                        refetchQueries: ['fetchAppointments']
                                    });
                                    if (data?.meeting?.billing_code_id ) {
                                        await createBill({
                                            variables: {
                                                bill: {
                                                    meeting_id: meetingId,
                                                    amount: data?.meeting?.billing_code?.amount,
                                                    provider_id: data?.meeting?.provider?.id,
                                                    session_id: sessionId,
                                                }
                                            }
                                        })
                                    }
                                    await refetch();
                                    closeModal();
                                }}
                                />
                                {!data?.meeting?.approved && <Tip content="A bill for this appoint will be generated once you approve it.">
                                    <Button plain icon={<Info/>} alignSelf="start" margin={{top: 'small'}}/>
                                </Tip>}
                            </Box>
                        </Form>
                    </Box></Layer>}
        </Formik>
    );
}