import React from "react";
import {colors} from "../../theme";

const LogoutIcon = ({active=true, ...rest}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        {...rest}
    >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fillRule="nonzero" transform="translate(-701 -415)">
                <g transform="translate(701 415)">
                    <path
                        fill={active ? colors.brand : colors.gray}
                        d="M1.188 11.875c-.322 0-.6-.118-.835-.353A1.141 1.141 0 010 10.687V3.564c0-.99.346-1.831 1.04-2.524C1.731.346 2.572 0 3.561 0h7.126c.321 0 .6.118.834.353.235.235.353.513.353.835 0 .321-.118.6-.353.834a1.141 1.141 0 01-.835.353H3.564c-.322 0-.6.118-.835.353a1.141 1.141 0 00-.353.834v7.126c0 .321-.118.6-.353.834a1.141 1.141 0 01-.834.353zm35.624 0c-.321 0-.6-.118-.834-.353a1.141 1.141 0 01-.353-.835V3.564c0-.322-.118-.6-.353-.835a1.141 1.141 0 00-.834-.353h-7.126c-.321 0-.6-.118-.834-.353a1.141 1.141 0 01-.353-.834c0-.322.118-.6.353-.835S26.99 0 27.312 0h7.125c.99 0 1.831.346 2.524 1.04C37.654 1.731 38 2.572 38 3.561v7.126c0 .321-.118.6-.353.834a1.141 1.141 0 01-.834.353zM3.563 38c-.99 0-1.83-.346-2.523-1.04C.346 36.269 0 35.428 0 34.438v-7.124c0-.322.118-.6.353-.835s.513-.353.835-.353c.321 0 .6.118.834.353.235.235.353.513.353.834v7.125c0 .322.118.6.353.835s.513.353.834.353h7.126c.321 0 .6.118.834.353.235.235.353.513.353.834 0 .322-.118.6-.353.835a1.141 1.141 0 01-.835.353H3.564zm23.75 0c-.321 0-.6-.118-.834-.353a1.141 1.141 0 01-.353-.834c0-.322.118-.6.353-.835s.513-.353.834-.353h7.125c.322 0 .6-.118.835-.353s.353-.513.353-.834v-7.126c0-.321.118-.6.353-.834.235-.235.513-.353.834-.353.322 0 .6.118.835.353s.353.513.353.834v7.125c0 .99-.346 1.831-1.04 2.524-.692.693-1.533 1.039-2.523 1.039h-7.124z"
                    ></path>
                    <path
                        fill={active ? colors.green : colors.darkgray}
                        d="M19 21.375c1.262 0 2.474.186 3.637.557 1.162.395 2.232.94 3.21 1.632a11.982 11.982 0 012.579 2.487c.742.99 1.323 2.066 1.744 3.228.05.173.068.334.056.483a1.218 1.218 0 01-.13.445 1.218 1.218 0 01-.279.371 1.105 1.105 0 01-.39.223 1.716 1.716 0 01-.203.055 1.166 1.166 0 01-.89-.204 1.099 1.099 0 01-.428-.556 8.7 8.7 0 00-1.373-2.598 9.03 9.03 0 00-2.06-2.004 10.299 10.299 0 00-2.578-1.299 9.69 9.69 0 00-4.49-.315 9.151 9.151 0 00-1.522.39 9.746 9.746 0 00-3.563 2.245 9.041 9.041 0 00-2.226 3.58 1.393 1.393 0 01-.446.557c-.197.149-.42.223-.668.223-.074 0-.142-.006-.204-.019a1.716 1.716 0 01-.204-.055 1.105 1.105 0 01-.39-.223 1.218 1.218 0 01-.278-.371 1.218 1.218 0 01-.13-.445 1.35 1.35 0 01.056-.483 11.994 11.994 0 012.82-4.453 11.605 11.605 0 014.454-2.783c.618-.223 1.255-.39 1.91-.501A11.838 11.838 0 0119 21.375zm0-15.438c1.064 0 2.066.198 3.006.594a8.11 8.11 0 012.45 1.67 8.11 8.11 0 011.669 2.45c.396.94.594 1.941.594 3.005a7.666 7.666 0 01-.594 3.006 8.11 8.11 0 01-1.67 2.45 8.11 8.11 0 01-2.45 1.67 7.666 7.666 0 01-3.005.593 7.666 7.666 0 01-3.006-.594 8.11 8.11 0 01-2.45-1.67 8.11 8.11 0 01-1.669-2.449 7.666 7.666 0 01-.594-3.006c0-1.064.198-2.066.594-3.006a8.11 8.11 0 011.67-2.449 8.11 8.11 0 012.45-1.67A7.666 7.666 0 0119 5.938zm0 2.375c-1.484 0-2.746.52-3.785 1.56-1.04 1.038-1.559 2.3-1.559 3.784 0 1.485.52 2.746 1.559 3.785C16.254 18.481 17.515 19 19 19c1.484 0 2.746-.52 3.785-1.559 1.04-1.039 1.559-2.3 1.559-3.785 0-1.484-.52-2.746-1.559-3.785-1.039-1.039-2.3-1.559-3.785-1.559z"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

export default LogoutIcon;
