import React from "react";
import {Box, FormField, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";

const SurveyHeaderSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
    subtitle: Yup.string()
});

export const SurveyEditPanel = ({title, subtitle, updateHeader}) => (
    <Box pad="small">
        <Formik
            // same as onChange. Formik calls both validate and validationSchema.
            validate={({title, subtitle}) => updateHeader({title, subtitle})}
            initialValues={{title, subtitle}}
            validationSchema={SurveyHeaderSchema}
            enableReinitialize
            onSubmit={() => {
            }}
            render={({errors, values, handleChange}) => (
                <Form>
                    <FormField label="Title" error={errors.title}>
                        <TextInput
                            name="title"
                            value={values.title || ""}
                            onChange={handleChange}
                        />
                    </FormField>

                    <FormField label="Subtitle" error={errors.subtitle}>
                        <TextInput
                            name="subtitle"
                            value={values.subtitle || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                </Form>
            )
            }
        />
    </Box>
)
