import React, {useMemo} from 'react';
import Page from "../../components/Page";
import {Box, ResponsiveContext, Grid} from "grommet";
import {ClientsSidebar} from "./components/ClientsSidebar";
import {useChat} from "../../lib/useChat";
import {Chat} from "./components/Chat";
import Spinner from "../../components/Spinner";
import MessengerErrorPage from "./MessengerErrorPage";

export const ChatPlaceholder = ({children}) => (
    <Box elevation='xsmall' background='white' round="xsmall" pad='medium' fill>
        {children}
    </Box>
);
// TODO: Extend Page component, call useChat and display error on one level below this component and all other using chat functionality
const MessengerPage = ({match}) => {
    const activeChannelSid = useMemo(() => match.params?.activeChannelSid, [match.params]);
    const {loading, error, initialize} = useChat(); // TODO: Causes a lot of rerenders. This needs to be optimized
    if (error) {
        return (<MessengerErrorPage initialize={initialize}/>);
    }

    return (
        <Page title='Messenger'>
            <Box flex >
                <ResponsiveContext.Consumer>
                    {(deviceSize) => (
                        <Grid
                            gap='medium'
                            fill
                            columns={deviceSize === 'small' ? "fill" : ["auto", "1/4"]}
                        >
                            <Box fill height={{min: 'medium'}} >
                                {loading ?
                                    <ChatPlaceholder><Spinner fill/></ChatPlaceholder> :
                                    activeChannelSid ?
                                        <Chat sid={activeChannelSid}/> :
                                        <ChatPlaceholder>Choose a client from the right sidebar to begin
                                            conversation</ChatPlaceholder>
                                }
                            </Box>
                            <Box fill direction='row' justify='between' height={{min: 'medium'}} >
                                <ClientsSidebar activeChannelSid={activeChannelSid}/>
                            </Box>
                        </Grid>
                    )}
                </ResponsiveContext.Consumer>
            </Box>
        </Page>)
};

export default MessengerPage;
