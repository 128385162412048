import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, Text} from "grommet";
import Page from "../../components/Page";
import ReactToPrint from "react-to-print";
import {Print} from "grommet-icons";
import API from "../../services/api";
import Spinner from "../../components/Spinner";
import {ActivityBox} from "../../components/ActivityBox";
import moment from "moment";
import SurveyReport from "./components/SurveyReport";
import {ParticipantsList} from "./components/ParticipantsList";
import {NA} from "../../lib/constants";
import {BasicReportPrintPage} from "./BasicReportPrintPage";

const BasicReportPage = ({match}) => {
    const id = match.params.id;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState({survey: {questions: []}, sessions: []});
    const printRef = useRef();
    useEffect(() => {
        setLoading(true);
        setError(undefined);
        (async () => {
            try {
                const {data} = await API.get('/survey-result/basic-report', {params: {id}});
                setData(data);
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        })();
    }, [id]);

    if (loading) {
        return <Spinner fill/>
    }

    if (error) {
        console.log(error);
        return 'Error loading report'
    }
    return (
        <Page title='Report Results' boxProps={{gap: "medium"}}>
            <Box round="xsmall" border direction="row" background="white" justify='between' pad='medium'>
                <Box gap="small" justify="center">
                    <Text weight="bold">Survey</Text>
                    <Text color="gray">{data.survey.title}</Text>
                </Box>
                <ReactToPrint trigger={() => <Button plain icon={<Print/>}/>} content={() => printRef.current}/>
            </Box>
            <ParticipantsList boxProps={{flex: false}} sessions={data.sessions}/>
            <Grid
                align="start"
                columns={{count: "fit", size: "small"}}
                gap="medium"
            >
                <ActivityBox color='brand'
                             header={(Math.round(data.numberOfResults / data.allSurveysSentCount * 100) || 0).toString() + '%'}
                             text='Completed the Survey'/>
                <ActivityBox color='green' header={data.numberOfResults} text='Participated in Survey'/>
                <ActivityBox color='red' header={data.allSurveysSentCount} text='Received Survey'/>
                <ActivityBox color='active'
                             header={data.averageDuration === 0 ? NA : moment.duration(data.averageDuration, 'milliseconds').humanize()}
                             text='Average Duration'/>
            </Grid>
            <Box background='divider' elevation='xsmall' round="xsmall" pad="large">
                <Box background='white' round="xsmall">
                    <SurveyReport
                        template={data.survey}
                        answers={data.data}
                        surveysCount={data.numberOfResults}
                    />
                </Box>
            </Box>
            <BasicReportPrintPage survey={data} ref={printRef}/>
        </Page>
    )
};

export default BasicReportPage;
