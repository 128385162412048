import React, {useCallback, useContext} from "react";
import {withApollo} from 'react-apollo';
import {Box, Button, Text, ResponsiveContext} from "grommet";
import UserMenu from "../components/UserMenu";
// import {MailOption} from "grommet-icons";
import {ChatNotifications} from "../components/ChatNotifications";
import {withRouter} from "react-router-dom";
import {AUTH_TOKEN_KEY, REFRESH_TOKEN, removeState} from "../localStorage";
import Brand from "../components/Brand";
import {GeneralNotifications} from "../components/GeneralNotifications";
import {closeSubscription} from "../index";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../components/Spinner";
import {FETCH_DATA} from "../scenes/Profile/ProfilePage";

const Header = ({userSession, onToggleSidebar, history, client}) => {
    const size = useContext(ResponsiveContext);
    const {data, loading} = useQuery(FETCH_DATA);
    const renderName = useCallback((name) => {
            if (size === "small")
                return "";
            if (loading) return <Spinner />;
            return (
                <Box direction="column"
                     justify="center">
                    <Text weight="normal" size="small" color="gray" textAlign="end">Logged in as</Text>
                    <Box direction="row" gap="xxsmall">
                        <Text weight="bold" color="gray" textAlign="end">{`${data?.data?.user?.first_name} ${data?.data?.user?.last_name}`}</Text>
                        {data?.data?.credentials && <Text color="gray">({data.data.credentials})</Text>}
                    </Box>
                </Box>
            );
        },
        [size, data, loading],
    );

    return (
        <Box
            tag="header"
            direction="row"
            background="white"
            align="center"
            justify="between"
            elevation='xsmall'
            style={{zIndex: 1}} // Put this above the sidebar
        >
            <Button onClick={onToggleSidebar}>
                <Box margin={{left: "small"}}>
                    <Brand/>
                </Box>
            </Button>
            <Box direction="row" align="center">
                <Box
                    margin={{left: "medium"}}
                    direction="row"
                    align="center"
                    gap='medium'
                    pad={{horizontal: "medium"}}
                >
                    <ChatNotifications/>
                    <GeneralNotifications/>
                    {/*<Button plain label={<MailOption size="30px" color='sidebarInactive'/>}/>*/}
                    <Box as='span' background='sidebarInactive' width='2px' height='50px'
                         round="xsmall"/>
                </Box>
                {userSession && (
                    <Box direction="row" flex>
                        {renderName(userSession.name)}
                        <UserMenu
                            alignSelf="center"
                            user={userSession}
                            items={[{
                                label: "Profile",
                                onClick: () => history.push('/profile')
                            }, {
                                label: "Logout",
                                onClick: async () => {
                                    removeState(AUTH_TOKEN_KEY);
                                    removeState(REFRESH_TOKEN);
                                    window.Intercom('shutdown');
                                    closeSubscription();
                                    client.cache.writeData({data: {isAuthenticated: false}});
                                    history.push('/login');
                                }
                            }]}
                        />
                    </Box>
                )}
            </Box>
            {/*{<Notification*/}
            {/*    isVisible={!isConnected}*/}
            {/*    color='statusPending'*/}
            {/*    content={*/}
            {/*        <Box align="center" direction="row" gap="small">*/}
            {/*            <PowerCycle/>*/}
            {/*            <Text color='white'>Connection to server lost. Trying to reconnect...</Text>*/}
            {/*        </Box>*/}
            {/*    }*/}
            {/*/>}*/}
            {/*<Notification*/}
            {/*    isVisible={!!error}*/}
            {/*    color='statusPending'*/}
            {/*    content={*/}
            {/*        <Box align="center" direction="row" gap="small">*/}
            {/*            <PowerCycle/>*/}
            {/*            <Text color='white'>Cannot connect to server.</Text>*/}
            {/*            <Button label='Retry' onClick={() => initializeChat()}/>*/}
            {/*        </Box>*/}
            {/*    }*/}
            {/*/>*/}
        </Box>

    );
};

export default withRouter(withApollo(Header));
