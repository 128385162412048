import React from 'react';
import {Box, DataTable, Heading, Text} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {DeleteButton} from "../../../../components/DeleteButton";
import {CreateDiagnosisModal} from "./CreateDiagnosisModal";
import {useClientSession} from "../../hooks/useClientSession";

const FETCH_DATA = gql`
    query fetchDiagnoses($id: Int) {
        session_diagnosis(where: {session_id: {_eq: $id}}) {
            diagnosis_id
            diagnosis {
                id
                icd10_code
                name
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation($session_id:Int! $diagnosis_id: Int!) {
        delete_session_diagnosis(where: {_and: [{session_id: {_eq: $session_id}}, {diagnosis_id: {_eq: $diagnosis_id}}]}) {
            returning {
                session_id
                diagnosis_id
            }
        }
    }
`;

export const DiagnosisInformation = ({sessionId}) => {
    const {data, loading} = useQuery(FETCH_DATA, {variables: {id: sessionId}})
    const {active} = useClientSession();
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Diagnosis</Heading>
            <DataTable
                primaryKey='diagnosis.id'
                columns={[
                    {
                        property: 'diagnosis.name',
                        header: <Text>Name</Text>,
                        // primary: true,
                    },
                    {
                        property: 'diagnosis.icd10_code',
                        header: <Text>ICD-10 Code</Text>,
                    },
                    active ? {
                        property: 'actions',
                        render: ({diagnosis_id}) =>
                            <DeleteButton
                                query={DELETE_MUTATION}
                                text='Are you sure you want to delete this diagnosis?'
                                variables={{diagnosis_id, session_id: sessionId}}
                                queriesToRefetch={['fetchDiagnoses']}
                            />
                    } : undefined,
                ].filter(column => !!column)}
                placeholder={loading ? 'Loading...' : undefined}
                data={data?.session_diagnosis || []}
                border={{ body: 'bottom' }}
                pad={{ horizontal: 'large', vertical: 'small' }}
            />
            {active && <CreateDiagnosisModal sessionId={sessionId} refetchQueries={['fetchDiagnoses']}/>}
        </Box>
    )
};
