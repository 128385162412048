import React from 'react';
import {Select} from "grommet";
import {useQuery} from "@apollo/react-hooks";

export const EnumSelect = ({query, options, ...rest}) => {
    const {data, loading} = useQuery(query);

    if (loading) {
        return null;
    }

    return (
        <Select
            options={data ? typeof options === 'function' ? options(data) : data?.options || [] : []}
            valueKey={{key: 'id', reduce: true}}
            labelKey='name'
            {...rest}
        />
    )
}