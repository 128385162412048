import React from 'react'
import {colors} from "../../theme";

const Clients = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 25 19" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M7.798.5a5.25 5.25 0 012.01.388c.626.271 1.17.632 1.632 1.081.463.45.834.98 1.117 1.593.265.613.398 1.266.398 1.957 0 .691-.133 1.344-.4 1.962a5.257 5.257 0 01-1.115 1.588 5.42 5.42 0 01-1.636 1.084l-.22.081a7.5 7.5 0 011.234.396c.957.394 1.79.935 2.497 1.623a7.568 7.568 0 011.67 2.428c.408.918.612 1.9.612 2.944a.945.945 0 01-.3.695.986.986 0 01-.705.289.986.986 0 01-.705-.289.945.945 0 01-.3-.695c0-.776-.15-1.506-.45-2.19a5.71 5.71 0 00-1.243-1.78 5.896 5.896 0 00-1.832-1.21 5.86 5.86 0 00-2.264-.44c-.8 0-1.555.147-2.261.44a5.898 5.898 0 00-1.834 1.21 5.708 5.708 0 00-1.243 1.777c-.3.687-.45 1.417-.45 2.193a.945.945 0 01-.3.695.986.986 0 01-.705.289.986.986 0 01-.705-.289.945.945 0 01-.3-.695c0-1.043.204-2.026.61-2.943a7.57 7.57 0 011.671-2.43 7.806 7.806 0 012.496-1.621c.4-.168.812-.3 1.235-.397a3.567 3.567 0 01-.224-.084A5.413 5.413 0 014.157 9.07 5.264 5.264 0 013.04 7.476a4.871 4.871 0 01-.398-1.957c0-.691.133-1.344.4-1.962A5.257 5.257 0 014.157 1.97 5.42 5.42 0 015.793.885 5.245 5.245 0 017.798.5zm0 1.967c-.879 0-1.618.296-2.231.893a2.89 2.89 0 00-.915 2.159c0 .85.302 1.564.915 2.16.613.596 1.352.892 2.231.892.88 0 1.619-.296 2.232-.893a2.89 2.89 0 00.915-2.159 2.89 2.89 0 00-.915-2.16c-.613-.596-1.353-.892-2.232-.892z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <path
                d="M18.083 3.435c1.213 0 2.254.419 3.11 1.251.859.834 1.291 1.85 1.291 3.034 0 1.184-.432 2.2-1.29 3.034a4.46 4.46 0 01-1.102.793 6.93 6.93 0 013.186 2.161c.411.511.731 1.086.956 1.71.149.354.256.716.322 1.085.066.367.099.74.099 1.122a.945.945 0 01-.3.695.986.986 0 01-.705.289.986.986 0 01-.705-.289.945.945 0 01-.3-.695c0-.26-.022-.517-.067-.77a3.442 3.442 0 00-.224-.748 4.329 4.329 0 00-.915-1.486 4.403 4.403 0 00-1.424-1.007 5.853 5.853 0 00-.961-.308 4.39 4.39 0 00-.971-.108c-.267 0-.53.025-.79.075-.264.052-.524.121-.784.21a1.203 1.203 0 01-.337.059c-.208 0-.403-.062-.578-.182a.89.89 0 01-.352-.436.775.775 0 01-.086-.42.701.701 0 01.096-.325.807.807 0 01.237-.348.977.977 0 01.34-.197c.087-.03.174-.06.262-.087a4.524 4.524 0 01-1.106-.792c-.867-.834-1.304-1.85-1.304-3.035 0-1.184.437-2.2 1.304-3.035a4.321 4.321 0 013.098-1.25zm0 1.967a2.33 2.33 0 00-1.69.686c-.47.458-.702.997-.702 1.632 0 .635.232 1.174.703 1.632a2.33 2.33 0 001.689.686c.674 0 1.236-.227 1.7-.685.463-.457.692-.997.692-1.633s-.229-1.176-.692-1.633c-.464-.458-1.026-.685-1.7-.685z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
        </g>
    </svg>
)

export default Clients
