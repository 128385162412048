import React from 'react';
import {Box, Text} from "grommet";
import Check from "../../components/icons/Check";

export const PaymentSuccess = () => {
    return (
        <Box fill align="center" justify="center">
            <Box animation="jiggle">
                <Check width="52px" height="52px" />
            </Box>
            <Text>Thank you for your payment</Text>
        </Box>
    );
}