import React, {useState} from 'react';
import {Box, Button, Text} from "grommet";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import gql from 'graphql-tag';
import {SessionTag} from "./SessionTag";
import {Edit} from "grommet-icons";
import {EditSessionTagsField} from "./EditSessionTagsField";
import {FETCH_SESSION} from "./ProfileSidebar";

const FETCH_DATA = gql`
query fetchSessionTags($sessionId: Int!) {
    tags: tag(where: {session_tags: {session_id: {_eq: $sessionId}}}) {
        id
        name
        description
    }
}
`;

export const SessionTagsField = ({sessionId, canEdit = true}) => {
    const [editMode, setEditMode] = useState(false);
    const {data, loading, error} = useQuery(FETCH_DATA, {
        variables: {sessionId}
    });

    if (loading) {
        return <Spinner full/>
    }

    if (error) {
        return <Text color="red">Can't load tags for this client</Text>
    }

    if (editMode) {
        return <EditSessionTagsField
            preselectedOptions={data.tags.map(tag => tag.id)}
            sessionId={sessionId}
            onCancel={() => setEditMode(false)}
            onAccept={() => setEditMode(false)}
            refetchQueries={[{query: FETCH_DATA, variables: {sessionId}}, {query: FETCH_SESSION, variables: {id: sessionId}}]} // refetch the client session fields to update the Audiences
        />
    }

    return (
        <Box wrap gap="xsmall" direction="row">

            {data && data.tags.length > 0 ?
                data.tags.map(tag => <SessionTag key={tag.id} tag={tag}/>) : <Text color='gray'>No tags</Text>
            }

            {canEdit && <Button alignSelf='center' icon={<Edit/>} plain onClick={() => setEditMode(true)}/>}
        </Box>
    );
};
