import React from 'react';
import {Box, Button, Layer, Text} from "grommet";
import {FormClose} from "grommet-icons";
import {TermsAndConditions} from "./TermsAndConditions";

export const TermsAndConditionsModal = ({onClose = () => {}}) => (
    <Layer
        position="center"
        modal
        onClickOutside={onClose}
        onEsc={onClose}
        margin="medium"
    >
        <Box background='lightdirtywhite' round='small'>
            <Box as="header"
                 background='white'
                 round={{size: 'small', corner: 'top'}}
                 pad={{horizontal: 'medium', top: 'medium', bottom: 'large'}}
                 border={{side: 'bottom'}}
                 direction='row'
                 justify='between'
            >
                <Text weight='bold'>Terms and Conditions</Text>
                <Button icon={<FormClose/>} onClick={onClose} plain/>
            </Box>
            <TermsAndConditions/>
        </Box>
    </Layer>
);