import React, {useState} from 'react';
import {Box, Button} from "grommet";
import gql from "graphql-tag";
import {Modal} from "../../../components/Modal";
import {useMutation} from "@apollo/react-hooks";
import {Trash} from "grommet-icons";

const DELETE_AUDIENCE = gql`
    mutation($id: Int!){
        delete_audience(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const DeleteAudienceButton = ({queriesToRefetch, audienceId, onRemove = () => {}}) => {
    const id = parseInt(audienceId, 10);
    const [removeModalVisible, setRemoveModalVisible] = useState(false);
    const [deleteAudience] = useMutation(DELETE_AUDIENCE, {
        refetchQueries: queriesToRefetch
    });

    return (
        <Box direction="row">
            <Modal
                visible={removeModalVisible}
                onClose={() => setRemoveModalVisible(false)}
                text='Are you sure you want to delete this audience?'
                acceptLabel='Remove'
                accept={async (doneLoading) => {
                    await deleteAudience({variables: {id}});
                    doneLoading();
                    setRemoveModalVisible(false);
                    onRemove();
                }}
            />
            <Button
                onClick={() => setRemoveModalVisible(true)}
                icon={<Trash/>}/>
        </Box>
    );
};