import React from 'react';
import {Box, Text} from "grommet";
import Avatar from "../../../components/Avatar";
import {MessagesList} from "./MessagesList";
import {useChannel} from "../../../lib/useChat";
import {MessageInput} from "../MessageInput";

export const Chat = ({sid, showHeader = true}) => {
    const { client, sendMessage, typing, ...rest } = useChannel(sid);
    return (
        <Box elevation='xsmall' background='white' round="xsmall" fill justify='between'>
            {showHeader && <Box border={{side: 'bottom'}}
                 background='lightdirtywhite'
                 direction='row'
                 align='start'
                 pad='small'
                 gap='small'
                 round={{corner: 'top', size: 'xsmall'}}
            >
                <Avatar id={client.id} usePlaceholder={!client.id}/>
                <Box>
                    <Text weight='bold'>{client.name}</Text>
                    {/*{client.attributes && client.attributes.created_at && <Text>Client since {moment.unix(client.attributes.created_at).format('LL')}</Text>}*/}
                </Box>
            </Box>}
            <MessagesList
                client={client}
                channelSid={sid}
                {...rest}
            />
            <MessageInput send={(text) => {
                sendMessage(text);
                // messagesList.current.scrollTop = messagesList.current.scrollHeight;
            }} onChange={() => typing()} disabled={!rest.ready}/>
        </Box>
    );
};
