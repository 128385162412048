import React, {useMemo} from 'react';
import {Box, Stack, Text} from "grommet";
import {StatusGood} from "grommet-icons";

export const StepLabel = ({label, index, currentIndex}) => {
    const color = useMemo(() => currentIndex > index ? 'sidebarInactive' : currentIndex < index ? 'blueblack' : 'blue', [index, currentIndex]);
    return (
        <Stack anchor="top-right">
        <Box gap='small' align='center'>
            <Text size='54px' color={color}>{index + 1}</Text>
            <Text color={color}>{label}</Text>
        </Box>
            {currentIndex > index && <StatusGood color='sidebarInactive'/>}
    </Stack>
    );
};