import ExtendableError from "./ExtendableError";
import dot from 'dot-object';
/**
 * Class that parses validation errors returned from the server and puts them into Formik format so that it can
 * be passed to the Formik's setErrors method:
 *
 *  Example Usage:
 *
 *  if (e instanceof FormValidationError) {
 *           yield call(setErrors, Object.assign({}, ...e.validationErrors));
 *   }
 */
export default class FormValidationError extends ExtendableError {
    constructor(message, validationErrors, apiError = undefined) {
        super(message);
        this.validationErrors = validationErrors;
        this.apiError = apiError;
    }

    get validationErrors() {
        return this._validationErrors;
    }

    set validationErrors(validationErrors) {
        this._validationErrors = [
            ...validationErrors
        ];

        this._validationErrors.forEach((x, index, array) => {
            return array[index] = dot.object({[x.field]: x.message});
        });
    }
}