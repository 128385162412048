import React, {useState} from 'react';
import {Box, Button} from "grommet";
import {Modal} from "./Modal";

const ConfirmationButton = ({text = '', acceptLabel = '', acceptButtonColor='status-critical', onAccept = () => {}, ...rest}) => {
    const [removeModalVisible, setRemoveModalVisible] = useState(false);

    return (
        <Box direction="row">
            <Modal
                visible={removeModalVisible}
                onClose={() => setRemoveModalVisible(false)}
                text={text}
                acceptLabel={acceptLabel}
                accept={async (doneLoading) => {
                    doneLoading();
                    setRemoveModalVisible(false);
                    onAccept();
                }}
                acceptButtonColor={acceptButtonColor}
            />
            <Button {...rest}
                    onClick={() => setRemoveModalVisible(true)}
            />
        </Box>
    );
};


export default ConfirmationButton
