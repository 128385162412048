import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {transform} from "json-to-json-transformer";


const UPDATE_TX_PLAN = gql`
mutation(
  $tx_plan: tx_plan_set_input!
  $tx_plan_id: Int!
  $tx_plan_tags: [tx_plan_tx_plan_tag_insert_input!]!
  $problem_ids:[Int!]
  $tx_signatures: [tx_signature_insert_input!]!
) {

  update_tx_plan(where: { id: { _eq: $tx_plan_id } }, _set: $tx_plan) {
    returning {
      id
      name
    }
    affected_rows
  }
  
  delete_tx_plan_tx_plan_tag(where: { tx_plan_id: { _eq: $tx_plan_id } }) {
    affected_rows
  }
  
  insert_tx_plan_tx_plan_tag(objects: $tx_plan_tags) {
    affected_rows
  }
  
  delete_tx_problem(where: {_and: {id: {_nin: $problem_ids}, tx_plan_id: {_eq: $tx_plan_id}}}) {
    affected_rows
  }
  
  delete_tx_signature(where: { tx_plan_id: { _eq: $tx_plan_id } }) {
    affected_rows
  }
  
  insert_tx_signature(objects: $tx_signatures) {
    affected_rows
  }
}
`

const UPDATE_PROBLEM = gql`
mutation(
  $problem: tx_problem_set_input!
  $problemId: Int!
  $problemObjectiveIds: [Int!]
) {
  update_tx_problem_by_pk(_set: $problem, pk_columns: { id: $problemId }) {
    id
  }
  
  delete_tx_objective(
    where: {
      _and: {
        id: { _nin: $problemObjectiveIds }
        tx_problem_id: { _eq: $problemId }
      }
    }
  ) {
    affected_rows
  }
}

`

const INSERT_PROBLEM = gql`
mutation ($problem: tx_problem_insert_input!) {
  insert_tx_problem_one(object: $problem) {
    id
  }
}
`

const UPDATE_OBJECTIVE = gql`
mutation(
  $objective: tx_objective_set_input!
  $objectiveId: Int!
  $interventionIds: [Int!]
) {
  update_tx_objective_by_pk(
    _set: $objective
    pk_columns: { id: $objectiveId }
  ) {
    id
  }
  delete_tx_intervention(
    where: {
      _and: {
        tx_objective_id: { _eq: $objectiveId }
        id: { _nin: $interventionIds }
      }
    }
  ) {
    affected_rows
  }
}

`

const INSERT_OBJECTIVE = gql`
mutation ($objective: tx_objective_insert_input!) {
  insert_tx_objective_one(object: $objective) {
    id
  }
}
`

const UPDATE_INTERVENTION = gql`
mutation(
  $intervention: tx_intervention_set_input!
  $interventionId: Int!
  $selectedMeasurementSurveyIds: [Int!]
  $measurementSurveys: [tx_intervention_measurement_survey_template_insert_input!]!
) {
  update_tx_intervention_by_pk(
    pk_columns: { id: $interventionId }
    _set: $intervention
  ) {
    id
  }
  delete_tx_intervention_measurement_survey_template(
    where: {
      measurement_survey_template_id: { _nin: $selectedMeasurementSurveyIds }, 
      tx_intervention_id: {_eq: $interventionId}
    }
  ) {
    affected_rows
  }
  insert_tx_intervention_measurement_survey_template(
    objects: $measurementSurveys
    on_conflict: {
      constraint: tx_intervention_measurement_survey_template_pkey
      update_columns: measurement_survey_template_id
      where: { tx_intervention_id: { _eq: $interventionId } }
    }
  ) {
    affected_rows
  }
}
`

const INSERT_INTERVENTION = gql`
mutation ($intervention: tx_intervention_insert_input!) {
  insert_tx_intervention_one(object: $intervention) {
    id
  }
}
`

const fullOrNull = (input) => {
    return input ? input : null;
}


function useTxPlanUpdater() {
    /* eslint-disable */
    const [updateTxPlan, {loading: updateTxLoading, error: updateTxPlanError}] = useMutation(UPDATE_TX_PLAN, {refetchQueries: ['fetchTxPlanById']});
    const [updateProblem, {loading: updateProblemLoading, error: updateProblemError}] = useMutation(UPDATE_PROBLEM);
    const [insertProblem, {loading: insertProblemLoading, error: insertProblemError}] = useMutation(INSERT_PROBLEM);
    const [updateObjective, {loading: updateObjectiveLoading, error: updateObjectiveError}] = useMutation(UPDATE_OBJECTIVE);
    const [insertObjective, {loading: insertObjectiveLoading, error: insertObjectiveError}] = useMutation(INSERT_OBJECTIVE);
    const [updateIntervention, {loading: updateInterventionLoading, error: updateInterventionError}] = useMutation(UPDATE_INTERVENTION);
    const [insertIntervention, {loading: insertInterventionLoading, error: insertInterventionError}] = useMutation(INSERT_INTERVENTION);

    const loading = [
        updateTxLoading,
        updateProblemLoading,
        insertProblemLoading,
        updateObjectiveLoading,
        insertObjectiveLoading,
        updateInterventionLoading,
        insertInterventionLoading
    ].some(Boolean)

    const getExistingIds = arr => arr.flatMap(({id}) => id || [])

    const handleTxPlanUpdate = async (txPlanData) => {
        const {id: txPlanId} = txPlanData
        const TX_PLAN_TRANSFORM = {
            tx_plan_id: txPlanId,
            tx_plan: {
                name: '{{name}}',
                status_id: '{{status.id}}',
            },
            tx_plan_tags: [
                '{{tags}}',
                {
                    tx_plan_id: txPlanId,
                    tx_plan_tag_id: '{{id}}'
                }
            ],
            tx_signatures: [
                '{{signatures}}',
                {
                    tx_plan_id: txPlanId,
                    provider_id: '{{id}}',
                    order: '{{order}}',
                }
            ],
            problem_ids: getExistingIds(txPlanData.problems)
        }
        await updateTxPlan({variables: transform(TX_PLAN_TRANSFORM, txPlanData)})
    }


    async function upsertProblem(problem, txPlanId) {

        // Step 1. Determine if update or insert:
        if (problem.id) {
            // update
            const UPDATE_PROBLEM_TRANSFORM = {
                problemId: problem.id,
                problem: {
                    name: '{{name}}',
                    created_at: "{{created_at}}",
                    related_to_and_evidenced_by: "{{related_to_and_evidenced_by}}",
                    goals: "{{goals}}",
                    order: "{{order}}",
                },
                problemObjectiveIds: getExistingIds(problem.objectives)
            }
            await updateProblem({variables: transform(UPDATE_PROBLEM_TRANSFORM, problem)})

            // Update old or insert new **objectives** one at a time
            for (const objective of problem.objectives) {
                await upsertObjective(objective, problem.id)
            }
        } else {
            // Insert:
            const INSERT_PROBLEM_TRANSFORM = {
                problem: {
                    tx_plan_id: txPlanId,
                    name: '{{name}}',
                    created_at: "{{created_at}}",
                    related_to_and_evidenced_by: "{{related_to_and_evidenced_by}}",
                    goals: "{{goals}}",
                    order: "{{order}}",
                    objectives: {
                        data: [
                            "{{objectives}}",
                            {
                                description: '{{description}}',
                                created_at: '{{created_at}}',
                                order: '{{order}}',
                                interventions: {
                                    data: [
                                        '{{interventions}}',
                                        {
                                            description: '{{description}}',
                                            created_at: '{{created_at}}',
                                            order: '{{order}}',
                                            target_date: '=> fullOrNull(target_date)',
                                            resolved_date: '=> fullOrNull(resolved_date)',
                                            status_id: '=> fullOrNull(status.id)',
                                            measurements: {
                                                data: [
                                                    '{{measurements}}',
                                                    {
                                                        measurement_survey_template_id: '{{id}}'
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                },
                            }
                        ]
                    },
                }
            }
            await insertProblem({variables: transform(INSERT_PROBLEM_TRANSFORM, problem, {fullOrNull})})
        }
    }

    async function upsertObjective(objective, problemId) {
        if (objective.id) {
            // UPDATE
            const UPDATE_OBJECTIVE_TRANSFORM = {
                objectiveId: objective.id,
                objective: {
                    description: '{{description}}',
                    created_at: '{{created_at}}',
                    order: '{{order}}',
                },
                interventionIds: getExistingIds(objective.interventions)
            }
            await updateObjective({variables: transform(UPDATE_OBJECTIVE_TRANSFORM, objective)})

            for (const intervention of objective.interventions) {
                await upsertIntervention(intervention, objective.id)
            }
        } else {
            // Insert
            const INSERT_OBJECTIVE_TRANSFORM = {
                objective: {
                    tx_problem_id: problemId,
                    description: '{{description}}',
                    created_at: '{{created_at}}',
                    order: '{{order}}',
                    interventions: {
                        data: [
                            '{{interventions}}',
                            {
                                description: '{{description}}',
                                created_at: '{{created_at}}',
                                order: '{{order}}',
                                target_date: '=> fullOrNull(target_date)',
                                resolved_date: '=> fullOrNull(resolved_date)',
                                status_id: '=> fullOrNull(status.id)',
                                measurements: {
                                    data: [
                                        '{{measurements}}',
                                        {
                                            measurement_survey_template_id: '{{id}}'
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                }
            }
            await insertObjective({variables: transform(INSERT_OBJECTIVE_TRANSFORM, objective, {fullOrNull})})
        }
    }

    async function upsertIntervention(intervention, objectiveId) {
        if (intervention.id) {
            // update
            const UPDATE_INTERVENTION_TRANSFORM = {
                interventionId: intervention.id,
                intervention: {
                    description: '{{description}}',
                    created_at: '{{created_at}}',
                    order: '{{order}}',
                    target_date: '=> fullOrNull(target_date)',
                    resolved_date: '=> fullOrNull(resolved_date)',
                    status_id: '=> fullOrNull(status.id)',
                },
                selectedMeasurementSurveyIds: intervention.measurements.flatMap(({id}) => id ? id : []),
                measurementSurveys: [
                    '{{measurements}}',
                    {
                        measurement_survey_template_id: '{{id}}',
                        tx_intervention_id: intervention.id
                    }
                ]
            }
            await updateIntervention({variables: transform(UPDATE_INTERVENTION_TRANSFORM, intervention, {fullOrNull})})
        } else {
            //insert
            const INSERT_INTERVENTION_TRANSFORM = {
                intervention: {
                    tx_objective_id: objectiveId,
                    description: '{{description}}',
                    created_at: '{{created_at}}',
                    order: '{{order}}',
                    target_date: '=> fullOrNull(target_date)',
                    resolved_date: '=> fullOrNull(resolved_date)',
                    status_id: '=> fullOrNull(status.id)',
                    measurements: {
                        data: [
                            '{{measurements}}',
                            {
                                measurement_survey_template_id: '{{id}}'
                            }
                        ]
                    }
                }
            }
            await insertIntervention({variables: transform(INSERT_INTERVENTION_TRANSFORM, intervention, {fullOrNull})})
        }
    }


    async function update(txPlanData) {
        // Update TX Plan
        await handleTxPlanUpdate(txPlanData)

        // ## Step 2:
        // Update old or insert new **problems** one at a time
        for (const problem of txPlanData.problems) {
            await upsertProblem(problem, txPlanData.id)
        }
    }

    return [update, loading]
}

export default useTxPlanUpdater
