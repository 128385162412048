import React from 'react'
import {Box, Text} from "grommet";

export const Badge = ({text, color, ...rest}) => (
    <Box round="2px"
         width="fit-content"
         direction="row"
         align="center"
         justify="center"
         pad={{vertical: "2px", horizontal: "10px"}}
         background={color}
         {...rest}>
        <Text color='white'>{text}</Text>
    </Box>
);