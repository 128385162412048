import React from "react";
import {colors} from "../../theme";

function ExclamationMark(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
            <path
                d="M24 0c3.31 0 6.423.626 9.342 1.881 2.898 1.278 5.434 3.003 7.604 5.173s3.895 4.706 5.176 7.612C47.374 17.577 48 20.69 48 24c0 3.31-.626 6.423-1.881 9.342-1.278 2.898-3.003 5.434-5.173 7.604s-4.706 3.895-7.612 5.176C30.423 47.374 27.31 48 24 48c-3.31 0-6.423-.626-9.342-1.881-2.898-1.278-5.434-3.003-7.604-5.173s-3.895-4.706-5.176-7.612C.626 30.423 0 27.31 0 24c0-3.31.626-6.423 1.881-9.342C3.16 11.76 4.884 9.224 7.054 7.054s4.706-3.895 7.612-5.176C17.577.626 20.69 0 24 0zm0 4.73c-2.67 0-5.16.502-7.471 1.5-2.36 1.037-4.406 2.422-6.142 4.157-1.735 1.736-3.12 3.781-4.154 6.137C5.232 18.84 4.73 21.33 4.73 24s.5 5.16 1.5 7.471c1.036 2.36 2.42 4.406 4.156 6.142 1.736 1.735 3.781 3.12 6.137 4.154C18.84 42.768 21.33 43.27 24 43.27s5.16-.5 7.471-1.5c2.36-1.036 4.406-2.42 6.142-4.156 1.735-1.736 3.12-3.781 4.154-6.137C42.768 29.16 43.27 26.67 43.27 24s-.5-5.16-1.5-7.471c-1.036-2.36-2.42-4.406-4.156-6.142-1.736-1.735-3.781-3.12-6.137-4.154C29.16 5.232 26.67 4.73 24 4.73zm0 28.53c.845 0 1.59.307 2.185.901.594.594.902 1.34.902 2.185s-.308 1.591-.902 2.185a3.008 3.008 0 01-2.185.902c-.845 0-1.59-.307-2.185-.902a3.008 3.008 0 01-.902-2.185c0-.845.308-1.59.902-2.185A3.008 3.008 0 0124 33.26zm0-24.606c.638 0 1.21.241 1.667.698.457.457.698 1.03.698 1.667v15.866c0 .637-.241 1.21-.698 1.666A2.308 2.308 0 0124 29.25c-.638 0-1.21-.242-1.667-.699a2.308 2.308 0 01-.698-1.666V11.019c0-.638.241-1.21.698-1.667A2.308 2.308 0 0124 8.654z"
                fill={colors.lightred}
                fillRule="nonzero"
            />
        </svg>
    )
}

export default ExclamationMark
