import React from 'react';
import {Box, Paragraph, Text} from "grommet";
import Padlock from "../../components/icons/Padlock";

export const Sidebar = ({content}) => (
    <Box round='small' border elevation='xsmall' background='white' pad='medium' gap='medium'>
        {content}
        <Box gap='medium'>
            <Padlock style={{width: '50px', height: '50px'}}/>
            <Box>
                <Text color='blueblack' size='large'>We do not sell any of your information</Text>
                <Paragraph color='lightgray'>Your information, and data is safe in our system. Our servers are secured
                    by Google Cloud's 24/7/365 device security detection.</Paragraph>
            </Box>
        </Box>
    </Box>
);