import React, {useState} from 'react';
import {Box, Button, Heading, Layer, Text} from "grommet";
import {LoadingButton} from "./LoadingButton";

// TODO: Rename this component
export const Modal = ({onClose, text, accept, acceptLabel, visible, acceptButtonColor='status-critical'}) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const doneLoading = () => setLoading(false);
    if (!visible) {
        return null;
    }
    return (
        <Layer position="center" modal onClickOutside={onClose} onEsc={onClose}>
            <Box pad="medium" gap="small" width="medium">
                <Heading level={3} margin="none">
                    Confirm
                </Heading>
                <Text>{text}</Text>
                {error && <Text color='red'>The operation could not be performed.</Text>}
                <Box
                    as="footer"
                    gap="small"
                    direction="row"
                    align="center"
                    justify="end"
                    pad={{top: "medium", bottom: "small"}}
                >
                    <Button label="Cancel" onClick={onClose} color="dark-3"/>
                    <LoadingButton
                        loading={loading}
                        label={
                            <Text color="white" weight='bold'>{acceptLabel}</Text>
                        }
                        onClick={async () => {
                            setLoading(true);
                            try {
                                await accept(doneLoading);
                            } catch (e) {
                                setError(true);
                                setLoading(false);
                            }
                        }}
                        primary
                        color={acceptButtonColor}
                    />
                </Box>
            </Box>
        </Layer>
    )
}
