import React from 'react'
import {colors} from "../../theme";

const Settings = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 21 19" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M16.2.9c1.075 0 1.996.382 2.757 1.143A3.761 3.761 0 0120.1 4.8a3.761 3.761 0 01-1.143 2.757A3.761 3.761 0 0116.2 8.7a3.78 3.78 0 01-2.392-.823 3.833 3.833 0 01-1.312-1.85l-.052-.178L1.95 5.85a1 1 0 01-.518-.139l-.114-.078-.107-.094A1.013 1.013 0 01.9 4.8c0-.284.105-.532.311-.739.172-.172.373-.273.6-.302l.139-.009 10.494-.001.052-.176c.19-.575.497-1.077.92-1.503l.189-.178.203-.17A3.78 3.78 0 0116.2.9zm0 2.1c-.488 0-.908.177-1.265.535A1.726 1.726 0 0014.4 4.8c0 .488.177.908.535 1.265.357.358.777.535 1.265.535.508 0 .932-.177 1.28-.534A1.75 1.75 0 0018 4.8a1.75 1.75 0 00-.52-1.266A1.712 1.712 0 0016.2 3z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
            <path
                d="M8.6 10.4a3.78 3.78 0 012.392.823 3.833 3.833 0 011.312 1.85l.051.177h6.695a1 1 0 01.518.139l.114.078.107.094c.206.207.311.455.311.739s-.105.532-.311.739a1.016 1.016 0 01-.6.302l-.139.009h-6.695l-.051.177a3.789 3.789 0 01-.92 1.503l-.189.178-.203.17A3.78 3.78 0 018.6 18.2a3.78 3.78 0 01-2.392-.823 3.833 3.833 0 01-1.312-1.85l-.052-.177H1.95a1 1 0 01-.518-.139l-.114-.078-.107-.094A1.013 1.013 0 01.9 14.3c0-.284.105-.532.311-.739.172-.172.373-.273.6-.302l.139-.009h2.894l.052-.177c.19-.575.497-1.077.92-1.503l.189-.178.203-.17A3.78 3.78 0 018.6 10.4zm0 2.1c-.488 0-.908.177-1.265.535A1.726 1.726 0 006.8 14.3c0 .488.177.908.535 1.265.357.358.777.535 1.265.535.508 0 .932-.177 1.28-.534a1.75 1.75 0 00.52-1.266c0-.488-.177-.908-.535-1.265A1.726 1.726 0 008.6 12.5z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
        </g>
    </svg>
);

export default Settings
