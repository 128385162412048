import React from 'react'
// import {colors} from "../../theme";

const FilledInSurvey = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...rest}>
        <defs>
            <pattern
                id="prefix__c"
                patternUnits="objectBoundingBox"
                x="0%"
                width="100%"
                height="100%"
            >
                <use xlinkHref="#prefix__image-4" transform="scale(.1)" />
            </pattern>
            <filter
                x="-6.9%"
                y="-2.8%"
                width="113.7%"
                height="106.6%"
                filterUnits="objectBoundingBox"
                id="prefix__a"
            >
                <feMorphology
                    radius={1}
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                />
                <feOffset
                    dy={4}
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation={7}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feComposite
                    in="shadowBlurOuter1"
                    in2="SourceAlpha"
                    operator="out"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0.498039216 0 0 0 0 0.560784314 0 0 0 0 0.643137255 0 0 0 0.16 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <path
                d="M4 .297h342a4 4 0 014 4v717a4 4 0 01-4 4H4a4 4 0 01-4-4v-717a4 4 0 014-4z"
                id="prefix__b"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="#F9F9F9" d="M-1034-157H406V867h-1440z" />
            <g fillRule="nonzero" transform="translate(-22 -87)">
                <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
                <path
                    stroke="#EFEFEF"
                    d="M346-.203c1.243 0 2.368.504 3.182 1.318a4.486 4.486 0 011.318 3.182h0v717a4.486 4.486 0 01-1.318 3.182 4.486 4.486 0 01-3.182 1.318h0H4a4.486 4.486 0 01-3.182-1.318A4.486 4.486 0 01-.5 721.297h0v-717C-.5 3.055.004 1.93.818 1.115A4.486 4.486 0 014-.203h0z"
                    fill="#FFF"
                    fillRule="evenodd"
                />
            </g>
            <g transform="translate(-22 -21)">
                <path
                    stroke="#EFEFEF"
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M-.5-.5h351v68H-.5z"
                />
                <g transform="translate(14 13)">
                    <circle fill="url(#prefix__c)" cx={20} cy={20} r={20} />
                    <circle fill="#F9FCFE" cx={20} cy={20} r={20} />
                    <path
                        d="M20 25.875c2.214 0 4.286.405 6.214 1.216 1.953.834 3.65 1.952 5.09 3.354a15.754 15.754 0 012.713 3.514l.11.199A19.937 19.937 0 0120 40a19.937 19.937 0 01-14.127-5.843 15.746 15.746 0 012.823-3.712c1.44-1.402 3.137-2.52 5.09-3.354 1.928-.81 4-1.216 6.214-1.216zm-.41-19.809c1.132 0 2.2.215 3.205.645a8.39 8.39 0 014.359 4.385 8.19 8.19 0 01.64 3.224 8.19 8.19 0 01-.64 3.224 8.39 8.39 0 01-4.36 4.385 8.059 8.059 0 01-3.204.645c-1.133 0-2.201-.215-3.205-.645a8.39 8.39 0 01-4.359-4.385 8.19 8.19 0 01-.641-3.224c0-1.14.213-2.214.64-3.224a8.39 8.39 0 014.36-4.385 8.059 8.059 0 013.205-.645z"
                        fill="#819DB6"
                        fillRule="nonzero"
                    />
                </g>
                <circle
                    stroke="#EFEFEF"
                    fill="#FFF"
                    fillRule="nonzero"
                    cx={34}
                    cy={33}
                    r={20.5}
                />
                <g>
                    <path
                        d="M32.5 37.75c0-.938.18-1.812.54-2.625a7.126 7.126 0 011.452-2.145 6.555 6.555 0 012.133-1.44A6.523 6.523 0 0139.25 31c.938 0 1.812.18 2.625.54a6.492 6.492 0 012.156 1.44 7.126 7.126 0 011.453 2.145c.344.813.516 1.687.516 2.625s-.172 1.812-.516 2.625a7.126 7.126 0 01-1.453 2.145 6.492 6.492 0 01-2.156 1.44c-.813.36-1.687.54-2.625.54-.922 0-1.797-.18-2.625-.54a6.555 6.555 0 01-2.133-1.44 7.126 7.126 0 01-1.453-2.145 6.416 6.416 0 01-.539-2.625zm1.5 0c0 .719.14 1.398.422 2.04.281.64.66 1.198 1.137 1.675a5.18 5.18 0 001.652 1.113c.64.281 1.32.422 2.039.422.734 0 1.422-.14 2.063-.422a5.18 5.18 0 001.652-1.113 5.434 5.434 0 001.137-1.676 5.27 5.27 0 00.398-2.039 5.27 5.27 0 00-.398-2.04c-.282-.64-.66-1.198-1.137-1.675a5.18 5.18 0 00-1.653-1.113 5.074 5.074 0 00-2.062-.422c-.719 0-1.398.14-2.04.422a5.18 5.18 0 00-1.651 1.113 5.434 5.434 0 00-1.137 1.676A5.017 5.017 0 0034 37.75z"
                        fill="#28CC8B"
                    />
                    <path
                        d="M25.25 42a2.17 2.17 0 01-1.594-.656A2.17 2.17 0 0123 39.75v-16.5c0-.625.219-1.156.656-1.594A2.17 2.17 0 0125.25 21h10.64a2.257 2.257 0 011.57.656l2.884 2.883c.219.203.383.441.492.715.11.273.164.558.164.855v3.141a.72.72 0 01-.223.527.72.72 0 01-.527.223.72.72 0 01-.527-.223.72.72 0 01-.223-.527v-3.14a.726.726 0 00-.059-.282.716.716 0 00-.152-.234l-2.883-2.883a.716.716 0 00-.234-.152.726.726 0 00-.281-.059H25.25a.72.72 0 00-.527.223.72.72 0 00-.223.527v16.5a.72.72 0 00.223.527.72.72 0 00.527.223h5.25a.72.72 0 01.527.223.72.72 0 01.223.527.72.72 0 01-.223.527.72.72 0 01-.527.223h-5.25z"
                        fill="#3F75FF"
                    />
                    <path
                        d="M38.406 40.938a1.553 1.553 0 01-.89-.305l-.082-.082a.313.313 0 00-.082-.059l-1.5-1.5a1.166 1.166 0 01-.153-.246.726.726 0 010-.574.716.716 0 01.153-.235.845.845 0 01.246-.175.726.726 0 01.574 0c.094.039.172.097.234.175l1.5 1.5 2.907-3.89a.743.743 0 01.28-.211.812.812 0 01.563-.035.668.668 0 01.211.105c.172.125.274.29.305.492a.734.734 0 01-.14.563l-2.907 3.867a1.623 1.623 0 01-.445.387c-.172.101-.36.168-.563.2a.385.385 0 00-.094.01.493.493 0 01-.117.013z"
                        fill="#28CC8B"
                    />
                    <path
                        d="M30.906 35.5a.75.75 0 01.55.232.75.75 0 01.232.55.74.74 0 01-.232.56.772.772 0 01-.55.22h-1.125a.772.772 0 01-.55-.22.74.74 0 01-.231-.56.75.75 0 01.232-.55.75.75 0 01.55-.232h1.124zm-4 0a.75.75 0 01.55.232.75.75 0 01.231.55.74.74 0 01-.231.56.772.772 0 01-.55.22h-.125a.772.772 0 01-.55-.22.74.74 0 01-.231-.56.75.75 0 01.232-.55.75.75 0 01.55-.232h.124zm5.125-4.5a.75.75 0 01.55.232.75.75 0 01.232.55.74.74 0 01-.232.56.772.772 0 01-.55.22h-2.25a.772.772 0 01-.55-.22.74.74 0 01-.231-.56.75.75 0 01.232-.55.75.75 0 01.55-.232h2.25zm-5.125 0a.75.75 0 01.55.232.75.75 0 01.231.55.74.74 0 01-.231.56.772.772 0 01-.55.22h-.125a.772.772 0 01-.55-.22.74.74 0 01-.231-.56.75.75 0 01.232-.55.75.75 0 01.55-.232h.124zm10.25-4.5a.75.75 0 01.55.232.75.75 0 01.231.55.74.74 0 01-.231.56.772.772 0 01-.55.22h-7.375a.772.772 0 01-.55-.22.74.74 0 01-.231-.56.75.75 0 01.232-.55.75.75 0 01.55-.232h7.374zm-10.25 0a.75.75 0 01.55.232.75.75 0 01.231.55.74.74 0 01-.231.56.772.772 0 01-.55.22h-.125a.772.772 0 01-.55-.22.74.74 0 01-.231-.56.75.75 0 01.232-.55.75.75 0 01.55-.232h.124z"
                        fill="#3F75FF"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default FilledInSurvey
