import React, {useMemo} from 'react';
import {useNameAbreviation} from "../../../components/helpers/StringHelpers";
import moment from "moment";
import {Box, Text} from "grommet";
import Avatar from "../../../components/Avatar";
import RoutedButton from "../../../components/RoutedButton";
import {Group, MailOption, Tag, User} from "grommet-icons";
import {DeleteButton} from "../../../components/DeleteButton";
import {DELETE_CLIENT} from "../ClientsPage";
import MedicalCross from "../../../components/icons/MedicalCross";
import {SessionDiagnosesField} from "./SessionDiagnosesField";
import {ClientAudienceTags} from "./ClientAudienceTags";
import {SessionTagsField} from "./SessionTagsField";
import {NA} from "../../../lib/constants";
import {useHistory} from "react-router-dom";
import {SessionSelect} from "./SessionSelect";
import {useClientSession} from "../hooks/useClientSession";
import {CreateSessionButton} from "./CreateSessionButton";

const dateFormat = {day: 'numeric', month: 'short', year: 'numeric'};

// TODO: Needs to be refactored
export const ClientProfileCard = ({data, id}) => {
    const history = useHistory();
    const {active} = useClientSession();
    const inTreatment = useMemo(() => data?.session?.client?.sessions_aggregate.aggregate.count > 0, [data]);
    const maybeAbbreviatedFullName = useNameAbreviation(data?.session.client.user.first_name || "",
        data?.session.client.user.last_name || "");
    const iconsProps = {size: "13px", color: 'sidebarInactive'}
    return (
        <Box gap="medium">
            <Box direction="row" gap="medium" align="center">
                <Avatar size='large' id={data.session.client.id}/>
                <Box gap="xsmall">
                    <Box align="center" direction="row">
                        <Text weight="bold" size="large" truncate>
                            {maybeAbbreviatedFullName}
                        </Text>
                            <DeleteButton
                                query={DELETE_CLIENT}
                                plain
                                variables={{id: parseInt(id)}}
                                queriesToRefetch={['fetchDataClient', 'fetchClientSessionFields']}
                                onRemove={() => history.push('/clients')}
                            />
                    </Box>
                    <Box round='xxsmall' background={inTreatment ? 'blue' : 'red'}
                         pad={{horizontal: 'xsmall'}} alignSelf='start'>
                        <Text
                            color='white'>{inTreatment ? 'In Treatment' : 'Discharged'}
                        </Text>
                    </Box>
                    <Box direction="row" gap="large">
                        <Box>
                            <Text weight="bold">Client Since</Text>
                            <Text
                                color="gray">{new Date(data.session.client.user.created_at).toLocaleDateString("en-US", dateFormat)}
                            </Text>
                        </Box>
                        <Box>
                            <Text weight="bold">Last Active</Text>
                            <Text
                                color="gray">{data.session.client.user.token_issued_at ? moment.duration(moment(data.session.client.user.token_issued_at).diff(moment())).humanize() + ' ago' : NA}</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <SessionSelect/>
            {active ? <Box direction='row' justify='between'>
                <RoutedButton label="Open Messenger"
                              path={`/messenger/${data.session.provider_session && data.session.provider_session.twilio_channel_sid}`}
                              disabled={!data.session.provider_session}/>
                <RoutedButton label='Add Appointment' disabled={data.session.provider_session?.provider_id === data.providerId} path='/meetings/create'/>
            </Box> : <CreateSessionButton/>}
            <Box direction="row" align='baseline' gap="small">
                <MedicalCross {...iconsProps}/>
                <Box gap="small" fill>
                    <Text weight="bold">Diagnosis</Text>
                    <SessionDiagnosesField sessionId={data.session.id} canEdit={false}/>
                </Box>
            </Box>
            <Box direction='row' justify='between'>
                <Box direction="row" align='baseline' gap="small">
                    <User  {...iconsProps}/>
                    <Box gap="small">
                        <Box>
                            <Text weight="bold">Date of Birth</Text>
                            <Text
                                color="gray">{data.session.client.user.date_of_birth ? new Date(data.session.client.user.date_of_birth).toLocaleDateString("en-US", dateFormat) + ` (${moment().diff(moment(data.session.client.user.date_of_birth), 'years')} yo)` : NA}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold">Birth Sex</Text>
                            <Text
                                color="gray">{data.session.client.user.birth_sex?.name || NA}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold">Status</Text>
                            <Text
                                color="gray">{data.session.client.user.engaged ? 'Engaged' : 'Not Engaged'}</Text>
                        </Box>
                    </Box>
                </Box>
                <Box direction="row" align='baseline' gap="small">
                    <MailOption  {...iconsProps}/>
                    <Box gap="small">
                        <Box>
                            <Text weight="bold">MR Number</Text>
                            <Text
                                color="gray">{data.session.mr_number ? data.session.mr_number : NA}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold">Admission Date</Text>
                            <Text
                                color="gray">{new Date(data.session.created_at).toLocaleDateString("en-US", dateFormat)}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold">Counselor</Text>
                            <Text
                                color="gray">{data.session.provider_session ? `${data.session.provider_session.provider?.user?.first_name} ${data.session.provider_session.provider?.user?.last_name}` : NA}</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box direction="row" align='baseline' gap="small">
                <Group  {...iconsProps}/>
                <Box gap="small">
                    <Text weight="bold">Audiences</Text>
                    <ClientAudienceTags session={data.session}/>
                </Box>
            </Box>
            <Box direction="row" align='baseline' gap="small">
                <Tag  {...iconsProps}/>
                <Box gap="small" width={{min: 'small'}}>
                    <Text weight="bold">Risk Factors</Text>
                    <SessionTagsField sessionId={data.session.id} canEdit={true}/>
                </Box>
            </Box>
        </Box>
    )
}