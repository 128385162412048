import React from 'react';
import {Box, FormField, Grid, Heading} from "grommet";
import {UserEngagement} from "../../../../lib/constants";
import {StatusCell} from "../../../../components/table/StatusCell";
import {DisabledTextInput} from "./DisabledTextInput";

export const BasicInformation = ({data}) => {
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Basic Information</Heading>
            <Grid
                gap='medium'
                columns={{count: 'fill', size: 'small'}}
            >
                <DisabledTextInput label='First Name' value={data.session.client.user.first_name}/>
                <DisabledTextInput label='Middle Name' value={data.session.client.user.middle_name}/>
                <DisabledTextInput label='Last Name' value={data.session.client.user.last_name}/>
                <DisabledTextInput label='MR Number' value={data.session.mr_number}/>
                <FormField label='Engagement Status' error={null}>
                    <Box margin={{top: 'xsmall'}}>
                        <StatusCell
                            id={data?.session?.client?.user?.engaged ? 1 : 0}
                            name={data?.session?.client?.user?.engaged || false ? 'Engaged' : 'Not Engaged'}
                            map={UserEngagement}/>
                    </Box>
                </FormField>
                <DisabledTextInput label='Date of Birth' value={data.session.client.user.date_of_birth}/>
                <DisabledTextInput label='SSN' value={data.session.client.ssn}/>
                <DisabledTextInput label='Religion' value={data.session.client.religion}/>
            </Grid>
        </Box>
    )
};