import React, {useContext} from 'react';
import {Box, Text, TextInput, FormField, MaskedInput, Button, Grid, ResponsiveContext, CheckBox} from "grommet";
import {Link} from "react-router-dom";
import {LoadingButton} from "../../components/LoadingButton";
import {Form} from "formik";
import {ImageUpload} from "../../components/ImageUpload";
import {EMAIL_MASK, PHONE_NUMBER_MASK} from "../../lib/constants";

export const InviteStaffForm = ({status, values, errors, handleChange, setFieldValue, isSubmitting, loading, isValidating, roleOptions}) => {

    const deviceSize = useContext(ResponsiveContext);

    return (
        <Form>
            <Grid
                gap='medium'
                fill
                columns={deviceSize === 'small' ? 'auto' : ["auto", "1/3"]}
            >
                <Box background='white' fill pad='medium' elevation='xsmall' round='xsmall'
                     gap='small'>
                    <Text>Contact Details</Text>
                    <Box direction='row' gap='medium'>
                        <FormField label='First Name' error={errors.firstName}>
                            <TextInput
                                name='firstName'
                                value={values.firstName}
                                onChange={handleChange}
                            />
                        </FormField>
                        <FormField label='Last Name' error={errors.lastName}>
                            <TextInput
                                name='lastName'
                                value={values.lastName}
                                onChange={handleChange}
                            />
                        </FormField>
                    </Box>
                    <Box direction='row' gap='medium'>
                        <FormField label='Email' error={errors.email}>
                            <MaskedInput
                                name='email'
                                mask={EMAIL_MASK}
                                value={values.email}
                                onChange={handleChange}
                            />
                        </FormField>
                        <FormField label='Phone' error={errors.phoneNumber}>
                            <MaskedInput
                                name='phoneNumber'
                                mask={PHONE_NUMBER_MASK}
                                value={values.phoneNumber}
                                onChange={handleChange}
                            />
                        </FormField>
                    </Box>
                    <Box direction='row' gap='medium'>
                        <FormField label="Role" error={errors.isManager}>
                            <CheckBox
                                checked={values.isManager}
                                toggle
                                label="Is Clinical Administrator"
                                onChange={({target: {checked}}) => {setFieldValue('isManager', checked)}}
                            />
                        </FormField>
                    </Box>
                    {status && <Text color="lightgray">{status.message}</Text>}
                </Box>
                <Box background='white' fill pad='medium' elevation='xsmall' round='xsmall'
                     gap='small'>
                    <Text>Photo</Text>
                    <ImageUpload setFieldValue={setFieldValue} usePlaceholder/>
                    {errors.avatar && <Text color='red'>{errors.avatar}</Text>}
                </Box>
            </Grid>
            <Box margin={{top: 'small'}} direction='row' gap='small'>
                <LoadingButton loading={loading || isSubmitting || isValidating} type='submit' primary label='Invite'/>
                <Link to='/staff/create'><Button primary label='Cancel' color='lightgray'/></Link>
            </Box>
        </Form>
    )
};
