import React, { useState } from 'react';
import { Select } from 'grommet';
import {useMutation} from "react-apollo";
import {useQuery} from "@apollo/react-hooks";

// the prefix name of the Create option entry
const prefix = 'Create';

const updateCreateOption = (text, defaultOptions, property) => {
    const len = defaultOptions.length;
    if (len && defaultOptions[len - 1][property].includes(prefix)) {
        // remove Create option before adding an updated one
        defaultOptions.pop();
    }
    defaultOptions.push({id: 0, [property]: `${prefix} '${text}'`});
};

// improving Search support of special characters
const getRegExp = text => {
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    return new RegExp(escapedText, 'i');
};

export const CreateOptionSelect = ({value, onChange, createOptionMutation, getOptionsQuery, mutateProperty = 'name', ...rest}) => {
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [options, setOptions] = useState(defaultOptions);
    const [searchValue, setSearchValue] = useState('');
    useQuery(getOptionsQuery, {
        onCompleted: (data) => {
            setDefaultOptions(data.options);
            setOptions(data.options)
        }});
    const [createOption] = useMutation(createOptionMutation, {
        // TODO: This should be the proper way to do it but this needs to done synchronously in the onChange method
        // update(cache, { data: { inserted_option } }) {
        //     const { options } = cache.readQuery({ query: getOptionsQuery });
        //     cache.writeQuery({
        //         query: getOptionsQuery,
        //         data: { options: options.concat([inserted_option]) },
        //     });
        // }
    });

    return (
        <Select
            value={value}
            valueKey={{key: 'id', reduce: true}}
            labelKey={mutateProperty}
            options={options}
            onClose={() => setOptions(defaultOptions)}
            onChange={async ({ option }) => {
                if (option[mutateProperty].includes(prefix)) {
                    const {data} = await createOption({variables: {[mutateProperty]: searchValue}},);
                    defaultOptions.pop(); // remove Create option
                    defaultOptions.push(data.inserted_option);
                    onChange(data.inserted_option.id);
                } else {
                    onChange(option.id)
                }
            }}
            onSearch={(text) => {
                updateCreateOption(text, defaultOptions, mutateProperty);
                const exp = getRegExp(text);
                setOptions(defaultOptions.filter(o => exp.test(o[mutateProperty])));
                setSearchValue(text);
            }}
            {...rest}
        />
    )
}