import React from 'react';
import {Box, Text} from "grommet";
import Page from "../../components/Page";
import * as Yup from "yup";
import {Formik} from 'formik';
import gql from 'graphql-tag';
import {useMutation, useQuery} from 'react-apollo';
import Spinner from "../../components/Spinner";
import {EditStaffForm, ROLE_IDS} from "./components/EditStaffForm";
import {MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS, useImageUpload} from "../../services/image";
import {COMPANY_OWNER, MANAGER, PROVIDER} from "../../lib/constants";

const EditMemberSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    phone_number: Yup.string(),
    status: Yup.string().required('Required'),
    roles: Yup.string().required('Required'),
    avatar: Yup.mixed()
        .test('fileSize', "File is too large", value => !value.name || value.size <= MAX_FILE_SIZE)
        .test('fileType', "Unsupported file format", value => !value.name || SUPPORTED_IMAGE_FORMATS.includes(value.type)),
});

const GET_USER = gql`
    query fetchStaffMember($id: Int!) {
        id @client
        role @client
        provider: provider_by_pk (id: $id){
            id
            phone_number
            user {
                id
                first_name
                last_name
                created_at
                email
                status {
                    id
                    name
                }
            }
        }
        statuses: user_status {
            id
            name
        }
        roles: auth_assignment(where: {user_id: {_eq: $id}}) {
            value: item_name
            item_name: user_id
        }
        sessions: session(where: {provider_session: {provider_id: {_eq: $id}}}) {
            id
            client {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation ($id: Int! $phone_number: String $user: user_set_input! $selectedRoles: [auth_assignment_insert_input!]! $deletedSessionsIds: [Int!] $insertSessions: [provider_session_insert_input!]!) {
        update_provider(where: {id: {_eq: $id}}, _set: {
            phone_number: $phone_number
        }) {
            returning {
                id
                phone_number
            }
        }
        update_user(where: {id: {_eq: $id}}, _set: $user) {
            returning {
                id
                first_name
                last_name
                email
            }
        }
        delete_auth_assignment(where: {user_id: {_eq: $id}}) {
            affected_rows
        }
        insert_auth_assignment (objects: $selectedRoles){
            affected_rows
        }
        delete_provider_session(where: {session_id: {_in: $deletedSessionsIds}}) {
            affected_rows
        }
        insert_provider_session(objects: $insertSessions) {
            returning {
                provider_id
                session_id
            }
        }
    }
`;


const sessionsToInsert = (assigned, initial) => {
    return assigned.filter(obj => !initial.some(initialObj => obj.id === initialObj.id));
}

const sessionsToDelete = (assigned, initial) => {
    return initial.filter(obj => !assigned.some(initialObj => obj.id === initialObj.id));
}

export const roleName = {
    [MANAGER]: `Clinical Administrator`,
    [PROVIDER]: `Clinician`,
    [COMPANY_OWNER]: `Practice Administrator`,
}

const EditStaffPage = ({match, history}) => {
    const id = (match.params && match.params.id) || 0;
    const {data, loading, error} = useQuery(GET_USER, {variables: {id}});
    const {uploadAvatar} = useImageUpload();
    const [updateUser] = useMutation(UPDATE_USER, {
        refetchQueries: ['fetchStaffMember'],
        awaitRefetchQueries: true
    });

    if (loading) {
        return 'Loading';
    }

    if (error) {
        return 'Error loading data.';
    }

    const initialSessions = data.sessions;
    const currentRoles = data.roles.map((role) => ({id: ROLE_IDS[role.value], role: role.value, name: roleName[role.value]}));

    const handleSubmit = async (values, actions) => {
        try {
            const {first_name, last_name, email, phone_number, assignedSessions, roles, status} = values;
            const userData = {first_name, last_name, email, status_id: status.id};

            const newSessions = sessionsToInsert(assignedSessions, initialSessions);
            const deletedSessions = sessionsToDelete(assignedSessions, initialSessions);

            const selectedRoles = roles.map((role) => {
                return {item_name: role.role, user_id: id}
            });

            const deletedSessionsIds = deletedSessions.map((session) => {
                return session.id;
            });

            const insertSessions = newSessions.map((session) => {
                return {provider_id: id, session_id: session.id}
            });

            await updateUser({
                variables: {
                    id,
                    phone_number,
                    user: userData,
                    selectedRoles,
                    deletedSessionsIds,
                    insertSessions
                }
            });
            if (values.avatar.name) {
                await uploadAvatar(values.avatar, id);
            }
            history.push('/staff');
        } catch (e) {
            console.log('error', e);
        }
    };

    return (
        <Page title="Staff Member">
            <Box flex="grow">
                {error ?
                    <Text color='red'>Could not load user profile data.</Text> :
                    loading ? <Spinner fill/> : <Formik

                        validationSchema={EditMemberSchema}
                        initialValues={{
                            id: data.provider.id,
                            roles: currentRoles,
                            phone_number: data.provider.phone_number,
                            first_name: data.provider.user.first_name,
                            last_name: data.provider.user.last_name,
                            email: data.provider.user.email,
                            status: data.provider.user.status,
                            statuses: data.statuses,
                            assignedSessions: initialSessions,
                            avatar: {}
                        }}
                        onSubmit={handleSubmit}
                    >
                        {(props) => <EditStaffForm currentRoles={currentRoles} {...props} />}
                    </Formik>}
            </Box>
        </Page>
    );
};

export default EditStaffPage;
