import React from 'react';
import {Box, Text} from "grommet";
import {Badge} from "../../../components/Badge";
import Avatar from "../../../components/Avatar";
import RoutedButton from "../../../components/RoutedButton";
import {Link} from "react-router-dom";
import {dateFormat} from "./constants";

export const SessionInfoHeader = ({session: {id: session_id, client: {user: {id: user_id, first_name, last_name}}}, latestResultOn, severity, linkToIndividualReport, template_id, twilioChannelSid, ...rest}) => {
    const containerWidth = severity ? 'large' : 'medium';

    return (
        <Box direction='row' justify='between' width={containerWidth}>
            <Box direction='row' gap='small'>
                <Link to={`/clients/${user_id}/${session_id}`}><Avatar id={user_id}/> </Link>
                <Box direction='column' gap='small'>
                    <Link to={`/clients/${user_id}/${session_id}`}>
                        <Text weight='bold' color='dark'>{first_name} {last_name}</Text>
                    </Link>
                    {
                        linkToIndividualReport ?
                            <RoutedButton path={`/measurement/report/${session_id}/${template_id}`}
                                          plain
                                          label={<Text color='brand'>View Individual Report</Text>}
                            /> :
                            twilioChannelSid && <RoutedButton path={`/messenger/${twilioChannelSid}`}
                                          plain
                                          label={<Text color='brand'>{`Message ${first_name}`}</Text>}
                            />
                    }
                </Box>
            </Box>

            <Box direction='column' gap='small'>
                <Text weight='bold' color='dark'>Latest Result</Text>
                {latestResultOn ?
                    <Text color='dark'>{new Date(latestResultOn).toLocaleDateString("en-US", dateFormat)}</Text> :
                    <Text color='dark'>No data</Text>}
            </Box>
            {severity &&
            <Box direction='column' gap='small'>
                <Text weight='bold' color='dark'>Latest Severity Status</Text>
                <Text color='dark'><Badge text={severity.label} color={severity.color}/></Text>
            </Box>
            }
        </Box>
    )
}


;
