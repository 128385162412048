import React from 'react';
import {Box, Text} from "grommet";
import {Link} from "react-router-dom";

const PlusButtonItem = ({link, label, icon}) => (
    <Link to={link}>
        <Box direction="row" align='center'>
            <Box margin={{right: "15px"}}>
                {icon}
            </Box>
            <Text weight='bold'>
                {label}
            </Text>
        </Box>
    </Link>
)

export default PlusButtonItem
