import React from 'react';
import {QuestionTypes} from "../../../../components/survey/Questions";
import {Box, Text} from "grommet";
import {TextEditor} from "./TextEditor";
import {MultipleChoiceEditor} from "./MultipleChoiceEditor";

const questionEditorMap = {
    [QuestionTypes.CHECKBOXES]: (question, updateQuestion) => {
        return <MultipleChoiceEditor {...question} updateQuestion={updateQuestion}/>
    },
    [QuestionTypes.DROPDOWN]: (question, updateQuestion) => {
        return <MultipleChoiceEditor {...question} updateQuestion={updateQuestion}/>
    },
    [QuestionTypes.RADIO_BUTTONS]: (question, updateQuestion) => {
        return <MultipleChoiceEditor {...question} updateQuestion={updateQuestion}/>
    },
    [QuestionTypes.SINGLE_LINE_TEXT]: (question, updateQuestion) => {
        return <TextEditor {...question} updateQuestion={updateQuestion}/>
    },
    [QuestionTypes.MULTI_LINE_TEXT]: (question, updateQuestion) => {
        return <TextEditor {...question} updateQuestion={updateQuestion}/>
    }
};

export const QuestionEditPanel = ({question, updateQuestion}) => (
    <Box pad="small">
        {question ? questionEditorMap[question.type](question, updateQuestion) : <Text>No Question Selected.</Text>}
    </Box>
);
