import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
    Accordion,
    AccordionPanel,
    Box,
    Button,
    Grid,
    ResponsiveContext,
    Tab,
    Tabs,
    Text,
    TextInput,
    FormField,
    CheckBox
} from "grommet";
import Page from "../../components/Page";
import {Alert, Close} from "grommet-icons";
import {newId} from "../../components/survey/Questions";
import {DroppableField} from "./DocumentBuilder/components/DroppableField";
import {Field, FieldTypes, getField} from "./DocumentBuilder/components/Field";
import {TextInputFieldProperties} from "./DocumentBuilder/components/TextInputField";
import {TextAreaInputFieldProperties} from "./DocumentBuilder/components/TextAreaInputField";
import {DropdownInputFieldProperties} from "./DocumentBuilder/components/DropdownInputField";
import {CheckboxInputFieldProperties} from "./DocumentBuilder/components/CheckboxInputField";
import {HeadingFieldProperties} from "./DocumentBuilder/components/HeadingElement";
import {ParagraphFieldProperties} from "./DocumentBuilder/components/ParagraphElement";
import gql from "graphql-tag";
import {useMutation} from "react-apollo";
import {LoadingButton} from "../../components/LoadingButton";
import {useHistory} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {Formik} from "formik";
import * as Yup from "yup";
import {DateInputFieldProperties} from "./DocumentBuilder/components/DateInputField";
import {TimeInputFieldProperties} from "./DocumentBuilder/components/TimeInputField";
import {PhoneNumberInputFieldProperties} from "./DocumentBuilder/components/PhoneNumberInputField";
import {RichTextFieldProperties} from "./DocumentBuilder/components/RichTextElement";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const CREATE_DOCUMENT_TEMPLATE = gql`
    mutation ($data: document_template_insert_input!) {
        insert_document_template_one(object: $data) {
            id
            title
            body
            created_at
            updated_at
            provider_signature_required
            client_signature_required
        }
    }
`;
const UPDATE_DOCUMENT_TEMPLATE = gql`
    mutation ($id: Int!, $data: document_template_set_input!) {
        update_document_template_by_pk(pk_columns: {id: $id} _set: $data) {
            id
            title
            body
            created_at
            updated_at
            provider_signature_required
            client_signature_required
        }
    }
`;

const FETCH_DOCUMENT_TEMPLATE = gql`
    query ($id: Int!){
        document: document_template_by_pk(id: $id) {
            id
            title
            body
            created_at
            updated_at
            provider_signature_required
            client_signature_required
        }
    }
`;


const defaultSettings = {
    className: "layout",
    rowHeight: 50,
    // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
};
// TODO: Add FieldTypes.RADIO_BUTTON, FieldTypes.EMAIL,
export const InputFields = [FieldTypes.TEXT, FieldTypes.TEXTAREA, FieldTypes.CHECKBOX, FieldTypes.DROPDOWN, FieldTypes.DATE, FieldTypes.PHONE_NUMBER, FieldTypes.TIME];

const fieldPropertiesMap = {
    [FieldTypes.TEXT]: (field, updateField) => {
        return <TextInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.TEXTAREA]: (field, updateField) => {
        return <TextAreaInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.DROPDOWN]: (field, updateField) => {
        return <DropdownInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.CHECKBOX]: (field, updateField) => {
        return <CheckboxInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.HEADING]: (field, updateField) => {
        return <HeadingFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.PARAGRAPH]: (field, updateField) => {
        return <ParagraphFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.RICH_TEXT]: (field, updateField) => {
        return <RichTextFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.DATE]: (field, updateField) => {
        return <DateInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.TIME]: (field, updateField) => {
        return <TimeInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.PHONE_NUMBER]: (field, updateField) => {
        return <PhoneNumberInputFieldProperties {...field} updateField={updateField}/>
    },
    [FieldTypes.DIVIDER]: () => (<Text>No properties for this element.</Text>)
}

const DocumentBuilderPage = ({match}) => {
    const documentId = useMemo(() => match?.params?.id, [match]);
    const typeId = match?.params?.type;
    const {data} = useQuery(FETCH_DOCUMENT_TEMPLATE, {variables: {id: documentId}, skip: !documentId, onCompleted: ({document} = {}) => {
        if (!document) {
            return;
        }
        setProviderSignatureRequired(document.provider_signature_required);
        setClientSignatureRequired(document.client_signature_required);
        setFields(document.body);
        setFieldsData(document.body.reduce((obj, item) => {
            obj[item.i] = item.data;
            return obj
        }, {}))
    },});
    const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
    const [providerSignatureRequired, setProviderSignatureRequired] = useState(true);
    const [clientSignatureRequired, setClientSignatureRequired] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [selectedField, setSelectedField] = useState(null);
    const [fieldsData, setFieldsData] = useState({});
    const [mounted, setMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [draggedField, setDraggedField] = useState(undefined);
    const [createDocumentTemplate, {loading: createLoading}] = useMutation(CREATE_DOCUMENT_TEMPLATE);
    const [updateDocumentTemplate, {loading: updateLoading}] = useMutation(UPDATE_DOCUMENT_TEMPLATE);
    const history = useHistory();
    const size = useContext(ResponsiveContext);

    useEffect(() => {
        setMounted(true);
    }, []);

    const generateDOM = useCallback(() => {
        const selectField = (id) => {
            if (id) {
                setSelectedField(id);
                setActiveTab(1);
                return;
            }
            setSelectedField(null);
            setActiveTab(0);
        }

        const onDelete = (id) => {
            selectField(null);
            setFields(prev => (prev.filter(({i}) => i !== id)))
            setFieldsData(prev => _.omit(prev, id));
            // console.log('onDelete', fields);
        }

        return _.map(fields, (l, i) => {
            return (
                <Box
                    key={l.i}
                    className={l.static ? "static" : ""}
                    data-grid={l}
                    onClick={() => selectField(l.i)}
                    border={{color: l.i === selectedField ? 'brand' : 'border'}}
                    round='xsmall'
                    wrap
                    overflow='visible'
                >
                    <Button
                        plain
                        icon={<Close size='10px' color='status-error'/>}
                        style={{zIndex: 9999, position: 'fixed', right: 14, bottom: 2}}
                        onClick={(e) => {onDelete(l.i); e.stopPropagation();}}
                    />
                    <Box pad='small' fill>
                        <Field {...fieldsData[l.i]} />
                    </Box>
                </Box>
            );
        });
    }, [fieldsData, fields, selectedField])

    const onBreakpointChange = breakpoint => {
        setCurrentBreakpoint(breakpoint);
    };

    const updateField = (id, fieldData) => {
        // console.log('updatefield', id, fieldData);
        setFieldsData(old => ({
            ...old,
            [id]: {
                ...old[id],
                ...fieldData
            }
        }))
    }

    const onLayoutChange = useCallback((layout) => {
        // console.log('layout change', layout, allLayouts, fields);

        if (layout.length === fields.length) {
            setFields(layout);
        }
    }, [fields]);

    const saveDocument = async (title) => {
        const data = fields.map(f => {
            const {dimensions, ...data} = fieldsData[f.i];
            return {...f, data};
        })
        let response;
        if (documentId) {
            response = await updateDocumentTemplate({
                variables: {
                    id: documentId, data: {
                        title,
                        body: data,
                        provider_signature_required: providerSignatureRequired,
                        client_signature_required: clientSignatureRequired
                    }
                }
            });
        } else {
            response = await createDocumentTemplate({
                variables: {
                    data: {
                        title, body: data, type_id: typeId, provider_signature_required: providerSignatureRequired,
                        client_signature_required: clientSignatureRequired
                    }
                }
            });
        }
        console.log('create document', JSON.stringify(data), response);
        history.push('/document-template-library');
    }

    const onDrop = (layout, layoutItem, _event) => {
        const type = _event.dataTransfer.getData('text/plain');
        const dimensions = getField(type).defaultSize;
        const item = {...layoutItem, type, ...dimensions};
        setFields(f => (f.concat([item])));
        setFieldsData(data => ({...data, [layoutItem.i]: {...data[layoutItem.i], type, dimensions}}));
        // console.log('type',_event.dataTransfer.getData('text/plain'));
        // console.log(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
        // console.log('onDrop', layout, layoutItem, _event);
        // console.log('onDrop', layout, layouts.lg.concat([layoutItem]));
    };

    const onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
        // `oldLayoutItem` contains the state of the item before the resize.
        // You can modify `layoutItem` to enforce constraints.
        // console.log('onResize', layout, layoutItem,);
        setFields(layout);
    }

    return (
        <Page title='Create Document'>
                <Grid
                    columns={size === 'small' ? 'auto' : size === 'medium' ? ["2/3", "1/3"] : ["3/4", "1/4"]}
                    gap="small"
                    align='start'
                >
                    <Formik
                        initialValues={{title: data?.document?.title || ''}} // TODO: Load all document data in Formik
                        enableReinitialize
                        validationSchema={Yup.object({title: Yup.string().required('This field is required').max(255)})}
                        onSubmit={async ({title}) => {
                            await saveDocument(title);
                        }}
                    >
                        {({values, errors, handleChange, submitForm}) => (
                            <Box gap='small'>
                                <Box overflow='vertical' gap='small' background='divider' pad='small' round='xsmall'>
                                    <Box background='white' round='xsmall' border pad='small' gap='small' width={{max: 'xxlarge'}} alignSelf='center' fill>
                                        <FormField error={errors.title}>
                                            <TextInput name='title' value={values.title} onChange={handleChange} placeholder='Title' size='large'/>
                                        </FormField>
                                        {['sm', 'xs', 'xxs'].includes(currentBreakpoint) && <Box background='yellow' gap='small' pad={{horizontal: 'medium', vertical: 'small'}} direction='row' round='xxsmall'>
                                            <Alert color='black'/>
                                            <Text weight='bold'>Screen is not big enough. Document layout may appear different on other screen sizes!</Text>
                                        </Box>}
                                        {/*<div>*/}
                                        {/*    Screen Size: {currentBreakpoint} (*/}
                                        {/*    {defaultSettings.cols[currentBreakpoint]} columns)*/}
                                        {/*</div>*/}
                                        <ResponsiveReactGridLayout
                                            {...defaultSettings}
                                            layouts={{lg: fields}}
                                            breakpoints={{lg: 996, md: 800, sm: 700, xs: 600, xxs: 500}}
                                            cols={{lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
                                            onBreakpointChange={onBreakpointChange}
                                            onLayoutChange={onLayoutChange}
                                            onDrop={onDrop}
                                            onResize={onResize}
                                            // WidthProvider option
                                            measureBeforeMount={false}
                                            // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                                            // and set `measureBeforeMount={true}`.
                                            useCSSTransforms={mounted}
                                            compactType='vertical'
                                            isDroppable={true}
                                            droppingItem={{w: 2, h: 2, i: newId()}}
                                            onDropDragOver={(e) => {
                                                if (draggedField) {
                                                    const {w, h} = getField(draggedField).defaultSize;
                                                    return {w, h};
                                                }
                                                return {w:2, h:2};
                                            }}
                                            style={{minHeight: '100px'}}
                                        >
                                            {generateDOM()}
                                        </ResponsiveReactGridLayout>
                                    </Box>
                                </Box>
                                <LoadingButton
                                    onClick={submitForm}
                                    label='Save'
                                    loading={createLoading || updateLoading}
                                    alignSelf='start'
                                />
                            </Box>
                        )}
                    </Formik>

                    <Box gap='medium' width='20%' style={{position: 'absolute', right: '12px'}}>
                        <Box background='white' round='xsmall' border pad='small' gap='small'>
                            <Tabs activeIndex={activeTab}
                                  onActive={(tab) => setActiveTab(tab)}>
                                <Tab title="ELEMENTS">
                                    <Accordion
                                        multiple
                                    >
                                        <AccordionPanel label="Inputs">
                                            <Grid
                                                columns={{count: 'fill', size: 'xsmall'}}
                                                gap="small"
                                                pad={{bottom: 'small'}}
                                            >
                                                {InputFields.map(field => <DroppableField
                                                    key={field}
                                                    label={getField(field).title}
                                                    type={field}
                                                    onClick={setDraggedField}
                                                    />)}
                                            </Grid>
                                        </AccordionPanel>
                                        <AccordionPanel label="Elements">
                                            <Grid
                                                columns={{count: 'fill', size: 'xsmall'}}
                                                gap="small"
                                                pad={{bottom: 'small'}}
                                            >
                                                <DroppableField
                                                    label='Heading'
                                                    type={FieldTypes.HEADING}
                                                    onClick={setDraggedField}
                                                />
                                                <DroppableField
                                                    label='Paragraph'
                                                    type={FieldTypes.PARAGRAPH}
                                                    onClick={setDraggedField}
                                                />
                                                <DroppableField
                                                    label='Rich Text'
                                                    type={FieldTypes.RICH_TEXT}
                                                    onClick={setDraggedField}
                                                />
                                                <DroppableField
                                                    label='Divider'
                                                    type={FieldTypes.DIVIDER}
                                                    onClick={setDraggedField}
                                                />
                                            </Grid>
                                        </AccordionPanel>
                                    </Accordion>
                                </Tab>
                                <Tab title="PROPERTIES" disabled={!selectedField}>
                                    {selectedField &&
                                        fieldPropertiesMap[fieldsData[selectedField].type]({id: selectedField, ...fieldsData[selectedField], item : fields.find(i => i.i === selectedField)}, updateField)}
                                </Tab>
                            </Tabs>

                        </Box>
                        <Text weight='bold'>Options</Text>
                        <Box background='white' pad='medium' round='xsmall' gap='small'>
                            <CheckBox
                                checked={providerSignatureRequired}
                                label="Provider Signature"
                                onChange={(event) => setProviderSignatureRequired(event.target.checked)}
                            />
                            <CheckBox
                                checked={clientSignatureRequired}
                                label="Client Signature"
                                onChange={(event) => setClientSignatureRequired(event.target.checked)}
                            />
                        </Box>
                    </Box>
                </Grid>

        </Page>
    );
}

export default DocumentBuilderPage;