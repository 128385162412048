import React, {useState} from 'react';
import {Box, Button, TextInput} from "grommet";
import {Hide, View} from "grommet-icons";

export const PasswordInput = ({value, onChange, name, onBlur}) => {
  const [reveal, setReveal] = useState(false);

  return (
      <Box
          direction="row"
          align="center"
          round="xsmall"
          border
      >
          <TextInput
              plain
              name={name}
              type={reveal ? "text" : "password"}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
          />
          <Button
              icon={reveal ? <Hide size="medium" /> : <View size="medium" />}
              onClick={() => setReveal(!reveal)}
          />
      </Box>
  );
};
