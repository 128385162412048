import React from 'react';
import {Box, Button, Text} from "grommet";
import GeneralError from "../../components/icons/GeneralError";

const MessengerErrorPage = ({initialize}) => (
    <Box fill align="center" justify='center' gap='small'>
        <Box height='medium'><GeneralError/></Box>
        <Text>Something went wrong.</Text>
        <Text color="sidebarInactive">Cannot connect to the chat server.</Text>
        <Button margin={{top: "small"}} label="Reconnect" onClick={initialize}/>
    </Box>
);

export default MessengerErrorPage;
