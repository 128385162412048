import React, {useState} from 'react' ;
import {Box, Text} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import RoutedButton from "../../components/RoutedButton";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import {Link} from "react-router-dom";
import {StatusCell} from "../../components/table/StatusCell";

const dateFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        primary: true,
        Cell: ({row: {original: {name, id}}}) => (
                <Link to={`/campaigns/view/${id}`}><Text>{name}</Text></Link>
        ),
    },
    {
        id: 'is_enabled',
        Header: 'Status',
        Cell: ({row: {original: {is_enabled}}}) => (
            <StatusCell name={is_enabled ? 'Enabled' : 'Disabled'} id={is_enabled ? 1 : 2} map={{1: {color: 'status-ok'}, 2: {color: 'status-critical'}}}/>
        ),
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => new Date(created_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Created On'
    },
    {
        id: 'updated_at',
        accessor: ({updated_at}) => new Date(updated_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Last Modified'
    },
];

const FETCH_DATA = gql`
query fetchCampaigns($limit: Int! $offset: Int! $orderBy: [campaign_order_by!] $filter: campaign_bool_exp) {
    data: campaign(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
        id
        name
        is_enabled
        created_at
        updated_at
    }
    data_aggregate: campaign_aggregate(where: $filter) {
        aggregate {
            count
        }
    }
}
`;

const CampaignsPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({name: {_ilike: `%${term}%`}})
            }/>
            <RoutedButton path='/campaigns/create' label='New Campaign' primary/>
        </Box>
    );
    return (
        <Page title="Campaigns" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns}/>
        </Page>
    );
};

export default CampaignsPage
