import React from "react";
import { Button, Box, Text } from "grommet";
import {colors} from "../theme";

const spinning = (
    <svg
        version="1.1"
        viewBox="0 0 32 32"
        width="18px"
        height="18px"
        fill={colors.white}
    >
        <path
            opacity=".25"
            d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
        />
        <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 16 16"
                to="360 16 16"
                dur="0.8s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
);

export const Spinner = () => (
    <Box align="center" justify="center">
        {spinning}
    </Box>
);

export const LoadingButton = ({loading, onClick, label, ...rest}) => (
    <Button
        onClick={!loading ? onClick : null}
        label={
            loading ? (
                <Box direction="row" gap="xsmall" align='center'>
                    <Spinner /><Text> Loading... </Text>
                </Box>
            ) : label
        }
        {...rest}
    />
);
