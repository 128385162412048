import React, {useState} from 'react';
import Page from "../../components/Page";
import gql from 'graphql-tag';
import {useMutation} from "@apollo/react-hooks";
import {LoadingButton} from "../../components/LoadingButton";
import {withRouter} from "react-router-dom";
import {ClientsSelect} from "../../components/ClientsSelect";

const ASSIGN_SESSIONS_TO_SURVEY = gql`
mutation ($surveyResults: [survey_result_insert_input!]!) {
    insert_survey_result(objects: $surveyResults) {
        returning { 
            id
        }
    }
}
`;

const ASSIGN_SESSIONS_TO_MEASUREMENT_SURVEY = gql`
mutation ($surveyResults: [measurement_survey_result_insert_input!]!) {
    insert_measurement_survey_result(objects: $surveyResults) {
        returning { 
            id
        }
    }
}
`;


export const SurveyAssignment = ({match, history}) => {
    const {id:templateId, measurement: isMeasurement = false} = match.params;
    const mutationQuery = isMeasurement ? ASSIGN_SESSIONS_TO_MEASUREMENT_SURVEY : ASSIGN_SESSIONS_TO_SURVEY;
    const title = isMeasurement ? 'Measurement Audience' : 'Survey Audience';

    const [selectedSessions, setSelectedSessions] = useState([]);
    const [assignSessions, {loading}] = useMutation(mutationQuery, { // TODO: Handle error
        onCompleted: () => isMeasurement ? history.push('/measurement/results') : history.push('/survey/results')
    });

    return (
        <Page title={title} header={
            <LoadingButton
                primary
                loading={loading}
                label="Assign"
                onClick={() => {
                    return assignSessions({
                    variables: {
                        surveyResults: selectedSessions.map(session => ({
                            session_id: session.id,
                            template_id: templateId
                        }))
                    }
                })}}
            />
        }>
            <ClientsSelect onChange={setSelectedSessions}/>
        </Page>
    )
};

export default withRouter(SurveyAssignment)
