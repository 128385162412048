import React, {useState} from 'react';
import {Box, Button, Layer, Text} from "grommet";
import {Close, Contract, Expand} from "grommet-icons";
import LocalVideoPreview from "./LocalVideoPreview";
import {InCall} from "./InCall";

export const VideoApp = () => {
    const [minimised, setMinimised] = useState(false);
    const [confirmDisconnect, setConfirmDisconnect] = useState(false);
    return (
        <Layer position={minimised ? 'bottom-left' : 'center'} modal={!minimised}>
        {/*<ErrorDialog dismissError={() => setError(null)} error={error}/>*/}
        <Box width={{min: minimised ? '316px' : "medium", max: minimised ? '316px': undefined}}
             height={{min: minimised ? 'small' : "medium"}}
             background='lightdirtywhite'
             round='small'
             justify='between'
        >
            <Box as="header"
                 background='white'
                 round={{size: 'small', corner: 'top'}}
                 pad='medium'
                 border={{side: 'bottom'}}
                 direction='row'
                 justify='between'
            >
                <Text weight='bold'>Video Call</Text>
                <Box direction='row' gap='small'>
                    <Button plain icon={minimised ? <Expand/> : <Contract/>} onClick={() => setMinimised(minimised => !minimised)}/>
                    <Button plain icon={<Close/>} onClick={() => setConfirmDisconnect(true)}/>
                </Box>
            </Box>
            <InCall minimised={minimised} disconnectConfirmation={confirmDisconnect} setDisconnectConfirmation={setConfirmDisconnect}/>
            <Box
                style={{position: 'absolute', bottom: minimised ? '6px' : '24px', right: minimised ? '6px' : '24px'}}
                width={minimised ? '152px' : 'small'}
                overflow="hidden"
                round='small'
            ><LocalVideoPreview/></Box>
        </Box>
    </Layer>
    );
}