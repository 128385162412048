import React, {useState} from 'react';
import {DataTable} from "../../../../components/DataTable";
import {Box} from "grommet";
import {BillStatus, BillStatusTags} from "../../../../lib/constants";
import gql from "graphql-tag";
import {SearchField} from "../../../../components/SearchField";
import moment from "moment";
import {useParams} from "react-router-dom";
import {StatusCell} from "../../../../components/table/StatusCell";
import {EditMeeting} from "../EditMeeting";
import {useMutation} from "@apollo/react-hooks";
import ConfirmationButton from "../../../../components/ConfirmationButton";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const columns = (markBillPaid) => [
    {
        accessor: 'meeting.name',
        Header: 'Name',
        primary: true,
        Cell: ({row: {original: {meeting: {name}}}}) => `Meeting ${name}`
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        Cell: ({row: {original: {amount}}}) => formatter.format(amount)
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => moment(created_at).format('MMM D, h:mm a'),
        Header: 'Created on'
    },
    {
        'id': 'status_id',
        Header: 'Status',
        Cell: ({row: {original: {bill_status}}}) => (
            <StatusCell id={bill_status.id} name={bill_status.name} map={BillStatusTags}/>
        )
    },
    {
        'id': 'actions',
        Cell: ({row: {original: {id, status_id}}}) => (
            <ConfirmationButton label='Mark Paid'
                                size="small"
                                disabled={status_id === BillStatus.PAID}
                                onAccept={async () => await markBillPaid({variables: {id}})}
                                text="Are you sure you want to mark this bill as paid? This operation cannot be undone."
                                acceptLabel="Save"
                                acceptButtonColor='brand'
                                primary
            />
        )

    }
];

const FETCH_MEETINGS = gql`
    query fetchBills($limit: Int! $offset: Int! $orderBy: [bill_order_by!] $filter: bill_bool_exp) {
        data: bill(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            created_at
            updated_at
            session_id
            provider_id
            amount
            meeting_id
            status_id
            bill_status {
                id
                name
            }
            meeting {
                id
                name
            }
        }
        data_aggregate: bill_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const MARK_BILL_PAID = gql`
    mutation markBillPaid($id: Int!) {
        bill: update_bill_by_pk(pk_columns: {id: $id}, _set: {status_id: 2}) {
            id
            status_id
        }
    }
`;

export const BillsTab = () => {
    const {session_id: sessionId} = useParams();
    const [termFilter, setTermFilter] = useState({});
    const [markBillPaid] = useMutation(MARK_BILL_PAID, {refetchQueries: ['fetchBills']});
    const [selectedBill, setSelectedBill] = useState(undefined);
    return (
        <Box gap='small'>
            {selectedBill &&
                <EditMeeting sessionId={sessionId} meetingId={selectedBill}
                             closeModal={() => setSelectedBill(undefined)}/>}
            <SearchField containerProps={{alignSelf: 'end'}}
                         onSubmit={(term) => setTermFilter({name: {_ilike: `%${term}%`}})}/>
            <DataTable
                query={FETCH_MEETINGS}
                applyFilter={{_and: [{session_id: {_eq: sessionId}}, termFilter]}}
                columns={columns(markBillPaid)}
                defaultSort={{'created_at': 'desc'}}
            />
        </Box>
    );
}