import React from 'react';
import {Box, FormField, Grid, MaskedInput, TextInput} from "grommet";
import IField, {CARD_TYPE, CVV_TYPE} from "react-cardknox-ifields";
import {PAYMENT_ACCOUNT, PAYMENT_OPTIONS} from "../services/payment";
import {EXPIRATION_DATE_MASK} from "../lib/constants";

export const CardFieldsGrid = ({fieldOptions = PAYMENT_OPTIONS, errors, handleChange, handleBlur, values, setFieldError, gridArea, cardNumberRef, cvvRef, setFieldValue, ...rest}) => {
    return (
        <Grid
            gridArea={gridArea}
            areas={
                [
                    {name: 'cardholder', start: [0, 0], end: [2, 0]},
                    {name: 'cardNumber', start: [0, 1], end: [2, 1]},
                    {name: 'expirationDate', start: [0, 2], end: [0, 2]},
                    {name: 'cvv', start: [1, 2], end: [1, 2]},
                ]}
            columns={['auto', 'auto', 'auto']}
            rows={['auto', 'auto', 'auto']}
            {...rest}
        >
            <Box gridArea='cardholder'>
                <FormField label="Cardholder Name (as it appears on the card)"
                           error={errors.name}>
                    <TextInput
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </Box>
            <Box gridArea='cardNumber'>
                <FormField label="Card Number" error={errors.card_number_token}>
                    <IField
                        onUpdate={() => cardNumberRef?.current?.getToken()}
                        ref={cardNumberRef}
                        onToken={(data) => setFieldValue('card_number_token', data.xToken)}
                        type={CARD_TYPE}
                        account={PAYMENT_ACCOUNT}
                        src={process.env.REACT_APP_IFIELDS_SRC}
                        options={fieldOptions}/>
                    <TextInput type="hidden" name="card_number_token" value={values.card_number_token}/>
                </FormField>
            </Box>
            <Box gridArea='expirationDate'>
                <FormField label="Expiration Date" error={errors.expiration_date}>
                    <MaskedInput
                        name='expiration_date'
                        mask={EXPIRATION_DATE_MASK}
                        value={values.expiration_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </Box>
            <Box gridArea='cvv'>
                <FormField label="CVV" error={errors.cvv_token} margin={{left: 'small'}}>
                    <IField
                        onUpdate={() => cvvRef?.current?.getToken()}
                        ref={cvvRef}
                        onToken={(data) => setFieldValue('cvv_token', data.xToken)}
                        type={CVV_TYPE}
                        account={PAYMENT_ACCOUNT}
                        src={process.env.REACT_APP_IFIELDS_SRC}
                        options={{...fieldOptions, iFieldstyle: {...fieldOptions.iFieldstyle, width: '100px'}}}/>
                    <TextInput type="hidden" name="cvv_token" value={values.cvv_token}/>
                </FormField>
            </Box>
        </Grid>
    );
}