import React, {useState} from 'react';
import {Box, Text} from "grommet";
import {DragDropContext} from "react-beautiful-dnd";
import Page from "../../components/Page";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import {UserCardDroppable} from "./components/UserCardDroppable";
import {SearchSelect} from "../../components/SearchSelect";
import {SESSION_STATUS} from "../../lib/constants";

const FETCH_DATA = gql`
{
    provider (order_by: {user: {first_name: asc, last_name: asc}}){
        id
        user {
            id
            first_name
            last_name
        }
        provider_sessions (where: {session: {status_id: {_eq: ${SESSION_STATUS.ACTIVE}}}} order_by: {session: {client: {user: {first_name: asc}}}}){
            session {
                id
                client {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
        }   
    }
    session(order_by: {client: {user: {first_name: asc, last_name: asc}}}, where: {_not: {provider_session: {}}}) {
        id
        client {
            id
            user {
                id
                first_name
                last_name
            }
        }
    }
}
`;

const ASSIGN_CLIENT = gql`
    mutation ($sessionId: Int! $providerId: Int!) {
        insert_provider_session(objects: {
            session_id: $sessionId,
            provider_id: $providerId
        }) {
            returning { 
                provider_id
                session_id
            }
        }
    }
`;

const UNASSIGN_CLIENT = gql`
    mutation ($sessionId: Int!) {
        delete_provider_session(where: {session_id: {_eq: $sessionId}}) {
            returning {
                provider_id
                session_id
            }
        }
    }
`;

const CaseloadsPage = () => {
    const [provider, setProvider] = useState();
    const {data, loading} = useQuery(FETCH_DATA, {fetchPolicy: "cache-and-network"}); // TODO: Handle error

    const [assignSession] = useMutation(ASSIGN_CLIENT, {
        update(cache, {data: {insert_provider_session: {returning}}}) {
            const data = cache.readQuery({query: FETCH_DATA});
            const s = data.session.find(session => session.id === returning[0].session_id);
            const p = data.provider.find(provider => provider.id === returning[0].provider_id);
            cache.writeQuery({
                query: FETCH_DATA,
                data: {
                    session: data.session.filter(session => session.id !== s.id),
                    provider: [
                        ...data.provider.filter(provider => provider.id !== p.id),
                        {
                            ...p,
                            provider_sessions: [
                                ...p.provider_sessions,
                                {session: s, __typename: "provider_session"}
                            ]
                        }
                    ]
                },
            });
        }
    });

    const [unassignSession] = useMutation(UNASSIGN_CLIENT, {
        update(cache, {data: {delete_provider_session: {returning}}}) {
            const data = cache.readQuery({query: FETCH_DATA});
            const p = data.provider.find(provider => provider.id === returning[0].provider_id);
            const s = p.provider_sessions.find(ps => ps.session.id === returning[0].session_id).session;
            cache.writeQuery({
                query: FETCH_DATA,
                data: {
                    session: [...data.session, s],
                    provider: [
                        ...data.provider.filter(provider => provider.id !== p.id),
                        {
                            ...p,
                            provider_sessions: p.provider_sessions.filter(ps => ps.session.id !== s.id)
                        }
                    ]
                },
            });
        }
    });

    if (loading) {
        return 'Loading...';
    }

    const onDragEnd = (movement) => {
        const {source, destination} = movement;
        if (!destination || source.droppableId === destination.droppableId) {
            return;
        }
        let sessionID;
        if (source.droppableId === 'providerSessions') {
            sessionID = data.provider.find(p => provider.id === p.id).provider_sessions[source.index].session.id;
            unassignSession({variables: {sessionId: sessionID}});
        } else {
            sessionID = data.session[source.index].id;
            assignSession({variables: {sessionId: sessionID, providerId: provider.id}});
        }
    };

    return (
        <Page title="Caseloads" boxProps={{overflow: "none"}}>
            <Box direction="row" justify='between'>
                <DragDropContext
                    onDragEnd={(result) => onDragEnd(result)}>
                    <Box elevation='xsmall' background='white' round="xsmall" pad='medium' fill
                         margin={{right: 'small'}} gap='small'>
                        <Box direction='row' gap='small'>
                            <Text color='darkgray' size='xsmall' weight='bold'>SELECT PROVIDER </Text>
                            <span className='dotDivider'> . </span>
                            <Text color='lightgray' size='xsmall'> Find providers to view their clients and/or
                                assign them new clients.</Text>
                        </Box>

                        <SearchSelect options={data.provider.map(({id, user}) => ({id, user}))}
                                      labelKey={(option) => `${option.user.first_name} ${option.user.last_name}`}
                                      onSelect={(selectedProvider) => setProvider(selectedProvider)}
                        />

                        {provider && <Box direction='row' gap='small'>
                            <Text color='darkgray' size='xsmall' weight='bold'>ASSIGNED CLIENTS </Text>
                            <span className='dotDivider'> . </span>
                            <Text color='lightgray' size='xsmall'> Assigned clients for this provider.</Text>
                        </Box>}
                        {provider && <UserCardDroppable
                            boxProps={{gap: "small"}}
                            id='providerSessions'
                            providers={data.provider
                                .find(p => p.id === provider.id).provider_sessions
                                .map(ps => ps.session)}
                        />}
                    </Box>
                    <Box elevation='xsmall' background='white' round="xsmall" pad='medium' width="medium" gap='small'>
                        <Text color='darkgray' size='xsmall' weight='bold'>UNASSIGNED CLIENTS</Text>
                        <Text color='lightgray' size='xsmall'>
                            Drag & Drop clients to assign them to the
                            provider.
                        </Text>
                        <UserCardDroppable
                            id='unassignedSessions'
                            providers={data.session}
                            boxProps={{direction: "column", wrap: false}}
                        />
                    </Box>
                </DragDropContext>
            </Box>
        </Page>
    );
};

export default CaseloadsPage;
