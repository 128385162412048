import React, {useEffect, useState} from "react";
import {Box, Button, Select, Text} from "grommet";
import {FormClose} from "grommet-icons";

const prefix = 'Create: ';


export const TagSelector = ({defaultOptions = [], createNewTag, preselectedTags = [], onChange, placeholder = 'Select Tags', disabled=false}) => {
    const [tagOptions, setTagOptions] = useState(defaultOptions);
    const [selectedTags, setSelectedTags] = useState(preselectedTags);
    const [filteredOptions, setFilteredOptions] = useState(tagOptions);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setTagOptions(defaultOptions.filter(o => !selectedTags.includes(o)))
    }, [defaultOptions, selectedTags])

    useEffect(() => {
        const exp = getRegExp(searchValue);
        setFilteredOptions(tagOptions.filter(o => exp.test(o.name)));
    }, [tagOptions, searchValue])

    const onRemoveTag = tag => {
        setSelectedTags(
            selectedTags.filter(selectedTag => selectedTag.id !== tag.id),
        );
        setFilteredOptions(options => [...options, tag])
    };

    const updateSelectedOptions = () => {
        onChange(selectedTags)
    }

    useEffect(updateSelectedOptions, [selectedTags])

    const renderTag = tag => {

        return (
            <Button
                key={`tag_${tag.id}_${new Date().valueOf()}`}
                href="#"
                disabled={disabled}
                onClick={event => {
                    if (!disabled) {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemoveTag(tag);
                    }
                }}
                onFocus={event => event.stopPropagation()}
            >
                <Box
                    align="center"
                    direction="row"
                    gap="xsmall"
                    pad={{vertical: 'xsmall', horizontal: 'small'}}
                    margin="xsmall"
                    background="brand"
                    round="4px"
                >
                    <Text size="small" color='white'>{tag.name}</Text>
                    <Box round="full" margin={{left: 'xsmall'}}>
                        <FormClose size="small" style={{width: '12px', height: '12px'}}/>
                    </Box>
                </Box>
            </Button>
        );
    }

    const updateCreateOption = (text) => {
        const len = tagOptions.length;

        if (len > 0 && tagOptions[len - 1].name.includes(prefix)) {
            // remove Create option before adding an updated one
            setTagOptions(tagOptions => tagOptions.slice(0, -1));
        }
        if (text && !filteredOptions.some(o => o.name === text)) {
            const id = defaultOptions.length + selectedTags.length + tagOptions.length
            setTagOptions(tagOptions => [...tagOptions, {id: id, name: `${prefix} '${text}'`}]);
        }
    };
    // improving Search support of special characters
    const getRegExp = text => {
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        return new RegExp(escapedText, 'i');
    };


    const renderOption = (option, state) => (
        <Box pad="small" background={state.active ? 'active' : undefined}>
            {option.name}
        </Box>
    );

    return (
        <Select
            closeOnChange={false}
            multiple
            disabled={disabled}
            value={
                <Box wrap direction="row">
                    {selectedTags && selectedTags.length ? (
                        selectedTags.map(option => renderTag(option))
                    ) : (
                        <Box
                            pad={{vertical: 'xsmall', horizontal: 'small'}}
                            margin="xsmall"
                        >
                            <Text weight={400} color="gray">{placeholder}</Text>
                        </Box>
                    )}
                </Box>
            }

            options={filteredOptions}
            labelKey="name"
            valueKey={{key: 'id', reduce: true}}
            selected={selectedTags.map(option =>
                filteredOptions.indexOf(option),
            )}
            onChange={async ({option}) => {
                if (option.name.includes(prefix)) {
                    setTagOptions(tagOptions => tagOptions.slice(0, -1));
                    setFilteredOptions(options => options.filter(o => o.id !== option.id))
                    await createNewTag(searchValue)
                } else {
                    setFilteredOptions(options => options.filter(o => o.id !== option.id))
                    setSelectedTags(selected => [...selected, option])
                }
            }}
            onSearch={(text) => {
                if (typeof createNewTag !== 'undefined') {
                    // handle tag creation
                    updateCreateOption(text);
                }
                setSearchValue(text);
            }}
            onClose={() => {
                setSearchValue("")
            }}
        >
            {renderOption}
        </Select>
    );
};
