import React from 'react';
import {Box, Text} from "grommet";
import {BasicInformation} from "./BasicInformation";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../../components/Spinner";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from 'yup';
import {ContactInformation} from "./ContactInformation";
import {SexIdentityInformation} from "./SexIdentityInformation";
import {Contacts} from "./Contacts";
import {EmployerInformation} from "./EmployerInformation";
import {TreatmentInformation} from "./TreatmentInformation";
import {CurrentMedications} from "./CurrentMedications";
import {useMutation} from "react-apollo";
import dot from "dot-object";
import {DiagnosisInformation} from "./DiagnosisInformation";
import {useParams} from "react-router-dom";

const VALIDATION_SCHEMA = Yup.object({
    session: Yup.object({
        mr_number: Yup.string().nullable(),
        client: Yup.object({
            ssn: Yup.string().nullable(),
            religion: Yup.string().nullable(),
            address: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            zip: Yup.string().nullable(),
            phone_number: Yup.string().nullable(),
            user: Yup.object({
                first_name: Yup.string().required('This field is required'),
                middle_name: Yup.string().nullable(),
                date_of_birth: Yup.string().nullable(),
                last_name: Yup.string().required('This field is required'),
                birth_sex_id: Yup.number().nullable(),
                sexual_orientation_id: Yup.number().nullable(),
                gender_identity_id: Yup.number().nullable(),
            })
        })
    }),
});

export const FETCH_SESSION = gql`
    query fetchClientSessionFields ($id: Int!) {
        providerId: id @client
        providerRoles: roles @client
        session: session_by_pk(id: $id) {
            id
            mr_number
            created_at
            projected_discharge_date
            discharge_date
            client {
                id
                recovery_date
                phone_number
                ssn
                religion
                address
                state
                zip
                city
                user {
                    id
                    first_name
                    middle_name
                    last_name
                    created_at
                    updated_at
                    token_issued_at
                    email
                    date_of_birth
                    birth_sex_id
                    sexual_orientation_id
                    gender_identity_id
                    gender_id
                    gender {
                        id
                        name
                    }
                }
            }
            status {
                id
                name
            }
            provider_session {
                twilio_channel_sid
                provider {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
            session_tags{
                tag_id
            }
            session_diagnoses {
                diagnosis_id
            }
        }
        birth_sex {
            id
            name
        }
        gender_identity {
            id
            name
        }
        sexual_orientation {
            id
            name
        }
    }
`;

const FIELDS = [
    // Basic Information
    'session.client.user.first_name', 'session.client.user.middle_name', 'session.client.user.last_name',
    'session.client.user.date_of_birth', 'session.client.ssn', 'session.client.religion',
    // Contact Information
    'session.client.address', 'session.client.city', 'session.client.state', 'session.client.zip', 'session.client.phone_number',
    'session.client.user.email',
    // Sexual Identity
    'session.client.user.birth_sex_id', 'session.client.user.sexual_orientation_id', 'session.client.user.gender_identity_id',
    // Treatment Information
    'session.projected_discharge_date',

];

const UPDATE_SESSION_MUTATION2 = gql`
    mutation ($session: session_set_input!, $sessionId: Int!, $clientId: Int!, $userId: Int!, $user: user_set_input!, $client: client_set_input!) {
        update_session_by_pk(pk_columns: {id: $sessionId} _set: $session) {
            id
            mr_number
            created_at
            projected_discharge_date
            discharge_date
        }
        update_client_by_pk(pk_columns: {id: $clientId} _set: $client) {
            id
            recovery_date
            phone_number
            ssn
            religion
            address
            state
            zip
            city
        }
        update_user_by_pk(pk_columns: {id: $userId}, _set: $user) {
            id
            first_name
            middle_name
            last_name
            created_at
            updated_at
            token_issued_at
            email
            date_of_birth
            birth_sex_id
            sexual_orientation_id
            gender_identity_id
            gender_id
        }
    }

`;

const generateInitialValues = (fields, data) => {
    const values = {};
    fields.forEach(field => dot.copy(field, field, data, values));
    return values;
}

export const FacesheetTab = () => {
    const {session_id: id} = useParams();
    const {data = {}, loading} = useQuery(FETCH_SESSION, {
            variables: {id},
            // Set to cache and network to update the ui after re-fetching the session query when tags are edited
            // fetchPolicy: 'cache-and-network'
        }
    ); // TODO: Handle Error
    const [updateSession] = useMutation(UPDATE_SESSION_MUTATION2);
    const formik = useFormik({
        initialValues: generateInitialValues(FIELDS, data),
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            const allValues = {...values};
            const postData = {user: {}, client: {}, session: {}};
            dot.transfer('session.client.user', 'user', allValues, postData);
            dot.transfer('session.client', 'client', allValues, postData);
            dot.transfer('session', 'session', allValues, postData);
            await updateSession({
                variables: {
                    ...postData,
                    sessionId: id,
                    clientId: data?.session?.client?.id,
                    userId: data?.session?.client?.user?.id
                }
            });
        },
        validationSchema: VALIDATION_SCHEMA
    });

    if (loading) {
        return <Spinner/>;
    }

    return (
        <FormikProvider value={formik}>
            <Form>
                <Box gap='medium'>
                    <Box background='white' round='xsmall' elevation='xsmall'>
                        <BasicInformation data={data}/>
                        <ContactInformation data={data}/>
                        <SexIdentityInformation data={data}/>
                        <Contacts sessionId={id}/>
                        <EmployerInformation clientId={data?.session?.client?.id}/>
                    </Box>
                    <Text weight='bold'>Treatment Information</Text>
                    <Box background='white' round='xsmall' elevation='xsmall'>
                        <TreatmentInformation data={data}/>
                        <DiagnosisInformation sessionId={id}/>
                        <CurrentMedications sessionId={id}/>
                    </Box>
                </Box>
            </Form>
        </FormikProvider>
    );
};