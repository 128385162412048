import React from 'react';
import {Box, Meter} from "grommet";
import {StepLabel} from "./StepLabel";

export const StepPagination = ({steps, stepIndex}) => (
    <Box gap='medium' align='center'>
            <Box direction='row' justify='evenly' gap='xlarge'>
                {steps.map((step, index) =>
                    <StepLabel key={index} label={step.label} index={index} currentIndex={stepIndex}/>
                )}
            </Box>
        <Box border round='small'>
            <Meter type="bar" values={[{value: (stepIndex + 1) / steps.length, color: 'green'}]} max={1} round
                   thickness='small'/>
        </Box>
    </Box>
);