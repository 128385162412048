import React from 'react'
import * as PropTypes from "prop-types";
import {Box, DataTable, Text} from "grommet";

export const ImportClientsPreview = (props) => {
    return (
        <Box background='white' fill pad='medium' elevation='xsmall' round='xsmall'>
            <DataTable
                size='medium'
                primaryKey
                columns={[
                    {
                        property: "rowNumber",
                        header: <Text>#</Text>,
                        size: 'xsmall'
                    },
                    {
                        property: "name",
                        header: <Text>Name</Text>,
                        primary: true,
                        render: client => `${client.firstName} ${client.lastName}`
                    },
                    {
                        property: "email",
                        header: <Text>Email</Text>,
                    },
                    {
                        property: "phoneNumber",
                        header: <Text>Phone</Text>,
                    },
                    {
                        property: "gender",
                        header: <Text>Gender</Text>,
                    },
                    {
                        property: "dateOfBirth",
                        header: <Text>DoB</Text>,
                    },
                    {
                        property: "mrNumber",
                        header: <Text>MR</Text>,
                    },
                    {
                        property: "sessionStatus",
                        header: <Text>Session Status</Text>
                    },
                    {
                        property: "diagnoses",
                        header: <Text>Diagnose</Text>
                    },
                    {
                        property: "recoveryDate",
                        header: <Text>Recovery Date</Text>
                    }

                ]}
                data={props.clientsData}
            />
        </Box>
    );
};

ImportClientsPreview.propTypes = {clientsData: PropTypes.any};
