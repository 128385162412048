import React from 'react';
import Page from "../../components/Page";
import {Text} from "grommet";
import {Formik} from "formik";
import {ProfileForm} from "./ProfileForm";
import gql from 'graphql-tag';
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spinner from "../../components/Spinner";
import * as Yup from "yup";
import YupPassword from 'yup-password'
import bcrypt from 'bcryptjs';
import {withRouter} from "react-router-dom";
import {MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS, useImageUpload} from "../../services/image";

YupPassword(Yup)

const validationSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    credentials: Yup.string(),
    phone_number: Yup.string().required('Required')
        .test('len', 'Phone number is not valid', value => !value || value.length === 14),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    avatar: Yup.mixed()
        .test('fileSize', "File is too large", value => !value.name || value.size <= MAX_FILE_SIZE)
        .test('fileType', "Unsupported file format", value => !value.name || SUPPORTED_IMAGE_FORMATS.includes(value.type)),
    password: Yup.string()
        .when('new_password', {
            is: (password) => password && password.length > 0,
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
    new_password: Yup.string()
        .when('password', {
            is: (password) => password && password.length > 0,
            then: Yup.string()
                .minNumbers(1, 'Must contain at least 1 number')
                .minLowercase(1, 'Must contain at least 1 lowercase character')
                .minUppercase(1, 'Must contain at least 1 uppercase character')
                .minSymbols(1, 'Must contain at least 1 special symbol')
                .min(8, 'Must contain at least 8 characters')
                .max(64, 'Must be shorter than 64 characters')
                .required('Required'),
            otherwise: Yup.string()
        }),
    repeat_new_password: Yup.string()
        .when('new_password', {
            is: (password) => password && password.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('new_password'), null], "New Passwords must match")
                .required('Required'),
            otherwise: Yup.string()
        }),
}, ['password', 'new_password']);

export const FETCH_DATA = gql`
query providerProfile($id: Int!) {
    id @client @export(as: "id")
    role @client
    data: provider_by_pk(id: $id) {
        phone_number
        credentials
        user {
            id
            first_name
            last_name
            email
            password_hash
        }
    }
}
`;

const UPDATE_PROFILE = gql`
mutation ($id: Int! $phone_number: String $credentials: String $user: user_set_input!) {
    update_provider(where: {id: {_eq: $id}}, _set: {
        phone_number: $phone_number
        credentials: $credentials
    }) {
        affected_rows
    }
    update_user(where: {id: {_eq: $id}}, _set: $user) {
        affected_rows
    }
}
`;

const ProfilePage = ({history}) => {
    const {data, loading, error} = useQuery(FETCH_DATA);
    const {uploadAvatar} = useImageUpload();
    const [updateProfile, {loading: updateLoading, error: updateError}] = useMutation(UPDATE_PROFILE, {
        refetchQueries: ['providerProfile']
    });
    return (
        <Page title='Your Profile'>
            {error ?
                <Text color='red'>Could not load user profile data.</Text> :
                loading ? <Spinner fill/> : <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        id: data.id,
                        role: data.role,
                        ...data.data.user,
                        phone_number: data.data.phone_number,
                        password: '',
                        credentials: data.data.credentials || '',
                        new_password: '',
                        repeat_new_password: '',
                        avatar: {}
                    }}
                    onSubmit={async ({id, phone_number, first_name, last_name, email, new_password, avatar, credentials}) => {
                        const userData = {
                            first_name, last_name, email,
                            ...(new_password.length > 0 ? {password_hash: bcrypt.hashSync(new_password, 13)} : {})
                        };
                        await updateProfile({variables: {id, phone_number, credentials, user: userData}});
                        if (avatar.name) {
                            await uploadAvatar(avatar, id);
                        } // TODO: Handle errors
                        // TODO: Update cached user name

                        window.Intercom('update', {
                            user_id: data.id,
                            email: userData.email,
                            name: `${userData.first_name} ${userData.last_name}`
                        });
                        history.push('/');
                    }}
                >
                    {(props) => <ProfileForm {...props} loading={updateLoading} error={updateError}/>}
                </Formik>}
        </Page>
    );
};

export default withRouter(ProfilePage);
