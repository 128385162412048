import React, {useState} from 'react';
import {DataTable} from "../../../../components/DataTable";
import {Box, Text} from "grommet";
import {MeetingType, MeetingTypeTags, NA} from "../../../../lib/constants";
import gql from "graphql-tag";
import {SearchField} from "../../../../components/SearchField";
import moment from "moment";
import {MeetingStatusButton} from "../../../Communication/components/MeetingStatusButton";
import {Badge} from "../../../../components/Badge";
import {useParams} from "react-router-dom";
import {StatusCell} from "../../../../components/table/StatusCell";
import {EditMeeting} from "../EditMeeting";
import Check from "../../../../components/icons/Check";
import {Close} from "grommet-icons";

// const timeFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};
const columns = (setSelectedMeeting) => [
    {
        accessor: 'name',
        Header: 'Name',
        primary: true,
        Cell: ({row: {original: {name, id}}}) =>
            <Text color="gray" size="small" weight="bold" onClick={() => setSelectedMeeting(id)}
                  style={{cursor: 'pointer'}}>{name}</Text>
    },
    {
        'id': 'description',
        // accessor: ({description}) => description || NA,
        Header: 'Description',
        Cell: ({row: {original: {description}}}) => (
            <Text truncate>{description || NA}</Text>
        )
    },
    {
        id: 'start_at',
        accessor: ({start_at}) => moment(start_at).format('MMM D, h:mm a'),
        Header: 'Starts on'
    },
    {
        'id': 'type_id',
        Header: 'Type',
        Cell: ({row: {original: {meeting_type}}}) => (
            <StatusCell id={meeting_type.id} name={meeting_type.name} map={MeetingTypeTags}/>
        )
    },
    {
        'id': 'approved',
        sortable: true,
        Header: 'Approved',
        Cell: ({row: {original: {approved}}}) => (approved ? <Check color="green"/> : <Close color="red"/>)
    },
    {
        'id': 'meeting_participants',
        Header: 'Checked In',
        Cell: ({row: {original: {meeting_participants}}}) => (meeting_participants.some(p => p.checked_in) ?
            <Check color="green"/> : <Close color="red"/>)
    },
    {
        id: 'notifications',
        sortable: false,
        Header: 'Client Notified',
        Cell: ({row: {original: {sms_notification, email_notification, message_notification}}}) =>
            <Box direction='row' gap='xsmall'>
                {sms_notification && <Badge color='brand' text='SMS'/>}
                {email_notification && <Badge color='green' text='Email'/>}
                {message_notification && <Badge color='yellow' text='Chat'/>}
                {!sms_notification && !email_notification && !message_notification && NA}
            </Box>
    },
    // {
    //     accessor: 'status_id',
    //     Header: 'Status',
    //     Cell: ({row: {original: {document_status: {id, name}}}}) => (
    //         <StatusCell id={id} name={name} map={SurveyResultStatus}/>
    //     ),
    // },
    {
        id: "join",
        sortable: false,
        // Header: '',
        Cell: ({row: {original: {id, status, start_at, type_id}}}) => type_id === MeetingType.ONLINE &&
            <MeetingStatusButton startAt={start_at} status={status} id={id}/>
    },
];

const FETCH_MEETINGS = gql`
    query fetchAppointments($limit: Int! $offset: Int! $orderBy: [meeting_order_by!] $filter: meeting_bool_exp) {
        data: meeting(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            start_at
            end_at
            approved
            name
            status
            type_id
            meeting_type {
                id
                name
            }
            meeting_participants {
                checked_in
            }
            description
            sms_notification
            email_notification
            message_notification
        }
        data_aggregate: meeting_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

export const AppointmentsTab = () => {
    const {session_id: sessionId} = useParams();
    const [termFilter, setTermFilter] = useState({});
    const [selectedMeeting, setSelectedMeeting] = useState(undefined);
    return (
        <Box gap='small'>
            {selectedMeeting &&
                <EditMeeting sessionId={sessionId} meetingId={selectedMeeting}
                             closeModal={() => setSelectedMeeting(undefined)}/>}
            <SearchField containerProps={{alignSelf: 'end'}}
                         onSubmit={(term) => setTermFilter({name: {_ilike: `%${term}%`}})}/>
            <DataTable
                query={FETCH_MEETINGS}
                applyFilter={{_and: [{meeting_participants: {session_id: {_eq: sessionId}}}, termFilter]}}
                columns={columns(setSelectedMeeting)}
                defaultSort={{'start_at': 'desc'}}
            />
        </Box>
    );
}