import React from 'react';
import {Box} from "grommet";
import Avatar from "../Avatar";
import RoutedAnchor from "../RoutedAnchor";
import {useNameAbreviation} from "../helpers/StringHelpers";

export const AvatarCell = ({first_name, last_name, id, path, user_id}) => {
    const fullName = useNameAbreviation(first_name, last_name);
    return (
        <Box direction='row' gap='small' align='center' flex wrap>
            <Avatar id={user_id}/>
            <RoutedAnchor
                path={`/${path}/${id}`}
                label={fullName}
                color="black"/>
        </Box>
    )
};

