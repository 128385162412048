import React from 'react';
import {Box, Text, TextInput, FormField, MaskedInput, Button, Grid, ResponsiveContext} from "grommet";
import Page from "../../components/Page";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import {Link, withRouter} from "react-router-dom";
import {ImageUpload} from "../../components/ImageUpload";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import API from "../../services/api";
import {LoadingButton} from "../../components/LoadingButton";
import {MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS, useImageUpload} from "../../services/image";
import {EMAIL_MASK, PHONE_NUMBER_MASK} from "../../lib/constants";

const InviteClientSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string(),
    avatar: Yup.mixed()
        .test('fileSize', "File is too large", value => !value.name || value.size <= MAX_FILE_SIZE)
        .test('fileType', "Unsupported file format", value => !value.name || SUPPORTED_IMAGE_FORMATS.includes(value.type)),
});

const InviteClientPage = ({history}) => {
    const {uploadAvatar} = useImageUpload();
    return (
        <Page title="Invite Client">
            <Formik
                initialValues={{
                    email: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    avatar: {}
                }}
                validationSchema={InviteClientSchema}
                onSubmit={async (values, actions) => {
                    console.log('values', values);

                    try {
                        const response = await API.post('/session/create', {
                            ...values,
                            recoveryDate: new Date(values.recoveryDate)
                        });
                        if (values.avatar.name) {
                            await uploadAvatar(values.avatar, response.data.client_id);
                        }
                        history.push('/clients');
                    } catch (e) {
                        console.log('e', e);
                        SagaErrorHandler.handle(e, actions);
                    }
                }}
                render={props => (
                    <Form>
                        <Box>
                            <ResponsiveContext.Consumer>
                                {(deviceSize) => (
                                    <Grid
                                        gap='medium'
                                        columns={deviceSize === 'small' ? 'auto' : ["auto", "1/3"]}
                                    >
                                        <Box background='white' pad='medium' elevation='xsmall' round='xsmall'
                                             gap='small'>
                                            <Text>Contact Details</Text>
                                            <Box direction='row' gap='medium'>
                                                <FormField label='First Name' error={props.errors.firstName}>
                                                    <TextInput
                                                        name='firstName'
                                                        value={props.values.firstName}
                                                        onChange={props.handleChange}
                                                    />
                                                </FormField>
                                                <FormField label='Last Name' error={props.errors.lastName}>
                                                    <TextInput
                                                        name='lastName'
                                                        value={props.values.lastName}
                                                        onChange={props.handleChange}
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box direction='row' gap='medium'>
                                                <FormField label='Email' error={props.errors.email}>
                                                    <MaskedInput
                                                        name='email'
                                                        mask={EMAIL_MASK}
                                                        value={props.values.email}
                                                        onChange={props.handleChange}
                                                    />
                                                </FormField>
                                                <FormField label='Phone' error={props.errors.phoneNumber}>
                                                    <MaskedInput
                                                        name='phoneNumber'
                                                        mask={PHONE_NUMBER_MASK}
                                                        value={props.values.phoneNumber}
                                                        onChange={props.handleChange}
                                                    />
                                                </FormField>
                                            </Box>
                                            {props.status && <Text color="lightgray">{props.status.message}</Text>}
                                        </Box>
                                        <Box background='white' pad='medium' elevation='xsmall' round='xsmall'
                                             gap='small'>
                                            <Text>Photo</Text>
                                            <ImageUpload setFieldValue={props.setFieldValue} usePlaceholder/>
                                {props.errors.avatar && <Text color='red'>{props.errors.avatar}</Text>}
                                        </Box>
                                    </Grid>
                                )}
                            </ResponsiveContext.Consumer>
                        </Box>
                        <Box margin={{top: 'small'}} direction='row' gap='small'>
                            <LoadingButton loading={props.isSubmitting} type='submit' primary label='Invite'/>
                            <Link to='/clients/create'><Button primary label='Cancel' color='lightgray'/></Link>
                        </Box>
                    </Form>
                )}
            />
        </Page>
    );
};

export default withRouter(InviteClientPage);
