import React from 'react';
import {Box, TextInput, FormField} from "grommet";

export const TextField = ({name, error, handleChange, value = '', placeholder = '', icon: Icon, type = 'text', inputProps = {}}) => (
    <FormField error={error} margin={{bottom: error ? 'none' : 'medium'}}>
        <Box
            direction="row"
            align="center"
            round="xsmall"
            border={{color: error ? 'lightred' : 'border'}}
        >
            <Box pad="small">
                <Icon color="sidebarInactive" size="medium"/>
            </Box>
            <TextInput
                plain
                placeholder={placeholder}
                name={name}
                type={type}
                value={value}
                onChange={handleChange}
                {...inputProps}
            />
        </Box>
    </FormField>
);
