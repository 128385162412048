import React, {Fragment, useState} from 'react';
import {Button, FormField, Grid, TextInput} from "grommet";
import {useFormikContext} from "formik";
import {DateInputPicker} from "../../../Communication/components/DateInputPicker";
import {AutosaveFormField} from "./AutosaveFormField";
import {Modal} from "../../../../components/Modal";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {useParams} from "react-router-dom";
import moment from "moment";
import {useClientSession} from "../../hooks/useClientSession";
import {SESSION_STATUS} from "../../../../lib/constants";

const DISCHARGE_SESSION = gql`
    mutation ($id: Int! $data: session_set_input) {
        update_session_by_pk(pk_columns: {id: $id} _set: $data) {
            id
            mr_number
            discharge_date
        }
    }
`;

export const TreatmentInformation = ({data}) => {
    const { setFieldValue } = useFormikContext();
    const params = useParams();
    const [showModal, setShowModal] = useState(false);
    const [discharge] = useMutation(DISCHARGE_SESSION);
    const {active} = useClientSession();

    const provider = data?.session?.provider_session?.provider;
    return (
            <Grid
                gap='medium'
                columns={{count: 'fill', size: 'small'}}
                border={{side: 'bottom'}}
                pad='medium'
            >
                <FormField label='Primary Therapist'>
                    <TextInput
                        disabled
                        value={provider ? `${provider.user?.first_name} ${provider.user?.last_name}` : 'N/A'}
                    />
                </FormField>
                <FormField label='Admission Date'>
                    <DateInputPicker
                        value={data?.session?.created_at}
                        disabled
                    />
                </FormField>
                <AutosaveFormField name='session.projected_discharge_date' label='Projected Discharge Date'>
                    <DateInputPicker
                        disabled={!active}
                        onSelect={selectedDate => setFieldValue('session.projected_discharge_date', selectedDate)}
                    />
                </AutosaveFormField>
                <FormField label='Discharge Date'>
                    <DateInputPicker
                        value={data?.session?.discharge_date}
                        disabled
                    />
                </FormField>
                {active && <Fragment>
                    <Button label='Discharge' primary color='status-error' alignSelf='center' onClick={() => setShowModal(true)}/>
                    <Modal
                        onClose={() => setShowModal(false)}
                        text='Are you sure you want to discharge this client?'
                        acceptLabel='Discharge'
                        accept={async (doneLoading) => {
                            await discharge({variables: {id: params.session_id, data: {
                                        discharge_date: moment().toISOString(),
                                        in_treatment: false,
                                        status_id: SESSION_STATUS.COMPLETED
                                    }}, refetchQueries: ['fetchClientSessionFields']})
                            doneLoading();
                            setShowModal(false);
                        }}
                        visible={showModal}
                    />
                </Fragment>}
            </Grid>
    )
};