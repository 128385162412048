import React from 'react';
import {Box, DataTable, Heading, Text} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {DeleteButton} from "../../../../components/DeleteButton";
import {CreateMedicationModal} from "./CreateMedicationModal";
import {useClientSession} from "../../hooks/useClientSession";

const FETCH_DATA = gql`
    query fetchMedications($id: Int) {
        session_medication(where: {session_id: {_eq: $id}}) {
            id
            medication {
                rxcui
                full_name
                strength
            }
            medication_frequency {
                id
                name
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation($id:Int!) {
        delete_session_medication(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

export const CurrentMedications = ({sessionId}) => {
    const {data, loading} = useQuery(FETCH_DATA, {variables: {id: sessionId}})
    const {active} = useClientSession();
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Current Medications</Heading>
            <DataTable
                primaryKey='id'
                columns={[
                    {
                        property: 'medication.full_name',
                        header: <Text>Name</Text>,
                        // primary: true,
                    },
                    {
                        property: 'medication.strength',
                        header: <Text>Dose</Text>,
                    },
                    {
                        property: 'medication_frequency.name',
                        header: <Text>Frequency</Text>,
                    },
                    active ? {
                        property: 'actions',
                        render: ({id}) =>
                            <DeleteButton
                                query={DELETE_MUTATION}
                                text='Are you sure you want to delete this medication?'
                                variables={{id}}
                                queriesToRefetch={['fetchMedications']}
                            />
                    }: undefined,
                ].filter(column => !!column)}
                placeholder={loading ? 'Loading...' : undefined}
                data={data?.session_medication || []}
                border={{ body: 'bottom' }}
                pad={{ horizontal: 'large', vertical: 'small' }}
            />
            {active && <CreateMedicationModal sessionId={sessionId} refetchQueries={['fetchMedications']}/>}
        </Box>
    )
};