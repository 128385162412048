import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {Box, Button, Heading, Layer, Select, Spinner, Text} from "grommet";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {useHistory, useParams} from "react-router-dom";
import {LoadingButton} from "../../../components/LoadingButton";
import {userHasNecessaryRole} from "../../../services/auth/service";
import {MANAGER} from "../../../lib/constants";

const HAS_ACTIVE_SESSION = gql`
    query ($id: Int!) {
        id @client
        session_aggregate(where: {_and: [{client_id: {_eq: $id}}, {status_id: {_eq: 1}}]}) {
            aggregate {
                count
            }
        }
        user: user_by_pk(id: $id) {
            id
            first_name
            last_name
        }
    }
`;

const CREATE_SESSION = gql`
    mutation ($client_id: Int! $provider_id: Int!) {
        session: insert_session_one(object: {
            client_id: $client_id
            provider_sessions: {
                data: [{
                    provider_id: $provider_id
                }]
            }
        })  {
            id
            client_id
        } 
    }
`;

const FETCH_PROVIDERS = gql`
    query {
        providers: provider {
            id
            user {
                id
                first_name
                last_name
            }
        }
    }
`

const ProviderSelect = ({provider = '', onChange}) => {
    const {data, loading} = useQuery(FETCH_PROVIDERS);
    if (loading) {
        return <Spinner/>
    }
    return (
        <Box gap='xsmall'>
            <Text>Assign to provider</Text>
            <Select
                options={data?.providers}
                labelKey={(option) => `${option.user.first_name} ${option.user.last_name}`}
                valueKey={{key: 'id', reduce: true}}
                value={provider}
                onChange={onChange}
            />
        </Box>
    )
}

export const CreateSessionButton = () => {
    const params = useParams();
    const history = useHistory();
    const {data, loading} = useQuery(HAS_ACTIVE_SESSION, {variables: {id: params.id}, onCompleted: data => setProvider(data.id)});
    const isManager = useMemo(() => userHasNecessaryRole(MANAGER), []);
    const [show, setShow] = useState(false);
    const [provider, setProvider] = useState('');
    const [createSession] = useMutation(CREATE_SESSION);

    const closeModal = useCallback(() => setShow(false), []);

    if (loading) {
        return <Spinner/>
    }

    return (
        <Fragment>
            <Button label='Create Episode' primary disabled={data?.session_aggregate?.aggregate?.count > 0} onClick={() => setShow(true)}/>
            {show && <Layer position="center" modal onClickOutside={closeModal} onEsc={closeModal}>
                <Box pad="medium" gap="small" width="medium">
                    <Heading level={3} margin="none">
                        Create new episode for {data?.user.first_name} {data?.user.last_name}
                    </Heading>
                    {isManager ? <Box>
                        <ProviderSelect onChange={({value}) => setProvider(value)} provider={provider}/>
                    </Box> : <Text>The client episode will be assigned to your caseload.</Text>}
                    <Box
                        as="footer"
                        gap="small"
                        direction="row"
                        align="center"
                        justify="end"
                        pad={{top: "medium", bottom: "small"}}
                    >
                        <Button label="Cancel" onClick={closeModal} color="dark-3"/>
                        <LoadingButton
                            loading={loading}
                            label={
                                <Text color="white" weight='bold'>Create</Text>
                            }
                            onClick={async () => {
                                const {data} = await createSession({variables: {provider_id: provider, client_id: params.id}})
                                closeModal();
                                history.push(`/clients/${data.session.client_id}/${data.session.id}`);
                            }}
                            primary
                        />
                    </Box>
                </Box>
            </Layer>}
        </Fragment>
    )
}