import React, {useState} from 'react';
import {DataTable} from "../../../../components/DataTable";
import {Box, Text} from "grommet";
import {NA} from "../../../../lib/constants";
import gql from "graphql-tag";
import {SearchField} from "../../../../components/SearchField";
import moment from "moment";
import {useParams} from "react-router-dom";
import {DownloadCorrespondenceButton} from "../DownloadCorrespondenceButton";
import {DeleteButton} from "../../../../components/DeleteButton";

const REMOVE_CORRESPONDENCE = gql`
    mutation removeCorrespondence ($id: Int!) {
        remove_correspondence(id: $id)
    }
`;
const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        primary: true,
    },
    {
        accessor: 'description',
        Header: 'Description',
        Cell: ({row: {original: {description}}}) => (
            <Text truncate>{description || NA}</Text>
        )
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => moment(created_at).format('MMM DD, YYYY'),
        Header: 'Created on'
    },
    {
        id: "actions",
        sortable: false,
        Cell: ({row: {original: {id}}}) => <Box direction='row' gap='small'>
            <DeleteButton
                query={REMOVE_CORRESPONDENCE}
                variables={{id}}
                queriesToRefetch={["fetchCorrespondence"]}
            />
            <DownloadCorrespondenceButton id={id}/>
        </Box>
    },
];

const FETCH_CORRESPONDENCE = gql`
    query fetchCorrespondence($limit: Int! $offset: Int! $orderBy: [correspondence_order_by!] $filter: correspondence_bool_exp) {
        data: correspondence(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            name
            description
            created_at
        }
        data_aggregate: correspondence_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

export const CorrespondenceTab = () => {
    const {session_id: sessionId} = useParams();
    const [termFilter, setTermFilter] = useState({});
    return (
        <Box gap='small'>
            <SearchField containerProps={{alignSelf: 'end'}} onSubmit={(term) => setTermFilter({name: {_ilike: `%${term}%`}})}/>
            <DataTable query={FETCH_CORRESPONDENCE} defaultSort={{created_at: 'desc'}} applyFilter={{_and: [{session_id: {_eq: sessionId}}, termFilter]}} columns={columns}/>
        </Box>
    );
}