import React from 'react';
import {Box, Button, FormField, Select, Text, TextInput} from "grommet";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import {Add, Subtract} from "grommet-icons";
import {newId} from "../../../../components/survey/Questions";
import {NA} from "../../../../lib/constants";

export const DropdownInputField = ({options, title, id, value, error, setFieldValue, disabled, preview}) => {
    return (
        <FormField label={title} error={error}>
            {preview ? <Box pad='small' border round='xsmall'><Text>{options.find(option => option.id === value)?.content || NA}</Text></Box> :
            <Select
                name={id}
                value={value}
                disabled={disabled}
                onChange={({ value, option }) => setFieldValue(id, value)}
                options={options || []}
                valueKey='id'
                labelKey='content'
            />}
        </FormField>
    )
};

DropdownInputField.defaultSize = {
    w: 2,
    h: 2,
    minW: 2,
    minH: 2,
    maxH: 2
};

DropdownInputField.title = 'Dropdown';

const DropdownInputValidationSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
    options: Yup.array()
        .of(Yup.object().shape({
                id: Yup.string()
                    .required('Required'),
                content: Yup.string()
                    .min(2, 'Too Short!')
                    .max(255, 'Too Long!')
                    .required('Required')
            })
        )
        .required('Question should contain options.') // these constraints are shown if and only if inner constraints are satisfied
        .min(2, 'A minimum of 2 options is required.'),
});

const exampleOptions = [
    {id: newId(), content: 'Option 1'},
    {id: newId(), content: 'Option 2'}
];

export const DropdownInputFieldProperties = ({title, options = exampleOptions, item, id, updateField}) => {
    console.log('prop', title, id, item);
    return (
        <Formik
            // same as onChange. Formik calls both validate and validationSchema.
            validate={(values) => {
                updateField(id, values)
            }}
            initialValues={{title, options}}
            validationSchema={DropdownInputValidationSchema}
            enableReinitialize
            onSubmit={() => {
            }}
        >
            {({errors, values, handleChange}) => (
                <Form>
                    <FormField label="Title" error={errors.title}>
                        <TextInput
                            name="title"
                            value={values.title || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label='Options'>
                        <FieldArray name="options">
                            {({push, remove}) => (
                                values.options.map((option, index) => (
                                    <Field
                                        key={option.id}
                                        name={`options.${index}.content`}
                                        render={({field, form: {handleChange, errors}}) => (
                                            <Box direction='row'>
                                                <FormField
                                                    error={errors.options && errors.options[index] && errors.options[index].content}
                                                >
                                                    <TextInput
                                                        name={field.name}
                                                        value={field.value || ''}
                                                        onChange={handleChange}
                                                    />
                                                </FormField>
                                                <Button icon={<Add/>}
                                                        onClick={() => push({id: newId(), content: 'Option'})}/>
                                                {index !== 0 ?
                                                    <Button icon={<Subtract/>} onClick={() => remove(index)}/>
                                                    :
                                                    null
                                                }
                                                {typeof errors.options === 'string' ?
                                                    <Text color="status-critical">{errors.options}</Text> : null
                                                }
                                            </Box>
                                        )}/>
                                ))
                            )}
                        </FieldArray>
                    </FormField>
                </Form>
            )}
        </Formik>
    )
}