import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, Text} from "grommet";
import Page from "../../components/Page";
import ReactToPrint from "react-to-print";
import {Print} from "grommet-icons";
import API from "../../services/api";
import Spinner from "../../components/Spinner";
import {ActivityBox} from "../../components/ActivityBox";
import moment from "moment";
import SurveyReport from "./components/SurveyReport";
import {ParticipantsList} from "./components/ParticipantsList";
import gql from "graphql-tag/lib/graphql-tag.umd";
import {useQuery} from "@apollo/react-hooks";
import {SearchSelect} from "../../components/SearchSelect";
import {NA} from "../../lib/constants";
import {PrintPage} from "../PrintPage";

const FETCH_SURVEYS = gql`
    query {
        templates: survey_template(where: {survey_results: {result: {_is_null: false}}}) {
            id
            title
        }
    }
`;

const SurveyAggregateReportPage = () => {
    const [template, setTemplate] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const {data: templates = {templates: []}, loading: initialLoading, error: initialError} = useQuery(FETCH_SURVEYS);
    const [data, setData] = useState({survey: {questions: []}, sessions: []});
    const printRef = useRef();
    useEffect(() => {
        setLoading(true);
        setError(undefined);
        (async () => {
            try {
                const {data} = await API.get('/survey-result/basic-report', {params: {id: template.id}});
                setData(data);
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        })();
    }, [template]);

    if (initialLoading) {
        return <Spinner fill/>
    }

    if (initialError) {
        console.log(initialError);
        return 'Error loading surveys'
    }

    const SurveyResults = () => {
        return (
            <SurveyReport
                template={data.survey}
                answers={data.data}
                surveysCount={data.numberOfResults}
            />
        )
    };

    return (
        <Page title='Report Results'>
            <Box gap='medium'>
                <Box round="xsmall" border direction="row" background="white" justify='between' pad='medium' flex={false}>
                    <Box gap="small" justify="center">
                        <Text weight="bold">Survey</Text>
                        <SearchSelect
                            labelKey="title"
                            options={templates.templates}
                            onSelect={(option) => setTemplate(option)}
                        />
                    </Box>
                    <ReactToPrint trigger={() => <Button plain icon={<Print/>}/>} content={() => printRef.current}/>
                </Box>
                {template && (error ? <Text color='red'>Error loading report</Text> : loading ? <Spinner full/> :
                    <Box gap="medium">
                        <ParticipantsList boxProps={{flex: false}} sessions={data.sessions}/>
                        <Grid
                            align="start"
                            columns={{count: "fit", size: "small"}}
                            gap="medium"
                        >
                            <ActivityBox color='brand'
                                         header={(Math.round(data.numberOfResults / data.allSurveysSentCount * 100) || 0).toString() + '%'}
                                         text='Completed the Survey'/>
                            <ActivityBox color='green' header={data.numberOfResults} text='Participated in Survey'/>
                            <ActivityBox color='red' header={data.allSurveysSentCount} text='Surveys Sent'/>
                            <ActivityBox color='active'
                                         header={data.averageDuration === 0 ? NA : moment.duration(data.averageDuration, 'milliseconds').humanize()}
                                         text='Average Duration'/>
                        </Grid>
                        <Box flex="grow" background='divider' elevation='xsmall' round="xsmall" pad="large">
                            <Box background='white' round="xsmall">
                                <SurveyResults/>
                            </Box>
                        </Box>
                    </Box>)}
            </Box>
            <Box style={{ overflow: 'hidden', height: '0'}}>
                <PrintPage ref={printRef}>
                    <SurveyResults/>
                </PrintPage>
            </Box>
        </Page>
    )
};

export default SurveyAggregateReportPage;
