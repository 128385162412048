import React from 'react';
import {Box, Button, FormField, Layer, Text, TextArea, TextInput} from "grommet";
import {Close} from "grommet-icons";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation} from "react-apollo";
import {EnumSelect} from "../../../../components/EnumSelect";
import {EnumSearchSelect} from "../../../../components/EnumSearchSelect";

const VALIDATION_SCHEMA = Yup.object({
    medication_id: Yup.number().required('This field is required'),
    frequency_id: Yup.number().required('This field is required'),
    notes: Yup.string(),
});

const CREATE_SESSION_MEDICATION = gql`
    mutation($data: [session_medication_insert_input!]!) {
        insert_session_medication(objects: $data) {
            returning {
                id
            }
        }
    }
`;

const FETCH_FREQUENCIES = gql`
    query {
        options: medication_frequency {
            id
            name
        }
    }
`;

const FETCH_MEDICATIONS = gql`
    query ($limit: Int! $offset: Int! $filter: medication_bool_exp) {
        options: medication (limit: $limit, offset: $offset, order_by: {full_name: asc}, where: $filter) {
            id: rxcui
            full_name
            strength
            route
        }
    }
`;

export const CreateMedicationModal = ({sessionId, refetchQueries = []}) => {
    const [show, setShow] = React.useState(false);
    const [createMedication] = useMutation(CREATE_SESSION_MEDICATION, {refetchQueries});
    return (
        <React.Fragment>
            <Button label="Add Medication" margin={{top: 'small'}} alignSelf='start' onClick={() => setShow(true)}/>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Button selfAlign='end' icon={<Close/>} onClick={() => setShow(false)}/>
                    <Box pad={{horizontal: 'large', vertical: 'small'}} width={{min: 'large'}}>
                        <Text weight='bold'>Add Medication</Text>
                        <Formik
                            validationSchema={VALIDATION_SCHEMA}
                            initialValues={{
                                medication_id: '',
                                strength: '',
                                route: '',
                                frequency_id: '',
                                notes: ''
                            }}
                            onSubmit={async (values) => {
                                const {route, strength, ...data} = values;
                                await createMedication({variables: {data: {...data, session_id: sessionId}}});
                                setShow(false);
                            }}
                        >
                            {({values, errors, handleChange, setFieldValue}) => (
                                <Form>
                                    <FormField label='Medication' error={errors.medication_id}>
                                        <EnumSearchSelect
                                            query={FETCH_MEDICATIONS}
                                            labelKey='full_name'
                                            name='medication_id'
                                            value={values.medication_id}
                                            onChange={({option, value}) => {
                                                setFieldValue('strength', option.strength);
                                                setFieldValue('route', option.route);
                                                setFieldValue('medication_id', value);
                                            }}
                                        />
                                    </FormField>
                                    <Box direction='row' gap='small'>
                                        <FormField label='Dose'>
                                            <TextInput
                                                name='strength'
                                                value={values.strength}
                                                disabled
                                            />
                                        </FormField>
                                        <FormField label='Route'>
                                            <TextInput
                                                name='route'
                                                disabled
                                                value={values.route}
                                            />
                                        </FormField>
                                    </Box>

                                    <FormField label='Frequency' error={errors.frequency_id}>
                                        <EnumSelect
                                            query={FETCH_FREQUENCIES}
                                            name='frequency_id'
                                            value={values.frequency_id}
                                            onChange={({value}) => setFieldValue('frequency_id', value)}
                                        />
                                    </FormField>
                                    <FormField label='Notes' error={errors.notes}>
                                        <TextArea
                                            name='notes'
                                            value={values.notes}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <Button label="Create" type='submit' alignSelf='stretch'/>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Layer>
            )}
        </React.Fragment>
    )
}