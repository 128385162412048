import React from 'react';
import {Box} from "grommet";
import {questionComponentMap, reportQuestionComponentMap} from "./Questions";

export const QuestionWrapper = ({question, isReport = false, ...rest}) => (
    <Box>
        {
            isReport ?
                reportQuestionComponentMap[question.type]({...question, ...rest}) :
                questionComponentMap[question.type]({...question, ...rest})
        }
    </Box>
);
