import React from 'react';
import {DataTable} from "../../../components/DataTable";
import {Link, useParams} from "react-router-dom";
import {Text} from "grommet";
import {StatusCell} from "../../../components/table/StatusCell";
import {NA, SurveyResultStatus, TxPlanStatus} from "../../../lib/constants";
import gql from "graphql-tag";
import moment from "moment";

const treatmentPlanColumns = [
    {
        Header: 'Treatment Plan',
        primary: true,
        accessor: ({id, name}) => (
            <Link to={`treatment-plans/view/${id}`}><Text weight='bold'>{name}</Text></Link>
        )
    },
    {
        accessor: 'status.name',
        Header: 'Status',
        Cell: ({row: {original: {status}}}) => {
            if (status) {
                return <StatusCell id={status.id} name={status.name} map={TxPlanStatus}/>
            }

            return "None"
        },
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => moment(created_at).format('MMM DD, YYYY'),
        Header: 'Created On'
    },
    {
        id: 'tx_plan_reviews',
        Cell: ({row: {original: {tx_plan_reviews, status_id, id, problems}}}) => {
            const review = tx_plan_reviews.length > 0 ? tx_plan_reviews[0] : false;
            if (review) {
                return <Link to={`treatment-plans/review/${review.id}`}><Text weight='bold'>View Review</Text></Link>
            }
            return NA;
        },
        Header: 'Review'
    },
    {
        accessor: 'tx_plan_reviews.status',
        Header: 'Status',
        Cell: ({row: {original: {tx_plan_reviews}}}) => {
            const review = tx_plan_reviews.length > 0 ? tx_plan_reviews[0] : false;
            return review ? <StatusCell id={review.status.id} name={review.status.name} map={SurveyResultStatus}/> : NA;
        },
    },
    {
        id: 'tx_plan_reviews.created_at',
        Cell: ({row: {original: {tx_plan_reviews}}}) => {
            const review = tx_plan_reviews.length > 0 ? tx_plan_reviews[0] : false;
            return review ? moment(review.created_at).format('MMM DD, YYYY') : NA;
        },
        Header: 'Created On'
    },
    {
        id: 'created_by',
        accessor: ({provider: {user: {first_name, last_name} = {}} = {}}) => `${first_name} ${last_name}`,
        Header: 'Created By'
    },
];

const FETCH_TREATMENT_PLANS = gql`
    query fetchTreatmentPlans($limit: Int! $offset: Int! $orderBy: [tx_plan_order_by!] $filter: tx_plan_bool_exp) {
        data: tx_plan(limit: $limit, offset: $offset,  order_by: $orderBy, where: $filter) {
            id
            name
            created_at
            status_id
            status {
                id
                name
            }
            tx_plan_reviews {
                id
                status_id
                status: tx_plan_review_status {
                    id
                    name
                }
            }
            provider {
                id
                user {
                    first_name
                    last_name
                }
            }
        }

        data_aggregate: tx_plan_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`

export const TreatmentPlansTable = () => {
    const params = useParams();
    return (
        <DataTable query={FETCH_TREATMENT_PLANS} applyFilter={{session_id: {_eq: params.session_id}}}
                   columns={treatmentPlanColumns}/>
    )
}