import {useEffect, useState} from "react";
import XLSX from "xlsx";


export const HEADER_OPTIONS = {
    FIRST_ROW: 0,
    ARRAY_OF_ARRAYS: 1,
    EXCEL_COLUMN_LETTERS: "A",
};

export const useExcelToJson = (file, header = HEADER_OPTIONS.FIRST_ROW, rowOffset = 0) => {

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);



    useEffect(() => {
        if (!file) {
            return;
        }

        setLoading(true);
        setError(false);


        let reader = new FileReader();

        reader.onload = (event) => {
            try {
                const bstr = event.target.result;
                const workbook = XLSX.read(bstr, {type: 'binary'});
                /* Get first worksheet */
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];

                /* Check ifi the number of columns match*/
                if (Array.isArray(header)) {
                    let range = XLSX.utils.decode_range(worksheet['!ref']);
                    let nCols = range.e.c - range.s.c + 1;

                    if (nCols !== header.length) {
                        throw new Error("The file does not contain the expected number of columns. Please use the template.");
                    }
                }

                /* Convert to JSON */
                // Configuring the parser to return each Excel row into a key-value JSON object
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                    header: header,
                    blankrows: false,
                    dateNF: 'yyyy-mm-dd;@',
                    raw: false,
                    range: rowOffset // when a number is given to the range, it is used as a row offset
                });
                setData(jsonData);
            } catch (e) {
                console.log("error:", e);
                setError(e.message);
            } finally {
                setLoading(false)
            }
        };

        reader.readAsBinaryString(file);
    }, [file, header, rowOffset]);

    return {data, loading, error};
};
