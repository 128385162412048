import {Box, Text} from "grommet";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {IndividualMeasurementReportHeader} from "./IndividualMeasurementReportHeader";
import {safeGet} from "../../../utils";
import {Suggestion} from "./Suggestion";
import LineChart from "./LineChart";
import BasicTemplateDataTable from "./BasicTemplateDataTable";

const BasicTemplate = ({session_id, printRef ,queryTable, queryData, status_id, template_id}) => {
    const variables = {
        session_id: session_id,
        template_id: template_id,
        status_id: status_id
    };
    const {data, loading} = useQuery(queryData, {variables: variables}); // TODO: Handle Error

    if (loading) {
        return <Spinner full/>
    }

    const latestResult = data.survey_results[data.survey_results.length - 1];

    return (
        <Box gap="medium">
            <Box justify='evenly' pad={{bottom: 'medium'}} gap='medium' flex="grow">
                <IndividualMeasurementReportHeader
                    reportName={data.template.title}
                    session={data.session}
                    latestResultOn={safeGet(() => latestResult.updated_at)}
                    twilioChannelSid={data.session?.provider_session?.twilio_channel_sid}
                    printRef={printRef}
                />
                {
                    latestResult &&
                    <Box gap='medium'>
                        {latestResult.suggestions.map((suggestion, index) => (
                            <Suggestion key={index} text={suggestion}/>
                        ))}
                        <Text weight="bold">{data.session.client.user.first_name}'s Report</Text>
                        <LineChart
                            data={data.survey_results}
                            colors={['#51DA5D']}
                            width='100%'
                            height='350'
                            minTick={data.template.metadata.domain.min}
                            maxTick={data.template.metadata.domain.max}
                            stepSize={data.template.metadata.domain.step_size}
                        />
                    </Box>
                }
                <Text weight="bold">{data.session?.client?.user?.first_name}'s Report Summary</Text>
                <BasicTemplateDataTable session_id={session_id} status_id={status_id} template_id={template_id}/>
            </Box>
        </Box>
    );
};

export default (BasicTemplate);
