import React, {useState} from 'react';
import {Select} from "grommet";

export const SearchSelect = ({options, valueKey = 'id', labelKey = 'name', selected, onSelect = () => {}, disabled=false}) => {
    const [value, setValue] = useState(selected);
    const [filteredValues, setFilteredValues] = useState(options);
    const filterValue = (option, exp) =>
        exp.test(typeof labelKey === "string" ? option[labelKey] : labelKey(option));
    return (
        <Select
            options={filteredValues}
            value={value || ''} // Handle uncontrolled input warning
            labelKey={labelKey}
            valueKey={valueKey}
            disabled={disabled}
            searchPlaceholder="Search..."
            onChange={({option}) => {setValue(option); onSelect(option);}}
            onClose={() => setFilteredValues(options)}
            onSearch={text => {
                const exp = new RegExp(text, "i");
                setFilteredValues(options.filter(option => filterValue(option, exp)));
            }}
        />
    )
};
