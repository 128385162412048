import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, ResponsiveContext} from "grommet";
import {Grommet as GrommetIcon} from "grommet-icons";
import routes from '../routes'
import Header from "./Header";
import {Sidebar} from "./Sidebar";
import {matchPath, withRouter} from "react-router-dom";
import {userHasNecessaryRole} from "../services/auth/service";
import {ChatProvider} from "../lib/ChatProvider";
import IdleTimer from 'react-idle-timer'
import {INACTIVE_MINUTES_TIMEOUT, UserInactiveModal} from "../components/UserInactiveModal";
import {useApolloClient, useQuery} from "@apollo/react-hooks";
import {AUTH_TOKEN_KEY, REFRESH_TOKEN, removeState} from "../localStorage";
import {INTERCOM_APP_ID} from "../lib/constants";
import {VideoMeetingProvider} from "../components/video/VideoMeetingProvider";
import {closeSubscription} from "../index";
import {menu} from "./menu";
import gql from "graphql-tag";
import Spinner from "../components/Spinner";
import {CompanySetupModal} from "../components/CompanySetupModal";

const COMPANY_QUERY = gql`
    query companySetupCheck($id: Int!) {
        company_by_pk(id: $id) {
            id
            cardknox_setup_complete
        }
    }
`;

// TODO: The methods below need to be extracted in a separate file (or at least moved into Sidebar.js)
const checkIfActiveMenu = ({exact, path, strict}, location) =>
    matchPath(location.pathname, {exact, path, strict}) != null;

// TODO: Do the filtering of the menu items based on the user role only once when the user is logged in.
//  (it should be invoked when the layout component reacts to userPermission changed)
const setActiveItems = (location) => {
    return menu.filter(item =>
        // This item has required permissions and the user does not meet them
        !(!!item.requiredRole && !userHasNecessaryRole(item.requiredRole))
    ).map(item => {
        // If no submenuItems check whether the path matches the current url
        if (!item.submenu) {
            return {...item, active: checkIfActiveMenu(item, location)};
        }
        // Else, check which is the active submenuItems and if one of them is active, make the parent active, too.
        let hasActiveSubmenu = false;
        const submenuItems = item.submenu.filter(submenuItem =>
            // This item has required permissions and the user does not meet them
            !(!!submenuItem.requiredRole && !userHasNecessaryRole(submenuItem.requiredRole))
        ).map((submenuItem) => {
            const isActive = checkIfActiveMenu(submenuItem, location);
            if (isActive) {
                hasActiveSubmenu = true; // Make the parent active menu, too
            }

            return {...submenuItem, active: isActive}
        });
        return {...item, active: hasActiveSubmenu, submenu: submenuItems}
    });
};

const AuthorizedLayout = ({user, location, history}) => {
    const size = useContext(ResponsiveContext);
    const [sidebarOpened, setSidebarOpened] = useState(true);
    const [inactivityModalOpened, setInactivityModalOpened] = useState(false);
    const client = useApolloClient();
    const {data, loading} = useQuery(COMPANY_QUERY, {variables: {id: user.companyID}});
    const cardKnoxSetupComplete = useMemo(() => data?.company_by_pk?.cardknox_setup_complete, [data]);

    useEffect(() => {
        window.Intercom('boot', {
            app_id: INTERCOM_APP_ID,
            email: user.email,
            user_id: user.id,
            name: user.name
        });
    });

    useEffect(() => {
        if (size !== "small" && !sidebarOpened) {
            setSidebarOpened(true);
        }
    }, [size, sidebarOpened]);

    useEffect(() => {
        if (size === "small") {
            setSidebarOpened(false);
        }
    }, [size, location]);

    const onIdle = () => {
        setInactivityModalOpened(true);
    };

    const toggleSidebar = () => setSidebarOpened(opened => !opened);
    const sidebarItems = useMemo(() => setActiveItems(location), [location]);

    if (loading) {
        return <Spinner fill/>
    }

    return (
        <ChatProvider>
            <VideoMeetingProvider>
                <Box fill background="light-1">
                    <Header
                        userSession={user}
                        onToggleSidebar={toggleSidebar}
                        client={client}
                    />
                    <Box direction="row" flex>
                        {sidebarOpened && (
                            <Sidebar
                                appIcon={<GrommetIcon color="brand"/>}
                                items={sidebarItems}
                                onToggleSidebar={toggleSidebar}
                                animation="slide"
                            />
                        )}
                        {!cardKnoxSetupComplete && <CompanySetupModal />}
                        <Box flex>
                            {routes}
                        </Box>
                    </Box>
                </Box>

                {inactivityModalOpened && <UserInactiveModal onTimeout={async () => {
                    removeState(AUTH_TOKEN_KEY);
                    removeState(REFRESH_TOKEN);
                    window.Intercom('shutdown');
                    closeSubscription();
                    client.cache.writeData({data: {isAuthenticated: false}});
                    history.push('/login');
                }} onClose={() => setInactivityModalOpened(false)}/>}

                <IdleTimer
                    element={document}
                    onIdle={onIdle}
                    debounce={250}
                    timeout={1000 * 60 * INACTIVE_MINUTES_TIMEOUT}
                />
            </VideoMeetingProvider>
        </ChatProvider>
    );
};

export default withRouter(AuthorizedLayout);
