import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useRouteMatch} from "react-router-dom";
import {Notification} from "../../../../components/Notification";
import {PrivateRoute} from "../../../../routes";
import {TreatmentPlansTable} from "../TreatmentPlansTable";
import {TreatmentPlanReview} from "../TreatmentPlanReview";
import CreateTreatmentPlanPage from "../../../TreatmentPlan/CreateTreatmentPlanPage";
import ViewTreatmentPlanPage from "../../../TreatmentPlan/ViewTreatmentPlanPage";
import EditTreatmentPlansPage from "../../../TreatmentPlan/EditTreatmentPlansPage";

// TODO: Notification needs to be refactored
export const TreatmentPlansTab = () => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const [showNotification, setShowNotification] = useState(location?.state?.hasOwnProperty('notification'));

    useEffect(() => {
        if (showNotification) {
            setTimeout(() => {
                setShowNotification(false);
            }, 3000);
        }
    }, [showNotification]);

    return (
        <Fragment>
            <PrivateRoute exact path={`${path}/review/:review_id`} component={TreatmentPlanReview}/>
            <PrivateRoute exact path={`${path}/view/:treatment_plan_id`} component={ViewTreatmentPlanPage}/>
            <PrivateRoute exact path={`${path}/create`} component={CreateTreatmentPlanPage}/>
            <PrivateRoute exact path={`${path}/edit/:treatment_plan_id`} component={EditTreatmentPlansPage}/>
            <PrivateRoute exact path={path} component={TreatmentPlansTable}/>
            <Notification content={location?.state?.notification.message}
                          color={location?.state?.notification.color}
                          isVisible={showNotification}
                          position='bottom'
            />
        </Fragment>
    );
}