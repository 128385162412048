import React, {forwardRef} from 'react';
import {PrintPage} from "../PrintPage";
import Survey from "../../components/survey/Survey";
import {Box, Text} from "grommet";
import Avatar from "../../components/Avatar";
import moment from "moment";

export const IndividualSurveyReportPrintPage = forwardRef(({survey}, ref) => (
    <Box style={{ overflow: 'hidden', height: '0'}}>
        <PrintPage ref={ref} gap='small'>
            <Box
                pad='medium'
                round='xsmall'
                direction="row"
                justify="between"
                align="center"
                gap="small"
                border
            >
                <Box direction="row" gap="medium" align="center">
                    <Avatar id={survey.session.client.user.id}/>
                    <Box gap="xsmall">
                        <Text
                            weight='bold'>{`${survey.session.client.user.first_name} ${survey.session.client.user.last_name}`}
                        </Text>
                    </Box>
                </Box>
                <Box gap="xsmall">
                    <Text weight='bold'>Completed On</Text>
                    <Text>{moment(survey.updated_at).format('MMM D, YYYY, h:mm A')}</Text>
                </Box>
                <Box gap="xsmall">
                    <Text weight='bold'>Duration</Text>
                    <Text>{moment.duration(moment(survey.updated_at).diff(survey.created_at, 'hours'), 'hours').humanize()}</Text>
                </Box>
            </Box>
            <Survey
                disableInput
                template={survey.template}
                answers={Array.isArray(survey.result) ? {} : survey.result}
                onSubmit={() => {}}
            />
        </PrintPage>
    </Box>
));