import React, {useMemo} from 'react';
import gql from "graphql-tag";
import moment from "moment";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {Form, Formik} from "formik";
import {Box, Button, CheckBox, FormField, List} from "grommet";
import {TimeInputPicker} from "../../Communication/components/TimeInputPicker";
import Page from "../../../components/Page";

export const FETCH_WORK_DAYS = gql`
    query fetchWorkDays($providerId: Int){
        id @client @export(as: "providerId")
        provider_id: id @client
        provider_work_schedule(where: {provider_id: {_eq: $providerId}}) {
            week_day
            start_hour
            end_hour
            breaks
        }
    }
`;

export const UPDATE_WORK_DAYS = gql`
    mutation ($providerId: Int!, $workDays: [provider_work_schedule_insert_input!]!) {
        delete_provider_work_schedule(where: {provider_id: {_eq: $providerId}}) {
            affected_rows
        }
        insert_provider_work_schedule(objects: $workDays) {
            affected_rows
        }
    }`;

const initialValues = moment.weekdays().reduce((acc, day) => {
    acc[day] = {
        checked: false,
        start_hour: '',
        end_hour: '',
    }
    return acc;
}, {});

export const WorkDays = () => {
    const {data, loading} = useQuery(FETCH_WORK_DAYS);
    const [updateWorkDays] = useMutation(UPDATE_WORK_DAYS, {refetchQueries: ['fetchWorkDays']});
    const initial = useMemo(() => {
        const values = {...initialValues};
        /* eslint-disable */
        data?.provider_work_schedule?.forEach(({week_day, start_hour, end_hour}) => {
            values[week_day] = {
                checked: true,
                start_hour: moment(start_hour, 'HH:mm').format('hh:mm a'),
                end_hour: moment(end_hour, 'HH:mm').format('hh:mm a'),
            }
        });
        return values;
    }, [data]);
    if (loading) return <Spinner/>;
    return (
        <Page title='Work days'>
            <Formik initialValues={initial} onSubmit={async (values) => {
                const workDays = moment.weekdays().filter(day => values[day].checked).map(day => ({
                    provider_id: data.provider_id,
                    week_day: day,
                    start_hour: values[day].start_hour,
                    end_hour: values[day].end_hour,
                }));
                await updateWorkDays({variables: {providerId: data.provider_id, workDays}});
            }}>
                {({values, handleChange, setFieldValue}) => (
                    <Form>
                        <Box background="white" elevation="xsmall" round="xsmall">
                            <List
                                primaryKey={(item) => item}
                                secondaryKey={(item) => <Box key={item} direction="row" gap="small"
                                                             alignSelf="center" justify="center" align="center">
                                    <FormField name={`${item}.checked`} margin="none">
                                        <CheckBox
                                            name={`${item}.checked`}
                                            checked={values[item].checked}
                                            onChange={(event) => setFieldValue(`${item}.checked`, event.target.checked)}
                                        />
                                    </FormField>
                                    {values[item].checked && (
                                        <FormField name={`${item}.start_hour`} margin="none">
                                            <TimeInputPicker
                                                name={`${item}.start_hour`}
                                                placeholder="Start hour"
                                                onChange={handleChange}
                                                value={values[item].start_hour}
                                            />
                                        </FormField>
                                    )}
                                    {values[item].checked && (
                                        <FormField name={`${item}.end_hour`} margin="none">
                                            <TimeInputPicker
                                                name={`${item}.end_hour`}
                                                placeholder="End hour"
                                                onChange={handleChange}
                                                value={values[item].end_hour}
                                            />
                                        </FormField>
                                    )}
                                </Box>}
                                data={moment.weekdays()}
                            />
                        </Box>
                        <Button type="submit" label="Save" primary margin={{top: 'small'}}/>
                    </Form>
                )}
            </Formik>
        </Page>
    )
}