import React, {useContext, useState} from 'react';
import {Calendar, DropButton, ResponsiveContext, TextInput} from "grommet";
import {Schedule} from "grommet-icons";

export const DateInputPicker = ({onSelect = () => {}, value, ...rest}) => {
    const [open, setOpen] = useState(false);
    const deviceSize = useContext(ResponsiveContext);
    return (
        <DropButton
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dropProps={{stretch: false}}
            disabled={rest?.disabled}
            dropContent={<Calendar size={deviceSize === 'small' ? 'small' : 'medium'} date={value} onSelect={(selected) => {
                onSelect(selected);
                setOpen(false);
            }}/>}
        >
            <TextInput
                icon={<Schedule/>}
                value={value ? new Date(value).toLocaleDateString() : "Select date"}
                {...rest}
            />
        </DropButton>
    )
};
