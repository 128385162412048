import React from 'react';
import {Box} from "grommet";

export const GreenDot = () => (
    <Box
        as='span'
        background="green"
        height='15px'
        width='15px'
        round
    />
);