import React from 'react';
import {Box, Button, Text} from "grommet";
import {Form} from "formik";
import {Lock} from "grommet-icons";
import {TextField} from "./TextField";

export const PasswordResetForm = ({values, errors, status, handleChange, touched, isSubmitting}) => (
    <Form>
        <TextField name='password'
                   type='password'
                   icon={Lock}
                   value={values.password}
                   handleChange={handleChange}
                   placeholder='New Password'
                   error={errors.password}
        />

        <TextField name='passwordRepeat'
                   type='password'
                   icon={Lock}
                   value={values.passwordRepeat}
                   handleChange={handleChange}
                   placeholder='Repeat Password'
                   error={errors.passwordRepeat}
        />

        {status && <Box margin={{bottom: "medium"}} width='small'>
            <Text color="lightgray">{status.message}</Text>
        </Box>}

        <Box direction='row-responsive'>
            <Button type="submit" primary fill label="Update" disabled={isSubmitting}/>
        </Box>
    </Form>
);
