import * as Yup from "yup";
import dot from 'dot-object';
import gql from "graphql-tag";
import React from "react";
import RoutedButton from "../../../components/RoutedButton";
import {castInput} from "../../../components/SelectClientsByAudiences";
import {SESSION_STATUS} from "../../../lib/constants";

export const AudienceValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
    audienceFilters: Yup.array().of(Yup.object().shape({
        operand: Yup.object().nullable().required('Required').shape({
            id: Yup.string(),
            name: Yup.string()
        }),
        operator: Yup.object().nullable().required('Required').shape({
            id: Yup.string(),
            name: Yup.string()
        }),
        filterValue: Yup.string().required('Required')
    }))
});

export const AUDIENCE = gql`
    query getAudience($id: Int!){
        audience_by_pk(id: $id) {
            company_id
            created_at
            id
            name
            locked
            filters {
                filterValue: filter_value
                operator {
                    id
                    name
                    expression
                }
                operand {
                    relation
                    column
                    type
                    name
                    options_table
                    id
                }
            }
        }
        audience_operand {
            id
            name
            options_table
            relation
            column
            type
            hidden
        }
        operator {
            id
            name
            expression
        }
    }
`;

export const SESSIONS = gql`
    query getSessions($where: session_bool_exp $filter: session_bool_exp){
        session (order_by: {client: {user: {first_name: asc, last_name: asc}}},where: {_and: [$where, $filter, {status_id: {_eq: ${SESSION_STATUS.ACTIVE}}}]}){
            id
            client {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
    }`;

export const buildWhereClauseAudiences = (audience) => {
    const filters = audience.filters.flat();
    return {
        _and: filters.map(filter =>
            dot.str(`${filter.operand.relation}.${filter.operand.column}.${filter.operator.expression}`, castInput(filter.operand.type, filter.filterValue), {})
        )
    };
};

// TODO: maybe this function is not useful and should restrict fetching hidden audience from the query, not to filter the fetched ones
export const getVisibleAudiencesOperands = (audienceOperands) =>  {
    return audienceOperands.filter(({hidden}) => !hidden);
};

export const getFiltersWithOptions = (filters) => filters.flatMap(({options_table}) => !!options_table ? options_table : []);


export const backButton = <RoutedButton label="Back to Audiences" path='/audiences'/>;
