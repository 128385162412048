import React, {useState} from "react";
import {SelectClientsByAudiences} from "./SelectClientsByAudiences";
import {Box, Button} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

const FETCH_DATA = gql`
    query {
        id @client
    }
`;

export const buildWhereClauseCaseload = (id) => {
    return {provider_session: {provider_id: {_eq: id}}};
};

export const ClientsSelect = (props) => {
    const [caseloadFilter, setCaseloadFilter] = useState(undefined);
    const {data = {}} = useQuery(FETCH_DATA); // TODO: Handle error
    const id = data.id;

    return (
        <Box background="white" pad="medium" round="xsmall" elevation="xsmall" gap="medium" flex={false}>
            <Box direction="row" gap="small" flex={false}>
                <Button primary label='Select caseload'
                        onClick={() => setCaseloadFilter({...buildWhereClauseCaseload(id)})}/>
            </Box>
            <SelectClientsByAudiences
                {...props}
                additionalFilter={caseloadFilter}
            />
        </Box>
    );
};