import React, {useEffect, useState} from 'react';
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {ChatPlaceholder} from "../../Communication/MessengerPage";
import {Box, Button, InfiniteScroll, Paragraph, Text, TextArea, Tip} from "grommet";
import Send from "../../../components/icons/Send";
import Page from "../../../components/Page";
import Avatar from "../../../components/Avatar";
import {useMutation} from "@apollo/react-hooks";
import moment from "moment";
import {DeleteButton} from "../../../components/DeleteButton";
import Spinner from "../../../components/Spinner";
import {useClientSession} from "../hooks/useClientSession";

const FETCH_NOTES = gql`
    query fetch_notes ($id: Int! $limit: Int! $offset: Int!){
        notes: session_note(where: {session_id: {_eq: $id}} limit: $limit offset: $offset order_by: {created_at: desc}) {
            id
            provider_id
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            note
            created_at
        }
    }
`;

const SUBSCRIBE_NOTES = gql`
    subscription subscribe_new_note ($id: Int!){
        note: session_note(where: {session_id: {_eq: $id}} limit: 1 order_by: {created_at: desc}) {
            id
            provider_id
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            note
            created_at
        }
    }
`;

const CREATE_NOTE = gql`
    mutation ($session_id: Int!, $note: String!) {
        insert_session_note_one(object: {session_id: $session_id, note: $note}) {
            id
            provider_id
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            note
            created_at
        }
    }
`

const DELETE_NOTE = gql`
    mutation ($id: Int!) {
        delete_session_note_by_pk(id: $id) {
            id
            provider_id
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            note
            created_at
        }
    }
`

const QUERY_LIMIT = 10;

export const ClientNotesBox = ({id}) => {
    const {data, loading, fetchMore, subscribeToMore} = useQuery(FETCH_NOTES, {
        // Initial offset is 1 because the first record is fetched with the subscription
        variables: {id, offset: 0, limit: QUERY_LIMIT}
    });
    const [createNote] = useMutation(CREATE_NOTE);
    const [inputValue, setInputValue] = useState('');
    const {active} = useClientSession();

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIBE_NOTES,
            variables: {id},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data || !prev) return prev;
                const newNote = subscriptionData.data.note[0];
                let notes = [...prev.notes];
                const existingNote = notes.findIndex(el => el.id === newNote.id);
                if (existingNote >= 0) {
                    notes.splice(existingNote, 1, newNote)
                } else {
                    notes = [newNote, ...prev.notes];
                }
                return Object.assign({}, prev, {
                    notes
                })
            },
            onError: () => {}
        })
    }, [id, subscribeToMore]);

    return (
        <Page title="Memo">
            <ChatPlaceholder>
                {active && <Box direction='row' gap='small' flex={false} align='center'>
                    <TextArea
                        resize={false}
                        value={inputValue}
                        placeholder='Space for reminders, considerations, thoughts...'
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Button primary icon={<Send width={24} height={24} color='white'/>} onClick={async () => {
                        if (!inputValue) return; // TODO: Wrap this in a form and add proper validation
                        await createNote({variables: {session_id: id, note: inputValue}});
                        setInputValue('');
                    }}/>
                </Box>}
                <Box overflow='auto'>
                    {loading ? <Spinner/> : data?.notes.length > 0 ?
                    <InfiniteScroll items={data?.notes || []} step={QUERY_LIMIT} onMore={() => fetchMore({
                        variables: {offset: data?.notes?.length || 0, id},
                        updateQuery: (prev, {fetchMoreResult}) => {
                            if (!fetchMoreResult) return prev;
                            return Object.assign({}, prev, {
                                notes: [...prev.notes, ...fetchMoreResult.notes]
                            })
                        }
                    })}>
                        {(note, index, ref) => <Box ref={ref} key={note.id} direction='row' gap='small' align='start' margin={{top: 'small'}} flex={false}>
                            <Tip content={`${note.provider.user.first_name} ${note.provider.user.last_name}`}><Box><Avatar id={note.provider_id} flex={false} margin={{top: 'xsmall'}}/></Box></Tip>
                            <Box gap='xsmall'>
                                <Box pad={{horizontal: 'small'}} border round='small'><Paragraph>{note.note}</Paragraph></Box>
                                <Box direction='row' gap='small' align='center'>
                                    {active && <DeleteButton
                                        text='Are you sure you want to delete this memo?'
                                        query={DELETE_NOTE}
                                        queriesToRefetch={['fetch_notes', 'subscribe_new_note']}
                                        variables={{id: note.id}}
                                        iconProps={{width: '16px', height: '16px', active: true}}
                                        buttonProps={{plain: true}}
                                    />}
                                    <Text>{moment(note.created_at).format('MMM D, YYYY')}</Text>
                                </Box>
                            </Box>

                        </Box>}
                    </InfiniteScroll> : <Text margin={{top: 'small'}}>No memos yet.</Text>}
                </Box>
            </ChatPlaceholder>
        </Page>
    )
}