import React, {useState} from 'react';
import {Box, Button, Text} from "grommet";
import {UserCard} from "../../../components/UserCard";

const NUMBER_OF_SESSIONS_SHOWN = 6;

export const ParticipantsList = ({sessions, boxProps = {}}) => {
    const [showAll, setShowAll] = useState(false);
    const sessionsToShow = showAll ? sessions : sessions.slice(0, NUMBER_OF_SESSIONS_SHOWN);

    return (
        <Box round='xsmall' border background='white' pad={{horizontal: 'medium', vertical: 'small'}}
             gap='small' {...boxProps}>
            <Box direction='row' justify='between'>
                <Text color='gray'>Participants</Text>
                {sessions.length > NUMBER_OF_SESSIONS_SHOWN &&
                <Button plain
                        onClick={() => setShowAll(show => !show)}
                        label={<Text
                            color='brand'>{showAll ? 'Hide some participants' : 'Show all participants'}</Text>}
                />}
            </Box>
            <Box
                pad="small"
                gap="small"
                background="light-1"
                round="xsmall"
                direction="row"
                wrap
            >
                {sessions.length > 0 ? sessionsToShow.map(session =>
                    <UserCard
                        key={session.id}
                        id={session.client.user.id}
                        firstName={session.client.user.first_name}
                        lastName={session.client.user.last_name}
                    />) : <Text>No participants found.</Text>}
                {!showAll && sessions.length > NUMBER_OF_SESSIONS_SHOWN &&
                <Text color='gray' alignSelf='center'>and others...</Text>}
            </Box>
        </Box>
    );
};
