import React from 'react'
import {Box, Header, Image, Text} from "grommet";
import {Wizard} from "./Wizard";
import {PROFESSIONAL_PLAN} from "../../lib/constants";

const JoinPage = ({match}) => {
    const plan = match.params.plan;
    // TODO: window._ss is not defined for some reason. I've put it in the index.html currently but it should be here.
    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.type = 'text/javascript';
    //     script.src = "http://koi-3QNMLV8FS4.marketingautomation.services/client/ss.js?ver=2.4.0";
    //     script.async = true;
    //     document.body.appendChild(script);
    //     script.onload = (a) => {
    //         /*global _ss*/
    //         console.log('onload', window._ss, a);
    //         // window._ss.push(['_setDomain', 'https://koi-3QNMLV8FS4.marketingautomation.services/net']);
    //         // window._ss.push(['_setAccount', 'KOI-4A31QLN7HE']);
    //         // window._ss.push(['_trackPageView']);
    //     }
    // }, []);
    return (
        <Box fill background="dirtywhite" overflow='auto'>
            <Header background='white' fill='horizontal' pad='medium' elevation='xsmall'>
                <Box>
                    <Image
                        src="/LogoHorizontal.svg"
                    />
                </Box>
                <Text size='large' color='blueblack'>
                    ContinueOn {Number(plan) === PROFESSIONAL_PLAN ? 'Professional' : 'Standard'} Plan
                </Text>
            </Header>
                <Wizard plan={plan}/>
        </Box>
    );
};

export default JoinPage;