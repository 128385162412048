import React, {useMemo} from "react";
import {SelectClientsByAudiences} from "../../../components/SelectClientsByAudiences";
import {buildWhereClauseCaseload} from "../../../components/ClientsSelect";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

const FETCH_DATA = gql`
    query {
        id @client
    }
`;

export const CaseloadClientsSelect = (props) => {
    const {data = {}} = useQuery(FETCH_DATA); // TODO: Handle error
    const id = data.id;
    return (
        <SelectClientsByAudiences
            boxProps={{background: "white", pad: "medium", round: "xsmall", elevation: "xsmall", flex: false}}
            {...props}
            fetchPolicy='network-only'
            staticFilter={useMemo(() => buildWhereClauseCaseload(id), [id])}
        />
    );
};