import React from 'react';
import {FormField, Heading, RangeInput, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";

export const HeadingElement = ({title, level}) => {
    return (
        <Heading margin='none' level={level} fill>{title}</Heading>
    )
};

HeadingElement.defaultSize = {
    w: 1,
    h: 1,
    minW: 1,
    minH: 1,
};

const HeadingSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('This field is required'),
    level: Yup.number().required('This field is required')
});

export const HeadingFieldProperties = ({title = '', level = 1, updateField, id, item}) => {
    return (

    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => {
            // const dimensions = {};
            // if (item.h < Math.sqrt(values.title.length * (6 - values.level))) {
            //     dimensions['h'] = Math.sqrt(values.title.length * (6 - values.level));
            // }
            // if (item.w < )
            updateField(id, {...values})
        }}
        initialValues={{title, level}}
        validationSchema={HeadingSchema}
        enableReinitialize
        onSubmit={() => {
        }}
    >
        {({errors, values, handleChange, setFieldValue}) => (
            <Form>
                <FormField label="Title" error={errors.title}>
                    <TextInput
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                    />
                </FormField>

                <FormField label="Size" error={errors.level}>
                    <RangeInput
                        name='level'
                        value={values.level}
                        onChange={event => setFieldValue('level', event.target.value)}
                        min={1}
                        max={6}
                        step={1}
                    />
                </FormField>
            </Form>
        )
        }
    </Formik>
)}