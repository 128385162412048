import {useState} from 'react';
import {useApolloClient} from "@apollo/react-hooks";
import gql from "graphql-tag";

const generateQuery = (options = []) => options.length ? gql`
    query{
        ${options.map(option => `${option} {
            id
            name
        }`).join("\n")}
    }
` : null;

export const useFetchAudienceFilterOptions = () => {
    const client = useApolloClient();
    const [options, setOptions] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const fetchOptions = async (filters, options) => {
        setLoading(true);
        setError(false);
        try {
            const optionsQuery = generateQuery(filters);
            if(optionsQuery){
                const {data} = await client.query({query: optionsQuery, ...options});
                setOptions(data);
            }
            else{
                setOptions([])
            }
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };
    return [fetchOptions, {options, loading, error}];
};
