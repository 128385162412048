import React from 'react';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {Box} from "grommet";
import {TemplatePreviewWrapper} from "./TemplatePreviewWrapper";
import {TemplateHeader} from "../../../../components/survey/TemplateHeader";

class TemplatePreview extends React.Component {
    render() {
        let {template, onHeaderActive, activeQuestionID} = this.props;
        const {onActive, onRemove, onClone, onUp, onDown} = this.props;


        if (!template) {
            return <div>No template</div>;
        }

        let {title, subtitle, questions} = template;
        const disableInput = false;
        return (
            <Box background="white" round="xsmall" fill overflow='auto' gap='medium'>
                <TemplateHeader title={title} subtitle={subtitle} onClick={onHeaderActive} />
                <Box pad={{horizontal: "large"}}>
                    <Formik
                        render={props => <TemplatePreviewWrapper {...{
                            ...props,
                            questions, disableInput, activeQuestionID,
                            onActive, onRemove, onClone, onUp, onDown
                        }} />}
                        initialValues={{}}
                        onSubmit={() => {
                        }}
                    />
                </Box>
            </Box>
        );
    }
}


TemplatePreview.propTypes = {
    survey: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        questions: PropTypes.array.isRequired
    }),
    answers: PropTypes.object,
};

export default TemplatePreview;
