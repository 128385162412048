import API from '../api';
import {saveState, loadState, AUTH_TOKEN_KEY, REFRESH_TOKEN} from "../../localStorage";
import {getClaimField} from "../resolvers";
import {PROVIDER} from "../../lib/constants";

export async function login(email, password) {
    const response = await API.post('/site/login', {
            email: email,
            password: password
        }
    );
    saveState(REFRESH_TOKEN, response.data.refreshToken);
    saveState(AUTH_TOKEN_KEY, response.data.jwtToken);
    return {
        authToken: response.data.jwtToken,
        refreshToken: response.data.refreshToken,
    };
}

export async function authenticateDevice(token, id) {
    const response = await API.post('/site/authenticate-device', {
            token,
        }, {params: {id}}
    );
    saveState(REFRESH_TOKEN, response.data.refreshToken);
    saveState(AUTH_TOKEN_KEY, response.data.jwtToken);
    return {
        authToken: response.data.jwtToken,
        refreshToken: response.data.refreshToken,
    };
}

export async function resendDeviceAuthenticationToken(id) {
    const response = await API.post('/site/resend-device-authentication-token', {}, {params: {id}});
    return response.data;
}

export async function requestPasswordReset(email) {
    const response = await API.post('/site/request-password-reset', {
        email: email,
    });
    return response.data;
}

export async function resetPassword(password, token) {
    const response = await API.post('/site/reset-password',
        {password},
        {params: {token}}
    );
    return response.data;
}

export async function requestSignUp(token, data) {
    const response = await API.post('/site/sign-up',
        data,
        {params: {token}}
    );
    return response.data;
}

export function isAuthenticated() {
    // TODO: Better fetch this from apollo
    return !!loadState(AUTH_TOKEN_KEY);
    // return store.getState().auth.isAuthenticated === true
}

export const userHasNecessaryRole = (requiredRole = PROVIDER) => {
    // The user has to logout and login in order to fetch new permissions
    // so it is perfectly fine to get the permissions from the jwt token like so
    // TODO: Better fetch those from apollo to utilize its cache functionality
    return Boolean(getClaimField('x-hasura-allowed-roles')?.includes(requiredRole));
};
