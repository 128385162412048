import React, {useContext, useMemo, useState, useEffect} from 'react';
import {Box, Grid, ResponsiveContext, Text} from "grommet";
import Step1 from "./Step1";
import {Form, Formik} from "formik";
import Step2 from "./Step2";
import {Sidebar} from "./Sidebar";
import Step3 from "./Step3";
import {StepPagination} from "./StepPagination";
import API from "../../services/api";
import {newId} from "../../components/survey/Questions";
import {LoadingButton} from "../../components/LoadingButton";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import {withRouter} from "react-router-dom";
import {PAYMENT_API_URL} from "../../services/payment";
import RoutedButton from "../../components/RoutedButton";
import Check from "../../components/icons/Check";
import {getCookie} from "../../lib/helpers";
import useGTM from "@elgorditosalsero/react-gtm-hook";

const steps = [Step1, Step2, Step3];
const submitLabel = ['Continue to Company Details', 'Continue to Billing Details', 'Start my 30 day trial'];
const id = newId();

export const Wizard = withRouter(({plan, history}) => {
    const [stepIndex, setStepIndex] = useState(0);
    const cardNumberRef = React.createRef();
    const cvvRef = React.createRef();

    const {sendDataToGTM, init} = useGTM();
    useEffect(() => init({
        id: process.env.REACT_APP_GTM_ID,
        environment: {
            gtm_auth: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_GTM_AUTH : process.env.REACT_APP_PROD_GTM_AUTH,
            gtm_preview: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_GTM_PREVIEW : process.env.REACT_APP_PROD_GTM_PREVIEW
        }
    }), [init]);

    const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
    const size = useContext(ResponsiveContext);
    const StepComponent = useMemo(() => steps[stepIndex], [stepIndex]);
    const smallScreenAreas = [
        ["pagination", "pagination"],
        ["form", "form"],
        ["sidebar", "sidebar"]
    ];
    const largeScreensAreas = [
        ["pagination", "pagination"],
        ["form", "sidebar"]
    ];

    return (
        <Box pad={{vertical: 'small'}} flex={false}>
            <Box gap='xsmall' flex={false}>
                <Box direction='row' justify='evenly' gap='medium'>
                    <Grid
                        gap='medium'
                        rows={["auto", "auto"]}
                        columns={size === 'small' ? ['auto'] : ['578px', '288px']}
                        areas={size === 'small' ? smallScreenAreas : largeScreensAreas}
                    >
                        <Box gridArea='pagination'>
                            <StepPagination stepIndex={registrationSuccessful ? 3 : stepIndex} steps={steps}/>
                        </Box>
                        <Box gridArea='form'>
                            {registrationSuccessful ?
                                <Box round='small' border elevation='xsmall' background='white' pad='large'
                                     justify='center'
                                     align='center' gap='medium'
                                     width={{min: 'large'}}
                                     height={{min: 'medium'}}
                                >
                                    <Check height='50px' width='50px'/>
                                    <Box align='center'>
                                        <Text size='xlarge' color='blueblack'>Registration Successful!</Text>
                                        <Text size='xlarge' color='blueblack'>You are ready to start using
                                            ContinueOn</Text>
                                    </Box>
                                    <Text color='lightgray'>You can login to your account by clicking the button
                                        below.</Text>
                                    <RoutedButton path='/login' label='Login' primary replace/>
                                </Box> :
                                <Box round='small' border elevation='xsmall' background='white' pad='large'>
                                    <Formik
                                        initialValues={{id, ...steps.reduce((values, step) => ({...values, ...step.initialValues}), {})}}
                                        validationSchema={StepComponent.validationSchema}
                                        onSubmit={async (values, actions) => {
                                            console.log('values', values);
                                            if (stepIndex < steps.length - 1) {
                                                try {
                                                    await API.post(`${PAYMENT_API_URL}/${StepComponent.url}`, {
                                                        ...values,
                                                        trackingid__sb: getCookie('__ss_tk')
                                                    });
                                                    sendDataToGTM({
                                                        event: 'advanceSignUpButtonClicked',
                                                        value: {step: stepIndex + 1}
                                                    });
                                                    setStepIndex(stepIndex + 1);
                                                } catch (e) {
                                                    SagaErrorHandler.handle(e, actions);
                                                }
                                                actions.setSubmitting(false);
                                                return;
                                            }
                                            try {
                                                await API.post(PAYMENT_API_URL + '/create-subscription', {...values, plan_id: plan});
                                                await API.post(`${PAYMENT_API_URL}/company-setup`, {
                                                    id,
                                                    planId: plan
                                                });
                                                setRegistrationSuccessful(true);
                                            } catch (e) {
                                                if (e.type) {
                                                    actions.setStatus(e);
                                                } else {
                                                    actions.setStatus({
                                                        type: 'status',
                                                        message: 'Your company profile could not be created. Please try another credit card.'
                                                    });
                                                }
                                            }
                                            sendDataToGTM({
                                                event: 'signUpComplete',
                                                value: {step: stepIndex + 2}
                                            });
                                            actions.setSubmitting(false);
                                        }}
                                    >
                                        {(formProps) => (<Form>
                                            <Box gap='medium'>
                                                <Text color='text'>{StepComponent.label}</Text>
                                                <StepComponent {...formProps} cardNumberRef={cardNumberRef}
                                                               cvvRef={cvvRef}/>
                                                <LoadingButton label={submitLabel[stepIndex]}
                                                               loading={formProps.isSubmitting}
                                                               type='submit' alignSelf='start' primary/>
                                                {formProps.status &&
                                                    <Text color='red'>{formProps.status.message}</Text>}
                                                {/*<Button primary label={`Continue`} type='submit' alignSelf='start'/>*/}
                                            </Box>
                                        </Form>)}
                                    </Formik>
                                </Box>
                            }
                        </Box>
                        {!registrationSuccessful && <Box gridArea='sidebar'>
                            <Sidebar content={StepComponent.sidebar}/>
                        </Box>}
                    </Grid>
                </Box>
            </Box>
        </Box>

    )
});