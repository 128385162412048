import React from 'react';
import {Box, Text} from "grommet";
import {QuestionTitle} from "./QuestionTitle";
import {AnswerRow} from "./AnswerRow";

const MultiLineTextReport = ({title, answers, type, number}) => (
    <Box gap="small">
        <QuestionTitle title={title} type={type} number={number}/>
        {answers.length > 0 ? answers.map((answer, index) => <AnswerRow title={answer.content} key={index}/>) :
            <Text weight='bold'>No results</Text>}
    </Box>
);

export default MultiLineTextReport;
