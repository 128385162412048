import React from 'react';
import {Box, Button} from "grommet";
import {QuestionWrapper} from "./QuestionWrapper";
import {Form} from "formik";


export const SurveyWrapper = ({questions, values, initialValues, errors, touched, status, disableInput, ...rest}) => (
    <Form>
        {questions.map(question => {
            return (
                <Box key={question.id}>
                    <QuestionWrapper
                        question={question}
                        value={values[question.id]}
                        error={errors[question.id]}
                        touched={touched[question.id]}
                        disabled={disableInput}
                        {...rest}
                    />
                </Box>)
        })}
        {disableInput ? '' :
            <Box align="center" pad="medium">
                <Button type="submit" primary label="Submit"/>
            </Box>}

    </Form>
)
