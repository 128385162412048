import {Redirect, Route, Switch} from "react-router-dom";
import DashboardPage from "./scenes/Dashboard/DashboardPage";
import RequestPasswordResetPage from "./scenes/Auth/RequestPasswordResetPage";
import PasswordResetPage from "./scenes/Auth/PasswordResetPage";
import LoginPage from "./scenes/Auth/LoginPage";
import React from "react";
import {isAuthenticated} from "./services/auth/service";
import ClientPage from "./scenes/Clients/ClientPage";
import SurveyPage from "./scenes/Surveys/SurveyPage";
import BasicReportPage from "./scenes/Surveys/BasicReportPage";
import CaseloadsPage from "./scenes/Clients/CaseloadsPage";
import SurveyResultsPage from "./scenes/Surveys/SurveyResultsPage";
import SurveyTemplatesPage from "./scenes/Surveys/SurveyTemplatesPage";
import SurveyAssignment from "./scenes/Surveys/SurveyAssignment";
import CreateSurveyTemplatePage from "./scenes/Surveys/CreateSurveyTemplatePage";
import {NoPermissionsPage} from "./scenes/NoPermissionsPage";
import ClientsPage from "./scenes/Clients/ClientsPage";
import {NewClientPage} from "./scenes/Clients/NewClientPage";
import SignUpPage from "./scenes/Auth/SignUpPage";
import MessengerPage from "./scenes/Communication/MessengerPage";
import NewAudiencePage from "./scenes/Audiences/NewAudiencePage";
import NewCampaignPage from "./scenes/Campaigns/NewCampaignPage";
import CampaignsPage from "./scenes/Campaigns/CampaignsPage";
import AudiencesPage from "./scenes/Audiences/AudiencesPage";
import ViewAudiencePage from "./scenes/Audiences/ViewAudiencePage";
import EditAudiencePage from "./scenes/Audiences/EditAudiencePage";
import InviteClientPage from "./scenes/Clients/InviteClientPage";
import StaffPage from "./scenes/Staff/StaffPage";
import {CreateStaffPage} from "./scenes/Staff/CreateStaffPage";
import InviteStaffPage from "./scenes/Staff/InviteStaffPage";
import EditStaffPage from "./scenes/Staff/EditStaffPage";
import {NoMatchPage} from "./scenes/NoMatchPage";
import BroadcastMessagePage from "./scenes/Communication/BroadcastMessagePage";
import TagsPage from "./scenes/Audiences/TagsPage";
import CreateTagPage from "./scenes/Audiences/CreateTagPage";
import ProfilePage from "./scenes/Profile/ProfilePage";
import MeasurementReportPage from "./scenes/Measurements/MeasurementReportPage";
import MeasurementSurveyTemplatesPage from "./scenes/Measurements/MeasurementSurveyTemplatesPage";
import MeasurementSurveyPage from "./scenes/Measurements/MeasurementSurveyPage";
import MeasurementComparisonReportPage from "./scenes/Measurements/MeasurementComparisonReportPage";
import MeasurementSurveyResultsPage from "./scenes/Measurements/MeasurementSurveyResutsPage";
import SurveyAggregateReportPage from "./scenes/Surveys/SurveyAggregateReportPage";
import ViewMeasurementSurveyPage from "./scenes/Measurements/ViewMeasurementSurveyPage";
import ViewSurveyTemplatePage from "./scenes/Surveys/ViewSurveyTemplatePage";
import CloneCampaignPage from "./scenes/Campaigns/CloneCampaignPage";
import CampaignPage from "./scenes/Campaigns/CampaignPage";
import {COMPANY_OWNER, MANAGER, PROFESSIONAL_PLAN, PROVIDER, STANDARD_PLAN} from "./lib/constants";
import MeetingsPage from "./scenes/Communication/MeetingsPage";
import CreateMeetingPage from "./scenes/Communication/CreateMeetingPage";
import BillingPage from "./scenes/Billing/BillingPage";
import JoinPage from "./scenes/Join/JoinPage";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Spinner from "./components/Spinner";
import CompanyDetailsPage from "./scenes/Settings/CompanyDetailsPage";
import NeedPlanUpgradePage from "./scenes/NeedPlanUpgradePage";
import {GeneralErrorPage} from "./scenes/GeneralErrorPage";
import ImportClientsPage from "./scenes/Clients/ImportClientsPage";
import EditSurveyTemplatePage from "./scenes/Surveys/EditSurveyTemplatePage";
import DocumentBuilderPage from "./scenes/Settings/DocumentBuilderPage";
import DocumentTemplatesPage from "./scenes/Settings/DocumentTemplatesPage";
import SchedulingPage from "./scenes/Scheduling/SchedulingPage";
import {WorkSchedulePage} from "./scenes/Scheduling/WorkSchedulePage";
import BillingCodesPage from "./scenes/Settings/BillingCodesPage";
import {CreateBillingCodePage} from "./scenes/Settings/CreateBillingCodePage";
import {CreatePaymentMethod} from "./scenes/Billing/CreatePaymentMethod";
import {PaymentSite} from "./scenes/PaymentSite/PaymentSite";
import {PaymentSuccess} from "./scenes/PaymentSite/PaymentSuccess";
import AuthenticateDevicePage from "./scenes/Auth/AuthenticateDevicePage";

export const FETCH_PLAN = gql`
    query {
        planId @client
        isAuthenticated @client
        roles @client
    }
`;

export const PrivateRoute = ({component: Component, requiredRole = PROVIDER, requiredPlan = STANDARD_PLAN, ...rest}) => {
    const {data, loading} = useQuery(FETCH_PLAN);
    if (loading) {
        return <Spinner fill/>;
    }
    return (
        <Route
            {...rest}
            render={props => {
                return (data.isAuthenticated ? (
                    data.roles.includes(requiredRole) ?
                        isNaN(data.planId) || data.planId >= requiredPlan ? <Component {...props} /> : <NeedPlanUpgradePage roles={data.roles}/> : <NoPermissionsPage/>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />
                ))
            }}
        />
    );
};

const AuthRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                let {from} = props.location.state || {from: {pathname: "/"}};
                return ( // TODO: not sure if local storage or store has to be used in isAuthenticated
                    isAuthenticated() ? (
                        <Redirect
                            to={from}
                        />
                    ) : (
                        <Component {...props} />
                    ))
            }}
        />
    );
};
// TODO: Divide Auth and Private routes in different variables and make a structure for the possible params to pass
const routes = (
    <Switch>
        <PrivateRoute exact path="/" component={DashboardPage}/>

        <PrivateRoute exact path="/profile" component={ProfilePage}/>
        {/*<PrivateRoute exact path="/survey/:id" component={SurveyPage}/>*/}

        {/* Surveys */}
        <PrivateRoute exact path="/survey/results" component={SurveyResultsPage}/>
        <PrivateRoute exact path="/survey/results/:id/:templateID" component={SurveyPage}/>
        <PrivateRoute exact path="/survey/templates/create" component={CreateSurveyTemplatePage}/>
        <PrivateRoute exact path="/survey/templates/edit/:id" component={EditSurveyTemplatePage}/>
        <PrivateRoute exact path="/survey/templates" component={SurveyTemplatesPage}/>
        <PrivateRoute exact path="/survey/templates/:id" component={ViewSurveyTemplatePage}/>
        <PrivateRoute exact path="/survey/assignment/:id/:measurement?" component={SurveyAssignment}/>
        <PrivateRoute exact path="/survey/report/aggregate" component={SurveyAggregateReportPage}/>
        <PrivateRoute exact path="/report/basic/:id" component={BasicReportPage}/>
        {/* Scheduling */}
        <PrivateRoute exact path="/scheduling" component={SchedulingPage}/>
        {/* Measurements */}
        <PrivateRoute exact path="/measurement/tools" component={MeasurementSurveyTemplatesPage}/>
        <PrivateRoute exact path="/measurement/tools/:id" component={ViewMeasurementSurveyPage}/>
        <PrivateRoute exact path="/measurement/results" component={MeasurementSurveyResultsPage}/>
        <PrivateRoute exact path="/measurement/results/:id/:templateID" component={MeasurementSurveyPage}/>
        <PrivateRoute exact path="/measurement/report/:sessionId/:templateId" component={MeasurementReportPage}/>
        <PrivateRoute exact path="/measurement/report/comparison" component={MeasurementComparisonReportPage}/>
        {/* Communications */}
        <PrivateRoute exact path="/messenger" component={MessengerPage}/>
        <PrivateRoute exact path="/messenger/:activeChannelSid" component={MessengerPage}/>
        <PrivateRoute exact path="/messenger-broadcast" component={BroadcastMessagePage}/>
        <PrivateRoute exact path="/meetings" component={MeetingsPage} requiredPlan={PROFESSIONAL_PLAN}/>
        <PrivateRoute exact path="/meetings/create" component={CreateMeetingPage} requiredPlan={PROFESSIONAL_PLAN}/>
        <PrivateRoute exact path="/work-schedule" component={WorkSchedulePage} requiredPlan={PROFESSIONAL_PLAN}/>
        {/* Clients */}
        <PrivateRoute exact path="/clients" component={ClientsPage}/>
        <PrivateRoute exact path="/clients/create" component={NewClientPage}/>
        <PrivateRoute exact path="/clients/invite" component={InviteClientPage}/>
        <PrivateRoute exact path="/clients/import" requiredRole={MANAGER} component={ImportClientsPage}/>
        <PrivateRoute  path="/clients/:id/:session_id" component={ClientPage}/>
        <PrivateRoute exact path="/assign-clients" requiredRole={MANAGER} component={CaseloadsPage}/>
        {/* Campaigns */}
        <PrivateRoute exact path="/campaigns" component={CampaignsPage}/>
        <PrivateRoute exact path="/campaigns/view/:id" component={CampaignPage}/>
        <PrivateRoute exact path="/campaigns/clone/:id" component={CloneCampaignPage}/>
        <PrivateRoute exact path="/campaigns/create" component={NewCampaignPage}/>
        {/* Audiences */}
        <PrivateRoute exact path="/audiences/create" component={NewAudiencePage}/>
        <PrivateRoute exact path="/audiences" component={AudiencesPage}/>
        <PrivateRoute exact path="/audiences/:id" component={ViewAudiencePage}/>
        <PrivateRoute exact path="/audiences/edit/:id" component={EditAudiencePage}/>
        <PrivateRoute exact path="/tags" component={TagsPage}/>
        <PrivateRoute exact path="/tags/create" component={CreateTagPage}/>
        {/*** Settings ***/}
        {/* Staff */}
        <PrivateRoute exact path="/staff" component={StaffPage} requiredRole={MANAGER}/>
        <PrivateRoute exact path="/staff/create" component={CreateStaffPage} requiredRole={MANAGER}/>
        <PrivateRoute exact path="/staff/invite" requiredRole={MANAGER} component={InviteStaffPage}/>
        <PrivateRoute exact path="/staff/:id" component={EditStaffPage} requiredRole={MANAGER}/>
        {/* Billing */}
        <PrivateRoute exact path="/billing/payment-method/create" component={CreatePaymentMethod} requiredRole={COMPANY_OWNER}/>
        <PrivateRoute exact path="/billing" component={BillingPage} requiredRole={COMPANY_OWNER}/>
        <PrivateRoute exact path="/billing-codes" component={BillingCodesPage} />
        <PrivateRoute exact path="/billing-codes/create" component={CreateBillingCodePage} />
        {/* Company Details */}
        <PrivateRoute exact path="/company-details" component={CompanyDetailsPage} requiredRole={COMPANY_OWNER}/>
        <PrivateRoute exact path="/document-template-library" component={DocumentTemplatesPage} />
        <PrivateRoute exact path="/document-builder/:type/:id?" component={DocumentBuilderPage} />
        {/* Authentication */}
        <AuthRoute path="/login" component={LoginPage}/>
        <AuthRoute path="/authenticate-device/:id" component={AuthenticateDevicePage}/>
        <AuthRoute path="/sign-up/:email/:token" component={SignUpPage}/>
        <AuthRoute path="/request-password-reset" component={RequestPasswordResetPage}/>
        <AuthRoute path="/password-reset/:token" component={PasswordResetPage}/>
        <AuthRoute path="/join/:plan" component={JoinPage}/>
        <AuthRoute path="/payment-site/:id/:amount" component={PaymentSite}/>
        <AuthRoute path="/payment-site/:id/:amount/success" component={PaymentSuccess}/>
        {/*TODO: Add 404 page via the following code: */}
        <PrivateRoute exact path="/error" component={GeneralErrorPage}/>
        <Route component={NoMatchPage}/>
    </Switch>
);

export default routes;
