import React from 'react';
import {Box, Paragraph, Text} from "grommet";

// const getActiveBorder = (isActive) => ({side: "bottom", size: "medium", color: isActive ? "green" : "divider"});

export const TemplateHeader = ({title, subtitle, onClick, isActive}) => (
    <Box
        flex={false}
        background="brand"
        justify="center"
        style={{cursor: "pointer"}}
        pad={{horizontal: "large", vertical: "small"}}
        // border={this.getActiveBorder(isActive)}
        onClick={onClick}
    >
        <Text size="large" color='white' weight='bold' margin={{top: "small"}}>{title}</Text>
        <Paragraph fill color="white" size="small">{subtitle}</Paragraph>
    </Box>
);
