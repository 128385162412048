import React from 'react';
import {Form} from "formik";
import {Box, Text} from "grommet";
import {Lock} from "grommet-icons";
import {TextField} from "./TextField";
import {LoadingButton} from "../../../components/LoadingButton";

export const AuthenticateDeviceForm = ({values, errors, handleChange, status, touched, isSubmitting, loading}) => (
    <Form>
        <TextField name='token'
                   icon={Lock}
                   value={values.token}
                   handleChange={handleChange}
                   placeholder='Token'
                   error={errors.token}
        />
        {status && <Box margin={{bottom: "medium"}} width='small'>
            <Text color="lightgray">{status.message}</Text>
        </Box>}

        <Box direction='row-responsive'>
            <LoadingButton type="submit" fill primary loading={loading || isSubmitting} label="Submit"/>
        </Box>
    </Form>
);
