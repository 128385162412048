import React, {useRef, useState} from 'react';
import {Box, Drop, Text} from "grommet";

export const SessionDiagnosis = ({diagnosis}) => {
    const [over, setOver] = useState(false);
    const ref = useRef();
    return (
        <React.Fragment>
            <Box
                ref={ref}
                onMouseOver={() => setOver(true)}
                onMouseOut={() => setOver(false)}
                onFocus={() => {}}
                onBlur={() => {}}
                style={{cursor: 'default'}}
                direction="column"
            >
                <Text weight={600} color="gray" truncate>{diagnosis.icd10_code} &emsp; {diagnosis.name}</Text>
            </Box>
            {diagnosis.name && ref.current && over && (
                <Drop align={{top:"bottom"}} target={ref.current} plain>
                    <Box
                        margin="xsmall"
                        pad="small"
                        background="dark-3"
                        round={{ size: "medium"}}
                    >
                        <Text color='white'>{diagnosis.name}</Text>
                    </Box>
                </Drop>
            )}

        </React.Fragment>
    );
}
