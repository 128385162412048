import React from 'react'

const NoPermissions = props => (
    <svg width="100%" height="100%" viewBox="0 0 316 352" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#E4E8EB" cx={158} cy={158} r={158}/>
            <path
                d="M249 218.2c0-48.16-39.175-87.2-87.5-87.2S74 170.04 74 218.2v116.267c0 3.854 1.536 7.55 4.271 10.276A14.609 14.609 0 0088.583 349h145.834c8.054 0 14.583-6.507 14.583-14.533V218.2z"
                stroke="#819DB6"
                strokeWidth={5}
                fill="#EEF0F4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke="#819DB6"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M132 136v213M190 136v213"
            />
            <circle
                stroke="#819DB6"
                strokeWidth={5}
                fill="#C7D2DB"
                strokeLinecap="round"
                strokeLinejoin="round"
                cx={95.5}
                cy={267.5}
                r={7.5}
            />
            <path
                stroke="#819DB6"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M234 305.5h44M234 233h44"
            />
            <path
                stroke="#819DB6"
                strokeWidth={5}
                fill="#EEF0F4"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M49 147.654v-17.308M57.5 139.25h-17M260.5 114.654V97.346M269 106.25h-17M121.5 28.808v-9.616M126 24h-9M162.5 101.808v-9.616M167 97h-9"
            />
            <path
                stroke="#C7D2DB"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M85.1 90.4l6.8-6.8M91.682 90.182l-6.364-6.364M225.1 50.4l6.8-6.8M231.682 50.182l-6.364-6.364M44.1 216.4l6.8-6.8M50.682 216.182l-6.364-6.364"
            />
            <path
                stroke="#819DB6"
                strokeWidth={5}
                fill="#EEF0F4"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M273.5 186.808v-9.616M278 182h-9"
            />
        </g>
    </svg>
)

export default NoPermissions
