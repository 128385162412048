import React,{Component} from 'react'
import {Box, Text} from "grommet";


export default class BigIconButton extends Component {

    state = {isHovered: false};

    render() {
        const {label,icon, ...rest} = this.props;
        const {isHovered} = this.state;
        const textColor = isHovered ? "brand" : "caption";

        return (<Box
            round="xsmall"
            width="100%"
            direction="column"
            align="center"
            justify="center"
            pad="17px"
            gap="small"
            border={{color: "divider"}}
            onMouseOver={() => this.setState({isHovered: true})}
            onMouseLeave={() => this.setState({isHovered: false})}
            {...rest}
        >
            {icon}
            <Text color={textColor} size="xsmall">{label}</Text>
        </Box>)
    }
}
