import React, {useRef, useState} from 'react';
import Page from "../../components/Page";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {
    COMPARISON_COLORS, MAX_CLIENTS_FOR_COMPARISON,
    PHQ9_TEMPLATE_ID,
    BAM_TEMPLATE_ID,
    STATUS_ID_COMPLETED
} from "./components/constants";
import {Legend} from "./components/Legend";
import {Box, Grid, ResponsiveContext, Text} from "grommet";
import Spinner from "../../components/Spinner";
import LineChart from "./components/LineChart";
import {result_to_severity} from "./components/PHQ9";
import {safeGet} from "../../utils";
import SessionResultTableCard from "./components/SessionResultTableCard";
import {ComparisonMeasurementReportHeader} from "./components/ComparisonMeasurementReportHeader";
import {ClientsSelect} from "../../components/ClientsSelect";
import {PrintPage} from "../PrintPage";

// Currently BAM reporting is not supported so it is excluded from the presented templates
const FETCH_TEMPLATES = gql`
    query($excludedTemplates: [Int!]){
        measurement_survey_template(where: {id: {_nin: $excludedTemplates}}) {
            id
            title
        }
    }
`;

const FETCH_DATA = gql`
    query ($session_ids: [Int!]! , $status_id: Int!, $template_id: Int!) {
        template: measurement_survey_template_by_pk(id: $template_id) {
            id
            metadata
            title
        }
        results: measurement_survey_result(where: {session_id: {_in: $session_ids}, status_id: {_eq: $status_id}, template_id: {_eq: $template_id}}, order_by: {updated_at: asc}) {
            session_id
            id
            score
            updated_at
        }
    }
`;


function prepareLabels(sessions) {
    let labels = {};
    sessions.forEach(session => {
        labels[session.id] = session.client.user.first_name
    });
    return labels;
}

function prepareColors(sessions) {
    let colors = {};
    sessions.forEach((session, index) => {
        colors[session.id] = COMPARISON_COLORS[index]
    });
    return colors;
}

const MeasurementComparisonReportPage = () => {
    const printRef = useRef();
    const [selectedSessions, setSelectedSessions] = useState([]);
    const colors = prepareColors(selectedSessions);

    const [template, setTemplate] = React.useState({id: 0, title: ''});
    // Exclude the bam template from the templates list as it is note supported yet
    const {data: templatesData, loading: loadingTemplates} = useQuery(FETCH_TEMPLATES, {variables: {excludedTemplates: [BAM_TEMPLATE_ID]}});
    const templates = safeGet(() => templatesData.measurement_survey_template);

    const variables = {
        session_ids: selectedSessions.map(session => session.id),
        template_id: safeGet(() => template.id),
        status_id: STATUS_ID_COMPLETED
    };
    const {data, loading} = useQuery(FETCH_DATA, {variables: variables, skip: !template.id, fetchPolicy: 'no-cache'}); // TODO: Handle Error

    const renderSessionDataTable = (session, index) => {
        const sessionResults = data.results.filter((result) => result.session_id === session.id);
        const latestResult = sessionResults.pop();
        const latestResultOn = safeGet(() => latestResult.updated_at);
        const severity = template.id === PHQ9_TEMPLATE_ID && latestResultOn ?
            result_to_severity(latestResult.score, data.template.metadata.legend) : null;
        return (
            <SessionResultTableCard key={index} template_id={template.id}
                                    session={session} color={colors[session.id]}
                                    latestResultOn={latestResultOn} severity={severity}/>
        )
    };
    const ReportResults = () => {
        return (
            <Box gap="medium">
                {data.results.length > 0 &&
                <Box gap="medium">
                    <Text weight="bold">Comparison Results</Text>
                    <LineChart
                        data={data.results}
                        colors={colors}
                        width='100%'
                        height='350'
                        categoricalYAxis={template.id === PHQ9_TEMPLATE_ID}
                        comparison
                        minTick={data.template.metadata.domain.min}
                        maxTick={data.template.metadata.domain.max}
                        stepSize={data.template.metadata.domain.step_size}
                        legend
                        labels={prepareLabels(selectedSessions)}
                    />
                    <ResponsiveContext.Consumer>
                        {(deviceSize) => (
                            <Grid
                                columns={deviceSize === 'small' ? 'fill' : ["flex", "flex"]}
                                gap="medium">
                                {selectedSessions.map((session, index) => renderSessionDataTable(session, index))}
                            </Grid>
                        )}
                    </ResponsiveContext.Consumer>
                </Box>
                }
                {template.id === PHQ9_TEMPLATE_ID &&
                <Box gap="medium">
                    <Text weight="bold">PHQ-9 Management Summary</Text>
                    <Legend data={data.template.metadata.legend}/>
                </Box>
                }
            </Box>
        )
    };
    return (
        <Page title="Comparison Report">
            <Box gap="medium">
                <Box pad={{bottom: 'medium'}} gap="medium" flex="grow" justify='evenly'>
                    <ClientsSelect collapsible
                                   onChange={setSelectedSessions}
                                   maxNumOfClients={MAX_CLIENTS_FOR_COMPARISON}
                    />
                    {loadingTemplates ?
                        <Spinner full/> :
                        <Box gap="medium">
                            <ComparisonMeasurementReportHeader printRef={printRef}
                                                               template={template}
                                                               setTemplate={setTemplate}
                                                               templates={templates}/>
                            {template.id ?
                                loading ?
                                    <Spinner full/> :
                                    <Box>
                                        <ReportResults/>
                                        <Box style={{ overflow: 'hidden', height: '0'}}>
                                            <PrintPage ref={printRef}>
                                                <ReportResults/>
                                            </PrintPage>
                                        </Box>
                                    </Box>
                                :
                                null
                            }
                        </Box>
                    }
                </Box>
            </Box>
        </Page>
    );
};

export default (MeasurementComparisonReportPage);
