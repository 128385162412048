import React from 'react';
import {TextInputField} from "./TextInputField";
import {TextAreaInputField} from "./TextAreaInputField";
import {DropdownInputField} from "./DropdownInputField";
import {CheckboxInputField} from "./CheckboxInputField";
import {HeadingElement} from "./HeadingElement";
import {ParagraphElement} from "./ParagraphElement";
import {DividerElement} from "./DividerElement";
import {DateInputField} from "./DateInputField";
import {TimeInputField} from "./TimeInputField";
import {PhoneNumberInputField} from "./PhoneNumberInputField";
import {RichTextElement} from "./RichTextElement";
import {FastField} from "formik";

export const FieldTypes = {
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA',
    CHECKBOX: 'CHECKBOX',
    DROPDOWN: 'DROPDOWN',
    RADIO_BUTTON: 'RADIO_BUTTON',
    DATE: 'DATE',
    PHONE_NUMBER: 'PHONE_NUMBER',
    EMAIL: 'EMAIL',
    TIME: 'TIME',
    HEADING: 'HEADING',
    PARAGRAPH: 'PARAGRAPH',
    DIVIDER: 'DIVIDER',
    RICH_TEXT: 'RICH_TEXT'
};

export const getField = (type) => {
    switch (type) {
        case FieldTypes.TEXT:
            return TextInputField;
        case FieldTypes.TEXTAREA:
            return TextAreaInputField;
        case FieldTypes.DROPDOWN:
            return DropdownInputField;
        case FieldTypes.CHECKBOX:
            return CheckboxInputField;
        case FieldTypes.DATE:
            return DateInputField;
        case FieldTypes.TIME:
            return TimeInputField;
        case FieldTypes.PHONE_NUMBER:
            return PhoneNumberInputField;
        case FieldTypes.HEADING:
            return HeadingElement;
        case FieldTypes.PARAGRAPH:
            return ParagraphElement;
        case FieldTypes.DIVIDER:
            return DividerElement;
        case FieldTypes.RICH_TEXT:
            return RichTextElement;
        default:
            return React.Fragment;
    }
}

export const Field = ({id, type, ...props}) => {
    const Component = getField(type);
    if (id)
        return (
            <FastField name={id}>
                {({field, form, meta}) => (
                    <Component
                        {...props}
                        id={id}
                        value={field.value || ''}
                        error={meta.error || null}
                        handleChange={field.onChange || (() => {})}
                        setFieldValue={form.setFieldValue || (() => {})}
                    />
                )}
            </FastField>

        )
    return (
        <Component {...props}
                   value=''
                   error={null}
                   handleChange={(() => {})}
                   setFieldValue={(() => {})}
        />
    );

}