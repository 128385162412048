import * as React from "react"
import {colors} from "../../theme";

const Circle30 = ({active = true, ...rest}) => {
    return (
        <svg width={47} height={47} viewBox="0 0 47 47" {...rest}>
            <title>{"5C0D6B56-2E43-410C-A722-434FE2CF7E99"}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M44.805 28.413a22.236 22.236 0 01-2.116 5.545M38.715 39.137a23.679 23.679 0 01-4.78 3.525M25.433 1.59c1.98.175 3.927.621 5.785 1.327M36.912 6.103a21.042 21.042 0 014.149 4.24M44.14 16.101a20.445 20.445 0 011.195 5.81M27.881 44.864c-11.856 2.42-23.428-5.23-25.848-17.086C-.387 15.922 7.263 4.349 19.12 1.93"
                    stroke= {active ? colors.brand : colors.sidebarInactive}
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.432 31.976A5.981 5.981 0 0022.408 26a5.868 5.868 0 00-1.752-4.2 5.45 5.45 0 00-1.68-1.176L22 17.504c.096-.096.168-.216.24-.336.096-.192.168-.408.168-.648 0-.744-.6-1.344-1.368-1.344h-7.08c-.744 0-1.344.6-1.344 1.344 0 .768.6 1.368 1.344 1.368h3.888L15.4 20.384c-.504.552-.504 1.392.048 1.92 0 .024 0 .024.024.024.24.288.6.456.984.432.84.024 1.656.312 2.28.96.648.624.96 1.44.96 2.28 0 .84-.312 1.656-.96 2.304a3.29 3.29 0 01-2.304.936c-.84 0-1.656-.312-2.28-.936a1.362 1.362 0 00-1.92 0 1.362 1.362 0 000 1.92 5.968 5.968 0 004.2 1.752zM31.153 32c1.848 0 3.432-1.152 4.44-2.664 1.032-1.536 1.608-3.552 1.608-5.736 0-2.208-.576-4.2-1.608-5.736-1.008-1.536-2.592-2.664-4.44-2.664-1.896 0-3.456 1.128-4.464 2.664-1.056 1.536-1.608 3.528-1.632 5.736.024 2.184.576 4.2 1.632 5.736C27.697 30.848 29.257 32 31.153 32zm0-2.664c-.744 0-1.56-.456-2.28-1.488-.672-1.008-1.152-2.544-1.152-4.248 0-1.728.48-3.24 1.152-4.272.72-1.008 1.536-1.488 2.28-1.464.744-.024 1.536.456 2.232 1.464.672 1.032 1.152 2.544 1.152 4.272 0 1.704-.48 3.24-1.152 4.248-.696 1.032-1.488 1.488-2.232 1.488z"
                    fill={active ? colors.green : colors.sidebarInactive}
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
};

export default Circle30