import React from 'react';
import {Box, Keyboard, Text, TextInput} from "grommet";
import {FormClose} from "grommet-icons";

const Tag = ({children, onRemove, ...rest}) => {
    const tag = (
        <Box
            direction="row"
            align="center"
            background="dirtywhite"
            pad='5px'
            margin={{vertical: 'xxsmall', horizontal: 'xsmall'}}
            round="xxsmall"
            {...rest}
        >
            <Text size="14px">
                {children}
            </Text>
            {onRemove && <FormClose style={{cursor: 'pointer'}}
                                    size="small" color="gray"
                                    onClick={onRemove}/>}
        </Box>
    );

    return tag;
};

const TagInput = ({value = [], onAdd, onChange, onRemove, ...rest}) => {
    const [currentTag, setCurrentTag] = React.useState('');
    const [box, setBox] = React.useState();
    const boxRef = React.useCallback(setBox, []);

    const updateCurrentTag = event => {
        setCurrentTag(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    const onAddTag = tag => {
        if (onAdd) {
            onAdd(tag);
        }
    };

    const onEnter = () => {
        if (currentTag.length) {
            onAddTag(currentTag);
            setCurrentTag('');
        }
    };

    const renderValue = () =>
        value.map((v, index) => (
            <Tag
                key={`${v}${index + 0}`}
                onRemove={() => onRemove(v)}
            >
                {v}
            </Tag>
        ));

    return (
        <Keyboard onEnter={onEnter}>
            <Box
                background='white'
                pad='small'
                direction="row"
                align="center"
                border="all"
                ref={boxRef}
                wrap
            >
                {value.length > 0 && renderValue()}
                <Box flex style={{minWidth: '120px'}}>
                    <TextInput
                        type="search"
                        plain
                        dropTarget={box}
                        {...rest}
                        onChange={updateCurrentTag}
                        value={currentTag}
                        onBlur={onEnter}
                        onSuggestionSelect={event => onAddTag(event.suggestion)}
                    />
                </Box>
            </Box>
        </Keyboard>
    );
};

export default TagInput
