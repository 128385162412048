import React from 'react';
import {Box, Text} from "grommet";
import Padlock from "../components/icons/Padlock";
import Page from "../components/Page";
import RoutedButton from "../components/RoutedButton";
import {COMPANY_OWNER} from "../lib/constants";


const NeedPlanUpgradePage = ({roles}) => (
    <Page>
        <Box elevation='xsmall' background='white' round="xsmall" pad='large' fill justify='center' align='center'
             gap='medium'>
            <Padlock/>
            <Box gap='small' align='center'>
                <Text weight='bold'>You don't have access to this section</Text>
                <Text color='gray'>{roles.includes(COMPANY_OWNER) ?
                    'Please upgrade your company plan to access this section.' :
                    'Please contact the company owner to upgrade your plan to Professional.'}
                </Text>
            </Box>
            <RoutedButton
                primary
                path={roles.includes(COMPANY_OWNER) ? '/billing' : '/'}
                label={roles.includes(COMPANY_OWNER) ? 'Go to Billing' : 'Go Back'}
            />
        </Box>
    </Page>
);

export default NeedPlanUpgradePage;