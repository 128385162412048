import React from 'react';
import {Box, DataTable, Heading, Text} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {CreateEmployerModal} from "./CreateEmployerModal";
import {DeleteButton} from "../../../../components/DeleteButton";
import {useClientSession} from "../../hooks/useClientSession";

const FETCH_DATA = gql`
    query fetchClientEmployers($id: Int) {
        client_employer(where: {client_id: {_eq: $id}}) {
            id
            company_name
            job_title {
                id
                name
            }
            email
            phone_number
            city
            state
        } 
    }
`;

const DELETE_MUTATION = gql`
    mutation($id:Int!) {
        delete_client_employer(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

export const EmployerInformation = ({clientId}) => {
    const {data, loading} = useQuery(FETCH_DATA, {variables: {id: clientId}})
    const {active} = useClientSession();
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Employer Information</Heading>
            <DataTable
                primaryKey='id'
                columns={[
                    {
                        property: 'company_name',
                        header: <Text>Business Name</Text>,
                        // primary: true,
                    },
                    {
                        property: 'job_title.name',
                        header: <Text>Job Title</Text>,
                    },
                    {
                        property: 'phone_number',
                        header: <Text>Phone</Text>,
                    },
                    {
                        property: 'city',
                        header: <Text>Address</Text>,
                        render: ({city, state}) => <Text>{city}, {state}</Text>
                    },
                    active ? {
                        property: 'actions',
                        render: ({id}) =>
                            <DeleteButton
                                query={DELETE_MUTATION}
                                text='Are you sure you want to delete this employer?'
                                variables={{id}}
                                queriesToRefetch={['fetchClientEmployers']}
                            />
                    } : undefined,
                ].filter(column => !!column)}
                placeholder={loading ? 'Loading...' : undefined}
                data={data?.client_employer || []}
                border={{ body: 'bottom' }}
                pad={{ horizontal: 'large', vertical: 'small' }}
            />
            {active && <CreateEmployerModal clientId={clientId} refetchQueries={['fetchClientEmployers']}/>}
        </Box>
    )
};