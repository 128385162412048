import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    FormField,
    Text,
    TextArea,
} from "grommet";

const MultiLineText = ({id, title, placeholder, value, error, handleChange, disabled, ...rest}) => (
    <Box gap="small" {...rest}>
        <Text color="lightgray">{title}</Text>
        <FormField error={error} className="surveyFormInput">
            <TextArea
                name={id}
                placeholder={placeholder}
                type='text'
                size='mediumLarge'
                disabled={disabled}
                value={value || ''}
                onChange={handleChange}
                fill={true}
            />
        </FormField>
    </Box>
);

MultiLineText.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
};

export default MultiLineText;
