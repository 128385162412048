import React from 'react';
import {Box} from "grommet";
import {Form} from "formik";
import {QuestionPreviewWrapper} from "./QuestionPreviewWrapper";


export const TemplatePreviewWrapper = ({activeQuestionID, onActive, onRemove, onClone,
                                        onUp, onDown, questions, values, initialValues,
                                        errors, touched, status, disableInput, ...rest}) => (
    <Form>
        <Box>
            {questions.map((question, index) => {
                return (
                    <Box key={question.id}>
                        <QuestionPreviewWrapper
                            question={question}
                            value={values[question.id]}
                            error={errors[question.id]}
                            touched={touched[question.id]}
                            disabled={disableInput}
                            showUp={index !== 0}
                            showDown={index !== questions.length - 1}
                            isActive={question.id === activeQuestionID}
                            onActive={() => onActive(question.id)}
                            onRemove={() => onRemove(question.id)}
                            onClone={() => onClone(question.id)}
                            onUp={() => onUp(question.id)}
                            onDown={() => onDown(question.id)}
                            {...rest}
                        />
                    </Box>)
            })}
        </Box>
    </Form>
)
