import React from 'react';
import {Box, List, Stack, Text} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {useMutation} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {useHistory, useParams} from "react-router-dom";
import {TX_PLAN_STATUS} from "../../../lib/constants";

const FETCH_TREATMENT_PLANS = gql`
    query ($session_id: Int!) {
        id @client
        plans: tx_plan(where: {_and: [{session_id: {_eq: $session_id}}, {status_id: {_eq: ${TX_PLAN_STATUS.COMPLETED}}}, {_not: {tx_plan_reviews: {}}}]}) {
            id
            name
            problems {
                id
                name
            }
        }
    }
`;

const CREATE_REVIEW = gql`
    mutation ($data: tx_plan_review_insert_input!) {
        insert_tx_plan_review_one(object: $data) {
            id
        }
    }
`;

export const CreateTreatmentReview = ({closeModal}) => {
    const {session_id, id} = useParams();
    const history = useHistory();
    // TODO: Update cache here so that we don't need to refetch the query
    const [createReview, {loading}] = useMutation(CREATE_REVIEW, {refetchQueries: ['fetchTreatmentPlans']});
    const {data, loading: fetchLoading} = useQuery(FETCH_TREATMENT_PLANS, {
        variables: {session_id},
        fetchPolicy: 'no-cache',
    })
    return (
        <Stack>
            <Box overflow='auto' height={{max: 'medium'}}>
                {data?.plans?.length > 0 ? <List
                    primaryKey="name"
                    data={data.plans}
                    onClickItem={async ({item}) => {
                        await createReview({
                            variables: {
                                data: {
                                    tx_plan_id: item.id,
                                    problems: item.problems.map(problem => ({name: problem.name, description: ''})),
                                    tx_plan_review_signatures: {
                                        data: [
                                            {user_id: data.id},
                                            {user_id: id}
                                        ]
                                    }
                                }
                            }
                        });
                        history.push(`/clients/${id}/${session_id}/treatment-plans`);
                        closeModal();
                    }}
                /> : fetchLoading ? <Spinner/> :
                    <Text margin={{left: 'small', bottom: 'small'}}>No completed treatment plans found.</Text>}

            </Box>
            {loading && <Box round='xsmall' fill background={{color: 'white', opacity: 'strong'}}><Spinner fill/></Box>}
        </Stack>
    )
}