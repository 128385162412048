import gql from "graphql-tag";

export const CAMPAIGN_BY_ID_FRAGMENT = gql`
fragment campaign_fields on query_root {
    campaign: campaign_by_pk(id: $campaign_id) {
        id
        name
        is_enabled
        campaign_audiences {
            audience {
                id
                name
            }
        }
        campaign_actions(order_by: {action_id: asc}) {
            action_details {
                id
                type: action_type {
                    id
                    name
                }
                time: time_delay_minutes
                metadata
            }
            campaign_action_events {
                event_id
                event {
                    id
                    type: event_type{
                        id
                        name
                    }
                    field_change_trigger: field_change_trigger_details {
                        json_value
                        trigger_operand {
                            name
                            id
                            options_table
                            type
                            table
                        }
                        operator {
                            id
                            name
                            expression
                        }
                    }
                }
            }
        }
    }
}    
`;

export const ADDITIONAL_CAMPAIGN_FRAGMENT = gql`fragment additional_fields on query_root {
    survey_template {
        id
        title
    }
    measurement_survey_template{
        id
        title
    }
    audience {
        id
        name
    }
    action_type {
        id
        name
    }
    event_type {
        type_id: id
        name
    }
    trigger_operand {
        id
        options_table
        name
    }
    operator {
        id
        name
    }
}`;

