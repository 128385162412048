import React, {useEffect, useState} from 'react';
import {FormField, Spinner} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {EditorState, convertToRaw} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

const emptyRawContent = convertToRaw(EditorState.createEmpty().getCurrentContent());

export const RichTextElement = ({text}) => {
    return (
        <div dangerouslySetInnerHTML={{__html: draftToHtml(text ?? emptyRawContent)}}/>
    )
};

RichTextElement.defaultSize = {
    w: 2,
    h: 2,
    minW: 1,
    minH: 1,
};

const RichTextSchema = Yup.object().shape({
    text: Yup.string()
        .min(1, 'Too Short!')
        .required('This field is required'),
});

export const RichTextFieldProperties = ({text = emptyRawContent, updateField, id, item}) => {
    const [rerender, setRerender] = useState(false);
    useEffect(() => {
        // Ugly hack. Implementing Editor as controlled component using ContentState is broken,
        // therefore we have to rerender the component when we've selected another cell of the same type.
        setRerender(true);
        setTimeout(() => setRerender(false), 100);
    }, [id]);

    return (
    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => {
            updateField(id,values);
        }}
        initialValues={{text}}
        validationSchema={RichTextSchema}
        enableReinitialize
        onSubmit={() => {
        }}
    >
        {({errors, values, handleChange, setFieldValue}) => (
            <Form>
                {rerender ? <Spinner/> : <FormField error={errors.text}>
                    <Editor
                        editorClassName='wysiwyg-editor'
                        toolbarClassName='wysiwyg-toolbar'
                        defaultContentState={values.text}
                        onContentStateChange={(data) => setFieldValue('text', data)}
                    />
                </FormField>}
            </Form>
        )
        }
    </Formik>
)}