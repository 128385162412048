import React from 'react';
import {theme} from "./theme";
import {Grommet} from "grommet";
import AuthorizedLayout from "./layout/AuthorizedLayout";
import {UnauthorizedLayout} from "./layout/UnauthorizedLayout";
import {Router} from "react-router";

import gql from "graphql-tag";
import {useQuery} from '@apollo/react-hooks'
import Spinner from "./components/Spinner";
import browserHistory from "./browserHistory";

const IS_LOGGED_IN_QUERY = gql`
query {
    isAuthenticated @client
    id @client
    name @client
    email @client
    chatToken @client
    role @client
    roles @client
    companyID @client
}
`;

const App = () => {
    const {data: user, loading} = useQuery(IS_LOGGED_IN_QUERY, {fetchPolicy: 'cache-first'});
    return (
        <Router history={browserHistory}>
            <Grommet theme={theme} full>
                {loading ? <Spinner fill/> : user.isAuthenticated ? <AuthorizedLayout user={user}/> :
                    <UnauthorizedLayout/>}
            </Grommet>
        </Router>
    );
};

export default App
