import React, {createContext, useContext, useCallback} from 'react';

import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed';
import useRoom from './useRoom';
import {VideoApp} from "./VideoApp";
import useLocalTracks from "./useLocalTracks";

export const VideoContext = createContext();

export const connectionOptions = {
    bandwidthProfile: {
        video: {
            mode: 'collaboration',
            dominantSpeakerPriority: 'standard',
            renderDimensions: {
                high: { height: 1080, width: 1920 },
                standard: { height: 720, width: 1280 },
                low: { height: 90, width: 160 },
            },
        },
    },
    dominantSpeaker: true,
    maxAudioBitrate: 12000,
    networkQuality: { local: 1, remote: 1 },
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

export function VideoProvider({ meeting, onError, onDisconnect}) {
    const onErrorCallback = useCallback((error) => {
        console.log(`ERROR: ${error.message}`, error);
        onError(error);
    }, [onError]);

    const { localTracks, getLocalVideoTrack } = useLocalTracks();
    const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, connectionOptions, meeting, onDisconnect);

    const stopLocalTracks = useCallback(() => {
        localTracks.forEach(track => {
            track.stop();
        });
    }, [localTracks]);
    // Register onError and onDisconnect callback functions.
    useHandleRoomDisconnectionErrors(room, onError);
    useHandleTrackPublicationFailed(room, onError);
    useHandleOnDisconnect(room, stopLocalTracks);

    return (
        <VideoContext.Provider
            value={{
                room,
                localTracks,
                isConnecting,
                onError: onErrorCallback,
                onDisconnect,
                getLocalVideoTrack,
                connect,
            }}
        >
            <VideoApp/>
        </VideoContext.Provider>
    );
}

export function useVideoContext() {
    const context = useContext(VideoContext);
    if (!context) {
        throw new Error('useVideoContext must be used within a VideoProvider');
    }
    return context;
}
