import React from 'react';
import {Box, Button, Text} from "grommet";
import Page from "../../components/Page";
import {Link, withRouter} from "react-router-dom";
import {AudienceFilterForm, emptyAudienceFilter} from "./components/AudienceFilterForm";
import {Formik, Form} from "formik";
import gql from 'graphql-tag';
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {LoadingButton} from "../../components/LoadingButton";
import {useFetchAudienceFilterOptions} from "../../lib/useFetchAudienceFilterOptions";
import Spinner from "../../components/Spinner";
import {
    AudienceValidationSchema,
    buildWhereClauseAudiences,
    getFiltersWithOptions,
    getVisibleAudiencesOperands,
    SESSIONS
} from "./components/AudienceFunctions";
import {ParticipantsList} from "../Surveys/components/ParticipantsList";

const FETCH_FILTERS = gql`
    query {
        operator {
            id
            name
            expression
        }
        audience_operand {
            id
            name
            options_table
            relation
            column
            hidden
            type
        }
    }
`;

const CREATE_AUDIENCE = gql`
    mutation ($object: [audience_insert_input!]!){
        insert_audience(objects: $object) {
            returning {
                id
                name
            }
        }
    }
`;

const NewAudiencePage = ({history}) => {
    const generateFiltersPayload = (filters) =>
        filters.map(({operand, operator, filterValue}) => ({
            operand_id: operand.id,
            operator_id: operator.id,
            filter_value: typeof filterValue === "string" ? filterValue : filterValue.id.toString()
        }));

    const [createAudience, {loading: createLoading, error: createError}] = useMutation(CREATE_AUDIENCE);
    const [fetchOptions, {options, loading: loadingOptions, error: errorOptions}] = useFetchAudienceFilterOptions();
    const {data, loading} = useQuery(FETCH_FILTERS, {
        onCompleted: ({audience_operand}) =>
            fetchOptions(getFiltersWithOptions(audience_operand))
    });


    const [getSessions, {data: sessions = [], loading: loadingSessions, error: errorSessions}] = useLazyQuery(SESSIONS);


    if (loading || loadingOptions) {
        return <Spinner full/>;
    }

    if (errorOptions) {
        return 'Error';
    }

    const refetchParticipants = (filters) => {
        getSessions({variables: {where: buildWhereClauseAudiences({filters})}});
    }

    return (
        <Page title="Create New Audience">
            <Formik
                initialValues={{
                    name: '',
                    audienceFilters: [emptyAudienceFilter],
                }}
                validationSchema={AudienceValidationSchema}
                onSubmit={async (values, actions) => {
                    const filters = generateFiltersPayload(values.audienceFilters);
                    const object = {filters: {data: filters}, name: values.name};
                    await createAudience({variables: {object}});
                    if (createError) {
                        actions.setStatus('The audience could not be created.');
                        return;
                    }
                    history.push('/audiences');
                }}
            >
                {({errors, values, handleChange, setFieldValue,validateForm}) => (
                    <Form>
                        <Box background='white' round="xsmall" elevation="xsmall" pad="medium">
                            <AudienceFilterForm
                                audienceOperands={getVisibleAudiencesOperands(data.audience_operand)}
                                options={options}
                                operators={data.operator}
                                errors={errors}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                onFiltersChange={
                                    (filters) => refetchParticipants(filters)
                                }
                                validateForm={validateForm}
                            />
                        </Box>
                        <Box margin={{top: "small"}} gap="medium">
                            {
                                loadingSessions ?
                                    <Spinner full/> :
                                    errorSessions ? <Text>Can't load clients</Text> :
                                        <ParticipantsList boxProps={{flex: false}}
                                                          sessions={sessions.session || []}/>
                            }
                        </Box>
                        <Box margin={{top: "small"}} direction="row" gap="small">
                            <LoadingButton primary label="Create" type="submit" loading={createLoading}/>
                            <Link to='/audiences'><Button primary label='Cancel' color='lightgray'/></Link>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Page>
    );
};

export default withRouter(NewAudiencePage);
