import EventEmitter from 'events';
import {useCallback, useEffect, useRef, useState} from 'react';
import Video from 'twilio-video';
import API from "../../services/api";

const createMeetingRoom = (id) => API.get('site/create-meeting-room', {params: {id}});

export default function useRoom(localTracks, onError, options, meeting, onDisconnect) {
    const [room, setRoom] = useState(new EventEmitter());
    const [isConnecting, setIsConnecting] = useState(false);
    const disconnectHandlerRef = useRef(() => {
    });
    const localTracksRef = useRef([]);

    useEffect(() => {
        // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
        // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
        // once the user is connected to the room.
        localTracksRef.current = localTracks;
    }, [localTracks]);

    const connect = useCallback(
        () => {
            setIsConnecting(true);
            return createMeetingRoom(meeting).then(({data: {token, name}}) => Video.connect(token, {
                ...options,
                name,
                tracks: []
            }).then(
                newRoom => {
                    setRoom(newRoom);

                    newRoom.once('disconnected', () => {
                        // Reset the room only after all other `disconnected` listeners have been called.
                        setTimeout(() => {
                            setRoom(new EventEmitter());
                            onDisconnect();
                        });
                        window.removeEventListener('beforeunload', disconnectHandlerRef.current);
                    });

                    // @ts-ignore
                    window.twilioRoom = newRoom;

                    localTracksRef.current.forEach(track =>
                        // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
                        // However, tracks must be published manually in order to set the priority on them.
                        // All video tracks are published with 'low' priority. This works because the video
                        // track that is displayed in the 'MainParticipant' component will have it's priority
                        // set to 'high' via track.setPriority()
                        newRoom.localParticipant.publishTrack(track, {priority: track.kind === 'video' ? 'low' : 'standard'})
                    );

                    disconnectHandlerRef.current = () => newRoom.disconnect();
                    setIsConnecting(false);

                    // Add a listener to disconnect from the room when a user closes their browser
                    window.addEventListener('beforeunload', disconnectHandlerRef.current);
                },
                error => {
                    setIsConnecting(false);
                    localTracksRef.current.forEach(track => track.stop());
                    onError(error);
                }
            ), error => {
                setIsConnecting(false);
                localTracksRef.current.forEach(track => track.stop());
                onError(error);
            })
        },
        [options, onError, meeting, onDisconnect]
    );

    useEffect(() => {
       connect();
    }, [connect]);

    return {room, isConnecting, connect};
}
