import * as React from "react"

function HangUp(props) {
    return (
        <svg width='1em' height='1em' viewBox="0 0 24 24" {...props}>
            <path
                d="M23.78.22a.75.75 0 01.073.976l-.073.084-22.5 22.5a.75.75 0 01-1.133-.976l.073-.084L22.72.22a.75.75 0 011.06 0zm-6.222 12.804c.623 0 1.222.223 1.692.623l.151.14 3.827 3.826a2.61 2.61 0 01-.265 3.937c-4.5 3.31-9.472 2.721-14.424-1.068a.75.75 0 11.912-1.192c1.21.926 2.404 1.638 3.58 2.127l.249.101-.105-.152a4.466 4.466 0 01.414-5.444l.158-.166 1.969-1.968a2.606 2.606 0 011.842-.764zm0 1.5c-.251 0-.494.086-.688.241l-.094.083-1.968 1.968a2.964 2.964 0 00-.134 4.047l.133.143 1.17 1.169.26.02c1.926.12 3.793-.423 5.583-1.67l.243-.175a1.11 1.11 0 00.198-1.57l-.091-.104-3.83-3.828a1.106 1.106 0 00-.782-.324zM6.248.641l.137.129 3.83 3.829a2.606 2.606 0 01.14 3.534l-.14.151-1.97 1.97a4.462 4.462 0 01-5.582.589l-.181-.125.099.248c.45 1.085 1.09 2.185 1.913 3.3l.21.279a.75.75 0 11-1.192.91C-.272 10.503-.858 5.537 2.458 1.03a2.608 2.608 0 013.79-.388zm-1.65.86h-.136c-.32.021-.616.18-.803.426-1.375 1.87-1.976 3.819-1.853 5.834l.019.261 1.17 1.17a2.962 2.962 0 001.882.86l.213.008c.714 0 1.402-.258 1.938-.722l.157-.145 1.969-1.97a1.106 1.106 0 00.083-1.47l-.083-.094-3.832-3.831a1.108 1.108 0 00-.723-.327z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </svg>
    )
}

export default HangUp
