import React from 'react'

const Info = ({active, ...rest}) => (
    <svg width="25px" height="25px" viewBox="0 0 25 25" {...rest}>
        <g id="Asset-Artboard-Page">
            <g id="icon-_x2F_-medium-copy-icon-_x2F_-medium-_x2F_-info" fill='#FAC200'>
                <path id="_xE9AE_-copy" className="st0" d="M12,22c-1.3,0-2.5-0.2-3.6-0.7c-1.1-0.5-2.1-1.2-2.9-2c-0.8-0.8-1.5-1.8-2-2.9
			C3,15.2,2.8,14,2.8,12.8S3,10.3,3.5,9.2c0.5-1.1,1.2-2.1,2-2.9c0.8-0.8,1.8-1.5,2.9-2c1.1-0.5,2.3-0.7,3.6-0.7s2.5,0.2,3.6,0.7
			c1.1,0.5,2.1,1.2,2.9,2c0.8,0.8,1.5,1.8,2,2.9c0.5,1.1,0.7,2.3,0.7,3.6s-0.2,2.5-0.7,3.6c-0.5,1.1-1.2,2.1-2,2.9
			c-0.8,0.8-1.8,1.5-2.9,2C14.4,21.7,13.3,22,12,22z M12,20.1c1,0,2-0.2,2.9-0.6c0.9-0.4,1.7-0.9,2.4-1.6c0.7-0.7,1.2-1.4,1.6-2.4
			c0.4-0.9,0.6-1.8,0.6-2.9s-0.2-2-0.6-2.9c-0.4-0.9-0.9-1.7-1.6-2.4c-0.7-0.7-1.4-1.2-2.4-1.6C14,5.6,13,5.4,12,5.4
			c-1,0-2,0.2-2.9,0.6C8.2,6.3,7.4,6.9,6.8,7.5C6.1,8.2,5.6,9,5.2,9.9c-0.4,0.9-0.6,1.8-0.6,2.9s0.2,2,0.6,2.9
			c0.4,0.9,0.9,1.7,1.6,2.4c0.7,0.7,1.4,1.2,2.4,1.6C10,19.9,11,20.1,12,20.1z M12,9.2c-0.3,0-0.6-0.1-0.8-0.3
			c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8C11.4,7,11.7,6.8,12,6.8c0.3,0,0.6,0.1,0.8,0.3s0.3,0.5,0.3,0.8
			c0,0.3-0.1,0.6-0.3,0.8C12.6,9.1,12.3,9.2,12,9.2z M12,18.6c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6v-6.1
			c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6v6.1c0,0.2-0.1,0.5-0.3,0.6
			C12.4,18.5,12.2,18.6,12,18.6z"/>
            </g>
        </g>
    </svg>
);

export default Info
