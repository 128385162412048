import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Stack, Text} from "grommet";
import ParticipantTracks from "./ParticipantTracks";
import HangUp from "../icons/HangUp";
import ToggleAudioButton from "./controls/ToggleAudioButton";
import {useVideoContext} from "./VideoProvider";
import Phone from "../icons/Phone";
import {LoadingDots} from "../LoadingDots";

const DisconnectConfirmOverlay = ({children, closeOverlay, leaveRoom, stayInRoom, closeDisabled}) => (
    <Stack>
        {children}
        {closeOverlay && <Box background={{color: 'white', opacity: 'strong'}} justify='center' align='center' fill>
            <Text weight='bold' color='darkgray'>Are you sure you want to close this meeting?</Text>
            <Text color='gray' margin={{top: 'small'}}>The meeting will be open for 5 more minutes after you close it.</Text>
            <Box direction='row' gap='medium' justify='center' margin={{top: 'medium'}}>
                <Button primary label='Close Meeting' color='lightred' onClick={leaveRoom} disabled={closeDisabled}/>
                <Button primary label='Stay in Meeting' color='brand' onClick={stayInRoom}/>
            </Box>
        </Box>}
    </Stack>
);

export const InCall = ({disconnectConfirmation, setDisconnectConfirmation, minimised}) => {
    const {room, isConnecting} = useVideoContext();
    const [participant, setParticipant] = useState(undefined);

    const leaveRoom = useCallback(() => room.disconnect(), [room]);
    useEffect(() => {
        const removeParticipant = () => setParticipant(undefined);
        room.on('participantDisconnected', removeParticipant);
        return () => {
            room.off('participantDisconnected', removeParticipant);
        };
    }, [room]);

    useEffect(() => {
        const _setParticipant = (participant) => setParticipant(participant);
        room.on('participantConnected', _setParticipant);
        return () => {
            room.off('participantConnected', _setParticipant);
        };
    }, [room]);

    useEffect(() => {
        room.participants && room.participants.size > 0 && setParticipant(Array.from(room.participants.values())[0]);
        return () => {
            setParticipant(undefined);
        }
    }, [room]);

    return (
        <Box>
            <DisconnectConfirmOverlay closeDisabled={!room.disconnect}
                                      closeOverlay={disconnectConfirmation}
                                      leaveRoom={leaveRoom}
                                      stayInRoom={() => setDisconnectConfirmation(false)}
            >{
                participant ? <ParticipantTracks minimised={minimised} participant={participant}/> :
                    <Box height={minimised ? 'small' : 'medium'} justify='center' align='center' gap='small'>
                        <Phone width={40} height={40} active/>
                        <Box direction="row">
                            <Text weight='bold'>{isConnecting ? 'Connecting' : 'Waiting for other participants'}</Text>
                            <LoadingDots/>
                        </Box>
                    </Box>
            }</DisconnectConfirmOverlay>
            <Box
                as="footer"
                gap="small"
                direction="row"
                background='white'
                align="center"
                border={{side: 'top'}}
                justify="start"
                pad='medium'
            >
                <ToggleAudioButton/>
                <Button icon={<HangUp height={25} width={25}/>} onClick={() => setDisconnectConfirmation(true)} primary
                        color="status-critical" disabled={!room.disconnect}/>
            </Box>
        </Box>
    );
};