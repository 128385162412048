import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Anchor, Box, Text} from "grommet";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {Layout} from "./Layout";
import {AuthenticateDeviceForm} from "./components/AuthenticateDeviceForm";
import Spinner from "../../components/Spinner";

const ValidationSchema = Yup.object().shape({
    token: Yup.string().required('Required'),
});

export const MUTATION = gql`
    mutation ($token: String! $id: String!){
        authenticateDevice(token: $token id: $id) @client
    }
`;

export const RESEND_MUTATION = gql`
    mutation ($id: String!){
        resendDeviceToken(id: $id) @client
    }
`;

const AuthenticateDevicePage = ({match}) => {
    const id = match.params.id;
    const [localLogin, {loading}] = useMutation(MUTATION);
    const [resendToken, {loading: resendLoading}] = useMutation(RESEND_MUTATION);
    return (
        <Layout header='Two Factor Authentication'>
            <Box align="center">
                <Box
                    background="white"
                    round="small"
                    border
                    elevation='xsmall'
                    pad="34px"
                >
                    <Formik
                        initialValues={{
                            token: "",
                        }}
                        validationSchema={ValidationSchema}
                        onSubmit={async ({token}, actions) => {
                            try {
                                await localLogin({variables: {token, id}});
                            } catch (e) {
                                console.log('er', e);
                                // Apollo transforms the error to ApolloError
                                // but it has been parsed by Axios before this
                                SagaErrorHandler.handle(e.networkError, actions);
                            }
                        }}
                        render={props => <AuthenticateDeviceForm {...props} loading={loading}/>}
                    />
                </Box>

                <Text margin={{top: 'medium'}} color="lightgray">You didn't receive a message with the token?
                    <Anchor
                        label=' Resend the message.'
                        onClick={() => resendToken({variables: {id}})}
                    />
                    {resendLoading && <Spinner/>}
                </Text>
            </Box>
        </Layout>
    );
};

export default AuthenticateDevicePage;
