import {CheckboxSelected, Down, Edit, RadialSelected, List} from "grommet-icons";
import SingleLineText from "./questions/SingleLineText";
import MultiLineText from "./questions/MultiLineText";
import CheckboxList from "./questions/CheckboxList";
import RadioButtonList from "./questions/RadioButtonList";
import Dropdown from "./questions/Dropdown";
import React from "react";
import SingleLineTextReport from "./report/questions/SingleLineTextReport";
import MultiLineTextReport from "./report/questions/MultiLineTextReport";
import CheckboxListReport from "./report/questions/CheckboxListReport";
import DropdownReport from "./report/questions/DropdownReport";
import RadioButtonListReport from "./report/questions/RadioButtonListReport";

export const newId = () => {
    /**
     * @return {string}
     */
    let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};

export const QuestionTypes = {
    SINGLE_LINE_TEXT: 'SINGLE_LINE_TEXT',
    MULTI_LINE_TEXT: 'MULTI_LINE_TEXT',
    CHECKBOXES: 'CHECKBOXES',
    DROPDOWN: 'DROPDOWN',
    RADIO_BUTTONS: 'RADIO_BUTTONS'
};

export const questionIcons = {
    [QuestionTypes.SINGLE_LINE_TEXT]: Edit,
    [QuestionTypes.MULTI_LINE_TEXT]: List,
    [QuestionTypes.CHECKBOXES]: CheckboxSelected,
    [QuestionTypes.DROPDOWN]: Down,
    [QuestionTypes.RADIO_BUTTONS]: RadialSelected
};

export const reportQuestionComponentMap = {
    [QuestionTypes.SINGLE_LINE_TEXT]: (props) => {
        return <SingleLineTextReport {...props}/>
    },
    [QuestionTypes.MULTI_LINE_TEXT]: (props) => {
        return <MultiLineTextReport {...props}/>
    },
    [QuestionTypes.CHECKBOXES]: (props) => {
        return <CheckboxListReport {...props}/>
    },
    [QuestionTypes.DROPDOWN]: (props) => {
        return <DropdownReport {...props}/>
    },
    [QuestionTypes.RADIO_BUTTONS]: (props) => {
        return <RadioButtonListReport {...props}/>
    },
};

export const questionComponentMap = {
    [QuestionTypes.SINGLE_LINE_TEXT]: (props) => {
        return <SingleLineText {...props}/>
    },
    [QuestionTypes.MULTI_LINE_TEXT]: (props) => {
        return <MultiLineText {...props}/>
    },
    [QuestionTypes.CHECKBOXES]: (props) => {
        return <CheckboxList {...props}/>
    },
    [QuestionTypes.RADIO_BUTTONS]: (props) => {
        return <RadioButtonList {...props}/>
    },
    [QuestionTypes.DROPDOWN]: (props) => {
        return <Dropdown {...props}/>
    }
};

export const QuestionDescriptions = [{
    text: 'Single Line Text',
    type: QuestionTypes.SINGLE_LINE_TEXT
}, {
    text: 'Multiple Line Text',
    type: QuestionTypes.MULTI_LINE_TEXT
}, {
    text: 'Checkboxes',
    type: QuestionTypes.CHECKBOXES
}, {
    text: 'Dropdown',
    type: QuestionTypes.DROPDOWN
}, {
    text: 'Radio Buttons',
    type: QuestionTypes.RADIO_BUTTONS
}];

export const InitQuestions = {
    [QuestionTypes.CHECKBOXES]: () => ({
        "id": newId(),
        "type": QuestionTypes.CHECKBOXES,
        "title": "Check All That Apply",
        "options": [
            {
                "id": newId(),
                "content": "First choice"
            },
            {
                "id": newId(),
                "content": "Second choice"
            },
            {
                "id": newId(),
                "content": "Third choice"
            }
        ]
    }),
    [QuestionTypes.RADIO_BUTTONS]: () => ({
        "id": newId(),
        "type": QuestionTypes.RADIO_BUTTONS,
        "title": "Select a choice",
        "options": [
            {
                "id": newId(),
                "content": "First choice"
            },
            {
                "id": newId(),
                "content": "Second choice"
            },
            {
                "id": newId(),
                "content": "Third choice"
            }
        ]
    }),
    [QuestionTypes.SINGLE_LINE_TEXT]: () => ({
        "id": newId(),
        type: QuestionTypes.SINGLE_LINE_TEXT,
        title: 'Untitled',
        placeholder: ''
    }),
    [QuestionTypes.MULTI_LINE_TEXT]: () => ({
        "id": newId(),
        type: QuestionTypes.MULTI_LINE_TEXT,
        title: 'Untitled',
        placeholder: ''
    }),
    [QuestionTypes.DROPDOWN]: () => ({
        "id": newId(),
        "type": QuestionTypes.DROPDOWN,
        "title": "Select a choice",
        "options": [
            {
                "id": newId(),
                "content": "First choice"
            },
            {
                "id": newId(),
                "content": "Second choice"
            },
            {
                "id": newId(),
                "content": "Third choice"
            }
        ]
    }),
    // 'abc': () => ({
    //     "id": newId(),
    //     type: 'MULTI_CHOICE',
    //     title: 'Evaluate the following statements',
    //     questions: [
    //         {
    //             id: newId(),
    //             content: 'First Question'
    //         },
    //         {
    //             id: newId(),
    //             content: 'Second Question'
    //         },
    //         {
    //             id: newId(),
    //             content: 'Third Question'
    //         }],
    //     options: [
    //         {
    //             "id": newId(),
    //             "content": "First choice"
    //         },
    //         {
    //             "id": newId(),
    //             "content": "Second choice"
    //         },
    //         {
    //             "id": newId(),
    //             "content": "Third choice"
    //         }
    //     ]
    // })
};

