import React from "react";

import {Menu, Text} from "grommet";
import Avatar from "./Avatar";

const UserMenu = ({user = {}, items = [], ...rest}) => {
    return (
    <Menu
        dropAlign={{top: "bottom", right: "right"}}
        icon={false}
        items={items.map(item => ({
            ...item,
            label: <Text size="small">{item.label}</Text>,
        }))}
        label={<Avatar/>}
        {...rest}
    >
    </Menu>
)};

export default UserMenu;
