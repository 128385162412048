import React from 'react'
import {colors} from "../../theme";

const Pen = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M15.357 4.303l4.91 4.656a1.25 1.25 0 01.047 1.767l-.023.024L8.606 22.435a.795.795 0 01-.153.12 2.993 2.993 0 00-.119.075.589.589 0 01-.209.065L1.299 24.45a.457.457 0 01-.205.05H1a1.034 1.034 0 01-.716-.284.946.946 0 01-.254-.46 1.06 1.06 0 01.001-.505l1.75-6.83c0-.049.01-.097.03-.142l.003-.006a.175.175 0 00-.01.055l.024-.057v.008l.005-.02.044-.106a.212.212 0 00-.03.044l.008-.028c.018-.037.04-.078.067-.122a.795.795 0 01.12-.153l2.273-2.274 1.567-1.565C6.96 10.978 8.106 9.836 9.32 8.628c1.078-1.074 2.508-2.507 4.29-4.3a1.25 1.25 0 011.747-.025zM3.29 18.548l-.912 3.572 3.572-.931-2.66-2.641zm11.468-12.53L4.167 16.608l3.723 3.703L18.482 9.742l-3.724-3.724z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <path
                d="M22.575 2.002a4.663 4.663 0 00-1.396-.915 4.318 4.318 0 00-3.253-.036c-.507.201-.963.492-1.362.869a1 1 0 00-.02 1.435l4.719 4.703a1 1 0 001.443-.033 4.258 4.258 0 001.144-2.996 4.214 4.214 0 00-1.275-3.027zm-3.713.84c.211-.061.432-.092.666-.092.3 0 .587.059.871.18.223.094.429.215.618.366a.84.84 0 00.33.324c.334.41.497.875.503 1.43.002.243-.026.47-.085.685l-2.903-2.893z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
        </g>
    </svg>
)

export default Pen
