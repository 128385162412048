import { extractFiles } from 'extract-files'
import gql from "graphql-tag";
import {useApolloClient} from "@apollo/react-hooks";
import {useCallback, useState} from "react";
import API from "./api";
import {print} from "graphql";
import {InvariantError} from "ts-invariant";

export const MAX_FILE_SIZE = 4096000;
export const SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif'];

export const UPLOAD_AVATAR = gql`
    mutation ($file: Upload! $id: Int) {
        upload_avatar(file: $file id: $id) {
            id
            url
        }
    }
`;

export const UPLOAD_BRAND = gql`
    mutation ($file: Upload!) {
        upload_brand(file: $file ) {
            url
        }
    }
`;

export const FETCH_AVATAR = gql`
    query ($id: Int){
        avatar(id: $id) {
            id
            url
        }
    }
`;

export const FETCH_BRAND = gql`
    query {
        brand {
            url
        }
    }
`;

const url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_IMAGE_API_URL : process.env.REACT_APP_PROD_IMAGE_API_URL;

export function graphqlFetchOptions(operation) {
    const fetchOptions = {
        url,
        method: 'POST',
        headers: { Accept: 'application/json' }
    }

    const { clone, files } = extractFiles(operation)
    const operationJSON = JSON.stringify(clone)

    if (files.size) {
        // See the GraphQL multipart request spec:
        // https://github.com/jaydenseric/graphql-multipart-request-spec

        const form = new FormData()

        form.append('operations', operationJSON)

        const map = {}
        let i = 0
        files.forEach(paths => {
            map[++i] = paths
        })
        form.append('map', JSON.stringify(map))

        i = 0
        files.forEach((paths, file) => {
            form.append(`${++i}`, file, file.name)
        })

        fetchOptions.data = form
    } else {
        fetchOptions.headers['Content-Type'] = 'application/json'
        fetchOptions.data = operationJSON
    }

    return fetchOptions
}

export const useImageUpload = () => {
    const {cache} = useApolloClient();
    const [error, setError] = useState(undefined);
    const uploadAvatar = useCallback(async(file, userId) => {
        setError(undefined);
        const id = parseInt(userId);
        const response = await API.request(graphqlFetchOptions({
            query: print(UPLOAD_AVATAR),
            variables: {file, id},
        }));
        if (response.data.errors) {
            setError(response.data.errors[0]);
            return
        }
        try {
            const data = cache.readQuery({query: FETCH_AVATAR, variables: {id}});
            cache.writeQuery({query: FETCH_AVATAR, variables: {id}, data: {
                    avatar: {
                        ...data.avatar,
                        url: response.data.data.upload_avatar.url
                    }
                }})
        } catch (e) {
            if (e instanceof InvariantError) {
                return; // We don't have it in the cache yet. That's fine
            }
            throw e;
        }
    }, [cache]);

    const uploadBrand = useCallback(async(file) => {
        setError(undefined);
        const response = await API.request(graphqlFetchOptions({
            query: print(UPLOAD_BRAND),
            variables: {file},
        }));
        if (response.data.errors) {
            throw response.data.errors[0];
        }
        try {
            const data = cache.readQuery({query: FETCH_BRAND});
            cache.writeQuery({query: FETCH_BRAND, data: {
                    brand: {
                        ...data.brand,
                        url: response.data.data.upload_brand.url
                    }
                }})
        } catch (e) {
            if (e instanceof InvariantError) {
                return; // We don't have it in the cache yet. That's fine
            }
            throw e;
        }
    }, [cache]);

    return {uploadAvatar, uploadBrand, error};
};