import FormValidationError from "./FormValidationError";

export default class SagaErrorHandler {
    static handle(error, formActions) {
        const {setErrors, setSubmitting, setStatus} = formActions;
        // If our API throws an error we will leverage Formik's existing error system to pass it along
        // to the view layer, as well as clearing the loading indicator.
        if (error instanceof FormValidationError) {
            setErrors(Object.assign({}, ...error.validationErrors));
        } else {
            setStatus(error);
        }
        setSubmitting(false);
    }
}
