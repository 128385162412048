import React from 'react';
import {Box, Text} from "grommet";
import moment from "moment";
import {NA} from "../../../lib/constants";

export const InformationRibbon = ({document}) => {
    return (
      <Box border round='xsmall' direction='row-responsive'>
          <Box pad='medium'>
              <Text weight="bold">{document.provider.company.name}</Text>
              <Text color='gray'>Address: {document.provider.company.address}</Text>
              <Text color='gray'>{document.provider.company.state}</Text>
          </Box>
          <Box pad='medium'>
              <Text weight="bold">Contacts</Text>
              <Text color='gray'>Clinician: {document.provider.user.first_name} {document.provider.user.last_name}</Text>
              {document.provider.phone_number && <Text color='gray'>Phone: {document.provider.phone_number}</Text>}
          </Box>
          <Box pad='medium' border={{side: 'left'}} direction="row-responsive" gap="medium">
              <Box>
                  <Text weight="bold">{document.session.client.user.first_name} {document.session.client.user.last_name} ({document.session.mr_number || NA})</Text>
                  <Box direction='row' gap='small'>
                      <Box>
                          <Text color='gray'>SSN: {document.session.client.ssn || NA}</Text>
                          <Text color='gray'>DOB: {document.session.client.user.date_of_birth ? moment(document.session.client.user.date_of_birth).format('MMM D, YYYY') + ` (${moment().diff(moment(document.session.client.user.date_of_birth), 'years')} yo)` : NA}</Text>
                          {document.session.client.phone_number && <Text color='gray'>Phone: {document.session.client.phone_number}</Text>}
                      </Box>
                      <Box>
                          <Text color='gray'>Address: {document.session.client.address || NA}</Text>
                          <Text color='gray'>{document.session.client.state}</Text>
                      </Box>
                  </Box>
                  <Text margin={{top: `small`}} color="gray">Diagnoses: {document.session.session_diagnoses.map(({diagnosis}) => diagnosis.icd10_code).join(', ') || NA}</Text>
              </Box>
              {document.meeting && <Box>
                  <Text weight="bold">Appointment {document.meeting.name}</Text>
                  <Text color="gray">Date: {moment(document.meeting.start_at).format('MMM D YYYY')}</Text>
                  <Text color="gray">Started: {moment(document.meeting.start_at).format('h:mm a')}</Text>
                  <Text color="gray">Ended: {moment(document.meeting.end_at).format('h:mm a')}</Text>
                  <Text color="gray">Type: {document.meeting.meeting_type.name}</Text>
              </Box>}
          </Box>
      </Box>
    );
}