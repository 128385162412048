import React from 'react';
import {Button, Text} from "grommet";
import {withRouter} from "react-router-dom";
import {useVideoMeetingContext} from "../../../components/video/VideoMeetingProvider";

const getColor = (status) => {
    switch (status) {
        case 'Start now':
            return 'green';
        case 'Failed':
            return 'status-critical';
        default:
            return 'gray';
    }
};

export const MeetingStatusButton = withRouter(({id, status, startAt, ...rest}) => {
    const {joinMeeting} = useVideoMeetingContext();
    return (
        <Button alignSelf='start'
                primary
                label={<Text color="white" weight="bold">{status}</Text>}
                color={getColor(status)}
                disabled={status !== 'Start now'}
                onClick={() => joinMeeting(id)}
                {...rest}
        />
    )
});