import React from 'react';
import {Box, FormField, Select, Text} from "grommet";
import {SubscriptionCancelled} from "./SubscriptionCancelled";
import moment from "moment";

export const AccountSettings = ({subscription, plans, setFieldValue, values, status, subscriptionActive}) => {
    return (
        <Box background='white' round="xsmall" elevation="xsmall" pad='medium' gap='medium'>
            <Text>Account Settings</Text>
            <Box direction='row' gap='large'>
                <Box gap='small'>
                    <FormField label={<Text color='lightgray'>Plan</Text>}>
                        <Select
                            disabled
                            options={plans}
                            value={plans.find(plan => plan.id === values.plan_id)}
                            labelKey='name'
                            valueKey='id'
                            onChange={({option}) => setFieldValue('plan_id', option.id)}
                        >
                        </Select>
                    </FormField>
                </Box>
                <Box gap='small'>
                    <Text color='lightgray'>Your next invoice will be</Text>
                    <Box pad={{vertical: 'small'}}>
                        {subscriptionActive ?
                            <Text size='16px'>$ {plans.find(plan => plan.id === values.plan_id).price}.00 on {moment(subscription.NextScheduledRunTime).format('L')}</Text> :
                            <SubscriptionCancelled/>
                        }
                    </Box>
                </Box>
            </Box>
            {status && <Text color={status.type === 'failure' ? 'red' : 'green'}>{status.message}</Text>}
        </Box>
    );
};