import {Box, Button, Select, Text} from "grommet";
import React, {useEffect, useState} from "react";
import {Next, Previous} from "grommet-icons";

export const PageButton = ({page, primary, ...rest}) => (
    <Box round='xsmall'
         border={{color: 'lightborder'}}
         pad={{horizontal: 'small', vertical: 'xsmall'}}
         background={primary ? 'white' : null} {...rest}
    >
        <Text color='gray'>{page}</Text>
    </Box>
);

export const Pagination = ({pageSize, pageIndex, pageCount, gotoPage, setPageSize, boxProps}) => {
    const [visiblePages, setVisiblePages] = useState([]);

    const filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    };
    // Taken from https://codesandbox.io/s/012ywx6mp0
    useEffect(() => {
        const page = pageIndex + 1;
        const total = pageCount;
        if (total < 7) {
            setVisiblePages(filterPages([1, 2, 3, 4, 5, 6], total));
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                setVisiblePages([1, page - 1, page, page + 1, total]);
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                setVisiblePages([1, total - 3, total - 2, total - 1, total]);
            } else {
                setVisiblePages([1, 2, 3, 4, 5, total]);
            }
        }
    }, [pageCount, pageIndex]);

    return (
        <Box justify='between' direction='row' {...boxProps}>
            <Box direction='row' gap='small'>
                <Text color='gray' alignSelf='center'>Items per page</Text>
                    <Select options={['5', '10', '20', '50', '100']} value={pageSize.toString()}
                    onChange={({option}) => setPageSize(Number(option))} style={{height: 20, width: 60}}/>
            </Box>
            <Box direction='row' gap='xsmall' align='center'>
                {pageIndex > 0 && <Button icon={<Previous size='small' color='brand'/>} plain onClick={() => gotoPage(pageIndex - 1)}/>}
                {visiblePages.map((page, index, array) =>
                    <PageButton
                        key={page}
                        page={array[index - 1] + 2 < page ? `... ${page}` : page}
                        onClick={() => gotoPage(page - 1)}
                        primary={pageIndex === page - 1}
                    />
                )}
                {pageIndex < pageCount - 1 && <Button icon={<Next size='small' color='brand'/>} plain onClick={() => gotoPage(pageIndex + 1)}/>}
            </Box>
        </Box>
    )
};
