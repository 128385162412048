import React, {Component} from 'react';
import {Box, Text, Button, FormField, TextInput, CheckBox} from "grommet";
import {FormClose, PlayFill, StopFill} from "grommet-icons";
import {AddCircle, FormEdit} from "grommet-icons";
import {Diagram, Stack} from "grommet";
import {FieldArray} from "formik";
import ConfigurationModal from "./ConfigurationModal";
import MultiSelect from "../../../components/input/MultiSelect";
import {safeGet} from "../../../utils";
import {ACTION_TYPE} from "../constants/enums";
import {Modal} from "../../../components/Modal";
import RoutedButton from "../../../components/RoutedButton";

export const emptyEvent = {
    type_id: '',
    name: '',
    fieldName: '',
    operator: '',
    value: ''
};

export const emptyAction = {
    type: {
        id: '',
        name: ''
    },
    subject: '',
    body: '',
    time: 0,
    unit: 'days',
    events: emptyEvent,
    message: '',
    sms: false,
    chat: false,
    survey: {
        id: '',
        title: ''
    }
};


export default class CampaignForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openModalOfIdx: undefined,
            openModalOnDelete: false
        }
    }

    displayAddNewActionButton(arrayHelpers, index, hideTheButton) {

        return (
            <Box margin='medium' background='divider' round='50%' height={hideTheButton ? '0px' : '20px'}
                 align='center'>
                {hideTheButton ? "" :
                    <Button focusIndicator={false} width='20px' height='20px' align='center' background='white'
                            onClick={() => arrayHelpers.insert(index, {...emptyAction})}>
                        <AddCircle size='20px' color='sidebarInactive'/>
                    </Button>
                }
            </Box>
        )

    }

    getActionSummary(action) {
        switch (action.type.id) {
            case ACTION_TYPE.SEND_EMAIL:
                return action.body;
            case ACTION_TYPE.SEND_SURVEY:
            case ACTION_TYPE.SEND_MEASUREMENT_SURVEY:
                return action.survey.title;
            case ACTION_TYPE.SEND_MESSAGE:
                return action.message;
            default:
                return 'Add action'
        }
    }


    render() {
        const {
            errors, values, handleChange, setFieldValue, setFieldError, surveyOptions, measurementSurveyOptions,
            audienceOptions, eventTypeOptions, actionTypeOptions, eventsChangeOfFieldOperands,
            eventsChangeOfFieldOperators, onDelete, viewMode = false, campaignId, preselectedAudiences = []
        } = this.props;


        const getMessage = (actionIndex) => {
            let message = 'Wait';
            const waitAdditional = values.actions.slice(0, actionIndex).some(action => action.time > 0);
            if (waitAdditional) {
                message = 'Wait additional ';
            }
            return message;
        };

        return (
            <div>
                <Box gap="medium">
                    <Modal
                        visible={this.state.openModalOnDelete}
                        onClose={() => this.setState({openModalOnDelete: false})}
                        text='Are you sure you want to delete this campaign?'
                        acceptLabel='Delete'
                        accept={() => {
                            onDelete(values.id);
                        }}
                    />
                    <Box background='white' round='xxsmall' pad='medium' elevation='xsmall' justify='start'
                         gap='medium'>
                        <Box direction='row'>
                            <Text>Campaign Name and Status</Text>
                        </Box>
                        <Box direction='row' gap='medium'>
                            <Box direction='column' width='48%'>
                                <FormField label="Name" error={errors.name}>
                                    <TextInput name="name" value={values.name || ""} onChange={handleChange}/>
                                </FormField>
                            </Box>
                            <Box direction='column' pad={{top: 'large'}}>
                                <CheckBox name='is_enabled' label="Enabled" checked={values.is_enabled}
                                          onChange={(event) => setFieldValue('is_enabled', event.target.checked)}/>
                            </Box>
                        </Box>
                        <Box flex="grow">
                            <FormField error={errors.audiences}>
                                <MultiSelect
                                    options={audienceOptions}
                                    preselectedOptions={preselectedAudiences}
                                    handleChange={(selected) => setFieldValue('audiences', [...selected])}
                                    placeholder='Select Campaign Audiences'
                                    searchPlaceholder="Search for Audience"
                                    emptySearchMessage="No audience found"
                                    disabled={viewMode}
                                />
                            </FormField>
                        </Box>
                    </Box>

                    <Box background='divider' round='xsmall' align='center' pad='large'>
                        <Stack guidingChild={1}>
                            <Diagram
                                connections={[
                                    {
                                        fromTarget: 'start',
                                        toTarget: 'end',
                                        thickness: 'xxsmall',
                                        color: 'sidebarInactive',
                                    },
                                ]}
                            />
                            <Box align='center' direction='column' flex>
                                <Box id='start' background='green' pad='small' width='75px' align='center'
                                     round='xsmall'
                                     gap='xsmall'>
                                    <Box round='50%' border={{color: 'white', size: 'small'}} pad='xsmall'
                                         align='center'>
                                        <PlayFill size='8px' color='white'/>
                                    </Box>
                                    <Text color='white' weight='bold'>Start</Text>
                                </Box>

                                <FieldArray name="actions" validateOnChange={false}>
                                    {(arrayHelpers) => (
                                        <Box>
                                            {this.displayAddNewActionButton(arrayHelpers, 0, viewMode)}
                                            {
                                                values.actions.map((action, index) => {
                                                    const {type, time, unit, events} = action;
                                                    let actionBorderColor = safeGet(() => errors.actions[index]) ? 'red' : 'white';
                                                    let timeDelay = time > 0 ? `${getMessage(index)} ${time} ${unit}` : 'Immediately';
                                                    let summary = this.getActionSummary(action);

                                                    return (
                                                        <Box key={index}>
                                                            <Box align='center'>
                                                                <Box background='white' border={{
                                                                    "color": actionBorderColor,
                                                                    "size": "xsmall",
                                                                    "style": "solid",
                                                                    "side": "all"
                                                                }} width='300px' flex
                                                                     round='xsmall' gap='xsmall'>
                                                                    {events.length > 0 &&
                                                                    <Box round={{"size": "xsmall", "corner": "top"}}
                                                                         direction='row'
                                                                         pad='15px' background='white' border='bottom'>
                                                                        {events.map(({name}, eventIndex) => (
                                                                            <Text color='darkgray' weight='bold'
                                                                                  key={eventIndex}
                                                                                  style={{'lineHeight': '12px'}}>{name}</Text>
                                                                        ))}
                                                                    </Box>
                                                                    }
                                                                    <Box direction='row'
                                                                         pad={{'horizontal': '15px', 'top': '15px'}}
                                                                         justify='between'>
                                                                        <Box direction='row' justify='start'>
                                                                            <Text color='darkgray' weight='bold'
                                                                                  style={{'lineHeight': '12px'}}>{type.name || 'Action'}</Text>
                                                                            <Text color='darkgray' size='22px' style={{
                                                                                'lineHeight': '0',
                                                                                'padding': '0 7px 0 7px'
                                                                            }}> . </Text>
                                                                            <Text color='darkgray'
                                                                                  style={{'lineHeight': '12px'}}>{timeDelay}</Text>
                                                                        </Box>
                                                                        <Box direction='row'>
                                                                            <Button focusIndicator={false}
                                                                                    onClick={() => this.setState({openModalOfIdx: index})}>
                                                                                <FormEdit size='16px'
                                                                                          color='sidebarInactive'/>
                                                                            </Button>
                                                                            <Button focusIndicator={false}
                                                                                    disabled={viewMode}
                                                                                    onClick={() => arrayHelpers.remove(index)}>
                                                                                <FormClose size='16px'
                                                                                           color='sidebarInactive'/>
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box pad={{'horizontal': '15px', 'bottom': '15px'}}>
                                                                        <Button focusIndicator={false}
                                                                                onClick={() => this.setState({openModalOfIdx: index})}>
                                                                            <Text as='div' truncate
                                                                                  color='darkgray'>{summary}</Text>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                                {this.displayAddNewActionButton(arrayHelpers, index + 1, viewMode)}
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    )}

                                </FieldArray>

                                {(this.state.openModalOfIdx !== undefined) && <ConfigurationModal
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    onClose={() => this.setState({openModalOfIdx: undefined})}
                                    actionTypeOptions={actionTypeOptions}
                                    index={this.state.openModalOfIdx}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                    surveyOptions={surveyOptions}
                                    measurementSurveyOptions={measurementSurveyOptions}
                                    eventTypeOptions={eventTypeOptions}
                                    eventsChangeOfFieldOperands={eventsChangeOfFieldOperands}
                                    eventsChangeOfFieldOperators={eventsChangeOfFieldOperators}
                                    disabled={viewMode}
                                />}

                                <Box id='end' background='statusPending' pad='small' width='75px' align='center' flex
                                     round='xsmall' gap='xsmall'>
                                    <Box round='50%' border={{color: 'white', size: 'small'}} pad='xsmall'>
                                        <StopFill size='8px' color='white'/>
                                    </Box>
                                    <Text color='white' weight='bold'>End</Text>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    <Box direction='row' gap='small' justify='center' pad={{bottom: 'small'}}>
                        <Button label='Save Campaign' primary type='submit'/>
                        {viewMode &&
                        <Button label='Delete Campaign' onClick={() => {
                            this.setState({openModalOnDelete: true})
                        }}/>
                        }
                        {viewMode &&
                        <RoutedButton path={`/campaigns/clone/${campaignId}`} label='Clone Campaign'/>
                        }
                    </Box>
                </Box>
            </div>
        );
    }
}
