import React, {useRef} from 'react';
import Page from "../../components/Page";
import gql from "graphql-tag";
import PHQ9 from "./components/PHQ9";
import BasicTemplate from "./components/BasicTemplate";
import BAM from "./components/BAM";
import {BAM_TEMPLATE_ID, PHQ9_TEMPLATE_ID, STATUS_ID_COMPLETED} from "./components/constants";
import {Box} from "grommet";
import {PrintPage} from "../PrintPage";

const FETCH_DATA = gql`
    query ($session_id: Int!, $status_id: Int!, $template_id: Int!) {
        survey_results: measurement_survey_result(where: {session_id: {_eq: $session_id}, status_id: {_eq: $status_id}, template_id: {_eq: $template_id}}, order_by: {updated_at: asc}) {
            id
            score
            suggestions
            updated_at
        }
        session: session_by_pk(id: $session_id) {
            id
            client {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            provider_session {
                twilio_channel_sid
            }
        }
        template: measurement_survey_template_by_pk(id: $template_id) {
            id
            metadata
            title
        }
    }
`;


const MeasurementReportPage = ({match}) => {
    const printRef = useRef();
    const session_id = parseInt(match.params.sessionId);
    const template_id = parseInt(match.params.templateId);

    const renderTemplate = (template_id) => {
        switch (template_id) {
            case PHQ9_TEMPLATE_ID:
                return <PHQ9 session_id={session_id}
                             queryData={FETCH_DATA}
                             printRef={printRef}
                             status_id={STATUS_ID_COMPLETED}
                />;
            case BAM_TEMPLATE_ID:
                return <BAM session_id={session_id}
                            queryData={FETCH_DATA}
                            printRef={printRef}
                            status_id={STATUS_ID_COMPLETED}
                />;
            default:
                return <BasicTemplate session_id={session_id}
                                      printRef={printRef}
                                      template_id={template_id}
                                      queryData={FETCH_DATA}
                                      status_id={STATUS_ID_COMPLETED}
                />;
        }
    };
    return (
        <Page title="Measurement Report">
            {renderTemplate(template_id)}
            <Box style={{ overflow: 'hidden', height: '0'}}>
                <PrintPage ref={printRef}>
                    {renderTemplate(template_id)}
                </PrintPage>
            </Box>
        </Page>
    );
};

export default (MeasurementReportPage);
