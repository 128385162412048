import React from 'react';
import Page from "../../components/Page";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Formik} from "formik";
import TreatmentPlanForm from "./components/TreatmentPlanForm";
import {transform} from "json-to-json-transformer";
import * as Yup from 'yup';
import {Box, Button, FormField, Select, TextInput} from "grommet";
// import TxPlanTagSelector from "./components/TxPlanTagSelector";
import Spinner from "../../components/Spinner";
import {Notification} from "../../components/Notification";
import RequiredSignaturesSelector from "./components/RequiredSignaturesSelector";
import {useParams} from "react-router-dom";


const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
})

const CREATE_TX_PLAN = gql`
    mutation insertTreatmentPlan ($tx_plan: tx_plan_insert_input!)  {
        insert_tx_plan_one(object: $tx_plan) {
            id,
            name
            problems {
                name
                order
                goals
                objectives{
                    description
                    interventions{
                        description
                    }
                }
            }
        }
    }
`

export const FETCH_OPTIONS_DATA = gql`
    query {
        interventionStatuses: tx_intervention_status(order_by: {id: asc}) {
            id
            name
        }

        measurementOptions: measurement_survey_template(order_by:{title: asc}) {
            id
            name: abbreviation
        }

        txPlanStatuses: tx_plan_status(order_by:{id: asc}) {
            id
            name
        }
    }
`

const FETCH_CLIENT_ID = gql`
    query ($session_id: Int!) {
        session: session_by_pk(id: $session_id) {
            client_id
        }
    }
`

const TODAY = new Date().toJSON().slice(0, 10).replace(/-/g, '-');

export const EMPTY_INTERVENTION = {
    "description": "",
    "created_at": TODAY,
    "order": 0,
    "target_date": undefined,
    "resolved_date": undefined,
    "status": undefined,
    "measurements": [],
}

export const EMPTY_OBJECTIVE = {
    "description": "",
    "created_at": TODAY,
    "order": 0,
    "interventions": [EMPTY_INTERVENTION]
}

export const EMPTY_PROBLEM = {
    "name": "",
    "related_to_and_evidenced_by": [],
    "goals": [],
    "created_at": TODAY,
    "order": 0,
    "objectives": [EMPTY_OBJECTIVE]
}

const EMPTY_TX_PLAN = {
    "name": "",
    "created_at": TODAY,
    "problems": [EMPTY_PROBLEM],
    "tags": [],
    "status": undefined,
    "signatures": []
}

const fullOrNull = (input) => {
    return input ? input : null;
}

const TX_PLAN_INPUT_TRANSFORMATION_TEMPLATE = {
    tx_plan: {
        name: '{{name}}',
        created_at: '{{created_at}}',
        // status_id: '{{status.id}}',
        tags: {
            data: [
                '{{tags}}',
                {
                    tx_plan_tag_id: '{{id}}'
                }
            ]
        },
        tx_signatures: {
            data: [
                "{{signatures}}",
                {
                    provider_id: '=> fullOrNull(id)',
                    order: '{{order}}',
                }
            ]
        },
        problems: {
            data: [
                "{{problems}}",
                {
                    name: '{{name}}',
                    created_at: "{{created_at}}",
                    related_to_and_evidenced_by: "{{related_to_and_evidenced_by}}",
                    goals: "{{goals}}",
                    order: "{{order}}",
                    objectives: {
                        data: [
                            "{{objectives}}",
                            {
                                description: '{{description}}',
                                created_at: '{{created_at}}',
                                order: '{{order}}',
                                interventions: {
                                    data: [
                                        '{{interventions}}',
                                        {
                                            description: '{{description}}',
                                            created_at: '{{created_at}}',
                                            order: '{{order}}',
                                            target_date: '=> fullOrNull(target_date)',
                                            resolved_date: '=> fullOrNull(resolved_date)',
                                            status_id: '=> fullOrNull(status.id)',
                                            measurements: {
                                                data: [
                                                    '{{measurements}}',
                                                    {
                                                        measurement_survey_template_id: '{{id}}'
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                },
                            }
                        ]
                    },
                }
            ]
        }
    }
}

const CreateTreatmentPlanPage = ({match, history}) => {
    const {session_id: sessionId} = useParams();
    const [createTxPlan, {loading, error: createError}] = useMutation(CREATE_TX_PLAN);
    const {data: optionsData, loading: optionDataLoading} = useQuery(FETCH_OPTIONS_DATA);
    const {data: client} = useQuery(FETCH_CLIENT_ID, {variables: {session_id: sessionId}});

    if (optionDataLoading || loading) {
        return <Spinner/>
    }

    return (
        <Page>
            <Formik
                initialValues={EMPTY_TX_PLAN}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    let txPlanData = transform(TX_PLAN_INPUT_TRANSFORMATION_TEMPLATE, values, {fullOrNull});
                    txPlanData.tx_plan.session_id = sessionId
                    await createTxPlan({variables: txPlanData})
                    history.push(`/clients/${client.session.client_id}/${sessionId}/treatment-plans`, {
                        notification: {
                            color: 'green',
                            message: `The new TX Plan '${txPlanData.tx_plan.name}' is successfully created!`
                        }
                    });
                }}
            >
                {(props) => {
                    return (
                        <Box fill pad={{right: 'medium'}} flex='grow' gap='medium'>
                            <Box direction='row' gap='medium' flex={false}>

                                <Box background='white'
                                     elevation='xsmall'
                                     fill='horizontal'
                                     round="xsmall"
                                     pad='20px 15px'
                                >

                                    <FormField label='Document Name' error={props.errors.name}>
                                        <TextInput name='name' value={props.values.name}
                                                   onChange={props.handleChange}/>
                                    </FormField>

                                    <FormField label='Status' error={props.errors.status}>
                                        {/*<TextInput name={'status'} value={props.values.status}*/}
                                        {/*           onChange={props.handleChange}/>*/}

                                        <Select
                                            valueKey="id"
                                            labelKey="name"
                                            options={optionsData.txPlanStatuses || []}
                                            value={props.values.status}
                                            onChange={({option}) => props.setFieldValue(`status`, option)}
                                        />
                                    </FormField>

                                </Box>

                                <Box background='white'
                                     elevation='xsmall'
                                     fill='horizontal'
                                     round="xsmall"
                                     pad='20px 15px'
                                     direction='column'
                                     gap='small'>
                                    <RequiredSignaturesSelector onChange={(signatures) => {
                                        props.setFieldValue('signatures', signatures)
                                    }}/>
                                </Box>
                            </Box>
                            <Box fill='horizontal' height='fit-content' pad={{bottom: 'medium'}}>

                                <TreatmentPlanForm {...props}
                                                   interventionStatusOptions={optionsData.interventionStatuses}
                                                   interventionMeasurementsOptions={optionsData.measurementOptions}
                                />


                                <Box align="start" pad={{left: "12px"}}>
                                    <Button label='Save Tx Plan' primary onClick={props.handleSubmit}/>
                                </Box>
                            </Box>
                        </Box>
                    );
                }
                }
            </Formik>

            <Notification content={"Something went wrong and we couldn't save your tx plan. "}
                          color={'lightred'}
                          isVisible={createError}
                          position='bottom'
            />
        </Page>
    )
}

export default CreateTreatmentPlanPage
