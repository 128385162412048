import React, {useRef, useEffect} from 'react';
import {Video} from "grommet";

export default function VideoTrack({ track, isLocal, priority, options = {} }) {
    const ref = useRef(null);

    useEffect(() => {
        const el = ref.current;
        el.muted = true;
        if (track.setPriority && priority) {
            track.setPriority(priority);
        }
        track.attach(el);
        return () => {
            track.detach(el);
            if (track.setPriority && priority) {
                // Passing `null` to setPriority will set the track's priority to that which it was published with.
                track.setPriority(null);
            }
        };
    }, [track, priority]);

    // The local video track is mirrored.
    const style = isLocal ? { transform: 'rotateY(180deg)', zIndex: 30 } : {objectFit: 'contain', zIndex: 0};

    return <Video fit='contain' ref={ref} style={style} controls={false} {...options} />;
}