import {Box, Text} from "grommet";
import TableIcon from "../../../components/icons/TableIcon";
import {Link} from "react-router-dom";
import React from "react";
import {BAM_TEMPLATE_ID, timeFormat} from "./constants";
import {DataTable} from "../../../components/DataTable";
import FETCH_DATA_TABLE from "./measurementReportDataTableQuery";
import {MANAGER, NA} from "../../../lib/constants";

const columns = [
    {
        accessor: 'survey_template.title',
        Header: 'Title',
        primary: true,
        Cell: ({row: {original: {survey_template: {title}, template_id, id}}}) => (
            <Box>
                <TableIcon/>
                <Link to={`/measurement/results/${id}/${template_id}`}><Text weight='bold'>{title}</Text></Link>
            </Box>

        ),
    },
    {
        accessor: 'score.useScore',
        Header: 'Use'
    },
    {
        accessor: 'score.riskFactorsScore',
        Header: 'Risk Factors'
    },
    {
        accessor: 'score.protectiveFactorsScore',
        Header: 'Protective Factors'
    },
    {
        id: "provider.id",
        Header: "Provider",
        requiredRole: MANAGER,
        accessor: ({provider}) => provider ? `${provider.user.first_name} ${provider.user.last_name}` : NA
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => new Date(created_at).toLocaleDateString("en-US", timeFormat),
        Header: 'Received On'
    },
    {
        id: 'updated_at',
        accessor: ({updated_at}) => new Date(updated_at).toLocaleDateString("en-US", timeFormat),
        Header: 'Completed On'
    },
];


const BAMDataTable = ({session_id, status_id}) => {
    return (
        <DataTable query={FETCH_DATA_TABLE} applyFilter={{
            session_id: {_eq: session_id},
            template_id: {_eq: BAM_TEMPLATE_ID},
            status_id: {_eq: status_id}
        }} columns={columns} defaultSort={{updated_at: "desc"}}/>
    );
};

export default (BAMDataTable);
