import React from 'react';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {
    AUDIENCE,
    backButton,
    buildWhereClauseAudiences,
    SESSIONS,
    AudienceValidationSchema,
    getVisibleAudiencesOperands,
    getFiltersWithOptions,
} from "./components/AudienceFunctions";
import {useFetchAudienceFilterOptions} from "../../lib/useFetchAudienceFilterOptions";
import Spinner from "../../components/Spinner";
import Page from "../../components/Page";
import gql from 'graphql-tag';
import {useMutation} from "@apollo/react-hooks";
import {Form, Formik} from "formik";
import {AudienceFilterForm} from "./components/AudienceFilterForm";
import {LoadingButton} from "../../components/LoadingButton";
import {Box, Text} from "grommet";
import {ParticipantsList} from "../Surveys/components/ParticipantsList";
import {DeleteAudienceButton} from "./components/DeleteAudienceButton";
import {withRouter} from "react-router-dom";

const UPDATE_AUDIENCE = gql`
    mutation($id: Int!, $name: String, $filters: [audience_filter_insert_input!]!){
        update_audience(where: {id: {_eq: $id}}, _set: {name: $name}) {
            affected_rows
        }
        delete_audience_filter(where: {audience_id: {_eq: $id}}) {
            affected_rows
        }
        insert_audience_filter(objects: $filters) {
            affected_rows
        }
    }
`;


// TODO: This page, ViewAudiencePage and NewAudiencePage share a lot of common logic. Extract it somewhere and reuse it.
const EditAudiencePage = ({match, history}) => {
    const id = (match.params && match.params.id) || 0;
    const [fetchOptions, {options, loading: loadingOptions, error: errorOptions}] = useFetchAudienceFilterOptions();

    const [getSessions, {data: sessions = [], loading: loadingSessions, error: errorSessions}] = useLazyQuery(SESSIONS);

    const {data, loading, error} = useQuery(AUDIENCE, {
        variables: {id}, onCompleted: ({audience_operand, audience_by_pk}) => {
            fetchOptions(getFiltersWithOptions(audience_operand));
            getSessions({variables: {where: buildWhereClauseAudiences(audience_by_pk)}});
        }
    });

    const generateFiltersPayload = (filters) =>
        filters.map(({operand, operator, filterValue}) => ({
            operand_id: operand.id,
            operator_id: operator.id,
            filter_value: typeof filterValue === "string" ? filterValue : filterValue.id.toString(),
            audience_id: id
        }));

    const [updateAudience, {loading: createLoading, error: createError}] = useMutation(UPDATE_AUDIENCE);

    if (loading || loadingOptions || !options) {
        return <Spinner full/>;
    }

    if (error || errorOptions || createError) {
        return 'Error';
    }

    const handleSubmit = async (values, actions) => {
        try {
            const name = values.name;
            const filters = generateFiltersPayload(values.audienceFilters);
            await updateAudience({variables: {id, name, filters}});
            history.push('/audiences');
        } catch (e) {
            console.log('error', e);
        }
    };

    const refetchParticipants = (filters) => {
        getSessions({variables: {where: buildWhereClauseAudiences({filters})}});
    }

    return (
        <Page title="Edit Audience" header={backButton}>
            <Box gap='medium' flex={false}>
                <Formik
                    initialValues={{
                        name: data.audience_by_pk.name,
                        audienceFilters: data.audience_by_pk.filters,
                    }}
                    validationSchema={AudienceValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({errors, values, handleChange, setFieldValue, validateForm}) => (
                        <Form>
                            <Box background='white' round="xsmall" elevation="xsmall" pad="medium">
                                <Box
                                    fill
                                    justify='between'
                                    direction="row">
                                    <Box justify='start'><Text>Audience type</Text></Box>
                                    <Box justify='end'>
                                        <DeleteAudienceButton
                                            audienceId={id}
                                            queriesToRefetch={['getAudience', 'getSessions']}
                                            onRemove={() => history.push('/audiences')}
                                        />
                                    </Box>
                                </Box>
                                <AudienceFilterForm
                                    audienceOperands={getVisibleAudiencesOperands(data.audience_operand)}
                                    operators={data.operator}
                                    options={options || {}}
                                    errors={errors}
                                    values={values}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    onFiltersChange={(filters) => refetchParticipants(filters)}
                                    validateForm={validateForm}
                                />
                            </Box>
                            <Box margin={{top: "small"}} gap="medium">
                                {
                                    loadingSessions ?
                                        <Spinner full/> :
                                        errorSessions ? <Text>Can't load clients</Text> :
                                            <ParticipantsList boxProps={{flex: false}}
                                                              sessions={sessions.session || []}/>
                                }
                            </Box>
                            <Box margin={{top: "small"}} direction="row" gap="medium">
                                <LoadingButton primary label="Update" loading={createLoading} type="submit"/>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Page>
    );
};

export default withRouter(EditAudiencePage);
