import {ResponsiveContext} from "grommet";
import {useContext} from "react";

export const useNameAbreviation = (firstName, lastName) => {
    let fullName = `${firstName} ${lastName}`;
    const size = useContext(ResponsiveContext);
    const numberCharacters = getMaximumCharacters(size);

    if (fullName.length > numberCharacters) {
        fullName = `${firstName} ${lastName[0]}.`;
    }

    return fullName;
};

function getMaximumCharacters(deviceSize) {
    switch (deviceSize) {
        case 'large':
            return 15;
        case 'medium':
            return 8;
        case 'small':
            return 8;
        default:
            return 0
    }
}