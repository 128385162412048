import React from 'react'
import PropTypes from "prop-types";
import {
    Box,
    FormField,
    Text,
    TextInput,
} from "grommet";

const SingleLineText = ({id, title, placeholder, value, error, handleChange, disabled, ...rest}) => (
    <Box gap="small" {...rest}>
        <Text color="lightgray">{title}</Text>
        <FormField error={error} className="surveyFormInput">
            <TextInput
                name={id}
                placeholder={placeholder}
                type='text'
                size='mediumLarge'
                disabled={disabled}
                value={value || ''}
                onChange={handleChange}
            />
        </FormField>
    </Box>
);

SingleLineText.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    handleChange: PropTypes.func.isRequired
};

export default SingleLineText;
