import React from 'react';
import Info from "../../../components/icons/Info";
import {Box, Text} from "grommet";

export const Suggestion = ({text, ...rest}) => (
    <Box border={{
        "color": 'yellow',
        "size": "small",
        "style": "solid",
        "side": "all"
    }}
         round='xsmall'
         pad='small'
         background='white'
         direction='row'
         gap='xsmall'
         {...rest}>
        <Info/>
        <Text color='gray' margin={{top:'4px'}}>{text}</Text>
    </Box>
);
