import React, {useState} from 'react';
import Page from "../../components/Page";
import {Form, Formik} from "formik";
import {Box, Button, FormField, Text, TextArea, TextInput} from "grommet";
import {LoadingButton} from "../../components/LoadingButton";
import {Link, withRouter} from "react-router-dom";
import * as Yup from "yup";
import gql from 'graphql-tag';
import {useMutation} from "@apollo/react-hooks";
import {ClientsSelect} from "../../components/ClientsSelect";

const SESSION_FIELDS = `{
        id
        client {
            id
            user {
                id
                first_name
                last_name
            }
        }
    }`;

const CREATE_TAG_WITH_SESSIONS = gql`
mutation ($name: String! $description: String! $sessions: [session_tag_insert_input!]!) {
    insert_tag(objects: {
        name: $name,
        description: $description,
        session_tags: {
            data: $sessions
        }
    }) {
        returning { 
            id
        }
    }
}
`;

const initialValues = {
    name: '',
    description: ''
};

const TagSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(30, 'Too Long!')
        .required('Required'),
    description: Yup.string()
});

const CreateTagPage = ({history}) => {
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [createTag, {loading}] = useMutation(CREATE_TAG_WITH_SESSIONS, {
        onCompleted: () => history.push('/tags')
    });
    return (
        <Page title='Create Risk Factor'>
            <Formik
                validationSchema={TagSchema}
                initialValues={initialValues}
                onSubmit={async (values) =>
                    createTag({
                        variables: {
                            name: values.name,
                            description: values.description,
                            sessions: selectedSessions.map(s => ({session_id: s.id}))
                        }
                    })
                }
            >
                {({values, handleChange, handleSubmit, errors}) => (
                    <Box gap="small">
                        <Box elevation='xsmall' background='white' round="xsmall" pad='medium' gap="medium" flex="grow"
                             height={{min: "small"}}>
                            <Form>
                                <FormField label="Name" error={errors.name}>
                                    <TextInput
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                </FormField>
                                <FormField label="Description" error={errors.description}>
                                    <TextArea
                                        placeholder="Write your description here..."
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                    />
                                </FormField>
                            </Form>
                        </Box>
                        <Text weight="bold">Audience</Text>
                        <ClientsSelect onChange={setSelectedSessions}
                                       fields={SESSION_FIELDS}/>
                        <Box flex={false} pad={{bottom: "small"}} direction="row" gap="small">
                            <LoadingButton label="Save" type="submit" onClick={handleSubmit} primary loading={loading}/>
                            <Link to='/tags'><Button primary label='Cancel' color='lightgray'/></Link>
                        </Box>
                    </Box>
                )}
            </Formik>
        </Page>
    );
};

export default withRouter(CreateTagPage);
