import React from 'react'
import {colors} from "../../theme";

const WindowUpload  = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 37 38" {...rest}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g  transform="translate(-414.000000, -245.000000)" fillRule="nonzero">
                <g transform="translate(296.000000, 245.000000)">
                    <g transform="translate(118.000000, 0.000000)">
                        <path d="M17.9636364,38 C17.3097144,38 16.7789951,37.4738036 16.7789951,36.8254545 L16.7789951,18.5182061 L11.6929355,23.5609212 C11.4686434,23.7833018 11.1716933,23.9054545 10.855789,23.9054545 C10.5398847,23.9054545 10.2413551,23.7833018 10.0186425,23.5609212 C9.55742219,23.1036315 9.55742219,22.3581867 10.0186425,21.8993309 L17.1264899,14.8520582 C17.2370564,14.7424339 17.3665772,14.6563006 17.5134727,14.5967903 C17.5261089,14.5920921 17.538745,14.5873939 17.5529607,14.5826958 C17.6887996,14.5325818 17.8246385,14.5090909 17.9636364,14.5090909 C18.1042138,14.5090909 18.2432117,14.5341479 18.3758915,14.5842618 C18.3869482,14.5873939 18.3980048,14.5920921 18.4074819,14.5952242 C18.5606955,14.6578667 18.6902163,14.744 18.8023624,14.8551903 L25.9086302,21.900897 C26.3698505,22.3581867 26.3698505,23.1036315 25.9086302,23.5624873 C25.6843381,23.7848679 25.3873881,23.9070206 25.0714837,23.9070206 C24.7555794,23.9070206 24.4570498,23.7848679 24.2343373,23.5624873 L19.1482776,18.5197721 L19.1482776,36.8254545 C19.1482776,37.4738036 18.6159788,38 17.9636364,38 Z" id="Path"
                              fill={active ? colors.green : colors.sidebarInactive}
                        />
                        <path d="M29.9393939,26.2545455 C29.2783321,26.2545455 28.7418182,25.7199074 28.7418182,25.061157 C28.7418182,24.4024066 29.2783321,23.8677686 29.9393939,23.8677686 L32.3345455,23.8677686 C32.9956073,23.8677686 33.5321212,23.3331306 33.5321212,22.6743802 L33.5321212,9.54710744 L2.39515152,9.54710744 L2.39515152,22.6743802 C2.39515152,23.3331306 2.93166545,23.8677686 3.59272727,23.8677686 L5.98787879,23.8677686 C6.64894061,23.8677686 7.18545455,24.4024066 7.18545455,25.061157 C7.18545455,25.7199074 6.64894061,26.2545455 5.98787879,26.2545455 L3.59272727,26.2545455 C1.61113859,26.2545455 0,24.6490402 0,22.6743802 L0,3.58016529 C0,1.60550523 1.61113859,0 3.59272727,0 L32.3345455,0 C34.3161341,0 35.9272727,1.60550523 35.9272727,3.58016529 L35.9272727,22.6743802 C35.9272727,24.6490402 34.3161341,26.2545455 32.3345455,26.2545455 L29.9393939,26.2545455 Z M33.5321212,7.16033058 L33.5321212,3.58016529 C33.5321212,2.92141488 32.9956073,2.38677686 32.3345455,2.38677686 L3.59272727,2.38677686 C2.93166545,2.38677686 2.39515152,2.92141488 2.39515152,3.58016529 L2.39515152,7.16033058 L33.5321212,7.16033058 Z" id="Shape"
                              fill={active ? colors.brand : colors.sidebarInactive}/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default WindowUpload;
