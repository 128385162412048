import React from 'react';
import {Box, Button, FormField, TextArea, TextInput} from "grommet";
import {Form, Formik} from "formik";
import Page from "../../components/Page";
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {useHistory} from "react-router-dom";

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
    cpt_code: Yup.string(),
    amount: Yup.number().required('Required'),
});

const CREATE_BILLING_CODE = gql`
    mutation ($data: billing_code_insert_input!) {
        insert_billing_code_one(object: $data) {
            id
            amount
            cpt_code
            description
            name
        }
    }
`;


export const CreateBillingCodePage = () => {
    const [create] = useMutation(CREATE_BILLING_CODE, {refetchQueries: ['billingCodes']});
    const history = useHistory();
    return (
        <Page title="Create Billing Code">
            <Formik validationSchema={ValidationSchema} initialValues={{
                name: '',
                description: '',
                cpt_code: '',
                amount: 0,
            }} onSubmit={async (values) => {
                await create({variables: {data: values}});
                history.push('/billing-codes');
            }}>
                {({values, handleChange, errors}) => (
                    <Form>
                        <Box elevation="xsmall" pad="medium" round="xsmall" background="white"
                             direction="row-responsive" gap="medium" width={{max: "large"}}>
                            <Box fill>
                                <FormField label='Name' error={errors.name}>
                                    <TextInput name="name" value={values.name} onChange={handleChange}/>
                                </FormField>
                                <FormField label="Description" error={errors.description}>
                                    <TextArea name="description" value={values.description}
                                              onChange={handleChange} resize={false} rows={4}/>
                                </FormField>
                            </Box>
                            <Box fill>
                                <FormField label="CPT Code" error={errors.cpt_code}>
                                    <TextInput name="cpt_code" value={values.cpt_code}
                                               onChange={handleChange}/>
                                </FormField>
                                <FormField label="Amount" error={errors.amount}>
                                    <TextInput type="number" name="amount" value={values.amount}
                                               onChange={handleChange}/>
                                </FormField>
                            </Box>
                        </Box>
                        <Button margin={{top: 'small'}} type="submit" label="Create" primary/>
                    </Form>
                )}
            </Formik>
        </Page>

    );
}