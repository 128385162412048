import React from "react";
import {colors} from "../../theme";

function Camera(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 38 28" {...props}>
            <path
                d="M24.889 0c.79 0 1.502.424 1.855 1.107L28.244 4h2.867c3.432 0 6.222 2.69 6.222 6v12c0 3.31-2.79 6-6.222 6H6.222C2.79 28 0 25.31 0 22V10c0-3.31 2.79-6 6.222-6h2.865l1.502-2.893A2.072 2.072 0 0112.444 0H24.89zm-1.28 4h-9.881l-1.502 2.893A2.072 2.072 0 0110.37 8H6.222c-1.145 0-2.074.896-2.074 2v12c0 1.104.93 2 2.074 2h24.89c1.144 0 2.073-.896 2.073-2V10c0-1.104-.929-2-2.074-2h-4.148c-.791 0-1.502-.424-1.856-1.107L23.608 4zm-4.942 3.467c4.118 0 7.466 3.35 7.466 7.466a7.473 7.473 0 01-7.466 7.467c-4.116 0-7.467-3.348-7.467-7.467 0-4.116 3.35-7.466 7.467-7.466zm0 4.266a3.205 3.205 0 00-3.2 3.2c0 1.764 1.436 3.2 3.2 3.2 1.763 0 3.2-1.436 3.2-3.2 0-1.763-1.437-3.2-3.2-3.2z"
                fill={colors.sidebarInactive}
                fillRule="nonzero"
            />
        </svg>
    )
}

export default Camera
