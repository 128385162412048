import React, {useMemo, useState} from 'react';
import {Box, Button, Text, TextInput} from "grommet";
import {SearchField} from "../../../components/SearchField";
import gql from 'graphql-tag';
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {UserCard} from "../../../components/UserCard";
import {Close, Trash} from "grommet-icons";
import {Modal} from "../../../components/Modal";
import {Form, Formik} from "formik";
import {userHasNecessaryRole} from "../../../services/auth/service";
import {MANAGER} from "../../../lib/constants";

const FETCH_DATA = gql`
query tagSessions($tag: Int!) {
    sessions: session(where: {session_tags: {tag_id: {_eq: $tag}}}) {
        id
        client {
            id
            user {
                id
                first_name
                last_name
            }
        }
    }
}
`;

const UPDATE_TAG = gql`
mutation ($name: String, $id: Int!) {
    update_tag_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
        id
    }
}
`;

const REMOVE_SESSION = gql`
mutation ($session: Int! $tag: Int!) {
    delete_session_tag(where: {_and: {session_id: {_eq: $session}, tag_id: {_eq: $tag}}}) {
        returning {
            session_id
        }
    }
}
`;

const REMOVE_TAG = gql`
mutation ($tag: Int!) {
    delete_tag(where: {id: {_eq: $tag}}) {
        affected_rows
    }
}
`;

export const SessionsSearchList = ({author, tag, tagName, onRemove = () => {}}) => {
    const [filter, setFilter] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [removeModalVisible, setRemoveModalVisible] = useState(false);
    const [selectedSession, setSelectedSession] = useState();
    const { data = {}, loading, error } = useQuery(FETCH_DATA, {
        variables: {tag},
        fetchPolicy: "cache-and-network"
    });
    const [removeSession] = useMutation(REMOVE_SESSION, {
        update(cache, {data: {delete_session_tag: {returning}}}) {
            const sessionsToExclude = returning.map(res => res.session_id);
            // const { sessions } = cache.readQuery({query: FETCH_OPTIONS_DATA, variables: {tag}});
            cache.writeQuery({
                query: FETCH_DATA,
                data: {
                    sessions: data.sessions.filter(session => !sessionsToExclude.includes(session.id)),
                },
                variables: {tag}
            });
        }
    });
    const canEdit = useMemo(() => {
        if (userHasNecessaryRole(MANAGER)) {
            return true;
        }
        if (author === data?.id) {
            return true;
        }
        return false;
    }, [data, author]);
    const [removeTag] = useMutation(REMOVE_TAG, {
        refetchQueries: ['tags'] // TODO: Pass this as prop
    });
    const [updateTag] = useMutation(UPDATE_TAG, {
        refetchQueries: ['tags']
    })

    return (
        <Box background='white' round='xsmall' elevation='xsmall' pad='small' gap='small' animation={{type: 'slideLeft', size: 'large'}}>
            <Modal // TODO: Use DeleteButton here
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                text='Are you sure you want remove this tag from the selected client?'
                acceptLabel='Remove'
                accept={async (doneLoading) => {
                        await removeSession({variables: {session: selectedSession.id, tag}});
                        doneLoading();
                        setSelectedSession(undefined);
                        setModalVisible(false);
                }}
            />
            <Modal // TODO: Use DeleteButton here
                visible={removeModalVisible}
                onClose={() => setRemoveModalVisible(false)}
                text='Are you sure you want remove this tag?'
                acceptLabel='Remove'
                accept={async (doneLoading) => {
                    await removeTag({variables: {tag}});
                    doneLoading();
                    setRemoveModalVisible(false);
                    onRemove();
                }}
            />
            {canEdit && <Formik initialValues={{name: tagName}} onSubmit={async (values) => {await updateTag({variables: {id: tag, name: values.name}})}}>
                {(props) => (<Form><Box flex={false} gap="small">
                    <TextInput placeholder="Name" value={props.values.name} name="name" onChange={props.handleChange} onBlur={props.handleBlur} />
                    <Box direction="row" gap="small" flex={false}>
                        <Button label="Update" primary color="brand" type="submit"/>
                        <Button icon={<Trash color="status-critical" />} plain onClick={() => setRemoveModalVisible(true)}/> {/* TODO: Extract this in a separate component*/}
                    </Box>
                </Box></Form>)}

            </Formik>}
            <Text color='darkgray' size='xsmall' weight='bold'>TAGGED CLIENTS</Text>
            <Text color='lightgray' size='xsmall'>
                Select a tag to see all clients connected to it.
            </Text>
            <SearchField placeholder='SEARCH CLIENTS'
                         containerProps={{flex: false}}
                         onChange={(searchTerm) => setFilter(searchTerm.toLowerCase())}
                         textInputProps={{size: 'large'}}
            />
            <Box gap='small' fill overflow='auto'>
                {loading ? <Spinner fill/> : error ?
                    <Text color='red'>Error when loading clients for this tag.</Text> :
                    data.sessions.length > 0 ? data.sessions
                        .filter(session =>
                            `${session.client.user.first_name.toLowerCase()} ${session.client.user.last_name.toLowerCase()}`.includes(filter)
                        )
                        .map(session => <UserCard
                        key={session.id}
                        boxProps={{flex: false, justify: 'between'}}
                        id={session.client.user.id}
                        firstName={session.client.user.first_name}
                        lastName={session.client.user.last_name}
                        button={<Button icon={<Close size='small' onClick={() => {
                            setSelectedSession(session);
                            setModalVisible(true);
                        }}/>} />}
                    />) : <Text>No clients</Text>
                }
            </Box>
        </Box>
    );
};
