import React, {useState} from 'react' ;
import {Box, Text} from "grommet";
import {Link} from "react-router-dom";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import {StatusCell} from "../../components/table/StatusCell";
import {SurveyResultStatus} from "../../lib/constants";
import {AvatarCell} from "../../components/table/AvatarCell";

const dateFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};

const columns = [
    {
        accessor: 'measurement_survey_template.title',
        Header: 'Title',
        Cell: ({row: {original: {measurement_survey_template: template, session}}}) => (
            <Link to={`/measurement/report/${session.id}/${template.id}`}>
                <Text weight='bold'>{template.title}</Text>
            </Link>
        ),
    },
    {
        accessor: 'session.client.user.first_name',
        Header: 'Client',
        Cell: ({row: {original: {session: {id, client: {user: {id: user_id, first_name, last_name}}}}}}) => (
          <AvatarCell user_id={user_id} id={id} last_name={last_name} first_name={first_name} path='clients' />
        ),
    },
    {
        accessor: 'survey_result_status.name',
        Header: 'Status',
        Cell: ({row: {original: {survey_result_status: status}}}) => (
            <StatusCell id={status.id} name={status.name} map={SurveyResultStatus}/>
        ),
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => new Date(created_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Created On'
    },
    {
        id: 'updated_at',
        accessor: ({updated_at}) => new Date(updated_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Last Modified'
    },
];

const FETCH_DATA = gql`
    query ($limit: Int! $offset: Int! $orderBy: [measurement_survey_result_order_by!] $filter: measurement_survey_result_bool_exp) {
        data: measurement_survey_result(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            updated_at
            created_at
            measurement_survey_template {
                id
                title
            }
            session {
                id
                client {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
            survey_result_status {
                id
                name
            }

        }
        data_aggregate: measurement_survey_result_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const MeasurementSurveyResultsPage = () => {
    const [filter, setFilter] = useState(null);

    // TODO: Add All button (it is showing all results if you are ProviderManager)
    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({
                    _or: [
                        {measurement_survey_template: {title: {_ilike: `%${term}%`}}},
                        {survey_result_status: {name: {_ilike: `${term}`}}},
                        {session: {client: {user: {first_name: {_ilike: `%${term}%`}}}}},
                        {session: {client: {user: {last_name: {_ilike: `%${term}%`}}}}}
                    ]
                })
            }/>
        </Box>
    );

    return (
        <Page title="Individual Measurement Reports" header={header()}>
            <DataTable query={FETCH_DATA}
                       applyFilter={filter}
                       columns={columns}
                       defaultSort={{updated_at: "desc"}}
            />
        </Page>
    );
};

export default MeasurementSurveyResultsPage
