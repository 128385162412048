import React from 'react';
import {DataTable} from "../../../../components/DataTable";
import gql from "graphql-tag";
import {Box, Text} from "grommet";
import {Link, useParams} from "react-router-dom";
import {StatusCell} from "../../../../components/table/StatusCell";
import {SurveyResultStatus} from "../../../../lib/constants";

const FETCH_SURVEYS = gql`
    query fetchDataClient($limit: Int! $offset: Int! $orderBy: [all_survey_results_order_by!] $filter: all_survey_results_bool_exp) {
        data: all_survey_results(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            updated_at
            created_at
            template_id
            session_id
            title
            status_id
            status_name
            is_measurement
        }
        data_aggregate: all_survey_results_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const timeFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};
const surveyColumns = [
    {
        accessor: 'survey_template.title',
        Header: 'Title',
        primary: true,
        Cell: ({row: {original: {title, template_id, id, session_id, is_measurement}}}) => (
            <Box>
                {is_measurement ? <Link to={`/measurement/report/${session_id}/${template_id}`}><Text
                        weight='bold'>{title}</Text></Link> :
                    <Link to={`/survey/results/${id}/${template_id}`}><Text weight='bold'>{title}</Text></Link>
                }
            </Box>
        ),
    },
    {
        accessor: 'status_name',
        Header: 'Status',
        Cell: ({row: {original: {status_id, status_name}}}) => (
            <StatusCell id={status_id} name={status_name} map={SurveyResultStatus}/>
        ),
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => new Date(created_at).toLocaleDateString("en-US", timeFormat),
        Header: 'Created On'
    },
    {
        id: 'updated_at',
        accessor: ({updated_at}) => new Date(updated_at).toLocaleDateString("en-US", timeFormat),
        Header: 'Last Modified'
    },
];

export const SurveysTab = () => {
    const {session_id: id} = useParams();
    return (
        <DataTable query={FETCH_SURVEYS} applyFilter={{session_id: {_eq: id}}}
                   columns={surveyColumns}/>
    );
}