import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {Button} from "grommet";

// A simple component that shows the pathname of the current location
class RoutedButton extends React.Component {

    changeRoute = () => {
        const {history, path, replace} = this.props;
        if (replace) {
            history.replace(path);
        } else {
            history.push(path);
        }
    };

    render() {
        const {
            active,
            onClick,
            ...rest
        } = this.props;
        return (
            // TODO: Do we really need to check active here?
            <Button {...rest} onClick={() => {
                if (onClick){
                    onClick();
                }
                this.changeRoute();
            }}/>
        );
    }
}

RoutedButton.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default withRouter(RoutedButton);
