import React from 'react' ;
import gql from 'graphql-tag';
import {useQuery} from 'react-apollo';
import {Box} from "grommet";
import Survey from '../../components/survey/Survey';
import Page from "../../components/Page";

const TEMPLATE = gql`
    query ($id: Int!) {
        measurement_survey_template_by_pk(id: $id) {
            created_at
            id
            questions
            subtitle
            title
            updated_at
        }
    }
`;

const ViewMeasurementSurveyPage = ({match}) => {

    const id = (match.params && match.params.id) || 0;
    const {data, loading} = useQuery(TEMPLATE, {variables: {id}});

    if (loading) {
        return null;
    }

    return (
        <Page title="Measurement Tool">
            <Box background='divider' elevation='xsmall' round="xsmall" fill pad="large">
                <Box background='white' round="xsmall">
                    <Survey
                        disableInput
                        template={data.measurement_survey_template_by_pk}
                        answers={Array.isArray(data.questions) ? {} : data.questions}
                        onSubmit={() => {
                        }}
                    />
                </Box>
            </Box>
        </Page>
    )
};

export default ViewMeasurementSurveyPage;