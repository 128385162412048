import React, {useEffect, useState} from 'react';
import {EmailTypeFields, NotificationTypeFields, SurveyTypeFields} from "./ActionTypeFields";
import {Box, Button, CheckBox, FormField, Layer, Select, Text, TextInput} from "grommet";
import {Close} from "grommet-icons";
import {safeGet} from "../../../utils";
import {emptyEvent} from "./CampaignForm";
import {useApolloClient} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {ACTION_TYPE, EVENT_TYPE} from "../constants/enums";
import {TIME_UNITS} from "../../../lib/constants";

const generateQuery = (table) => gql`
    query {
        ${table} {
            id
            name
        }
    }
`;

const ConfigurationModal = (props) => {
    const client = useApolloClient();
    const displayActionFields = (values, errors, handleChange, index, setFieldValue, surveyOptions, measurementSurveyOptions, disabled) => {
        let component;
        switch (values.actions[index].type.id) {
            case ACTION_TYPE.SEND_EMAIL:
                component = <EmailTypeFields values={values}
                                             errors={errors}
                                             handleChange={handleChange}
                                             index={index}
                                             disabled={disabled}
                />;
                break;

            case ACTION_TYPE.SEND_SURVEY:
                component = <SurveyTypeFields values={values}
                                              errors={errors}
                                              handleChange={handleChange}
                                              index={index}
                                              options={surveyOptions}
                                              setFieldValue={setFieldValue}
                                              disabled={disabled}
                />;
                break;
            case ACTION_TYPE.SEND_MEASUREMENT_SURVEY:
                component = <SurveyTypeFields values={values}
                                              errors={errors}
                                              handleChange={handleChange}
                                              index={index}
                                              options={measurementSurveyOptions}
                                              setFieldValue={setFieldValue}
                                              disabled={disabled}
                />;
                break;
            case ACTION_TYPE.SEND_MESSAGE:
                component =
                    <NotificationTypeFields values={values}
                                            errors={errors}
                                            handleChange={handleChange}
                                            index={index}
                                            disabled={disabled}
                    />;
                break;
            default:
                component = null;
        }
        return component
    };

    const {
        values, errors, handleChange, index, setFieldValue, setFieldError,
        surveyOptions, measurementSurveyOptions, onClose, actionTypeOptions, eventTypeOptions,
        eventsChangeOfFieldOperands, eventsChangeOfFieldOperators, disabled = false
    } = props;

    const fetchTriggerOperandOptions = async ({option}) => {
        const operand = option;
        // setFieldValue('actions[' + index + '].events.value', '');
        if (!operand.options_table) {
            setFieldValue('actions[' + index + '].events.fieldName', operand);
            return;
        }
        try {
            const {data} = await client.query({query: generateQuery(operand.options_table)});
            setFieldValue('actions[' + index + '].events.fieldName', {
                ...operand,
                options: data[operand.options_table]
            });
        } catch (e) {
            setFieldError('actions[' + index + '].events.fieldName.id', 'Could not load options.');
        }
    };
    const [timeDelay, setTimeDelay] = useState(values.actions[index].time !== 0);

    // Fetch the trigger operand options if the operand is already specified from the props
    useEffect(() => {
        if (values.actions[index].events.fieldName) {
            fetchTriggerOperandOptions({option: values.actions[index].events.fieldName});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layer position='center' onClickOutside={onClose}>
            <Box pad='medium' gap='medium' width='large'>
                <Box direction='row' justify='between'>
                    <Text weight='bold'>Add Action</Text>
                    <Button onClick={() => {
                        onClose()
                    }}>
                        <Close size='15px' color='darkgray'/>
                    </Button>
                </Box>
                <Box direction='row'>
                    <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight': '12px'}}>ACTION</Text>
                    <Text color='darkgray' size='22px' weight='bold'
                          style={{'lineHeight': '0', 'padding': '0 5px 0 5px'}}> . </Text>
                    <Text color='darkgray' size='14px' style={{'lineHeight': '12px'}}>Choose the type of the
                        action</Text>
                </Box>
                <Box direction='row' justify='start' gap='medium'>
                    <FormField
                        error={safeGet(() => errors.actions[index].type.id)}>
                        <Select
                            name={'actions[' + index + '].type'}
                            labelKey="name"
                            valueKey="id"
                            options={actionTypeOptions}
                            value={values.actions[index].type}
                            placeholder='Select an action type'
                            onChange={({option}) => {
                                setFieldValue('actions[' + index + '].type', option)
                            }}
                            disabled={disabled}
                        />
                    </FormField>
                </Box>
                <Box>
                    {displayActionFields(values, errors, handleChange, index, setFieldValue, surveyOptions, measurementSurveyOptions, disabled)}
                </Box>
                <Box direction='row'>
                    <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight': '12px'}}>TRIGGER</Text>
                    <Text color='darkgray' size='22px' weight='bold'
                          style={{'lineHeight': '0', 'padding': '0 5px 0 5px'}}> . </Text>
                    <Text color='darkgray' size='14px' style={{'lineHeight': '12px'}}>Choose a trigger (Optional)</Text>
                </Box>
                <Box direction='row' justify='start' gap='medium'>
                    <FormField>
                        <Select
                            name={'actions[' + index + '].events'}
                            labelKey="name"
                            valueKey="type_id"
                            options={eventTypeOptions}
                            value={values.actions[index].events}
                            placeholder='Select a trigger'
                            onChange={({option}) => {
                                setFieldValue('actions[' + index + '].events', option)
                            }}
                            disabled={disabled}
                        />
                    </FormField>
                    {values.actions[index].events.type_id &&
                    <Button label='Remove Trigger' plain disabled={disabled} onClick={() => {
                        setFieldValue('actions[' + index + '].events', emptyEvent)
                    }}/>
                    }
                </Box>
                {values.actions[index].events.type_id === EVENT_TYPE.CHANGE_OF_FIELD &&

                <Box direction='row'>
                    <Box direction='column'>
                        <FormField label="Field" error={safeGet(() => errors.actions[index].events.fieldName.id)}>
                            <Select
                                name={'actions[' + index + '].events.fieldName'}
                                labelKey="name"
                                valueKey="id"
                                options={eventsChangeOfFieldOperands}
                                value={values.actions[index].events.fieldName || ''}
                                onChange={fetchTriggerOperandOptions}
                                disabled={disabled}
                            />
                        </FormField>
                    </Box>

                    <FormField label="Operator"
                               error={safeGet(() => errors.actions[index].events.operator.id)}>
                        <Select
                            name={'actions[' + index + '].events.operator'}
                            options={eventsChangeOfFieldOperators}
                            labelKey="name"
                            valueKey="id"
                            value={values.actions[index].events.operator || ''}
                            onChange={({option}) => {
                                setFieldValue('actions[' + index + '].events.operator', option)
                            }}
                            disabled={disabled}
                        />
                    </FormField>


                    <FormField label="Desired Value"
                               error={safeGet(() => errors.actions[index].events.value)}>
                        {

                            Array.isArray(safeGet(() => values.actions[index].events.fieldName.options))
                            || values.actions[index].events.value instanceof Object ?
                                <Select
                                    name={'actions[' + index + '].events.value'}
                                    labelKey="name"
                                    valueKey="id"
                                    options={safeGet(() => values.actions[index].events.fieldName.options) || []}
                                    value={values.actions[index].events.value || ''}
                                    onChange={({option}) => setFieldValue('actions[' + index + '].events.value', option)}
                                    disabled={disabled}
                                />
                                :

                                <TextInput
                                    name={'actions[' + index + '].events.value'}
                                    value={values.actions[index].events.value || ''}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                        }

                    </FormField>

                </Box>
                }
                <CheckBox
                    checked={timeDelay}
                    label="TIME DELAY"
                    name="time_delay"
                    disabled={disabled}
                    onChange={() => {
                        setTimeDelay(!timeDelay);
                        setFieldValue('actions[' + index + '].time', 0);
                        setFieldValue('actions[' + index + '].unit', TIME_UNITS.DAYS);
                    }}
                />
                {timeDelay && <Box gap='medium'>
                    <Box direction='row'>
                        <Text color='darkgray' size='12px' weight='bold' style={{'lineHeight': '12px'}}>TIME
                            DELAY</Text>
                        <Text color='darkgray' size='22px' weight='bold'
                              style={{'lineHeight': '0', 'padding': '0 5px 0 5px'}}> . </Text>
                        <Text color='darkgray' size='14px' style={{'lineHeight': '12px'}}>Choose when to execute the
                            action</Text>
                    </Box>
                    <Box direction='row' gap='medium'>
                        <FormField style={{'width': '80px'}} error={safeGet(() => errors.actions[index].time)}>
                            <TextInput direction='column'
                                       disabled={disabled}
                                       type='number'
                                       min={0}
                                       name={'actions[' + index + '].time'}
                                       value={values.actions[index].time}
                                       onChange={handleChange}
                            />
                        </FormField>
                        <FormField style={{'width': '120px'}}
                                   error={safeGet(() => errors.actions[index].unit)}>
                            <Select
                                disabled={disabled}
                                name={'actions[' + index + '].unit'}
                                options={Object.values(TIME_UNITS)}
                                value={values.actions[index].unit || TIME_UNITS.MINUTES}
                                onChange={({option}) => {
                                    setFieldValue('actions[' + index + '].unit', option)
                                }}
                            />
                        </FormField>
                    </Box>
                </Box>}

                <Box direction='row' justify='end'>
                    <Button label='OK' primary onClick={() => {
                        onClose()
                    }}/>
                </Box>
            </Box>
        </Layer>
    )
};

export default ConfigurationModal;
