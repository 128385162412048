import React from 'react';
import {Box, Button, FormField, MaskedInput, Text, TextInput} from "grommet";
import {PasswordInput} from "./PasswordInput";
import {ImageUpload} from "../../components/ImageUpload";
import {LoadingButton} from "../../components/LoadingButton";
import {Link} from "react-router-dom";
import bcrypt from "bcryptjs";
import {PHONE_NUMBER_MASK} from "../../lib/constants";

export const ProfileForm = ({values, errors, handleChange, handleSubmit, isSubmitting, isValidating, handleBlur, loading, setFieldError, error, setFieldValue}) => (
    <Box gap='medium'>
        <Box direction='row' gap='medium'>
            <Box border basis='3/4' background='white' elevation='xsmall' round='xsmall' pad='medium'
                 gap='medium'>
                <Text>Name and Profile Information</Text>
                <Box>
                    <Box direction='row' gap='medium'>
                        <FormField label="First Name" error={errors.first_name}>
                            <TextInput
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label="Last Name" error={errors.last_name}>
                            <TextInput
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label="Role" error={errors.role}>
                            <TextInput
                                name="role"
                                value={values.role}
                                disabled
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                    </Box>
                    <Box direction='row' gap='medium'>
                        <FormField label="Email" error={errors.email}>
                            <TextInput
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label="Phone" error={errors.phone_number}>
                            <MaskedInput
                                name='phone_number'
                                mask={PHONE_NUMBER_MASK}
                                value={values.phone_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label="Credentials" error={errors.credentials}>
                            <TextInput
                                name="credentials"
                                value={values.credentials}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                    </Box>
                </Box>
                <Box gap='medium'>
                    <Text>Profile Management</Text>
                    <Text color='sidebarInactive'>To change your password please type in your Current
                        Password and New Password.</Text>
                    <Box direction='row' gap='medium'>
                        <FormField label="Current Password" error={errors.password}>
                            <PasswordInput
                                name="password"
                                onBlur={() => {
                                    if (values.password && values.password.length > 0 && !bcrypt.compareSync(values.password, values.password_hash)) {
                                        setFieldError('password', 'Wrong Password')
                                    } else {
                                        setFieldError('password', undefined);
                                    }
                                }}
                                value={values.password}
                                onChange={handleChange}
                            />
                        </FormField>
                        <FormField label="New Password" error={errors.new_password}>
                            <PasswordInput
                                name="new_password"
                                onBlur={handleBlur}
                                value={values.new_password}
                                onChange={handleChange}
                            />
                        </FormField>
                        <FormField label="Repeat New Password" error={errors.repeat_new_password}>
                            <PasswordInput
                                name="repeat_new_password"
                                onBlur={handleBlur}
                                value={values.repeat_new_password}
                                onChange={handleChange}
                            />
                        </FormField>
                    </Box>
                    {error && <Text color='red'>Could not update profile</Text>}
                </Box>
            </Box>
            <Box border basis='1/4' background='white' elevation='xsmall' round='xsmall' pad='medium'
                 gap='medium'>
                <Text>Photo</Text>
                <ImageUpload id={values.id} setFieldValue={setFieldValue}/>
                {errors.avatar && <Text color='red'>{errors.avatar}</Text>}
            </Box>
        </Box>
        <Box flex={false} pad={{bottom: "small"}} direction="row" gap="medium">
            <LoadingButton label="Update" type="submit" onClick={handleSubmit} primary loading={loading || isSubmitting || isValidating}/>
            <Link to='/'><Button primary label='Cancel' color='lightgray'/></Link>
        </Box>
    </Box>
);
