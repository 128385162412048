import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Box, Text,} from "grommet";
import RoutedAnchor from "../../components/RoutedAnchor";
import {PasswordResetForm} from "./components/PasswordResetForm";
import {resetPassword} from "../../services/auth/service";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import {Layout} from "./Layout";
import YupPassword from "yup-password";

YupPassword(Yup)

const PasswordResetSchema = Yup.object().shape({
    password: Yup.string()
        .minNumbers(1, 'Must contain at least 1 number')
        .minLowercase(1, 'Must contain at least 1 lowercase character')
        .minUppercase(1, 'Must contain at least 1 uppercase character')
        .minSymbols(1, 'Must contain at least 1 special symbol')
        .min(8, 'Must contain at least 8 characters')
        .max(64, 'Must be shorter than 64 characters')
        .required('Required'),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required('Password repeat is required'),
});

const PasswordResetPage = ({match}) => {
    const token = match.params.token;

    return (
        <Layout header='Reset your password'>
            <Box align="center">
                <Box
                    height={{min: '240px'}}
                    background="white"
                    round="small"
                    border
                    elevation='xsmall'
                    pad="34px"
                >
                    <Formik
                        initialValues={{
                            password: "",
                            passwordRepeat: ""
                        }}
                        validationSchema={PasswordResetSchema}
                        onSubmit={async ({password}, actions) => {
                            try {
                                const message = await resetPassword(password, token);
                                actions.resetForm();
                                actions.setStatus(message);
                            } catch (e) {
                                SagaErrorHandler.handle(e, actions);
                            }
                        }}
                        render={props => <PasswordResetForm {...props} />}
                    />
                </Box>

                <Text margin={{top: "medium"}} color="lightgray">
                    Try to login again?
                    <RoutedAnchor path='/login' label=' Back to Login.'/>
                </Text>
            </Box>
        </Layout>
    );
};

export default PasswordResetPage
