import React, {useState} from 'react' ;
import {Box, Text} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import RoutedButton from "../../components/RoutedButton";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import {MeetingType, MeetingTypeTags, NA} from "../../lib/constants";
import {DeleteButton} from "../../components/DeleteButton";
import {MeetingStatusButton} from "./components/MeetingStatusButton";
import moment from "moment";
import {StatusCell} from "../../components/table/StatusCell";

export const DELETE_MEETING = gql`
    mutation($id: Int!){
        delete_meeting(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        primary: true,
    },
    {
        'id': 'description',
        // accessor: ({description}) => description || NA,
        Header: 'Description',
        Cell: ({row: {original: {description}}}) => (
            <Text truncate>{description || NA}</Text>
        )
    },
    {
        'id': 'type_id',
        Header: 'Type',
        Cell: ({row: {original: {meeting_type}}}) => (
            <StatusCell id={meeting_type.id} name={meeting_type.name} map={MeetingTypeTags}/>
        )
    },
    {
        id: 'meeting_participants.session.client.user.first_name',
        // TODO: Temporary until we implement group meetings
        // accessor: ({meeting_participants}) => `${meeting_participants[0].session.client.user.first_name} ${meeting_participants[0].session.client.user.last_name}`,
        Header: 'Client',
        sortable: false,
        Cell: ({row: {original: {meeting_participants}}}) => (
            <Text>{`${meeting_participants[0].session.client.user.first_name} ${meeting_participants[0].session.client.user.last_name}`}</Text>
        )
    },
    {
        id: 'start_at',
        accessor: ({start_at}) => moment(start_at).format('MMM D, h:mm a'),
        Header: 'Starts on'
    },
    {
        id: "actions",
        sortable: false,
        Header: 'Actions',
        Cell: ({row: {original: {id}}}) => (
            <DeleteButton
                query={DELETE_MEETING}
                variables={{id: parseInt(id)}}
                queriesToRefetch={['fetchMeetings']}
            />
        )
    },
    {
        id: "join",
        sortable: false,
        // Header: '',
        Cell: ({row: {original: {id, status, start_at, type_id}}}) => type_id === MeetingType.ONLINE && <MeetingStatusButton startAt={start_at} status={status} id={id}/>
    },

];

const FETCH_DATA = gql`
    query fetchMeetings($limit: Int! $offset: Int! $orderBy: [meeting_order_by!] $filter: meeting_bool_exp) {
        data: meeting(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            name
            description
            twilio_room_name
            status
            type_id
            meeting_type {
                id
                name
            }
            start_at
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
            meeting_participants {
                session {
                    id
                    client {
                        id
                        user {
                            id
                            first_name
                            last_name
                        }
                    }
                }
            }
        }
        data_aggregate: meeting_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const MeetingsPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({
                    _or: [
                        {provider: {user: {first_name: {_ilike: `%${term}%`}}}},
                        {provider: {user: {last_name: {_ilike: `%${term}%`}}}},
                        {name: {_ilike: `%${term}%`}},
                        {description: {_ilike: `%${term}%`}}
                    ]
                })
            }/>
            <RoutedButton path='/meetings/create' label='New Meeting' primary/>
        </Box>
    );
    return (
        <Page title="Meetings" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns} defaultSort={[
                {twilio_room_completed: 'asc', start_at: 'desc', created_at: 'asc'}
                ]}/>
        </Page>
    );
};

export default MeetingsPage

