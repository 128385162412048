import React from 'react';
import {Box, Button, FormField, Select, Text, TextInput, Grid, ResponsiveContext} from "grommet";
import {Add, Subtract} from "grommet-icons";
import {FieldArray, getIn} from "formik";
export const emptyAudienceFilter = {operand: "", operator: '', filterValue: ''};

export const AudienceFilterForm = ({audienceOperands, operators, options, errors, values, handleChange, setFieldValue, status, disabled, validateForm= () => Promise.resolve({}), onFiltersChange = () => {}}) => (
    <React.Fragment>
        <FormField label="Name" error={errors.name}>
            <TextInput name="name" disabled={disabled} value={values.name} onChange={handleChange}/>
        </FormField>
        <Box>
            <FieldArray name="audienceFilters">
                {(arrayHelpers) => values.audienceFilters.map(({operand, operator, filterValue}, index) => {
                    let operandName = 'audienceFilters[' + index + "].operand";
                    let operatorName = 'audienceFilters[' + index + "].operator";
                    let filterValueName = 'audienceFilters[' + index + "].filterValue";
                    return (
                        <Box key={index}>
                            <Box direction='row' gap="small">
                                <ResponsiveContext.Consumer>
                                    {(deviceSize) => (
                                        <Grid
                                            fill
                                            gap='small'
                                            columns={deviceSize === 'small' ? 'fill' : ["1/4", "1/4", "1/4", "auto"]}
                                        >
                                            <Box>
                                                <FormField label="Field" error={getIn(errors, operandName)}>
                                                    <Select
                                                        disabled={disabled}
                                                        name={operandName}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        options={audienceOperands}
                                                        value={audienceOperands.find(el => el.id === operand.id) || ""}
                                                        onChange={({option}) => {
                                                            setFieldValue(filterValueName, '');
                                                            setFieldValue(operandName, option);
                                                        }}
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box>
                                                <FormField label="Operator" error={getIn(errors, operatorName)}>
                                                    <Select
                                                        name={operatorName}
                                                        options={operators}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        value={operators.find(el => el.id === operator.id) || ""}
                                                        onChange={({option}) => {
                                                            setFieldValue(operatorName, option);
                                                            // Desired value is set to null, so the participants will
                                                            // be re-fetched when selected with the new filters.
                                                            setFieldValue(filterValueName, "");
                                                        }}
                                                        disabled={disabled}
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box>
                                                <FormField label="Desired Value" error={getIn(errors, filterValueName)}>
                                                    {operand.options_table ?
                                                        <Select
                                                            name={filterValueName}
                                                            labelKey="name"
                                                            valueKey="id"
                                                            options={options[operand.options_table]}
                                                            value={options[operand.options_table].find(el => el.id === parseInt(filterValue, 10)) || ""}
                                                            onChange={({option}) => {
                                                                setFieldValue(filterValueName, option.id.toString());
                                                            }}
                                                            disabled={disabled}
                                                        />
                                                        :
                                                        <TextInput
                                                            name={filterValueName}
                                                            value={filterValue || ""}
                                                            onChange={handleChange}
                                                            disabled={disabled}
                                                        />
                                                    }
                                                </FormField>
                                            </Box>
                                            <Box direction='row' height={deviceSize === 'small' ? 'xxsmall' : 'xsmall'}>
                                                {!disabled ? <Button icon={<Add/>}
                                                                     onClick={() => arrayHelpers.push({...emptyAudienceFilter})}/> : ''}
                                                {index !== 0 ?
                                                    <Button icon={<Subtract/>}
                                                            onClick={() => arrayHelpers.remove(index)}/> : null}
                                            </Box>
                                        </Grid>
                                    )}
                                </ResponsiveContext.Consumer>
                            </Box>
                        </Box>
                    );
                })}
            </FieldArray>
            {!disabled ? <Box margin={{top: "small"}} direction="row" gap="small">
                <Button primary label="Refresh Participants" onClick={() => {
                    validateForm().then((errors) =>  {
                        // reload the filters only if the form is valid
                        if(Object.keys(errors).length === 0) {
                            onFiltersChange(values.audienceFilters)
                        }
                    })
                }}/>
            </Box> : ''}
        </Box>
        {status && <Text color='red'>{status}</Text>}
    </React.Fragment>
);
