import {getTimeInMinutes} from "../../../services/campaigns/helpers";
import {ACTION_TYPE} from "./enums";

// match formik data structure to the one defined in the backend
export const prepareForSending = (values) => {
    let valuesToSend = Object.assign({}, values, {
        actions: values.actions.map((action) => {
            let actionToSend = getActionOnlyTypeField(action);
            return Object.assign({}, actionToSend, {
                type_id: action.type.id,
                time_delay_minutes: getTimeInMinutes(action.time, action.unit),
                events: action.events.type_id ? [
                    Object.assign({}, {
                        type_id: action.events.type_id,
                        // TODO add operand_id, operator_id and value only for field change event
                        operand_id: action.events.fieldName.id,
                        operator_id: action.events.operator.id,
                        value: action.events.value.id ? action.events.value.id : action.events.value
                    })] : []
            });
        }),
        audience: values.audiences.map((audience) => {
            return audience.id
        })
    });
    delete valuesToSend.audiences;
    return valuesToSend
};

export const transformerTemplate = {
    id: '{{id}}',
    name: '{{name}}',
    is_enabled: '{{is_enabled}}',
    audiences: [
        '{{campaign_audiences}}',
        {
            id: '{{audience.id}}',
            name: '{{audience.name}}',
        }
    ],
    actions: [
        '{{campaign_actions}}',
        {

            type: '{{action_details.type}}',
            time: '{{action_details.time}}',
            unit: "min",
            subject: '=> either(action_details.metadata.subject, "")',
            body: '=> either(action_details.metadata.body, "")',
            message: '=> either(action_details.metadata.message, "")',
            sms: '=> either(action_details.metadata.sms, false)',
            chat: '=> either(action_details.metadata.chat, true)',
            survey: {
                id: '=> either(action_details.metadata.template_id, "")',
                title: '=> either(action_details.metadata.title, "")',
            },
            events: {
                type_id: '{{campaign_action_events[0].event.type.id}}',
                name: '{{campaign_action_events[0].event.type.name}}',
                value: '{{campaign_action_events[0].event.field_change_trigger.json_value}}',
                fieldName: '{{campaign_action_events[0].event.field_change_trigger.trigger_operand}}',
                operator: '{{campaign_action_events[0].event.field_change_trigger.operator}}'
            }
        }
    ],
};

// because of formik we need to define all possible action fields, regadless of the action type
// this method returns only the needed fields of a given action
// it is used right before saving the action
const getActionOnlyTypeField = (action) => {
    let actionWithFields;
    switch (action.type.id) {
        case ACTION_TYPE.SEND_EMAIL:
            actionWithFields = Object.assign({}, {
                subject: action.subject,
                body: action.body
            });
            break;
        case ACTION_TYPE.SEND_SURVEY:
            actionWithFields = Object.assign({}, {
                survey_template_id: action.survey.id
            });
            break;
        case ACTION_TYPE.SEND_MEASUREMENT_SURVEY:
            actionWithFields = Object.assign({}, {
                measurement_survey_template_id: action.survey.id
            });
            break;
        case ACTION_TYPE.SEND_MESSAGE:
            actionWithFields = Object.assign({}, {
                sms: action.sms,
                chat: action.chat,
                message: action.message
            });
            break;
        default:
            actionWithFields = {};
    }
    return actionWithFields;
};

