import React from 'react';
import {Box, Button, FormField, Layer, Text, TextInput} from "grommet";
import {Close} from "grommet-icons";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation} from "react-apollo";
import {EnumSearchSelect} from "../../../../components/EnumSearchSelect";

const VALIDATION_SCHEMA = Yup.object({
    diagnosis_id: Yup.number().required('This field is required'),
});

const CREATE_SESSION_DIAGNOSIS = gql`
    mutation($data: [session_diagnosis_insert_input!]!) {
        insert_session_diagnosis(objects: $data) {
            returning {
                session_id
                diagnosis_id
            }
        }
    }
`;

const FETCH_DIAGNOSES = gql`
    query ($limit: Int! $offset: Int! $filter: diagnosis_bool_exp) {
        options: diagnosis (limit: $limit, offset: $offset, order_by: {name: asc}, where: $filter) {
            id
            name
            icd10_code
        }
    }
`;

export const CreateDiagnosisModal = ({sessionId, refetchQueries = []}) => {
    const [show, setShow] = React.useState(false);
    const [createDiagnosis] = useMutation(CREATE_SESSION_DIAGNOSIS, {refetchQueries});
    return (
        <React.Fragment>
            <Button label="Add Diagnosis" margin={{top: 'small'}} alignSelf='start' onClick={() => setShow(true)}/>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Button selfAlign='end' icon={<Close/>} onClick={() => setShow(false)}/>
                    <Box pad={{horizontal: 'large', vertical: 'small'}} width={{min: 'large'}}>
                        <Text weight='bold'>Add Diagnosis</Text>
                        <Formik
                            validationSchema={VALIDATION_SCHEMA}
                            initialValues={{
                                diagnosis_id: '',
                                icd10_code: '',
                            }}
                            onSubmit={async ({diagnosis_id}) => {
                                await createDiagnosis({variables: {data: {diagnosis_id, session_id: sessionId}}});
                                setShow(false);
                            }}
                        >
                            {({values, errors, setFieldValue}) => (
                                <Form>
                                    <FormField label='Diagnosis' error={errors.diagnosis_id}>
                                        <EnumSearchSelect
                                            query={FETCH_DIAGNOSES}
                                            name='diagnosis_id'
                                            value={values.diagnosis_id}
                                            onChange={({option, value}) => {
                                                setFieldValue('icd10_code', option.icd10_code);
                                                setFieldValue('diagnosis_id', value);
                                            }}
                                        />
                                    </FormField>
                                    <Box direction='row' gap='small'>
                                        <FormField label='ICD-10 Code'>
                                            <TextInput
                                                name='icd10_code'
                                                value={values.icd10_code}
                                                disabled
                                            />
                                        </FormField>
                                    </Box>
                                    <Button label="Create" type='submit' alignSelf='stretch'/>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Layer>
            )}
        </React.Fragment>
    )
}
