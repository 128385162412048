import React, {useRef} from 'react';
import Survey from '../../components/survey/Survey';
import {Box} from "grommet";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/react-hooks";
import Page from "../../components/Page";
import {IndividualReportActionBar} from "../../components/survey/IndividualReportActionBar";
import {PrintPage} from "../PrintPage";

const FETCH_DATA = gql`
    query ($id: Int!) {
        id @client
        survey: measurement_survey_result_by_pk(id: $id) {
            id
            result
            status: survey_result_status {
                id
                name
            }
            updated_at
            created_at
            session {
                id
                client {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
                provider_session {
                    provider_id
                    twilio_channel_sid
                }
            }
            template: measurement_survey_template {
                id
                questions
                title
                subtitle
            }
        }
    }
`;

const MeasurementSurveyPage = ({match}) => {
    const printRef = useRef();
    const id = (match.params && match.params.id) || 0;
    const {data, loading, error} = useQuery(FETCH_DATA, {
        variables: {id},
        fetchPolicy: "no-cache" // This is a big query, so don't store it in cache
    });

    if (loading) {
        return 'Loading...';
    }
    if (error) {
        console.error(error);
        return 'Error loading the page';
    }
    const survey = data.survey;

    const SurveyResults = () => {
        return (
            <Survey
                disableInput
                template={survey.template}
                answers={Array.isArray(survey.result) ? {} : survey.result}
                onSubmit={() => {
                }}
            />
        )
    };

    return (
        <Page title='Report Results' boxProps={{gap: "medium"}}>
            <IndividualReportActionBar survey={survey}
                                       printRef={printRef}
                                       canMessage={survey.session.provider_session && survey.session.provider_session.provider_id === data.id}
            />
            <Box background='divider' elevation='xsmall' round="xsmall" fill pad="large">
                <Box background='white' round="xsmall">
                    <SurveyResults/>
                </Box>
                <Box style={{ overflow: 'hidden', height: '0'}}>
                    <PrintPage ref={printRef}>
                        <SurveyResults/>
                    </PrintPage>
                </Box>
            </Box>
        </Page>
    )
};

export default MeasurementSurveyPage
