import React from 'react'
import {Blank} from "grommet-icons";

const MedicalCross = props => (
    <Blank {...props}>
        <svg viewBox="0 0 12 12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1088.000000, -351.000000)" fill="#819DB6" fillRule="nonzero">
                    <g transform="translate(1087.149672, 351.000000)">
                        <g transform="translate(0.850328, 0.000000)">
                            <path d="M7.2,12 C7.525,12 7.80625,11.88125 8.04375,11.64375 C8.28125,11.40625 8.4,11.125 8.4,10.8 L8.4,10.8 L8.4,8.4 L10.8,8.4 C11.125,8.4 11.40625,8.28125 11.64375,8.04375 C11.88125,7.80625 12,7.525 12,7.2 L12,7.2 L12,4.8 C12,4.475 11.88125,4.19375 11.64375,3.95625 C11.40625,3.71875 11.125,3.6 10.8,3.6 L10.8,3.6 L8.4,3.6 L8.4,1.2 C8.4,0.875 8.28125,0.59375 8.04375,0.35625 C7.80625,0.11875 7.525,0 7.2,0 L7.2,0 L4.8,0 C4.475,0 4.19375,0.11875 3.95625,0.35625 C3.71875,0.59375 3.6,0.875 3.6,1.2 L3.6,1.2 L3.6,3.6 L1.2,3.6 C0.875,3.6 0.59375,3.71875 0.35625,3.95625 C0.11875,4.19375 0,4.475 0,4.8 L0,4.8 L0,7.2 C0,7.525 0.11875,7.80625 0.35625,8.04375 C0.59375,8.28125 0.875,8.4 1.2,8.4 L1.2,8.4 L3.6,8.4 L3.6,10.8 C3.6,11.125 3.71875,11.40625 3.95625,11.64375 C4.19375,11.88125 4.475,12 4.8,12 L4.8,12 L7.2,12 Z M7.2,10.8 L4.8,10.8 L4.8,7.8 C4.8,7.6375 4.740625,7.496875 4.621875,7.378125 C4.503125,7.259375 4.3625,7.2 4.2,7.2 L4.2,7.2 L1.2,7.2 L1.2,4.8 L4.2,4.8 C4.3625,4.8 4.503125,4.740625 4.621875,4.621875 C4.740625,4.503125 4.8,4.3625 4.8,4.2 L4.8,4.2 L4.8,1.2 L7.2,1.2 L7.2,4.2 C7.2,4.3625 7.259375,4.503125 7.378125,4.621875 C7.496875,4.740625 7.6375,4.8 7.8,4.8 L7.8,4.8 L10.8,4.8 L10.8,7.2 L7.8,7.2 C7.6375,7.2 7.496875,7.259375 7.378125,7.378125 C7.259375,7.496875 7.2,7.6375 7.2,7.8 L7.2,7.8 L7.2,10.8 Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </Blank>
);

export default MedicalCross
