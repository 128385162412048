import React from 'react';
import Page from "../../components/Page";
import {withRouter} from "react-router-dom";
import {ErrorMessage, Form, Formik} from "formik";
import CampaignForm, {emptyAction} from "./components/CampaignForm";
import {DisplayError} from "../../components/DisplayError";
import {Box} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../components/Spinner";
import API from "../../services/api";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import {ADDITIONAL_CAMPAIGN_FRAGMENT} from "./constants/queries";
import {CampaignValidationSchema} from "./constants/validationSchemas";
import {prepareForSending} from "./constants/dataTransormations";

const FETCH_DATA = gql`
    query {
        ...additional_fields
    }
    ${ ADDITIONAL_CAMPAIGN_FRAGMENT }
`;

const initialValues = {
    name: '',
    is_enabled: true,
    audiences: [],
    actions: [emptyAction]
};

const NewCampaignPage = ({history}) => {
    const {data, loading, error} = useQuery(FETCH_DATA, {fetchPolicy: "network-only"});

    if (loading) {
        return <Spinner full/>;
    }

    if (error) {
        return <DisplayError error={error}/>;
    }

    return (
        <Page title="Create New Campaign">
            <Formik
                initialValues={initialValues}
                validationSchema={CampaignValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const payload = prepareForSending(values);
                    try {
                        await API.post('/campaign/create', payload);
                        history.push('/campaigns');
                    } catch (e) {
                        SagaErrorHandler.handle(e, actions);
                        actions.setFieldError('globalError', e.message);
                    }
                }}
                render={({errors, values, handleChange, setFieldValue, setFieldError}) => (
                    <Box>
                        <ErrorMessage name='globalError'/>
                        <Form>
                            <CampaignForm
                                values={values}
                                errors={errors}
                                setFieldError={setFieldError}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                audienceOptions={data.audience}
                                surveyOptions={data.survey_template}
                                measurementSurveyOptions={data.measurement_survey_template}
                                eventTypeOptions={data.event_type}
                                actionTypeOptions={data.action_type}
                                eventsChangeOfFieldOperands={data.trigger_operand}
                                eventsChangeOfFieldOperators={data.operator}
                            />
                        </Form>
                    </Box>
                )}
            />
        </Page>
    );
};

export default withRouter(NewCampaignPage);
