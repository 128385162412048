import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Box, Text} from "grommet";
import {LoginForm} from "./components/LoginForm";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import RoutedAnchor from "../../components/RoutedAnchor";
import {Layout} from "./Layout";
import {useHistory} from "react-router-dom";

const SignInSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .required('Required')
});

export const LOGIN = gql`
    mutation ($email: String! $password: String!){
        login(email: $email password: $password) @client
    }
`;

const LoginPage = () => {
    const history = useHistory();
    const [localLogin, {loading}] = useMutation(LOGIN);
    return (
        <Layout header='Log in to your account'>
            <Box align="center">
                <Box
                    height={{min: '240px'}}
                    background="white"
                    round="small"
                    border
                    elevation='xsmall'
                    pad="34px"
                >
                    <Formik
                        initialValues={{
                            email: "",
                            password: ""
                        }}
                        validationSchema={SignInSchema}
                        onSubmit={async ({email, password}, actions) => {
                            try {
                                // Here we call REST API to login user and update the local data in the apollo cache,
                                // which will then refetch all queries that select any of the local data,
                                // which will trigger a rerender of the App component,
                                // which will change the Layout, therefore the Switch component will rerender and
                                // redirect to the '/' path.
                                await localLogin({variables: {email, password}});
                                // history.push('/');
                                // actions.resetForm(); // AuthorizedLayout will have been rendered already, therefore this is no op
                            } catch (e) {
                                console.log('er', e);
                                if(e.message.includes('Unauthorized device')) {
                                    history.push('/authenticate-device/' + e.message.split(' ').at(-1));
                                }
                                // Apollo transforms the error to ApolloError
                                // but it has been parsed by Axios before this
                                SagaErrorHandler.handle(e.networkError, actions);
                            }
                        }}
                        render={props => <LoginForm {...props} loading={loading}/>}
                    />
                </Box>

                <Text margin={{top: 'medium'}} color="lightgray">Forgot your password?
                    <RoutedAnchor path="/request-password-reset" label=' Reset it here.'/>
                </Text>
            </Box>
        </Layout>
    );
};

export default LoginPage;
