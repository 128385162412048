import React from 'react';
import {Box} from "grommet";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {UserCard} from "../../../components/UserCard";


export const UserCardDroppable = ({id, providers, boxProps = {}}) => (
    <Droppable droppableId={id}>
        {(provided, snapshot) => (
            <Box
                height={{min: "xsmall"}}
                flex
                direction="row"
                wrap
                fill
                ref={provided.innerRef}
                align="start"
                {...boxProps}
            >
                {providers.map((session, index) =>
                    <Draggable
                        key={session.id}
                        draggableId={session.id}
                        index={index}
                    >
                        {(provided, snapshot) => (
                            <UserCard
                                ref={provided.innerRef}
                                boxProps={{...provided.draggableProps, ...provided.dragHandleProps, margin: {top: "small"}, flex: false}}
                                id={session.client.user.id}
                                firstName={session.client.user.first_name}
                                lastName={session.client.user.last_name}
                            />
                        )}
                    </Draggable>
                )}
                {provided.placeholder}
            </Box>
        )}
    </Droppable>
);
