import React, {useState} from 'react' ;
import {Box} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import RoutedButton from "../../components/RoutedButton";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import RoutedAnchor from "../../components/RoutedAnchor";
import {DeleteAudienceButton} from "./components/DeleteAudienceButton";
import {Edit} from "grommet-icons";
import {MANAGER} from "../../lib/constants";

const dateFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};

let columns = [
    {
        accessor: 'name',
        Header: 'Name',
        primary: true,
        Cell: ({row: {original: {id, name}}}) => (
            <Box direction='row' align='center' gap='small'>
                <RoutedAnchor
                    path={`/audiences/${id}`}
                    label={name}
                    color="black"/>
            </Box>
        ),
    },
    {
        id: 'campaign_audiences',
        sortable: false,
        Header: 'Campaigns Connected',
        Cell: ({row: {original: {campaign_audiences}}}) => (
            campaign_audiences && campaign_audiences.map(ca => ca.campaign.name).join(', ')
        )
    },
    {
        requiredRole: MANAGER,
        id: 'provider.user.first_name',
        accessor: ({provider}) => `${provider?.user?.first_name} ${provider?.user?.last_name}`,
        Header: 'Created By'
    },
    {
        id: 'created_at',
        accessor: ({created_at}) => new Date(created_at).toLocaleDateString("en-US", dateFormat),
        Header: 'Created On'
    },
    {
        id: "actions",
        sortable: false,
        Header: 'Actions',
        Cell: ({row: {original: {id, locked}}}) => (
            locked ? null :
                <Box direction='row' align='center' gap='small'>
                    <DeleteAudienceButton
                        audienceId={id}
                        queriesToRefetch={['getAudiences']}
                    />
                    <RoutedButton
                        icon={<Edit/>}
                        path={`/audiences/edit/${id}`}
                        color="black"/>
                </Box>
        )
    },
];

const FETCH_DATA = gql`
    query getAudiences($limit: Int! $offset: Int! $orderBy: [audience_order_by!] $filter: audience_bool_exp) {
        data: audience(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            name
            created_at
            provider_id
            locked
            campaign_audiences {
                campaign {
                    id
                    name
                }
            }
            provider {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
        data_aggregate: audience_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const AudiencesPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({
                    _or: [
                        {provider: {user: {first_name: {_ilike: `%${term}%`}}}},
                        {provider: {user: {last_name: {_ilike: `%${term}%`}}}},
                        {name: {_ilike: `%${term}%`}},
                        {campaign_audiences: {campaign: {name: {_ilike: `%${term}%`}}}}
                    ]
                })
            }/>
            <RoutedButton path='/audiences/create' label='New Audience' primary/>
        </Box>
    );
    return (
        <Page title="Audiences" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns}/>
        </Page>
    );
};

export default AudiencesPage
