import React, {useEffect, useState} from 'react';
import MultiSelect from "./input/MultiSelect";
import {Box, Button, Text} from "grommet";
import gql from 'graphql-tag';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import dot from 'dot-object';
import {UserCard} from "./UserCard";
import {SearchField} from "./SearchField";
import {FormDown, FormUp} from 'grommet-icons';
import {SESSION_STATUS} from "../lib/constants";

const FETCH_DATA = gql`
    query {
        id @client
        audience {
            id
            name
            filters {
                id
                filter_value
                operator {
                    id
                    name
                    expression
                }
                operand {
                    id
                    relation
                    options_table
                    type
                    column
                }
            }
        }
    }
`;

const SESSION_FIELDS = `{
        id
        client {
            id
            user {
                id
                first_name
                last_name
            }
        }
    }`;

const fetchSessionQuery = (fields) => gql`
    query ($where: session_bool_exp $filter: session_bool_exp){
        included: session (order_by: {client: {user: {first_name: asc, last_name: asc}}}, where: {_and: [$where, $filter, {status_id: {_eq: ${SESSION_STATUS.ACTIVE}}}]}) ${fields}
        excluded: session (order_by: {client: {user: {first_name: asc, last_name: asc}}}, where: {_and: [{_not: $where}, $filter, {status_id: {_eq: ${SESSION_STATUS.ACTIVE}}}]}) ${fields}
    }`;

const ilike = (session, term) =>
    `${session.client.user.first_name.toLowerCase()} ${session.client.user.last_name.toLowerCase()}`.includes(term);

export const castInput = (type, input) => type === 'int' ? parseInt(input) : input;

const buildWhereClauseAudiences = (audiences) => {
    const filters = audiences.map(audience => audience.filters).flat();
    return {
        _or: filters.map(filter =>
            dot.str(`${filter.operand.relation}.${filter.operand.column}.${filter.operator.expression}`, castInput(filter.operand.type, filter.filter_value), {})
        )
    };
};
// TODO: Needs to be optimized as there are unnecessary rerenders
export const SelectClientsByAudiences = ({onChange = () => {}, fields = SESSION_FIELDS, staticFilter, collapsible = false, maxNumOfClients, additionalFilter, fetchPolicy = 'cache-and-network', boxProps = {}}) => {
    const {data = {}, loading, error} = useQuery(FETCH_DATA);
    const [getSessions, {data: sessions}] = useLazyQuery(fetchSessionQuery(fields), {fetchPolicy}); // TODO: Handle loading and error
    const [audiences, setAudiences] = useState([]);
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [remainingSessions, setRemainingSessions] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [warningMessage, setWarningMessage] = useState();

    useEffect(() => {
            getSessions({variables: {where: buildWhereClauseAudiences(audiences), filter: staticFilter}});
    }, [audiences, getSessions, staticFilter]);

    useEffect(() => {
        additionalFilter && getSessions({variables: {
            where: additionalFilter,
            filter: staticFilter
        }})
    }, [additionalFilter, staticFilter, getSessions]);

    useEffect(() => {
        if (maxNumOfClients && selectedSessions.length > maxNumOfClients) {
            setWarningMessage('You can select at most ' + maxNumOfClients + ' clients.');
            setRemainingSessions([...remainingSessions, ...selectedSessions.slice(maxNumOfClients)]);
            setSelectedSessions(selectedSessions.slice(0, maxNumOfClients));
        } else {
            onChange(selectedSessions);
        }
    }, [onChange, selectedSessions, remainingSessions, maxNumOfClients]);

    useEffect(() => {
        if (!sessions) {
            return;
        }
        setSelectedSessions(sessions.included || []);
        setRemainingSessions(sessions.excluded || []);
    }, [sessions, additionalFilter]);

    if (loading) {
        return 'Loading...';
    }
    if (error) {
        console.error('err', error);
        return 'Error';
    }

    return (
        <Box {...boxProps}>
            {collapsible && collapsed &&
            <Box align='end'><Button><FormDown onClick={() => setCollapsed(false)}/></Button></Box>
            }
            {collapsible && !collapsed &&
            <Box align='end'><Button><FormUp onClick={() => setCollapsed(true)}/></Button></Box>
            }
            <Box gap="medium">
                {(!collapsible || !collapsed) &&
                <Box gap="small" flex={false}>
                    <Text color="gray">Select Audiences</Text>
                    <MultiSelect options={data.audience} handleChange={setAudiences}/>
                </Box>
                }
                <Box gap="small" flex='grow'>
                    <Box direction='row' gap="small">
                        <Text color="gray">Selected Clients</Text>
                        <Text color="orange">{warningMessage}</Text>
                    </Box>
                    <Box
                        height={{min: "xsmall"}}
                        direction="row"
                        wrap
                        fill
                        gap="small"
                        pad={{horizontal: "small", bottom: "small"}}
                        align="start"
                        background="light-1"
                        round="xsmall"
                    >
                        {selectedSessions.map(session => <UserCard
                            key={session.id}
                            boxProps={{style: {cursor: "pointer"}, margin: {top: "small"}}}
                            id={session.client.user.id}
                            firstName={session.client.user.first_name}
                            lastName={session.client.user.last_name}
                            onClick={() => {
                                const index = selectedSessions.findIndex(s => s.id === session.id);
                                const deselectedSession = {...selectedSessions[index]};
                                setSelectedSessions(selectedSessions.filter((_, i) => i !== index));
                                setRemainingSessions([...remainingSessions, deselectedSession]);
                            }}
                        />)}
                    </Box>
                </Box>
                {(!collapsible || !collapsed) &&
                <Box gap="small" flex='grow'>
                    <Text color="gray">Remaining Clients</Text>
                    <Box
                        height={{min: "xsmall"}}
                        pad="small"
                        fill
                        background="light-1"
                        round="xsmall"
                    >
                        <SearchField containerProps={{fill: 'horizontal', flex: 'grow'}}
                                     onChange={(term) => {
                                         const excluded = sessions.excluded || [];
                                         const remaining = term === '' ?
                                             excluded :
                                             excluded.filter(s => ilike(s, term));
                                         setRemainingSessions(remaining);
                                     }}
                        />
                        <Box direction="row" wrap
                             fill gap="small"
                             align="start">
                            {remainingSessions.map(session => <UserCard
                                key={session.id}
                                boxProps={{style: {cursor: "pointer"}, margin: {top: 'small'}}}
                                id={session.client.user.id}
                                firstName={session.client.user.first_name}
                                lastName={session.client.user.last_name}
                                onClick={() => {
                                    const index = remainingSessions.findIndex(s => s.id === session.id);
                                    const selectedSession = {...remainingSessions[index]};
                                    setRemainingSessions(remainingSessions.filter((_, i) => i !== index));
                                    setSelectedSessions([...selectedSessions, selectedSession]);
                                }}
                            />)}
                        </Box>
                    </Box>
                </Box>
                }
            </Box>
        </Box>
    );
};