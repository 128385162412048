import React, {useEffect, useMemo, useRef} from 'react';
import Survey from '../../components/survey/Survey';
import {Box} from "grommet";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/react-hooks";
import Page from "../../components/Page";
import {IndividualReportActionBar} from "../../components/survey/IndividualReportActionBar";
import {IndividualSurveyReportPrintPage} from "./IndividualSurveyReportPrintPage";
import {useHistory} from "react-router-dom";
import Spinner from "../../components/Spinner";

const FETCH_DATA = gql`
    query ($id: Int!) {
        id @client
        survey: survey_result_by_pk(id: $id) {
            id
            result
            status {
                name
            }
            updated_at
            created_at
            session {
                id
                client {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
                provider_session {
                    provider_id
                    twilio_channel_sid
                }
            }
            template: survey_template {
                id
                questions
                title
                subtitle
            }
        }
    }
`;

const SurveyPage = ({match}) => {
    const printRef = useRef();
    const history = useHistory();
    const id = (match.params && match.params.id) || 0;
    const {data = {}, loading, error} = useQuery(FETCH_DATA, {
        variables: {id},
        fetchPolicy: "no-cache" // This is a big query, so don't store it in cache
    });
    const survey = useMemo(() => data.survey, [data]);

    useEffect(() => {
        if (!loading && !survey) {
            history.push('/not-found');
        }
    }, [loading, survey, history]);

    if (loading || !survey) {
        return <Spinner/>;
    }
    if (error) {
        console.error(error);
        return 'Error loading the page';
    }

    return (
        <Page title='Report Results'>
            <Box gap='medium'>
                <IndividualReportActionBar survey={survey} printRef={printRef}
                                           canMessage={survey.session.provider_session && survey.session.provider_session.provider_id === data.id}
                />
                <Box background='divider' elevation='xsmall' round="xsmall" fill pad="large">
                    <Survey
                        disableInput
                        template={survey.template}
                        answers={Array.isArray(survey.result) ? {} : survey.result}
                        onSubmit={() => {}}
                    />
                </Box>
            </Box>
            <IndividualSurveyReportPrintPage ref={printRef} survey={survey}/>
        </Page>
    )
};

export default SurveyPage
