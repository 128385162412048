import React from 'react';
import {Box, FormField, Text, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";

export const TextInputField = ({id, title, placeholder, handleChange, value, error, disabled, preview}) => {
    return (
        <FormField label={title} error={error}>
            {preview ? <Box pad='small' border round='xsmall'><Text>{value}</Text></Box> : <TextInput disabled={disabled} name={id} placeholder={placeholder} value={value} onChange={handleChange}/>}
        </FormField>
    )
};

TextInputField.defaultSize = {
    w: 2,
    h: 2,
    minW: 2,
    minH: 2,
    maxH: 2
};

TextInputField.title = 'Text';

const TextInputSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
    placeholder: Yup.string()
});

export const TextInputFieldProperties = ({title, placeholder, updateField, id, item}) => {
    console.log('prop', title, placeholder, id, item);
    return (

    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => {
            updateField(id, values)
        }}
        initialValues={{title, placeholder}}
        validationSchema={TextInputSchema}
        enableReinitialize
        onSubmit={() => {
        }}
    >
        {({errors, values, handleChange}) => (
            <Form>
                <FormField label="Title" error={errors.title}>
                    <TextInput
                        name="title"
                        value={values.title || ""}
                        onChange={handleChange}
                    />
                </FormField>

                <FormField label="Placeholder" error={errors.placeholder}>
                    <TextInput
                        name="placeholder"
                        value={values.placeholder || ""}
                        onChange={handleChange}
                    />
                </FormField>
            </Form>
        )
        }
    </Formik>
)}