import React from 'react';
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {Box, Select, Spinner, Text} from "grommet";
import {useHistory, useParams} from "react-router-dom";


const FETCH_SESSIONS = gql`
    query ($id: Int!) {
        sessions: session(where: {client_id: {_eq: $id}} order_by: {created_at: desc}) {
            id
            mr_number
            discharge_date
        }
    }
`;

export const SessionSelect = () => {
    const params = useParams();
    const history = useHistory();
    const {data, loading} = useQuery(FETCH_SESSIONS, {variables: {id: params.id}, fetchPolicy: 'no-cache'});

    if (loading) {
        return <Spinner/>
    }

    return (
        <Box gap='xsmall'>
            <Text weight='bold'>Episode</Text>
            <Select
                options={data.sessions}
                value={parseInt(params.session_id)}
                labelKey={(option) => `${option.mr_number} ${!option.discharge_date ? '(Active)' : ''}`}
                valueKey={{key: 'id', reduce: true}}
                onChange={({value}) => history.push(`/clients/${params.id}/${value}`)}
            />
        </Box>
    )
}