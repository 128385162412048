import React, {useState} from 'react';
import {Accordion, AccordionPanel, Box, Button, FormField, Select, Text, TextInput} from "grommet";
import {FieldArray, Form} from "formik";
import {DateInputPicker} from "../../Communication/components/DateInputPicker";
import {Close, Task} from "grommet-icons";
import {EMPTY_INTERVENTION, EMPTY_OBJECTIVE, EMPTY_PROBLEM} from "../CreateTreatmentPlanPage";
import TxItemOrdinalNameGenerator from "../utils/TxItemOrdinalNameGenerator";
import TagInput from "../../../components/input/TagInput";
import HorizontalDivider from "../../../components/HorizontalDivider";
import {TagSelector} from "./TagSelector";

const TreatmentPlanForm = ({
                               values,
                               handleChange, setFieldValue,
                               interventionStatusOptions, interventionMeasurementsOptions,
                               disabled=false
                           }) => {

    //TODO: This is a hack to display all problems, interventions and objectives by assuming that each of them would
    // have less than 100 items. In order to resolve this issue each of the accordion render methods should have its
    // own component to manage its state separate from the others
    const manyIds = [...Array(100).keys()]
    const [problemActiveIndex, setProblemActiveIndex] = useState(manyIds);
    const [interventionActiveIndex, setInterventionActiveIndex] = useState(manyIds);
    const [objectiveActiveIndex, setObjectiveActiveIndex] = useState(manyIds);
    const ordinalNameGenerator = new TxItemOrdinalNameGenerator()
    const renderAccordionHeader = (label, created_at, bgColor, removeItem, disabled=false) => {

        const created_at_prettified = new Date(created_at).toJSON().slice(0, 10).replace(/-/g, '-');

        return (
            <Box direction='row'
                 align='center'
                 round='small'
                 background={bgColor}
                 justify='between'
                 pad={{vertical: '15px', horizontal: '25px'}}
            >
                <Box justify='start' direction='row' gap='small'>
                    <Task color='white'/>
                    <Text color='white'> {label} </Text>
                </Box>

                <Box justify='end' direction='row' gap='small'>
                    <Text color='white'> Created on {created_at_prettified} </Text>
                    {!disabled && <Box onClick={removeItem}><Close color='white'/></Box>}
                </Box>
            </Box>
        )
    }

    function insertNewItem(array, insertAtIndex, itemTemplate, setActiveIndex) {
        array.insert(insertAtIndex, {...itemTemplate, order: insertAtIndex})
        setActiveIndex(activeIndex => [...activeIndex, insertAtIndex])
    }

    function removeItem(array, removeAtIndex, setActiveIndex) {
        array.remove(removeAtIndex)
        // setActiveIndex(activeIndex => [...activeIndex.filter(index => index !== removeAtIndex)])
    }

    function renderIntervention(problemIndex, objectiveIndex, intervention, index, arrayHelpers) {
        const interventionOrdinalName = ordinalNameGenerator.getForIntervention(problemIndex, objectiveIndex, index)
        const interventionLabel = `${interventionOrdinalName} Intervention`

        const current = `problems[${problemIndex}].objectives[${objectiveIndex}].interventions[${index}]`

        return (
            <AccordionPanel key={`p-${problemIndex}_o-${objectiveIndex}_i-${index}`}
                            header={renderAccordionHeader(interventionLabel,
                                intervention.created_at,
                                '#79D7D3',
                                () => removeItem(arrayHelpers, index, setInterventionActiveIndex),
                                disabled)
                            }>
                <Box gap='medium' margin='medium'>

                    <FormField label={interventionLabel}>
                        <TextInput name={`${current}.description`}
                                   value={intervention.description}
                                   onChange={handleChange}
                                   disabled={disabled}
                        />
                    </FormField>

                    <Box direction='row' gap='medium' fill='horizontal' flex='grow'>

                        <FormField label='Creation Date'>
                            <DateInputPicker name={`${current}.created_at`}
                                             value={intervention.created_at}
                                             disabled={disabled}
                                             onSelect={(selectedDate) => setFieldValue(`${current}.created_at`, selectedDate)}
                            />
                        </FormField>

                        <FormField label='Target Date'>
                            <DateInputPicker name={`${current}.target_date`}
                                             value={intervention.target_date}
                                             disabled={disabled}
                                             onSelect={(selectedDate) => setFieldValue(`${current}.target_date`, selectedDate)}
                            />
                        </FormField>
                        <FormField label='Resolved Date'>
                            <DateInputPicker name={`${current}.resolved_date`}
                                             value={intervention.resolved_date}
                                             disabled={disabled}
                                             onSelect={(selectedDate) => setFieldValue(`${current}.resolved_date`, selectedDate)}
                            />
                        </FormField>

                        <FormField label='Status'>
                            <Select
                                labelKey="name"
                                valueKey="id"
                                options={interventionStatusOptions || []}
                                value={intervention.status}
                                disabled={disabled}
                                onChange={({option}) => setFieldValue(`${current}.status`, option)}
                            />
                        </FormField>
                    </Box>

                    <Box fill='horizontal' flex='grow'>
                        <FormField label='Measurements'>
                            <TagSelector placeholder="Select Measurement Surveys"
                                         defaultOptions={interventionMeasurementsOptions}
                                         preselectedTags={intervention.measurements}
                                         onChange={(options) => setFieldValue(`${current}.measurements`, options)}
                                         disabled={disabled}
                            />
                        </FormField>
                    </Box>

                </Box>
            </AccordionPanel>
        )
    }

    const renderObjective = (problemIndex, objective, index, arrayHelpers) => {
        const objectiveOrdinalName = ordinalNameGenerator.getForObjective(problemIndex, index)
        const objectiveLabel = `${objectiveOrdinalName} Objective`
        const current = `problems[${problemIndex}].objectives[${index}]`
        return (

            <AccordionPanel key={`p-${problemIndex}_o-${index}`}
                            header={renderAccordionHeader(objectiveLabel,
                                objective.created_at,
                                '#63C890',
                                () => removeItem(arrayHelpers, index, setObjectiveActiveIndex),
                                disabled)}>
                <Box gap='small' pad='medium'>
                    <FormField label={objectiveLabel}>
                        <TextInput name={`${current}.description`}
                                   value={objective.description}
                                   onChange={handleChange}
                                   disabled={disabled}
                        />
                    </FormField>

                    <FieldArray name={`${current}.interventions`}>
                        {(interventionArrayHelpers) => (
                            <Box align='start' gap='medium'>
                                <Accordion animate fill
                                           multiple
                                           activeIndex={interventionActiveIndex}
                                >
                                    {objective.interventions.map((intervention, interventionIndex) => renderIntervention(problemIndex, index, intervention, interventionIndex, interventionArrayHelpers))}
                                </Accordion>
                                <Box pad={{horizontal: 'medium'}}>
                                    <Button label='Add Next Intervention'
                                            disabled={disabled}
                                            onClick={() => insertNewItem(interventionArrayHelpers, objective.interventions.length, EMPTY_INTERVENTION, setInterventionActiveIndex)}/>
                                </Box>
                            </Box>
                        )}
                    </FieldArray>
                </Box>

            </AccordionPanel>
        );
    }

    const renderProblem = (problem, index, arrayHelpers) => {
        const problemKey = `p-${index}`
        const problemOrdinalName = `${ordinalNameGenerator.getForProblem(index)} `
        const current = `problems[${index}]`

        const onRemoveTag = (tagsSoFar, tagToRemove, removeFromPath) => {
            const removeIndex = tagsSoFar.indexOf(tagToRemove);
            const newTags = [...tagsSoFar];
            if (removeIndex >= 0) {
                newTags.splice(removeIndex, 1);
            }
            setFieldValue(removeFromPath, newTags)
        };

        const onAddTag = (tagsSoFar, newTag, insertIntoPath) => {

            const updatedTags = [...tagsSoFar, newTag];
            setFieldValue(insertIntoPath, updatedTags);
        }

        return (
            <AccordionPanel key={problemKey}
                            header={renderAccordionHeader(`${problemOrdinalName} Problem / Priority`,
                                problem.created_at,
                                '#4B77F6',
                                () => removeItem(arrayHelpers, index, setProblemActiveIndex),
                                disabled)}>
                <Box gap='small' background='dirtywhite' pad='medium'>
                    <FormField label="Problem"
                        // error={errors.problems[index]?.name}
                    >
                        <TextInput name={`${current}.name`}
                                   value={problem.name}
                                   disabled={disabled}
                                   onChange={handleChange}/>
                    </FormField>
                    <HorizontalDivider/>
                    <FormField label='Related to and Evidenced by'>
                        <TagInput suggestions={[]}
                                  name={`${current}.related_to_and_evidenced_by`}
                                  value={problem.related_to_and_evidenced_by}
                                  onAdd={(tag) => onAddTag(problem.related_to_and_evidenced_by, tag, `${current}.related_to_and_evidenced_by`)}
                                  onRemove={(tag) => onRemoveTag(problem.related_to_and_evidenced_by, tag, `${current}.related_to_and_evidenced_by`)}
                                  onChange={() => {
                                  }}
                                  disabled={disabled}
                        />
                    </FormField>

                    <FormField label='Goals'>
                        <TagInput suggestions={[]}
                                  name={`${current}.goals`}
                                  value={problem.goals}
                                  onAdd={(tag) => onAddTag(problem.goals, tag, `${current}.goals`)}
                                  onRemove={(tag) => onRemoveTag(problem.goals, tag, `${current}.goals`)}
                                  onChange={() => {
                                  }}
                                  disabled={disabled}
                        />
                    </FormField>

                    <FieldArray name={`${current}.objectives`}>
                        {(objectiveArrayHelpers) => (
                            <Box align='start' gap='medium'>
                                <Accordion animate multiple fill
                                           activeIndex={objectiveActiveIndex}
                                >
                                    {problem.objectives.map((objective, objectiveIndex) => renderObjective(index, objective, objectiveIndex, objectiveArrayHelpers))}
                                </Accordion>
                                {/*TODO: Handle order by createNewObjective*/}
                                <Box pad={{horizontal: 'medium'}}>
                                    <Button label='Add Next Objective'
                                            disabled={disabled}
                                            onClick={() => insertNewItem(objectiveArrayHelpers, problem.objectives.length, EMPTY_OBJECTIVE, setObjectiveActiveIndex)}/>
                                </Box>
                            </Box>
                        )}

                    </FieldArray>
                </Box>

            </AccordionPanel>
        );
    }

    return (
        <Form>
            <Box flex="grow"
                 elevation='xsmall'
                 fill='horizontal'
                 background='white'
                 round="xsmall"
                 pad='20px 15px'
                 margin={{bottom: '20px'}}
            >
                <FieldArray name='problems'>
                    {(problemsArrayHelpers) => (
                        <Box align='start' gap='medium' background='dirtywhite'>
                            <Accordion fill
                                       animate
                                       multiple
                                       activeIndex={problemActiveIndex}
                            >
                                {values.problems.map((problem, index) => renderProblem(problem, index, problemsArrayHelpers))}
                            </Accordion>
                            <Box pad={{horizontal: 'medium', bottom: 'medium'}}>
                                <Button label='Add Next Problem'
                                        disabled={disabled}
                                        onClick={() => insertNewItem(problemsArrayHelpers, values.problems.length, EMPTY_PROBLEM, setProblemActiveIndex)}/>
                            </Box>
                        </Box>
                    )}

                </FieldArray>

            </Box>
        </Form>
    );
}

export default TreatmentPlanForm;
