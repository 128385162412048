import React from 'react'
import {colors} from "../../theme";

const StaffImport = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 38 41" {...rest}>
        <title>{'staff import'}</title>
        <g fill="none" fillRule="evenodd">
            <path
                d="M34.438 5.6c.99 0 1.83.346 2.523 1.04C37.654 7.331 38 8.172 38 9.161c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.835c0-.321-.118-.6-.353-.834a1.141 1.141 0 00-.834-.353H25v-2c0-.128-.024-.253-.071-.375h9.509zM13 5.975v2H3.562c-.321 0-.6.118-.834.353a1.141 1.141 0 00-.353.835c0 .321-.118.6-.353.834a1.141 1.141 0 01-.834.353c-.322 0-.6-.118-.835-.353A1.141 1.141 0 010 9.162c0-.99.346-1.83 1.04-2.523C1.731 5.946 2.572 5.6 3.561 5.6h9.509a1.024 1.024 0 00-.071.375z"
                fill={active ? colors.darkblue : colors.sidebarInactive}
                fillRule="nonzero"
            />
            <path
                d="M19 7c1.311 0 2.43.464 3.358 1.392.928.927 1.392 2.047 1.392 3.358h10.688c.99 0 1.83.346 2.523 1.04.693.692 1.039 1.533 1.039 2.523v21.374c0 .99-.346 1.831-1.04 2.524-.692.693-1.533 1.039-2.523 1.039H3.564c-.99 0-1.831-.346-2.524-1.04C.346 38.519 0 37.678 0 36.688V15.314c0-.99.346-1.831 1.04-2.524.692-.693 1.533-1.039 2.522-1.039H14.25c0-1.311.464-2.43 1.392-3.358C16.569 7.464 17.689 7 19 7zm-4.75 7.125H3.562c-.321 0-.6.118-.834.353a1.141 1.141 0 00-.353.835v21.374c0 .322.118.6.353.835s.513.353.834.353h30.875c.322 0 .6-.118.835-.353s.353-.513.353-.834V15.311c0-.321-.118-.6-.353-.834a1.141 1.141 0 00-.834-.353H23.75V16.5c0 .643-.235 1.2-.705 1.67s-1.027.705-1.67.705h-4.75c-.643 0-1.2-.235-1.67-.705s-.705-1.027-.705-1.67v-2.375zM19 9.375c-.643 0-1.2.235-1.67.705s-.705 1.027-.705 1.67v4.75h4.75v-4.75c0-.643-.235-1.2-.705-1.67s-1.027-.705-1.67-.705z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <g fill={active ? colors.green : colors.sidebarInactive}>
                <path d="M22.563 26c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.834c0-.322.118-.6.353-.835s.513-.353.834-.353h7.125c.322 0 .6.118.835.353s.353.513.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353h-7.125zM22.563 30.75c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.835c0-.321.118-.6.353-.834.235-.235.513-.353.834-.353h9.5c.322 0 .6.118.835.353s.353.513.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353h-9.5zM12.117 20c1.485 0 2.746.52 3.785 1.559 1.04 1.039 1.559 2.3 1.559 3.785 0 .99-.247 1.892-.742 2.709a5.238 5.238 0 01-1.93 1.892c.94.347 1.775.841 2.505 1.485a8.218 8.218 0 011.8 2.263c.074.149.123.297.148.446a.914.914 0 01-.037.445c-.05.148-.124.284-.223.408a1.095 1.095 0 01-.37.297.825.825 0 01-.26.111 1.22 1.22 0 01-.297.038c-.223 0-.427-.056-.613-.167a1.105 1.105 0 01-.426-.464 5.578 5.578 0 00-1.373-1.67 5.674 5.674 0 00-1.893-1.04 6.373 6.373 0 00-.816-.166 6.036 6.036 0 00-.817-.056c-.445 0-.89.056-1.336.167a5.07 5.07 0 00-1.261.501 5.03 5.03 0 00-1.318.946 5.81 5.81 0 00-.983 1.318 1.105 1.105 0 01-.427.464 1.22 1.22 0 01-.91.13.825.825 0 01-.259-.112 1.06 1.06 0 01-.575-.686 1.228 1.228 0 01.093-.91 7.937 7.937 0 011.391-1.874 7.937 7.937 0 011.874-1.391c.173-.1.347-.186.52-.26l.52-.223a5.238 5.238 0 01-1.93-1.892 5.126 5.126 0 01-.743-2.71c0-1.484.52-2.745 1.559-3.784 1.04-1.04 2.3-1.559 3.785-1.559zm0 2.375c-.816 0-1.515.29-2.096.872a2.859 2.859 0 00-.873 2.097c0 .816.291 1.515.873 2.096.58.582 1.28.873 2.096.873.817 0 1.515-.291 2.097-.873.581-.581.872-1.28.872-2.096 0-.817-.29-1.516-.872-2.097a2.859 2.859 0 00-2.097-.872z" />
            </g>
            <path
                d="M19 0c1.625 0 3.031.528 4.219 1.583 1.113.99 1.704 2.15 1.774 3.482l.007.268V8h-2V6.333c0-1.173-.396-2.189-1.188-3.047C21.021 2.43 20.084 2 19 2c-1.083 0-2.02.429-2.813 1.286C15.396 4.144 15 5.16 15 6.333V8h-2V5.333c0-1.444.594-2.694 1.781-3.75C15.97.528 17.375 0 19 0z"
                fill={active ? colors.darkblue : colors.sidebarInactive}
                fillRule="nonzero"
            />
        </g>
    </svg>
)

export default StaffImport
