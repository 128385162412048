import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Page from "../../components/Page";
import React from "react";
import Spinner from "../../components/Spinner";
import {transform} from 'json-to-json-transformer';
import {Box} from "grommet";
import {ErrorMessage, Form, Formik} from "formik";
import CampaignForm from "./components/CampaignForm";
import {ADDITIONAL_CAMPAIGN_FRAGMENT, CAMPAIGN_BY_ID_FRAGMENT} from "./constants/queries";
import {CampaignValidationSchema} from "./constants/validationSchemas";
import {transformerTemplate} from "./constants/dataTransormations";


const FETCH_DATA = gql`
    query fetchCampaignById($campaign_id: Int!) {
        ...campaign_fields
        ...additional_fields
    }
    ${CAMPAIGN_BY_ID_FRAGMENT}
    ${ADDITIONAL_CAMPAIGN_FRAGMENT}
`;

const UPDATE_CAMPAIGN = gql`
    mutation ($campaign_id: Int! $name: String! $is_enabled: Boolean!){
        update_campaign(where: {id: {_eq: $campaign_id}}, _set: {
            name: $name,
            is_enabled: $is_enabled
        }) {
            affected_rows
        }
    } 
`;

const DELETE_CAMPAIGN = gql`
    mutation DeleteCampaign($campaign_id: Int!) {
        delete_campaign(where: {id: {_eq: $campaign_id}}) {
            affected_rows
        }
    }`;

const CampaignPage = ({match,history}) => {
    const id = (match.params && match.params.id) || 0;

    const {data, loading} = useQuery(FETCH_DATA, {variables: {campaign_id: id},fetchPolicy: 'cache-and-network'});
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
        refetchQueries: ['fetchCampaigns']
    });
    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {refetchQueries: ['fetchCampaigns']});

    if (loading) {
        return <Spinner full/>
    }

    let campaignData = transform(transformerTemplate, data.campaign);

    return (
        <Page title={`${data.campaign.name} Campaign`}>
            <Formik
                initialValues={campaignData}
                validationSchema={CampaignValidationSchema}
                validateOnChange={false}
                onSubmit={async ({id, name, is_enabled}, actions) => {
                    const variables = {
                        campaign_id: id,
                        name,
                        is_enabled
                    };
                    await updateCampaign({
                        variables
                    });
                    history.push('/campaigns')
                }}
                render={({errors, values, handleChange, setFieldValue, setFieldError}) => (
                    <Box>
                        <ErrorMessage name='globalError'/>
                        <Form>
                            <CampaignForm
                                values={values}
                                errors={errors}
                                setFieldError={setFieldError}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                audienceOptions={data.audience}
                                surveyOptions={data.survey_template}
                                measurementSurveyOptions={data.measurement_survey_template}
                                eventTypeOptions={data.event_type}
                                actionTypeOptions={data.action_type}
                                eventsChangeOfFieldOperands={data.trigger_operand}
                                eventsChangeOfFieldOperators={data.operator}
                                viewMode={true}
                                preselectedAudiences={campaignData.audiences}
                                onDelete={async (id) => {
                                    await deleteCampaign({variables: {campaign_id: id}});
                                    history.push('/campaigns')
                                }}
                                campaignId={id}
                            />
                        </Form>
                    </Box>
                )}
            />
        </Page>
    )
};

export default CampaignPage
