import React from 'react';
import {render} from 'react-dom';
import browserHistory from "./browserHistory";

import * as serviceWorker from './serviceWorker';
import './index.css';

import App from './App';
import {ApolloProvider} from '@apollo/react-hooks'
import {AUTH_TOKEN_KEY, loadState, REFRESH_TOKEN, removeState, saveState} from "./localStorage";
import {setContext} from "apollo-link-context";
import {HttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import {getField, initialState, resolvers, typeDefs} from "./services/resolvers";
import {WebSocketLink} from "apollo-link-ws";
import {getMainDefinition} from 'apollo-utilities';
import {ApolloLink} from "apollo-link";
import API from "./services/api";
import * as Sentry from '@sentry/browser';
import {onError} from "apollo-link-error";
import {updateServiceWorker} from "./serviceWorker";
import {GraphQLError} from "graphql";
import ErrorBoundary from "./ErrorBoundary";

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({dsn: "https://f5d22aab19a84f87a042783702686afc@o370214.ingest.sentry.io/5185382"});
}

const token = loadState(AUTH_TOKEN_KEY);
if (token && getField('exp', token) < Date.now() / 1000) {
    removeState(AUTH_TOKEN_KEY);
    removeState(REFRESH_TOKEN);
}

// TODO: The whole apollo client initialization section needs to be extracted to another file.
let apolloClient = null;

const getToken = () => {
    let token = null;
    const jwt = loadState(AUTH_TOKEN_KEY);
    if (jwt) {
        token = 'Bearer ' + jwt;
    }
    return token
};

const logout = () => {
    removeState(AUTH_TOKEN_KEY);
    removeState(REFRESH_TOKEN);
    window.Intercom('shutdown');
    apolloClient.cache.writeData({data: {isAuthenticated: false}});
    browserHistory.push('/login');
};

const url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_GRAPHQL_URL : process.env.REACT_APP_PROD_GRAPHQL_URL;
const httpLink = new HttpLink({
    uri: url, // Server URL (must be absolute)
    credentials: 'same-origin' // Additional fetch() options like `credentials` or `headers`
});
const wsLink = new WebSocketLink({
    uri: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_WS_GRAPHQL_URL : process.env.REACT_APP_PROD_WS_GRAPHQL_URL,
    options: {
        connectionCallback: (error) => {
            // TODO: There is a bug with the current implementation
            //  if you put the tab in the background just before the jwt token expires,
            //  it will sign you out.

            // TODO: But even if we capture the user last activity timestamp and use it here,
            //  we may have the situation where the user was active 29 mins ago,
            //  a new token is generated and it is valid for another 30 mins, which is wrong
            if (error && error.includes('JWTExpired')) {
                if (document.visibilityState === 'hidden') {
                    return logout();
                }
                API.post('site/refresh-token', {
                    token: loadState(REFRESH_TOKEN)
                }).then(r => {
                    saveState(AUTH_TOKEN_KEY, r.data.jwtKey);
                    refreshSubscription();
                });
            }
        },
        reconnect: true,
        connectionParams: () => {
            const token = getToken();
            return token ? ({
                headers: {
                    Authorization: token
                }
            }) : ({})
        }
    }
});

export const refreshSubscription = () => {
    // refresh subscription whenever needed
    wsLink.subscriptionClient.close(false, false);
};

export const closeSubscription = () => {
    wsLink.subscriptionClient.close();
};

const link = ApolloLink.split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

function create(initialState) {
    const authMiddleware = setContext((request, {headers}) => {
        return {
            headers: {
                ...headers,
                authorization: getToken(),
            },
        }
    });

    const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
            if (graphQLErrors) {

                if (graphQLErrors.some(({message}) => message && message.includes('JWTExpired'))) {
                    logout();
                } else {
                    graphQLErrors.forEach(({message}) => {
                        Sentry.captureException(new GraphQLError(message));
                    });

                    browserHistory.push('/error');
                }
            }
            // After updating Hasura, whenever the websocket connection dies, it throws a network exception to show that
            // the jwt token has expired as well therefore skip it here.
            if (networkError && !networkError.message?.includes('JWTExpired')) {
                console.log(`[Network error]:`, networkError);
                Sentry.captureException(networkError);
                browserHistory.push("/error", {errorMessage: networkError.message});
            }
        }
    );

    const cache = new InMemoryCache();
    // TODO: Initialize this after the router provider and based on error code render GeneralErrorPage directly
    return new ApolloClient({
        connectToDevTools: true,
        link: errorLink.concat(authMiddleware.concat(link)),
        cache,
        // TODO: There is a bug in apollo, when fetchPolicy is network-only
        // defaultOptions: {
        //     query: {
        //         fetchPolicy: 'network-only',
        //         errorPolicy: 'all',
        //     },
        //     watchQuery: {
        //         fetchPolicy: 'network-only',
        //         errorPolicy: 'ignore',
        //     },
        // },
        typeDefs,
        resolvers
    });
}

apolloClient = create(initialState);

render(
    <ApolloProvider client={apolloClient}>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({onUpdate: updateServiceWorker});
