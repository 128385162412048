import React, {useState} from 'react';
import {Box} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import RoutedAnchor from "../../components/RoutedAnchor";
import {DeleteButton} from "../../components/DeleteButton";
import {PlusButton} from "../../components/PlusButton";
import RoutedButton from "../../components/RoutedButton";

const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const DELETE_MUTATION = gql`
    mutation($id:Int!) {
        delete_billing_code(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

const FETCH_DATA = gql`
    query fetch_billing_codes($limit: Int! $offset: Int! $orderBy: [billing_code_order_by!] $filter: billing_code_bool_exp) {
        data: billing_code(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            name
            description
            cpt_code
            amount
        }
        data_aggregate: billing_code_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        Cell: ({row: {original: {id, name,}}}) => (
            <Box direction='row' align='center' gap='small'>
                <RoutedAnchor
                    path={`/billing-codes/${id}`}
                    label={name}
                    color="black"/>
            </Box>
        ),

    },
    {
        accessor: 'cpt_code',
        Header: 'CPT Code',
    },
    {
        accessor: 'amount',
        Header: 'Amount',
        unit: 'USD',
        Cell: ({row: {original: {amount}}}) => numberFormatter.format(amount),
    },
    {
        id: "actions",
        sortable: false,
        Header: 'Actions',
        Cell: ({row: {original: {id}}}) => (
            <DeleteButton
                query={DELETE_MUTATION}
                text='Are you sure you want to delete this billing code?'
                variables={{id}}
                queriesToRefetch={['fetch_billing_codes']}
            />
        )
    },
];

const BillingCodesPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small' align='center'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({name: {_ilike: `%${term}%`}})
            }/>
            <RoutedButton path='/billing-codes/create'>
                <PlusButton/></RoutedButton>
        </Box>
    );
    return (
        <Page title="Billing Codes" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns}/>
        </Page>
    );
};

export default BillingCodesPage;
