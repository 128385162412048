import React from 'react';
import {Box} from "grommet";
import moment from "moment";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Page from "../../components/Page";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import Spinner from "../../components/Spinner";
import RoutedButton from "../../components/RoutedButton";
import {MeetingTypeTags} from "../../lib/constants";
import {Legend} from "./components/Legend";
import {PlusButton} from "../../components/PlusButton";
const localizer = momentLocalizer(moment);

const FETCH_MEETINGS = gql`
    query {
        meeting {
            id
            name
            start_at
            end_at
            description
            type_id
            meeting_participants {
                session {
                    client {
                        id
                        user {
                            id
                            first_name
                            last_name
                        }
                    }
                }
            }
        }
    }
`;

const SchedulingPage = () => {
    const {data, loading} = useQuery(FETCH_MEETINGS, {fetchPolicy: 'no-cache'});
    return (
        <Page title='Scheduling' boxProps={{overflow: 'scroll'}}
              header={<RoutedButton path='/meetings/create'>
                  <PlusButton/></RoutedButton>}>
            {loading ? <Spinner fill/> :
                <Box pad='medium' background='white' elevation='xsmall' round='xsmall' margin={{bottom: 'large'}}>
                    <Calendar
                        localizer={localizer}
                        defaultDate={new Date()}
                        defaultView={Views.WEEK}
                        events={data.meeting.map(meeting => ({
                            start: moment(meeting.start_at).toDate(),
                            end: moment(meeting.end_at).toDate(),
                            title: meeting.name,
                            type_id: meeting.type_id
                        }))}
                        eventPropGetter={(event) => ({style: {background: MeetingTypeTags[event.type_id].color}})}
                    />
                </Box>}
            <Legend/>
        </Page>
    )
}

export default SchedulingPage;