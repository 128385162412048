import React from 'react';
import {Box, Text} from "grommet";
import RoutedButton from "../components/RoutedButton";
import NoMatch from "../components/icons/NoMatch";

export const NoMatchPage = () => (
    <Box fill align="center" justify='center' gap='small'>
        <Box height='medium'>
            <NoMatch/>
        </Box>
        <Text>Page not found.</Text>
        <Text color="sidebarInactive">Go back to the Dashboard.</Text>
        <RoutedButton margin={{top: "small"}} label="Go to Dashboard" path='/'/>
    </Box>
);
