import React, {createContext, useCallback, useContext, useState} from 'react';

import {VideoProvider} from "./VideoProvider";

export const VideoMeetingContext = createContext();

export function VideoMeetingProvider({children}) {
    const [meeting, setMeeting] = useState(undefined);

    const onDisconnect = useCallback((e) => {
        console.log('video disconnected', e);
        setMeeting(undefined);
        }, []);

    const onError = useCallback((e) => {
        console.log('video error', e);
        setTimeout(() => setMeeting(undefined));
        }, []);

    const joinMeeting = useCallback((id) => setMeeting(id), []);
    return (
        <VideoMeetingContext.Provider
            value={{joinMeeting}}
        >
            {meeting && <VideoProvider meeting={meeting} onDisconnect={onDisconnect} onError={onError}/>}
            {children}
        </VideoMeetingContext.Provider>
    );
}

export function useVideoMeetingContext() {
    const context = useContext(VideoMeetingContext);
    if (!context) {
        throw new Error('useVideoMeetingContext must be used within a VideoProvider');
    }
    return context;
}
