import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Button, Layer, ResponsiveContext} from "grommet";
import {FormClose} from "grommet-icons";
import {MenuButton} from "../components/MenuButton";

export const Sidebar = ({items, onToggleSidebar, animation, ...rest}) => {
    const activeItem = useMemo(() => items?.find(item => item.active)?.label || '', [items]);
    const [itemOpened, setItemOpened] = useState(activeItem);
    const size = useContext(ResponsiveContext);
    const SidebarComponent = useMemo(() => size === "small" ? Layer : Box, [size]);
    const sidebarProps = useMemo(() => size === "small"
        ? {full: true, animation}
        : {
            fill: "vertical",
            width: "small",
            background: "light-1",
            pad: {top: "25px"},
        }, [size, animation]);

    useEffect(() => {
        setItemOpened(activeItem);
    }, [activeItem]);

    return (
        <SidebarComponent {...sidebarProps} {...rest} className="fitContent">
            {size === "small" && (
                <Box align="end">
                    <Button icon={<FormClose/>} onClick={onToggleSidebar}/>
                </Box>
            )}

            {items.map(({label, ...rest}) => (
                <MenuButton
                    submenuItemOpened={itemOpened}
                    label={label}
                    key={label}
                    onButtonClick={(itemClicked) => setItemOpened(itemClicked)}
                    {...rest}
                />
            ))}
        </SidebarComponent>
    );
}
