import React from 'react';
import {TextInput, FormField} from "grommet";
import {useFormikContext} from "formik";

export const DisabledTextInput = ({name, label, value}) => {
    const {handleChange} = useFormikContext();
    return (
        <FormField label={label} name={name}>
            <TextInput
                value={value || ''}
                onChange={handleChange}
                disabled
            />
        </FormField>
    );
};