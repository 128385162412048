import React from 'react';
import {Box, Button, FormField, Layer, Text, TextInput} from "grommet";
import {Close} from "grommet-icons";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation} from "react-apollo";
import {CreateOptionSelect} from "./CreateOptionSelect";
import {DateInputPicker} from "../../../Communication/components/DateInputPicker";

const VALIDATION_SCHEMA = Yup.object({
    company_name: Yup.string().required(),
    contact_name: Yup.string().required(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    email: Yup.string().email(),
    phone_number: Yup.string(),
    job_title_id: Yup.number()
});

const CREATE_JOB_TITLE_MUTATION = gql`
    mutation ($name: String!) {
        inserted_option: insert_job_title_one(object: {name: $name}) {
            id
            name
        }
    }
`;

const CREATE_EMPLOYER_MUTATION = gql`
    mutation($data: [client_employer_insert_input!]!) {
        insert_client_employer(objects: $data) {
            returning {
                id
            }
        }
    }
`;

const FETCH_JOB_TITLES = gql`
    query {
        options: job_title {
            id
            name
        }
    }
`;

export const CreateEmployerModal = ({clientId, refetchQueries = []}) => {
    const [show, setShow] = React.useState(false);
    const [createContact] = useMutation(CREATE_EMPLOYER_MUTATION, {refetchQueries});
    return (
        <React.Fragment>
            <Button label="Add Employer" margin={{top: 'small'}} alignSelf='start' onClick={() => setShow(true)}/>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Button selfAlign='end' icon={<Close/>} onClick={() => setShow(false)}/>
                    <Box pad={{horizontal: 'large', vertical: 'small'}}>
                        <Text weight='bold'>Add Contact</Text>
                        <Formik
                            validationSchema={VALIDATION_SCHEMA}
                            initialValues={{
                                company_name: '',
                                contact_name: '',
                                email: '',
                                phone_number: '',
                                job_title_id: undefined,
                                started_on: null,
                                ended_on: null,
                                address: '',
                                city: '',
                                state: '',
                                zip: '',
                            }}
                            onSubmit={async (values) => {
                                const response = await createContact({variables: {data: {...values, client_id: clientId}}});
                                console.log(response);
                                setShow(false);
                            }}
                        >
                            {({values, errors, handleChange, setFieldValue}) => (
                                <Form>
                                    <FormField label='Company Name' error={errors.company_name}>
                                        <TextInput
                                            name='company_name'
                                            value={values.company_name}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Contact Name' error={errors.contact_name}>
                                        <TextInput
                                            name='contact_name'
                                            value={values.contact_name}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Email' error={errors.email}>
                                        <TextInput
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Phone' error={errors.phone_number}>
                                        <TextInput
                                            name='phone_number'
                                            value={values.phone_number}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='Job Title' error={errors.job_title_id}>
                                        <CreateOptionSelect
                                            name='job_title_id'
                                            value={values.job_title_id}
                                            onChange={(option) => setFieldValue('job_title_id', option)}
                                            getOptionsQuery={FETCH_JOB_TITLES}
                                            createOptionMutation={CREATE_JOB_TITLE_MUTATION}
                                            mutateProperty='name'
                                        />
                                    </FormField>

                                    <FormField label='Start Date' error={errors.started_on}>
                                        <DateInputPicker
                                            value={values.started_on}
                                            onSelect={selectedDate => setFieldValue('started_on', selectedDate)}
                                        />
                                    </FormField>

                                    <FormField label='End Date' error={errors.ended_on}>
                                        <DateInputPicker
                                            value={values.ended_on}
                                            onSelect={selectedDate => setFieldValue('ended_on', selectedDate)}
                                        />
                                    </FormField>

                                    <FormField label='Address' error={errors.address}>
                                        <TextInput
                                            name='address'
                                            value={values.address}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='City' error={errors.city}>
                                        <TextInput
                                            name='city'
                                            value={values.city}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='State' error={errors.state}>
                                        <TextInput
                                            name='state'
                                            value={values.state}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='ZIP' error={errors.zip}>
                                        <TextInput
                                            name='zip'
                                            value={values.zip}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <Button label="Create" type='submit' alignSelf='stretch'/>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Layer>
            )}
        </React.Fragment>
    )
}