import React from 'react'
import {colors} from "../../theme";

const Chat = ({active = false, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 29 28" {...rest}>
        <title>{"notif mail"}</title>
        <path
            d="M.516 12.784l-.004.353a13.107 13.107 0 006.734 11.471l.359.193a13.108 13.108 0 0012.237-.11l.285-.16 7.134 3.396.106.043c.71.237 1.397-.501 1.06-1.21l-3.398-7.13.134-.237a13.125 13.125 0 10-24.647-6.61zm1.746.351a11.375 11.375 0 1121.034 5.987l-.06.112c-.1.23-.097.495.013.726l2.536 5.327-5.326-2.537a.875.875 0 00-.839.047 11.358 11.358 0 01-17.358-9.662z"
            fill={active ? colors.brand : colors.sidebarInactive}
            fillRule="nonzero"
        />
    </svg>
);

export default Chat
