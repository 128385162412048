import React from 'react';
import Page from "../../components/Page";
import {Box, Text} from "grommet";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {AudienceFilterForm} from "./components/AudienceFilterForm";
import {ParticipantsList} from "../Surveys/components/ParticipantsList";
import Spinner from "../../components/Spinner";
import {Form, Formik} from "formik";
import {DeleteAudienceButton} from "./components/DeleteAudienceButton";
import {useFetchAudienceFilterOptions} from "../../lib/useFetchAudienceFilterOptions";
import {
    AUDIENCE,
    backButton,
    buildWhereClauseAudiences,
    getVisibleAudiencesOperands,
    SESSIONS,
    getFiltersWithOptions
} from "./components/AudienceFunctions";

const ViewAudiencePage = ({match, history}) => {
    const id = (match.params && match.params.id) || 0;
    const [fetchOptions, {options, loading: loadingOptions, error: errorOptions}] = useFetchAudienceFilterOptions();

    const [getSessions, {data: sessions = [], loading: loadingSessions, error: errorSessions}] = useLazyQuery(SESSIONS);

    const {data, loading, error} = useQuery(AUDIENCE, {
        variables: {id},
        fetchPolicy: 'no-cache',
        onCompleted: ({audience_by_pk, audience_operand}) => {
            fetchOptions(getFiltersWithOptions(audience_operand));
            getSessions({variables: {where: buildWhereClauseAudiences(audience_by_pk)}});
        }
    });

    if (loading || loadingSessions || loadingOptions || !options) {
        return <Spinner full/>;
    }

    if (error || errorSessions || errorOptions) {
        return 'Error';
    }

    return (
        <Page title={`View ${data.audience_by_pk.name} Audience`} header={backButton}>
            <Box gap='medium' flex={false}>
                {
                    !data.audience_by_pk.locked &&
                    <Box background='white' round="xsmall" elevation="xsmall" pad="medium">
                        <Box
                            fill
                            justify='between'
                            direction="row">
                            <Box justify='start'><Text>Audience type</Text></Box>
                            <Box justify='end'>
                                <DeleteAudienceButton
                                    audienceId={id}
                                    queriesToRefetch={['getAudience', 'getSessions']}
                                    onRemove={() => {
                                        history.push('/audiences')
                                    }}
                                />
                            </Box>
                        </Box>
                        <Formik
                            initialValues={{
                                name: data.audience_by_pk.name,
                                audienceFilters: data.audience_by_pk.filters,
                            }}
                        >
                            {({errors, values}) => (
                                <Form>
                                    <AudienceFilterForm
                                        audienceOperands={getVisibleAudiencesOperands(data.audience_operand)}
                                        operators={data.operator}
                                        options={options || {}}
                                        errors={errors}
                                        values={values}
                                        disabled
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Box>
                }
                <Box gap="medium">
                    <ParticipantsList boxProps={{flex: false}}
                                      sessions={sessions.session || []}/>
                </Box>
            </Box>
        </Page>
    );
};

export default ViewAudiencePage;
