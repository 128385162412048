import React from 'react';

import useLocalAudioToggle from './useLocalAudioToggle';
import {Button} from "grommet";
import Unmute from "../../icons/Unmute";

export default function ToggleAudioButton({ disabled }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
      <Button disabled={disabled}
              icon={<Unmute height={25} width={25}/>}
              onClick={toggleAudioEnabled}
              primary
              color={isAudioEnabled ? 'green' : 'status-critical'}
      />
  );
}
