import React from 'react';
import {Box} from "grommet";
import {QuestionTitle} from "./QuestionTitle";
import {AnswerRow} from "./AnswerRow";

const RadioButtonListReport = ({answers, title, type, number, options, surveysCount}) => (
    <Box gap="small">
        <QuestionTitle title={title} type={type} number={number}/>
        {options.map(option => {
            const value = answers[option.id].count / surveysCount * 100;
            return <AnswerRow value={value} title={option.content} key={option.id}/>
        })}
    </Box>
);

export default RadioButtonListReport;
