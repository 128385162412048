import axios from "axios";

export const arrayToObject = (array, keyField = 'sid') =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj
    }, {});

// Copied from https://help.sharpspring.com/hc/en-us/articles/115002519551-Retrieving-SharpSpring-Tracking-ID
export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const getUserIpAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    return res.data.IPv4
}