import React, {useState, useCallback} from 'react';
import {Box, Button, TextInput} from "grommet";
import Send from "../../components/icons/Send";

export const MessageInput = ({send, onChange = () => {}, ...rest}) => {
    const [message, setMessage] = useState('');

    const sendMessage = useCallback(() => {
        send(message);
        setMessage('');
    }, [message, send]);

    const onKeyDown = (event) => {
        if (event.keyCode === 13 && message.length > 0) { // Enter
            sendMessage();
        }
    };

    return (
        <Box
            direction='row'
            align='center'
            gap='small'
            pad='medium'
            round={{corner: 'bottom', size: 'xsmall'}}
        >
        <Box border round='small' fill>
            <TextInput
                plain
                name='messageInput'
                placeholder='Send new message...'
                value={message}
                onKeyDown={onKeyDown}
                onChange={(e) => {
                    setMessage(e.target.value);
                    onChange();
                }}
                {...rest}
            />
            {/*<Box gap='xsmall' direction='row' pad={{horizontal: 'small'}}>*/}
            {/*    <Button label='SMS'*/}
            {/*            style={{padding: '3px 6px 3px 6px'}}*/}
            {/*            primary={sendSMS}*/}
            {/*            disabled={!activeChannel.client.attributes.message_as_sms}*/}
            {/*            onClick={() => this.setState({sendSMS: !sendSMS})}*/}
            {/*    />*/}
            {/*    <Button label='Email'*/}
            {/*            style={{padding: '3px 6px 3px 6px'}}*/}
            {/*            primary={sendEmail}*/}
            {/*            disabled={!activeChannel.client.attributes.message_as_email}*/}
            {/*            onClick={() => this.setState({sendEmail: !sendEmail})}*/}
            {/*    />*/}
            {/*</Box>*/}
        </Box>
            <Button icon={<Send width={24} height={24} color='white'/>}
                    onClick={sendMessage}
                    primary
                    color="brand"
            />
        </Box>
    );
};
