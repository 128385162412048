import React from 'react' ;
import Page from "../../components/Page";
import {Box} from "grommet";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/react-hooks";
import Survey from "../../components/survey/Survey";
import Spinner from "../../components/Spinner";

const TEMPLATE = gql`
    query ($id: Int!) {
        survey_template_by_pk(id: $id) {
            created_at
            id
            questions
            subtitle
            title
            updated_at
        }
    }
`;

const ViewSurveyTemplatePage = ({match}) => {
    const id = (match.params && match.params.id) || 0;
    const {data, loading} = useQuery(TEMPLATE, {variables: {id}});

    if (loading) {
        return <Spinner full/>;
    }

    return (
        <Page title="Survey">
            <Box background='divider' elevation='xsmall' round="xsmall" fill pad="large">
                <Box background='white' round="xsmall">
                    <Survey
                        disableInput
                        template={data.survey_template_by_pk}
                        answers={Array.isArray(data.questions) ? {} : data.questions}
                        onSubmit={() => {
                        }}
                    />
                </Box>
            </Box>
        </Page>
    );
};

export default ViewSurveyTemplatePage;