import React, {useEffect} from 'react';

export const CardknoxAgreement = () => {
    const ref = React.createRef();
    useEffect(() => {
        window.ckCustomerAgreement.enableAgreement({
            iframeField: 'agreement',
            xKey: process.env.REACT_APP_IFIELDS_KEY,
            // autoAgree: true,
            isDebug: process.env.NODE_ENV === 'development'
        });
    }, []);

    return (
        <iframe
            title="Agreement"
            style={{height: '600px', width: '100%', overflow: 'hidden'}}
            id="agreement"
            className="agreement"
            data-ifields-id="agreement"
            src="//cdn.cardknox.com/ifields/2.12.2201.2101/agreement.htm"
            ref={ref}
        >
        </iframe>
    )
}