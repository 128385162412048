import React from "react";
import {FormField, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";

const TextInputSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
    placeholder: Yup.string()
});

export const TextEditor = ({title, placeholder, updateQuestion, id}) => (
    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => updateQuestion(id, values)}
        initialValues={{title, placeholder}}
        validationSchema={TextInputSchema}
        enableReinitialize
        onSubmit={() => {}}
        render={({errors, values, handleChange}) => (
            <Form>
                <FormField label="Title" error={errors.title}>
                    <TextInput
                        name="title"
                        value={values.title || ""}
                        onChange={handleChange}
                    />
                </FormField>

                <FormField label="Placeholder" error={errors.placeholder}>
                    <TextInput
                        name="placeholder"
                        value={values.placeholder || ""}
                        onChange={handleChange}
                    />
                </FormField>
            </Form>
        )
        }
    />
)
