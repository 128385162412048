import FormValidationError from "./FormValidationError";

// TODO: Move this in some other file?
export const HttpStatus = {
    // HTML Status Codes Here
    UNPROCESSABLE_ENTITY: 422,
    SERVER_ERROR: 500
};


export default class ErrorFactory {
    static makeError(error) {
        return error.response ? this.makeHttpError(error) : error;
    }
    static makeHttpError(error) {
        let httpErrorCode = error.response.status;
        let message = error.response.statusText;
        let data = error.response.data;

        switch (httpErrorCode) {
            case HttpStatus.UNPROCESSABLE_ENTITY:
                return new FormValidationError(message, data, error);
            default:
                // Override the default error message to use Yii's error message
                error.message = data.message || message;
                return error;
        }
    }
}
