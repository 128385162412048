import React, {useState} from 'react' ;
import {Box} from "grommet";
import Page from "../../components/Page";
import {SearchField} from "../../components/SearchField";
import RoutedButton from "../../components/RoutedButton";
import gql from 'graphql-tag';
import {DataTable} from "../../components/DataTable";
import {userHasNecessaryRole} from "../../services/auth/service";
import {MANAGER} from "../../lib/constants";
import {AvatarCell} from "../../components/table/AvatarCell";
import {roleName} from "./EditStaffPage";

const PROVIDER_ROLES_ASC_ORDER = [
    'Provider',
    'ProviderManager',
    'CompanyOwner',
    'Administrator',
];

function getHighestRole(roles) {
    const sortedRoles = roles.sort((roleA, roleB) => {
            return PROVIDER_ROLES_ASC_ORDER.indexOf(roleB.name) - PROVIDER_ROLES_ASC_ORDER.indexOf(roleA.name);
        }
    )
    return sortedRoles[0];
}

const columns = [
    {
        accessor: 'user.first_name',
        Header: 'Name',
        primary: true,
        Cell: ({row: {original: {id, user: {first_name, last_name}}}}) => (
            <AvatarCell user_id={id} id={id} last_name={last_name} first_name={first_name} path='staff'/>
        ),
    },
    {
        accessor: 'provider_sessions_aggregate.aggregate.count',
        Header: "# Clients Assigned",
    },
    {
        accessor: 'user.email',
        Header: 'Email'
    },
    {
        id: 'user.role',
        Header: "Role",
        accessor: (provider) => roleName[getHighestRole(provider.user.roles).name]
    }
];

const FETCH_DATA = gql`
    query ($limit: Int! $offset: Int! $orderBy: [provider_order_by!] $filter: provider_bool_exp) {
        data: provider(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            user {
                id
                first_name
                last_name
                email
                roles {
                    name: item_name
                }
            }
            provider_sessions_aggregate {
              aggregate {
                count
              }
            }
        }
        data_aggregate: provider_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

const StaffPage = () => {
    const [filter, setFilter] = useState(null);

    const header = () => (
        <Box direction="row" gap='small'>
            <SearchField onSubmit={(term) => !term ? setFilter(null) :
                setFilter({
                    _or: [
                        {user: {first_name: {_ilike: `%${term}%`}}},
                        {user: {last_name: {_ilike: `%${term}%`}}}
                    ]
                })
            }/>
            {
                userHasNecessaryRole(MANAGER) &&
                <RoutedButton path='/staff/create' label='New Staff Member' primary/>
            }
        </Box>
    );
    return (
        <Page title="Staff" header={header()}>
            <DataTable query={FETCH_DATA} applyFilter={filter} columns={columns}/>
        </Page>
    );
};

export default StaffPage

