import React, {useState} from 'react';
import {Box, CheckBoxGroup, Text} from "grommet";
import {SearchField} from "../../../components/SearchField";
import {DataTable} from "../../../components/DataTable";
import {Link, useParams} from "react-router-dom";
import {StatusCell} from "../../../components/table/StatusCell";
import {SurveyResultStatus, TxPlanStatus} from "../../../lib/constants";
import gql from "graphql-tag";

const timeFormat = {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'};

const columns = [
    {
        accessor: 'title',
        Header: 'Title',
        primary: true,
        Cell: ({row: {original: {id, title}}}) => (
            <Box>
                <Link to={`documents/${id}`}><Text weight='bold'>{title}</Text></Link>
            </Box>
        ),
    },
    {
        accessor: 'type_id',
        Header: 'Type',
        Cell: ({row: {original: {document_type: {id, name}}}}) => (
            <StatusCell id={id} name={name} map={TxPlanStatus}/>
        ),
    },
    {
        accessor: 'status_id',
        Header: 'Status',
        Cell: ({row: {original: {document_status: {id, name}}}}) => (
            <StatusCell id={id} name={name} map={SurveyResultStatus}/>
        ),
    },
    {
        id: 'updated_at',
        accessor: ({updated_at}) => new Date(updated_at).toLocaleDateString("en-US", timeFormat),
        Header: 'Last Modified'
    },
];

const FETCH_DOCUMENTS = gql`
    query fetchSessionDocuments($limit: Int! $offset: Int! $orderBy: [document_order_by!] $filter: document_bool_exp) {
        data: document(limit: $limit, offset: $offset, order_by: $orderBy where: $filter) {
            id
            updated_at
            created_at
            session_id
            title
            type_id
            status_id
            document_status {
                id
                name
            }
            document_type {
                id
                name
            }
        }
        data_aggregate: document_aggregate(where: $filter) {
            aggregate {
                count
            }
        }
    }
`;


export const DocumentsTable = () => {
    const { session_id: sessionId } = useParams();
    const [termFilter, setTermFilter] = useState({});
    const [categories, setCategories] = useState([1,2,3,4,5]);

    return (
        <Box gap='small'>
            <Box round='xsmall' border background='white' pad='small' direction='row' justify='between'>
                <CheckBoxGroup
                    direction='row'
                    labelKey='name'
                    valueKey='sid'
                    onChange={({value}) => setCategories(value)}
                    options={[
                        {sid: 1, name: 'Assessment'},
                        {sid: 2, name: 'Consent'},
                        {sid: 3, name: 'Medical'},
                        {sid: 4, name: 'Discharge'},
                        {sid: 5, name: 'Notes'}
                    ]}
                    value={categories}
                />
                <SearchField onSubmit={(term) => setTermFilter({title: {_ilike: `%${term}%`}})}/>
            </Box>
            <DataTable query={FETCH_DOCUMENTS}
                       applyFilter={{_and: [{session_id: {_eq: sessionId}}, termFilter, {type_id: {_in: categories}}]}}
                       columns={columns}
                       defaultSort={{updated_at: 'desc'}}
            />
        </Box>
    )
}