import React from 'react';
import {Select} from "grommet";
import {useQuery} from "@apollo/react-hooks";

const LIMIT = 50;

export const EnumSearchSelect = ({query, labelKey = 'name', ...props}) => {
    const [options, setOptions] = React.useState([]);
    const [searchPhrase, setSearchPhrase] = React.useState('');
    const [offset, setOffset] = React.useState(0);
    useQuery(query, {
        variables: {offset, limit: LIMIT, filter: {[labelKey]: {_ilike: `%${searchPhrase}%`}}},
        onCompleted:
            data => setOptions(options => options.concat(data.options))
    });
    return (
        <Select
            options={options}
            valueKey={{key: 'id', reduce: true}}
            labelKey={labelKey}
            onMore={() => {
                setOffset(offset => LIMIT + offset)
            }}
            onSearch={(text) => {
                setOptions([]);
                setSearchPhrase(text);
                setOffset(0);
            }}
            {...props}
        />
    )
}