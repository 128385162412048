import React from 'react'
import {colors} from "../../theme";

const Mail = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 25 18" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M22.125.237a2.678 2.678 0 011.838.75l.148.157-.038-.025-.019-.008.035.02.032.026.013.034.045.047a2.567 2.567 0 01.54 1.593v12.5c0 .7-.254 1.313-.756 1.823a2.49 2.49 0 01-1.838.77H3.375a2.49 2.49 0 01-1.838-.77 2.522 2.522 0 01-.756-1.823v-12.5a2.544 2.544 0 01.527-1.572.25.25 0 01.07-.105c.253-.287.549-.512.889-.673A2.558 2.558 0 013.375.237h18.75zM3.594 2.398a.75.75 0 00-.75.75v12.183c0 .145.05.264.158.373a.501.501 0 00.373.158h18.75c.145 0 .264-.05.373-.158a.501.501 0 00.158-.373V3.148a.75.75 0 00-.75-.75H3.594z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <path
                d="M5.805 4.918a1 1 0 00-1.11 1.664l7.5 5a1 1 0 001.11 0l7.5-5a1 1 0 10-1.11-1.664l-6.945 4.63-6.945-4.63z"
                fill={active ? colors.green : colors.sidebarInactive}
            />
        </g>
    </svg>
)

export default Mail
