import React, {useState} from 'react';
import {Box, TextInput} from "grommet";
import {Search} from "grommet-icons";


export const SearchField = ({placeholder = 'SEARCH', searchTerm = '', onSubmit = () => {}, onChange = () => {}, containerProps = {}, textInputProps = {}, boxProps = {}}) => {
    const [value, setValue] = useState(searchTerm);

    const handleChange = (event) => {setValue(event.target.value); onChange(event.target.value)};
    const onKeyDown = (event) => event.keyCode === 13 && onSubmit(value);

    return (
        <Box {...containerProps}>
            <Box
                background="white"
                direction="row"
                align="center"
                pad={{right: "small"}}
                round="xsmall"
                border={{
                    side: "all",
                    color: "border"
                }}
                {...boxProps}
            >
                <TextInput type="search"
                           plain
                           style={{fontSize: "small", fontWeight: 'bold'}}
                           placeholder={placeholder}
                           value={value}
                           onChange={handleChange}
                           onKeyDown={onKeyDown}
                           {...textInputProps}
                />
                <Search color="sidebarInactive"/>
            </Box>
        </Box>
    );
};
