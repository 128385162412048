import React, {Component} from 'react';
import {Box, Paragraph, Tab, Tabs, Grid, ResponsiveContext} from "grommet";
import TemplatePreview from "./components/builder/TemplatePreview";
import QuestionListPanel from "./components/builder/QuestionListPanel";
import {InitQuestions} from "../../components/survey/Questions";
import {QuestionEditPanel} from "./components/builder/QuestionEditPanel";
import {SurveyEditPanel} from "./components/builder/SurveyEditPanel";
import {withRouter} from "react-router-dom";
import Page from "../../components/Page";
import gql from 'graphql-tag';
import {Mutation} from "react-apollo";
import {LoadingButton} from "../../components/LoadingButton";

const CREATE_TEMPLATE = gql`
    mutation ($title: String! $subtitle: String! $questions: jsonb) {
        insert_survey_template(objects: [{
            title: $title,
            subtitle: $subtitle,
            questions: $questions
        }] on_conflict: {constraint: survey_template_pkey, update_columns: [title, subtitle, questions]}) {
            returning {
                id
            }
        }
    }
`;

class SurveyTemplatePage extends Component {

    constructor(props) {
        super(props);
        const template = props.template || {};
        this.state = {
            title: 'Example Title',
            subtitle: 'Example Subtitle',
            questions: [],
            headerActive: false,
            activeTab: 0,
            activeQuestionID: undefined,
            error: undefined,
            ...template
        };
    }

    addQuestion(questionType) {
        const question = InitQuestions[questionType]();
        this.setState({
            questions: [...this.state.questions, question]
        }, this.onActive(question.id));
    }

    onUp(questionID) {
        const {questions} = this.state;
        const index = questions.findIndex(question => question.id === questionID);
        let question = questions[index];
        let newOrder = [...questions];
        newOrder[index] = newOrder[index - 1];
        newOrder[index - 1] = question;
        this.setState({questions: newOrder});
    }

    onDown(questionID) {
        const {questions} = this.state;
        const index = questions.findIndex(question => question.id === questionID);
        let question = questions[index];
        let newOrder = [...questions];
        newOrder[index] = newOrder[index + 1];
        newOrder[index + 1] = question;
        this.setState({questions: newOrder});
    }

    onClone(questionID) {
        const {questions} = this.state;
        const question = questions.find(question => question.id === questionID);
        this.addQuestion(question.type)
    }

    onRemove(questionID) {
        const {questions, activeQuestionID} = this.state;
        const index = questions.findIndex(question => question.id === questionID);
        const questionsCopy = [...questions];
        questionsCopy.splice(index, 1);
        this.setState({questions: questionsCopy});
        if (questionID === activeQuestionID) {
            this.setState({activeQuestionID: undefined});
        }
    }

    onActive(questionID) {
        this.setState({
            activeQuestionID: questionID,
            headerActive: false,
            activeTab: 1
        });
    }

    updateQuestion(id, values) {
        const {questions} = this.state;
        const index = questions.findIndex(question => question.id === id);
        const copy = [...questions];
        copy[index] = {...copy[index], ...values};
        this.setState({questions: copy});
    }

    updateHeader(header) {
        this.setState({...this.state, ...header});
    }

    render() {
        const {title, subtitle, questions, headerActive, activeTab, activeQuestionID, error} = this.state;
        const template = {title, subtitle, questions};

        const smallScreenAreas = [
            ["box2", "box2"],
            ["box1", "box1"],
        ];
        const largeScreensAreas = [
            ["box1", "box2"]
        ];

        return (
            <Page title={this.props.pageTitle} header={this.getHeaderButton(title, subtitle, questions)}>
                <Box fill height={{min: "medium"}} margin={{bottom: "small"}}>
                    <ResponsiveContext.Consumer>
                        {(deviceSize) => (
                            <Grid
                                gap='medium'
                                fill
                                areas={deviceSize === 'small' ? smallScreenAreas : largeScreensAreas}
                                rows={deviceSize === 'small' ? ["fill", "fill"] : ["fill"]}
                                columns={deviceSize === 'small' ? ["fill"] : ["auto", "1/4"]}
                            >
                                <Box
                                    gridArea='box1'
                                    fill height={{min: "medium"}} elevation='xsmall'
                                    background='divider' round='xsmall'
                                    pad={{horizontal: "large", vertical: "large"}}
                                    flex direction='row'>
                                    <TemplatePreview
                                        template={template}
                                        onHeaderActive={() => {
                                            this.setState({
                                                headerActive: true,
                                                activeTab: 2,
                                                activeQuestionID: undefined
                                            })
                                        }}
                                        activeQuestionID={activeQuestionID}
                                        isHeaderActive={headerActive}
                                        onActive={this.onActive.bind(this)}
                                        onRemove={this.onRemove.bind(this)}
                                        onClone={this.onClone.bind(this)}
                                        onUp={this.onUp.bind(this)}
                                        onDown={this.onDown.bind(this)}
                                    />
                                </Box>
                                <Box
                                    gridArea='box2'
                                    height={{min: "medium"}} overflow='auto' fill
                                    elevation='xsmall' background='white' round='xsmall'
                                    pad={{top: 'small'}}>
                                    <Tabs height='medium' fill flex='shrink' alignSelf='center'
                                          activeIndex={activeTab}
                                          onActive={(tab) => this.setState({activeTab: tab})}
                                    >
                                        <Tab title='ADD QUESTION'>
                                            <QuestionListPanel
                                                deviceSize={deviceSize}
                                                selectQuestion={(questionType) => this.addQuestion(questionType)}/>
                                        </Tab>
                                        <Tab title='EDIT QUESTION'>
                                            <QuestionEditPanel
                                                question={questions.find(question => question.id === activeQuestionID)}
                                                updateQuestion={this.updateQuestion.bind(this)}
                                            />
                                        </Tab>
                                        <Tab title='EDIT SURVEY'>
                                            <SurveyEditPanel title={title} subtitle={subtitle}
                                                             updateHeader={this.updateHeader.bind(this)}/>
                                            {error &&
                                            <Box pad={{horizontal: "small"}}><Paragraph
                                                color='red'>{error}</Paragraph></Box>}
                                        </Tab>
                                    </Tabs>
                                </Box>

                            </Grid>
                        )}
                    </ResponsiveContext.Consumer>
                </Box>
            </Page>
        )
    }

    getHeaderButton(title, subtitle, questions) {
        return <Mutation
            mutation={CREATE_TEMPLATE}
            onCompleted={(data) => this.props.history.push(`/survey/templates`)}
            onError={() => this.setState({
                activeTab: 2,
                error: 'The template could not be saved. Please ensure you\'ve entered title, subtitle and questions.'
            })}
        >{(createTemplate, {loading}) => (
            <LoadingButton primary label="Save Survey" loading={loading} onClick={() =>
                createTemplate({variables: {title, subtitle, questions}})
            }/>
        )}</Mutation>;
    }
}

export default withRouter(SurveyTemplatePage);
