import React, {useState} from 'react';
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import MultiSelect from "../../../components/input/MultiSelect";
import {List, Text} from "grommet";


export const FETCH_DATA = gql` 
query {
  id @client
  roles @client
  
  staff: provider(where: {user: {roles: {item_name: {_in: ["Provider", "ProviderManager"]}}}}) {
    id
    user {
      id
      first_name
      last_name
      roles {
        name: item_name
      }
    }
  }    
}
`

const PROVIDER_ROLES_ASC_ORDER = [
    'Provider',
    'ProviderManager',
    'CompanyOwner',
    'Administrator',
];

const ROLE_SHORT_DESC = {
    'Provider': 'C',
    'ProviderManager': 'CD',
    'CompanyOwner': 'Owner',
}


function getHighestRole(roles) {
    const sortedRoles = roles.sort((roleA, roleB) => {
            return PROVIDER_ROLES_ASC_ORDER.indexOf(roleB.name) - PROVIDER_ROLES_ASC_ORDER.indexOf(roleA.name);
        }
    )
    return sortedRoles[0];
}

function createSignatureOptions(currentUserId, staffData) {


    let staffOptions = staffData.filter(provider => !provider.user.roles.includes('Administrator'))
    staffOptions = staffOptions.map(provider => {
        const role = getHighestRole(provider.user.roles).name
        const fullName = `${provider.user.first_name} ${provider.user.last_name}`

        return {
            id: provider.id,
            name: `${fullName} (${ROLE_SHORT_DESC[role]})`,
            fullName: fullName,
        }
    })
    return staffOptions
}


const RequiredSignaturesSelector = ({onChange, value=[]}) => {

    const [signaturesOrder, setSignaturesOrder] = useState([])
    const {data, loading} = useQuery(FETCH_DATA)

    if (loading) {
        return <Spinner/>
    }

    const signatureOptions = createSignatureOptions(data.id, data.staff);

    const preselectedIds = value.map(o => o.id)
    const preselectedOptions = signatureOptions.filter( o => preselectedIds.includes(o.id));

    return (
        <>
            <Text color='gray'>Select who needs to sign the document</Text>
            <MultiSelect
                margin={{top: 'xsmall'}}
                preselectedOptions={preselectedOptions}
                options={signatureOptions}
                handleChange={(signatures) => {
                    const signaturesWithOrder = signatures.map((item, idx) => {
                        return {...item, order: idx}
                    })
                    setSignaturesOrder(signaturesWithOrder)
                    onChange(signaturesWithOrder)
                }}
            />
            <List primaryKey='name' data={signaturesOrder}
                  onOrder={(signatures) => {
                      const signaturesWithOrder = signatures.map((item, idx) => {
                          return {...item, order: idx}
                      })
                      setSignaturesOrder(signaturesWithOrder)
                      onChange(signaturesWithOrder)
                  }}/>
        </>
    )
}

export default RequiredSignaturesSelector
