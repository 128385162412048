import React, {useMemo} from 'react';
import {Box, Layer, Text, TextInput, FormField, Select, DateInput, MaskedInput} from "grommet";
import {userHasNecessaryRole} from "../services/auth/service";
import {COMPANY_OWNER, PHONE_NUMBER_MASK} from "../lib/constants";
import {Form, Formik} from "formik";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Spinner from "./Spinner";
import * as Yup from "yup";
import {LoadingButton} from "./LoadingButton";
import {useHistory} from "react-router-dom";
import {CardknoxAgreement} from "./CardknoxAgreement";

const AddressForm = ({name, values, handleChange, handleBlur, errors}) => (
    <Box>
        <FormField label='Street Address' error={errors?.streetAddress}>
            <TextInput name={`${name}.streetAddress`} value={values.streetAddress} onChange={handleChange}
                       onBlur={handleBlur}/>
        </FormField>
        <Box direction="row" gap="small">
            <FormField label='City' error={errors?.city}>
                <TextInput name={`${name}.city`} value={values.city} onBlur={handleBlur} onChange={handleChange}/>
            </FormField>
            <FormField label='State Code' error={errors?.state}>
                <TextInput name={`${name}.state`} value={values.state} onBlur={handleBlur} onChange={handleChange}/>
            </FormField>
            <FormField label='ZIP' error={errors?.zip}>
                <TextInput name={`${name}.zip`} value={values.zip} onBlur={handleBlur} onChange={handleChange}/>
            </FormField>
        </Box>
    </Box>
)
const QUERY = gql`
    query ($id: Int!) {
        id @client @export(as: "id")
        user: user_by_pk(id: $id) {
            id
            first_name
            last_name
            date_of_birth
            provider {
                id
                phone_number
                company {
                    id
                    name
                    address
                    city
                    state
                    zip
                }
            }
        }
    }
`;

const MUTATION = gql`
    mutation ($data: company_application_input) {
        create_company_application(data: $data) {
            Result
            Error
        }
    }
`;

const addressValidationSchema = Yup.object().shape({
    streetAddress: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required').min(2, 'Code needs to be 2 chars').max(2, 'Code needs to be 2 chars'),
    zip: Yup.string().required('Required').max(5, 'Invalid').min(5, 'Invalid').test('len', 'Invalid', val => val?.length === 5),
})

const validationSchema = Yup.object().shape({
    corporateName: Yup.string().required('Required'),
    dbaName: Yup.string().required('Required'),
    ownershipType: Yup.string().required('Required'),
    businessEmail: Yup.string().email('Invalid email').required('Required'),
    businessStartDate: Yup.date().required('Required'),
    taxId: Yup.string().required('Required').min(9, 'Invalid').max(9, 'Invalid'),
    businessPhone: Yup.string().required('Required'),
    businessAddress: addressValidationSchema,
    bankingInformation: Yup.object().shape({
        accountNumber: Yup.string().required('Required').min(9, 'Invalid').max(12, 'Invalid'),
        routingNumber: Yup.string().required('Required').min(9, 'Invalid').max(9, 'Invalid'),
        bankName: Yup.string().required('Required'),
    }),
    signer: Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        title: Yup.string().required('Required'),
        dateOfBirth: Yup.date().required('Required'),
        address: addressValidationSchema,
        ownerCellPhone: Yup.string().required('Required'),
        ownershipPercentage: Yup.number().required('Required').min(0).max(100),
        ssn: Yup.string().required('Required').min(9, 'Invalid').max(9, 'Invalid'),
    })
});

const ownerships = [
    {label: 'Sole Proprietor', value: 'SoleProprietor'},
    {label: 'Partnership', value: 'Partnership'},
    {label: 'Corporation', value: 'Corporation'},
    {label: 'LLC', value: 'LLC'},
    {label: 'Non-Profit', value: 'NonProfit'},
    {label: 'Sole Ownership', value: 'SoleOwnership'},
]

const addressInitialValues = {
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
}

const initialValues = {
    corporateName: '',
    dbaName: '',
    ownershipType: 'Corporation',
    businessStartDate: '',
    taxId: '',
    businessPhone: '',
    businessEmail: '',
    businessAddress: addressInitialValues,
    bankingInformation: {
        bankName: '',
        routingNumber: '',
        accountNumber: '',
    },
    signer: {
        firstName: '',
        lastName: '',
        title: 'Owner',
        ssn: '',
        address: addressInitialValues,
        dateOfBirth: '',
        ownerCellPhone: '',
        ownershipPercentage: 100,
    }
}

const ContactCompanyOwner = () => {
    return (
        <Layer>
            <Box pad='medium' gap='medium'>
                <Text weight="bold">Company Information Required</Text>
                <Text margin={{top: 'medium'}}>Please contact your organization owner to finish the required steps so
                    that you can continue using our application.</Text>
            </Box>
        </Layer>
    )
}

export const CompanySetupModal = () => {
    const {data, loading} = useQuery(QUERY);
    const [createApplication, {loading: creatingApplication}] = useMutation(MUTATION);
    const history = useHistory();
    const defaultFormValues = useMemo(() => ({
        ...initialValues,
        corporateName: data?.user?.provider?.company?.name || '',
        businessAddress: {
            ...addressInitialValues,
            streetAddress: data?.user?.provider?.company?.address || '',
            city: data?.user?.provider?.company?.city || '',
            state: data?.user?.provider?.company?.state || '',
            zip: data?.user?.provider?.company?.zip || '',
        },
        signer: {
            ...initialValues.signer,
            firstName: data?.user?.first_name || '',
            lastName: data?.user?.last_name || '',
            dateOfBirth: data?.user?.date_of_birth || '',
            ownerCellPhone: data?.user?.provider?.phone_number || '',
        }
    }), [data]);
    if (!userHasNecessaryRole(COMPANY_OWNER)) {
        return <ContactCompanyOwner/>
    }
    return (
        <Layer>
            {loading ? <Spinner/> : <Box pad="medium" overflow="auto">
                <Text size="large" weight="bold" margin={{bottom: 'medium'}}>Company Information Required</Text>
                <Formik validationSchema={validationSchema} initialValues={defaultFormValues}
                        onSubmit={async (values, actions) => {
                            const result = await window.ckCustomerAgreement.getToken()
                            if (!result?.token) {
                                actions.setSubmitting(false);
                                actions.setStatus({agreement: 'Please accept the agreement'});
                                return;
                            }
                            await createApplication({
                                variables: {
                                    data: {
                                        ...values,
                                        businessPhone: values.businessPhone.replace('(', '').replace(')', '').replace(' ', '-'),
                                        businessStartDate: values.businessStartDate.split('T')[0],
                                        signer: {
                                            ...values.signer,
                                            dateOfBirth: values.signer.dateOfBirth.split('T')[0],
                                            ownerCellPhone: values.signer.ownerCellPhone.replace('(', '').replace(')', '').replace(' ', '-'),
                                        }
                                    }
                                }
                            , refetchQueries: ['companySetupCheck']});
                            history.push('/');
                        }}>
                    {({values, errors, handleChange, handleBlur, setFieldValue, isSubmitting, status}) => (
                        <Form>
                            <Box>
                                <Text size="small" weight="bold">Company</Text>
                                <Box direction="row" gap="small">
                                    <FormField label='Company Name' error={errors.corporateName}>
                                        <TextInput
                                            name="corporateName"
                                            value={values.corporateName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </FormField>
                                    <FormField label='DBA (if applicable)' error={errors.dbaName}>
                                        <TextInput
                                            name="dbaName"
                                            value={values.dbaName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label='Ownership Type' error={errors.ownershipType}>
                                        <Select
                                            defaultValue="Corporation"
                                            name="ownershipType"
                                            options={ownerships}
                                            valueKey={{key: 'value', reduce: true}}
                                            labelKey="label"
                                            onChange={({value}) => setFieldValue('ownershipType', value)}
                                        />
                                    </FormField>
                                </Box>
                                <Box direction="row" gap="small">
                                    <FormField label='Business Start Date' error={errors.businessStartDate}>
                                        <DateInput
                                            name="businessStartDate"
                                            value={values.businessStartDate}
                                            format="m/d/yy"
                                            onChange={(event) => setFieldValue('businessStartDate', event.value)}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label='Tax ID' error={errors.taxId}>
                                        <TextInput
                                            name="taxId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.taxId}
                                        />
                                    </FormField>
                                    <FormField label="Phone" error={errors.businessPhone}>
                                        <MaskedInput
                                            name='businessPhone'
                                            mask={PHONE_NUMBER_MASK}
                                            value={values.businessPhone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </Box>
                                <FormField label='Business Email' error={errors.businessEmail}>
                                    <TextInput
                                        name="businessEmail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.businessEmail}
                                    />
                                </FormField>
                                <Box>
                                    <Text size="small" weight="bold">Company Address</Text>
                                    <AddressForm
                                        name="businessAddress"
                                        values={values.businessAddress}
                                        errors={errors.businessAddress}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                    />
                                </Box>

                            </Box>
                            <Box>
                                <Text size="small" weight="bold">Bank Details</Text>
                                <FormField label='Bank Name' error={errors.bankingInformation?.bankName}>
                                    <TextInput
                                        name="bankingInformation.bankName"
                                        value={values.bankingInformation.bankName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormField>
                                <Box direction="row" gap="small">
                                    <FormField label='Routing Number' fill="horizontal"
                                               error={errors.bankingInformation?.routingNumber}>
                                        <TextInput
                                            name="bankingInformation.routingNumber"
                                            value={values.bankingInformation.routingNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label='Account Number' fill="horizontal"
                                               error={errors.bankingInformation?.accountNumber}>
                                        <TextInput
                                            name="bankingInformation.accountNumber"
                                            value={values.bankingInformation.accountNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </Box>

                            </Box>
                            <Box>
                                <Text size="small" weight="bold">Signer</Text>
                                <Box direction="row" gap="small">
                                    <FormField label='First Name' fill="horizontal" error={errors.signer?.firstName}>
                                        <TextInput
                                            name="signer.firstName"
                                            value={values.signer.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label='Last Name' fill="horizontal" error={errors.signer?.lastName}>
                                        <TextInput
                                            name="signer.lastName"
                                            value={values.signer.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </Box>
                                <Box direction="row" gap="small">
                                    <FormField label='Ownership' width="xsmall"
                                               error={errors.signer?.ownershipPercentage}>
                                        <TextInput
                                            type="number"
                                            max={100}
                                            min={0}
                                            step={1}
                                            name="signer.ownershipPercentage"
                                            icon={<Text size="medium" weight="bold">%</Text>}
                                            reverse
                                            value={values.signer.ownershipPercentage}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label='Title' error={errors.signer?.title}>
                                        <TextInput
                                            name="signer.title"
                                            value={values.signer.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label='Date of Birth' error={errors.signer?.dateOfBirth}>
                                        <DateInput
                                            name="signer.dateOfBirth"
                                            format="m/d/yy"
                                            value={values.signer.dateOfBirth}
                                            onChange={(event) => setFieldValue('signer.dateOfBirth', event.value)}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </Box>
                                <Box direction="row" gap="small">
                                    <FormField label='SSN' fill="horizontal" error={errors.signer?.ssn}>
                                        <TextInput
                                            name="signer.ssn"
                                            value={values.signer.ssn}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField label="Phone" fill="horizontal" error={errors.signer?.ownerCellPhone}>
                                        <MaskedInput
                                            name='signer.ownerCellPhone'
                                            mask={PHONE_NUMBER_MASK}
                                            value={values.signer.ownerCellPhone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </Box>

                                <Box>
                                    <Text size="small" weight="bold">Signer Address</Text>
                                    <AddressForm
                                        name="signer.address"
                                        values={values.signer.address}
                                        errors={errors.businessAddress}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <CardknoxAgreement />
                                {status?.agreement && <Text color="lightred">Please accept the agreement</Text>}
                            </Box>
                            <LoadingButton margin={{top: 'small'}} loading={isSubmitting || creatingApplication} label="Save" type="submit" primary/>
                        </Form>
                    )}
                </Formik>

            </Box>}
        </Layer>
    )
}
