import React from 'react';
import {Box, Grid, Heading} from "grommet";
import {DisabledTextInput} from "./DisabledTextInput";

export const ContactInformation = ({data}) => {
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Contact Information</Heading>
            <Grid
                gap='medium'
                columns={{count: 'fill', size: 'small'}}
            >
                <DisabledTextInput label='Address' value={data.session.client.address}/>
                <DisabledTextInput label='City' value={data.session.client.city}/>
                <DisabledTextInput label='State' value={data.session.client.state}/>
                <DisabledTextInput label='ZIP' value={data.session.client.zip}/>
                <DisabledTextInput label='Phone' value={data.session.client.phone_number}/>
                <DisabledTextInput label='Email' value={data.session.client.user.email}/>
            </Grid>
        </Box>
    )
};