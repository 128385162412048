import React from 'react';
import {Box} from "grommet";

export const DividerElement = () => {
    return (
        <Box fill border='bottom'/>
    )
};

DividerElement.defaultSize = {
    w: 2,
    h: 2,
    minW: 1,
    minH: 1,
};