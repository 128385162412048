import React from 'react';
import {Box, FormField, Select} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {SESSION_STATUS} from "../../../lib/constants";

const FETCH_DATA = gql`
    query ($provider_id: Int!){
        id @client @export(as: "provider_id")
        sessions: session(where: {_and: [{provider_session: {provider_id: {_eq: $provider_id}}}, {status_id: {_eq: ${SESSION_STATUS.ACTIVE}}}]}) {
            id
            client {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
    }
`;

export const SelectParticipant = ({selectError, value, ...rest}) => {
    const {data, loading, error} = useQuery(FETCH_DATA);
    const sessions = data?.sessions?.map(({id, client}) => ({
        name: `${client.user.first_name} ${client.user.last_name}`,
        id
    })) || [];
    return (
        <Box background='white' round="xsmall" elevation="xsmall" pad="medium" gap='medium'>
            <FormField label='Participant' error={(error && 'Could not fetch participants') || selectError}>
            {loading ?
                <Spinner full/> :
                <Select
                    options={sessions}
                    valueKey='id'
                    labelKey='name'
                    value={sessions.find(session => session.id === value)}
                    {...rest}
            />}
            </FormField>
        </Box>
    );
};