import * as React from 'react';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {Box} from "grommet";
import {SurveyWrapper} from "./SurveyWrapper";
import {TemplateHeader} from "./TemplateHeader";

// TODO: This needs to be refactored
class Survey extends React.Component {
    render() {
        let {template, answers = {}, onSubmit = () => {}, disableInput} = this.props;

        if (!template) {
            return <div/>;
        }

        let {title, subtitle, questions} = template;
        return (
            <Box background="white" round="xsmall" fill overflow='auto' gap='medium'>
                <TemplateHeader title={title} subtitle={subtitle} />
                <Box pad={{horizontal: "large"}}>
                    <Formik
                        onSubmit={(values, actions) => onSubmit(values)}
                        initialValues={answers || {}}
                    >
                        {props => <SurveyWrapper {...{...props, questions, disableInput}} />}
                    </Formik>
                </Box>
            </Box>
        );
    }
}

Survey.propTypes = {
    survey: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        questions: PropTypes.array.isRequired
    }),
    answers: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
};

export default Survey;
