
export default class {

    getForProblem(problemIndex) {
        return `${problemIndex + 1}.`
    }

    getForObjective(problemIndex, objectiveIndex) {

        let letter = String.fromCharCode(65 + objectiveIndex);

        return `${this.getForProblem(problemIndex)}${letter}`
    }

    getForIntervention(problemIndex, objectiveIndex, interventionIndex) {
        return `${this.getForObjective(problemIndex,objectiveIndex)}${interventionIndex+1}`
    }

}
