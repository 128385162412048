import React, {createElement, useCallback, useState} from 'react';
import Spinner from "../../../components/Spinner";
import {Box, Button, DropButton, Layer, Text} from "grommet";
import {Close} from "grommet-icons";
import {CreateDocument} from "./CreateDocument";
import Page from "../../../components/Page";
import {ClientProfileCard} from "./ClientProfileCard";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {ClientNotesBox} from "./ClientNotesBox";
import {useClientSession} from "../hooks/useClientSession";
import {CreateTreatmentReview} from "./CreateTreatmentReview";
import {CreateCorrespondence} from "./CreateCorrespondence";
import {useHistory, useParams} from "react-router-dom";
import {CreateMeeting} from "./CreateMeeting";
import {CreateSurvey} from "./CreateSurvey";
import {PlusButton} from "../../../components/PlusButton";

export const FETCH_SESSION = gql`
    query fetchClientSessionFields ($id: Int!) {
        providerId: id @client
        providerRoles: roles @client
        session: session_by_pk(id: $id) {
            id
            mr_number
            created_at
            client {
                id
                recovery_date
                phone_number
                sessions_aggregate(where: {status_id: {_eq: 1}}) {
                    aggregate {
                        count
                    }
                }
                user {
                    id
                    engaged
                    first_name
                    last_name
                    created_at
                    updated_at
                    token_issued_at
                    email
                    date_of_birth
                    gender_id
                    gender {
                        id
                        name
                    }
                    birth_sex {
                        id
                        name
                    }
                }
            }
            status {
                id
                name
            }
            provider_session {
                twilio_channel_sid
                provider {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
            session_tags{
                tag_id
            }
            session_diagnoses {
                diagnosis_id
            }
        }
    }
`;

const MODAL_TYPES = {
    TX_PLAN: 'tx_plan',
    TX_PLAN_REVIEW: 'tx_plan_review',
    DOCUMENT: 'document',
    APPOINTMENT: 'appointment',
    CORRESPONDENCE: 'correspondence',
    SURVEY: 'survey'
};

const MODAL_HEADINGS = {
    [MODAL_TYPES.TX_PLAN]: 'Create treatment plan',
    [MODAL_TYPES.TX_PLAN_REVIEW]: 'Select a treatment plan to create review for',
    [MODAL_TYPES.DOCUMENT]: 'Create document',
    [MODAL_TYPES.APPOINTMENT]: 'Create appointment',
    [MODAL_TYPES.CORRESPONDENCE]: 'Create correspondence',
    [MODAL_TYPES.SURVEY]: 'Create survey'
};

const MODAL_CONTENT = {
    [MODAL_TYPES.TX_PLAN]: 'tx_plan',
    [MODAL_TYPES.TX_PLAN_REVIEW]: CreateTreatmentReview,
    [MODAL_TYPES.DOCUMENT]: CreateDocument,
    [MODAL_TYPES.APPOINTMENT]: CreateMeeting,
    [MODAL_TYPES.CORRESPONDENCE]: CreateCorrespondence,
    [MODAL_TYPES.SURVEY]: CreateSurvey
}

export const ProfileSidebar = () => {
    const {id, session_id} = useParams();
    const [showModal, setShowModal] = useState(null);
    const closeModal = useCallback(() => setShowModal(null), []);
    const history = useHistory();
    const {active, is_from_caseload} = useClientSession();
    const {data, loading} = useQuery(FETCH_SESSION, {
            variables: {id: session_id},
            // Set to cache and network to update the ui after re-fetching the session query when tags are edited
            fetchPolicy: 'cache-and-network'
        }
    ); // TODO: Handle Error

    const plusButton = (sessionId) => (
        <DropButton
            alignSelf="center"
            dropProps={{align: {top: 'bottom'}}}
            dropContent={
                <Box background="white" pad="medium" gap='small'>
                    <Button plain label='Document' onClick={() => setShowModal(MODAL_TYPES.DOCUMENT)}/>
                    <Button plain label='Survey' onClick={() => setShowModal(MODAL_TYPES.SURVEY)}/>
                    {is_from_caseload && <Button plain label='Appointment' onClick={() => setShowModal(MODAL_TYPES.APPOINTMENT)}/>}
                    <Button plain label='Correspondence' onClick={() => setShowModal(MODAL_TYPES.CORRESPONDENCE)}/>
                    <Button plain label='Treatment Plan' onClick={() => history.push(`/clients/${id}/${sessionId}/treatment-plans/create`)}/>
                    <Button plain label='Treatment Plan Review' onClick={() => setShowModal(MODAL_TYPES.TX_PLAN_REVIEW)}/>
                </Box>
            }
        >
            <PlusButton/>
        </DropButton>
    );

    if (loading) {
        return <Spinner/>;
    }

    return (
        <Box>
            <Box height={{min: 'medium'}} flex={false}>
                {showModal && <Layer
                    position="center"
                    onEsc={closeModal}
                    onClickOutside={closeModal}
                >
                    <Box width={{min: 'medium'}} gap='small'>
                        <Box direction='row' justify='between' align='center'>
                            <Text weight='bold' margin={{left: 'small'}}>{MODAL_HEADINGS[showModal]}</Text>
                            <Button icon={<Close/>} onClick={closeModal}/>
                        </Box>
                        {createElement(MODAL_CONTENT[showModal], {closeModal, session: data?.session})}
                    </Box>
                </Layer>}

                <Page title="Profile" boxProps={{overflow: "none"}} header={active ? plusButton(session_id): undefined}>
                    <Box flex="grow"
                         elevation='xsmall'
                         background='white'
                         alignSelf='center'
                         round="xsmall"
                         pad={{vertical: 'medium', horizontal: 'medium'}}
                         width='medium'
                    >
                        <ClientProfileCard data={data} id={session_id}/>
                    </Box>
                </Page>
            </Box>
            <Box height={{min: '260px'}}>
                <ClientNotesBox id={session_id}/>
            </Box>
        </Box>
    )
}