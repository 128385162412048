import React from "react";

import {colors} from "../theme";

const HorizontalDivider = ({color=colors.divider, height = 1, width = "100%"}) => {
    return <hr
        style={{
            color: color,
            backgroundColor: color,
            border: "none",
            height: height,
            width: width
        }}
    />;
};

export default HorizontalDivider
