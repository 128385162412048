import React, {useState} from 'react';
import * as Yup from "yup";
import {Anchor, Box, CheckBox, Image, Paragraph, RadioButton, Text} from "grommet";
import {AddressFields} from "./AddressFields";
import {CardFieldsGrid} from "../../components/CardFieldsGrid";
import {TermsAndConditionsModal} from "../Auth/components/TermsAndConditionsModal";
import {
    PROFESSIONAL_PLAN,
    PROFESSIONAL_PLAN_MONTHLY,
    STANDARD_PLAN_MONTHLY
} from "../../lib/constants";
import Circle30 from "../../components/icons/Circle30";
import {withRouter} from "react-router-dom";
import {colors} from "../../theme";

const CARD_PAYMENT = 'Card';

const Step3 = ({match, handleChange, handleBlur, errors, values, setFieldValue, setFieldError, cardNumberRef, cvvRef}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(CARD_PAYMENT);
    const plan = match.params.plan;
    return (
        <Box>
            <Box border="bottom" pad={{vertical: 'small'}} justify='between' direction='row'>
                {Number(plan) === PROFESSIONAL_PLAN ?
                    <Text size='large' color={colors.blueblack}>Professional {PROFESSIONAL_PLAN_MONTHLY}</Text> :
                    <Text color={colors.blueblack} size='large'>Standard {STANDARD_PLAN_MONTHLY}</Text>}
            </Box>
            <Box gap='small' margin={{top: 'small'}}>
                <Text color='text'>Payment Method</Text>
                <Box border="bottom" pad={{vertical: 'small'}} justify='between' direction='row'>
                    <RadioButton
                        name='paymentMethod'
                        checked={true}
                        label="Credit Card"
                        onChange={() => setPaymentMethod(CARD_PAYMENT)}
                    />
                    <Image src="/cards.png"/>
                </Box>
                {paymentMethod === CARD_PAYMENT && <Box>
                    <CardFieldsGrid
                        cardNumberRef={cardNumberRef}
                        cvvRef={cvvRef}
                        gridArea='card'
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        pad={{left: 'medium'}}
                    />
                    <Box gridArea='billing' gap='medium'>
                        <Text color='text'>Billing Address</Text>
                        <CheckBox
                            checked={values.billing_address_same}
                            name='billing_address_same'
                            label="Same as Company Address"
                            onChange={handleChange}
                        />
                        {
                            !values.billing_address_same &&
                            <AddressFields
                                heading={false}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                errors={errors.billing_address || {}}
                                values={values.billing_address}
                                field='billing_address'
                            />
                        }
                        <Box direction='row' gap='xxsmall'>
                            <Text>By starting my trial I agree to the</Text>
                            <Anchor onClick={() => {
                                setModalVisible(true)
                            }} size='medium' color='brand'>Terms of Service</Anchor>
                        </Box>
                        {modalVisible && (
                            <TermsAndConditionsModal
                                onClose={() => {
                                    setModalVisible(false)
                                }}
                            />
                        )}
                    </Box>
                </Box>
                }
            </Box>
        </Box>
    )
};

Step3.label = 'Your Plan';
Step3.validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    card_number_token: Yup.string().required('Required'),
    cvv_token: Yup.string().required('Required'),
    expiration_date: Yup.string().required('Required'),
    billing_address_same: Yup.bool(),
    billing_address: Yup.object().when("billing_address_same", {
        is: false,
        then: Yup.object().shape({
            address: Yup.string(),
            state: Yup.string(),
            city: Yup.string(),
            zip: Yup.string().test('len', 'Invalid', val => val.length === 5),
        })
    })
});
Step3.initialValues = {
    name: '',
    cvv_token: '',
    card_number_token: '',
    expiration_date: '',
    billing_address: {
        address: '',
        state: '',
        city: '',
        zip: ''
    },
    billing_address_same: true
};
Step3.sidebar = (
    <Box gap='medium'>
        <Circle30 style={{width: '50px', height: '50px'}}/>
        <Box>
            <Text color='blueblack' size='large'>Risk free 30 day trial</Text>
            <Paragraph color='lightgray'>
                Cancel anytime, for any reason - no questions asked. No long term contracts. No hidden fees.
            </Paragraph>
        </Box>
    </Box>
);


export default withRouter(Step3);