import React, {useRef, useState} from 'react';
import {Box, Drop, Text} from "grommet";

export const SessionTag = ({tag}) => {
    const [over, setOver] = useState(false);
    const ref = useRef();
    return (
        <React.Fragment>
            <Box
                ref={ref}
                onMouseOver={() => setOver(true)}
                onMouseOut={() => setOver(false)}
                onFocus={() => {}}
                onBlur={() => {}}
                round="xxsmall"
                border
                style={{cursor: 'default'}}
                flex={false}
                direction="row"
                margin={{bottom: "xsmall"}}
                width={{max: "xsmall"}}
                background="light-1"
                pad={{horizontal: 'xsmall', vertical: "xxsmall"}}
            >
                <Text alignSelf="center" weight="bold" color="gray" truncate>{tag.name}</Text>
            </Box>

            {tag.description && ref.current && over && (
                <Drop align={{top:"bottom"}} target={ref.current} plain>
                    <Box
                        margin="xsmall"
                        pad="small"
                        background="dark-3"
                        round={{ size: "medium"}}
                    >
                        <Text color='white' truncate>{tag.description}</Text>
                    </Box>
                </Drop>
            )}
        </React.Fragment>
    );
}
