const HOURS_TO_MINS = 60;
const DAYS_TO_MINS = 1440;
const WEEKS_TO_MINS = 10080;
const MONTHS_TO_MINS = 43829;

export const getTimeInMinutes = (time, unit) => {
    let timeInMinutes = time;
    switch (unit) {
        case 'hours':
            timeInMinutes *= HOURS_TO_MINS;
            break;
        case 'days':
            timeInMinutes *= DAYS_TO_MINS;
            break;
        case 'weeks':
            timeInMinutes *= WEEKS_TO_MINS;
            break;
        case 'months':
            timeInMinutes *= MONTHS_TO_MINS;
            break;
        default:
            break;
    }
    return timeInMinutes
};
