import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button, Heading, Notification, Text} from "grommet";
import {Alert, Print} from "grommet-icons";
import {Field} from "../../Settings/DocumentBuilder/components/Field";
import {Responsive, WidthProvider} from "react-grid-layout";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import Spinner from "../../../components/Spinner";
import _ from "lodash";
import {Form, Formik} from "formik";
import {InputFields} from "../../Settings/DocumentBuilderPage";
import {useMutation} from "@apollo/react-hooks";
import {useHistory, useParams} from "react-router-dom";
import {DOCUMENT_STATUS} from "../../../lib/constants";
import SignaturePadBox from "./SignaturePadBox";
import moment from "moment";
import ReactToPrint from "react-to-print";
import {InformationRibbon} from "./InformationRibbon";
import {DeleteButton} from "../../../components/DeleteButton";
import {useClientSession} from "../hooks/useClientSession";
import {getUserIpAddress} from "../../../lib/helpers";
import {DocumentPrintPage} from "./DocumentPrintPage";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const defaultSettings = {
    className: "layout",
    rowHeight: 50,
    isDraggable: false,
    isResizable: false,
    // cols: {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
};

const FETCH_DOCUMENT = gql`
    query ($id: Int!){
        id @client
        document: document_by_pk(id: $id) {
            id
            field_values
            provider {
                id
                phone_number
                user {
                    id
                    first_name
                    last_name
                }
                company {
                    id
                    name
                    address
                    zip
                    city
                    state
                }
            }
            session {
                id
                mr_number
                client {
                    id
                    phone_number
                    ssn
                    address
                    city
                    state
                    zip
                    user {
                        id
                        first_name
                        middle_name
                        last_name
                        date_of_birth
                    }
                }
                session_diagnoses {
                    diagnosis {
                        id
                        name
                        icd10_code
                    }
                }
            }
            meeting_id
            meeting {
                id
                name
                start_at
                end_at
                type_id
                meeting_type {
                    id
                    name
                }
            }
            document_signatures {
                id
                ip
                sign_path_data
                created_at
                user {
                    id
                    first_name
                    last_name
                }
            }
            body
            status_id
            title
            created_at
            updated_at
        }
    }
`;

const UPDATE_DOCUMENT = gql`
    mutation ($id: Int! $data: jsonb! $status_id: Int!) {
        document: update_document_by_pk(pk_columns: {id: $id} _set: {field_values: $data, status_id: $status_id}) {
            id
            field_values
            body
            status_id
            title
            created_at
            updated_at
        }
    }
`;

const DELETE_DOCUMENT = gql`
    mutation ($id: Int!) {
        document: delete_document_by_pk(id: $id) {
            id
        }
    }
`;

const UPDATE_SIGNATURE = gql`
    mutation ($id: Int! $data: document_signature_set_input!) {
        insert: update_document_signature_by_pk(pk_columns: {id: $id} _set: $data) {
            id
            ip
            sign_path_data
            created_at
            user {
                id
                first_name
                last_name
            }
        }
    }
`;

const pageTitle = document.title;

export const Document = () => {
    const { item_id: id } = useParams();
    const printRef = useRef();
    const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
    const [fieldsData, setFieldsData] = useState({});
    const [mounted, setMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const history = useHistory();
    const [updateDocument] = useMutation(UPDATE_DOCUMENT);
    const [updateSignature] = useMutation(UPDATE_SIGNATURE);
    const {active} = useClientSession();
    const {data, loading} = useQuery(FETCH_DOCUMENT, {
        variables: {id}, onCompleted: ({document}) => {
            setFields(document.body.map(field => ({...field, isDraggable: false})));

            setFieldsData(document.body.reduce((obj, item) => {
                obj[item.i] = item.data;
                return obj
            }, {}))
        }
    })
    const completed = useMemo(() => data?.document.status_id === DOCUMENT_STATUS.COMPLETED || !active, [data, active]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const generateDOM = useCallback(() => {
        return _.map(fields, (l, i) => {
            return (
                <Box
                    key={l.i}
                    className={l.static ? "static" : ""}
                    data-grid={l}
                    round='xsmall'
                    wrap
                >
                    <Box pad='small' fill>
                        <Field {...fieldsData[l.i]} id={l.i} disabled={completed}/>
                    </Box>
                </Box>
            );
        });
    }, [fieldsData, fields, completed])

    if (loading) {
        return <Spinner fill/>
    }

    return (
        <Box gap='small'>
            {['sm', 'xs', 'xxs'].includes(currentBreakpoint) &&
                <Box flex={false} background='yellow' gap='small' pad={{horizontal: 'medium', vertical: 'small'}} direction='row'
                     round='xxsmall'>
                    <Alert color='black'/>
                    <Text weight='bold'>Screen is not big enough. Please use a computer with a bigger screen, otherwise
                        document layout may appear different on other screen sizes!</Text>
                </Box>}
            <Formik
                initialValues={{
                    ...(data.document.field_values || Object.keys(fieldsData).reduce((obj, id) => {
                        if (InputFields.includes(fieldsData[id].type)) {
                            obj[id] = fieldsData[id].value || '';
                        }
                        return obj;
                    }, {})),
                    status_id: 1,
                    signatures: data.document.document_signatures
                }}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    actions.setStatus(undefined);
                    const {status_id, ...data} = values;
                    if (status_id === DOCUMENT_STATUS.COMPLETED) {
                        if (values.signatures.some(signature => !signature.sign_path_data)) {
                            actions.setStatus({status: 'critical', message: 'Please add required signatures!'});
                            return;
                        }
                        const ip = await getUserIpAddress();
                        values.signatures.forEach(async (signature) => {
                            await updateSignature({
                                variables: { id: signature.id, data: {
                                    sign_path_data: signature.sign_path_data,
                                        ip,
                                        created_at: moment().toISOString()
                                    }}
                            })
                        });
                    }
                    await updateDocument({variables: {data, id, status_id}});
                    actions.setStatus({status: 'normal', message: 'Changes saved successfully!'});
                    // history.goBack();
                }}
            >
                {formik => (
                    <Form>
                        <Box background='white' round='xsmall' elevation='xsmall' pad='small' gap='small'
                             width={{max: 'xxlarge'}} alignSelf='center' fill>
                            <Box pad={{horizontal: 'small', top: 'medium'}} direction='row' justify='between'>
                                <Box>
                                    <Heading level={3} margin={{vertical: 'none'}}>{data?.document?.title}</Heading>
                                    <Text>
                                        Created
                                        on {moment(data.document?.created_at).format('DD MMM YYYY [at] HH:mm')} by {data.document.provider.user.first_name} {data.document.provider.user.last_name}
                                    </Text>
                                </Box>
                                <ReactToPrint trigger={() =>  <Button plain icon={<Print size='30px'/>}/>}
                                              content={() => printRef.current}
                                              onBeforePrint={() => {
                                                  let date = new Date().toLocaleDateString('us-US');
                                                  document.title = `${date}_${data.document.title}`
                                              }}
                                              onAfterPrint={() => {
                                                  document.title = pageTitle;
                                              }}
                                />
                            </Box>
                            <InformationRibbon document={data.document}/>
                            <ResponsiveReactGridLayout
                                {...defaultSettings}
                                layouts={{lg: fields}}
                                breakpoints={{lg: 996, md: 800, sm: 700, xs: 600, xxs: 500}}
                                cols={{lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
                                onBreakpointChange={breakpoint => {
                                    setCurrentBreakpoint(breakpoint);
                                }}
                                isDraggable={false}
                                isResizable={false}
                                measureBeforeMount={false}
                                useCSSTransforms={mounted}
                                compactType='vertical'
                            >
                                {generateDOM(formik)}
                            </ResponsiveReactGridLayout>
                        </Box>
                        <Box margin={{top: 'medium'}} flex={false}>
                            <Text weight='bold'>Signatures</Text>
                            <Box direction='row' gap='medium' wrap flex={false}>
                                {formik.values.signatures.map((signature, index) =>
                                <Box key={signature.id} margin={{top: 'small'}} background='white' alignSelf='start' round='xsmall' elevation='xsmall' pad='small'>
                                        <SignaturePadBox disabled={completed} signature={signature} onChange={(path) => {
                                            let signatures = [...formik.values.signatures];
                                            signatures[index] = {...signatures[index], sign_path_data: path};
                                            formik.setFieldValue('signatures', signatures);
                                        }}/>
                                </Box>
                                )}
                            </Box>
                        </Box>
                        <Box direction='row' gap='small' margin={{top: 'medium'}}>
                            <Button label='Sign & Complete' type='submit'
                                    onClick={() => formik.setFieldValue('status_id', DOCUMENT_STATUS.COMPLETED)}
                                    primary disabled={completed}/>
                            <Button label='Save' type='submit' primary color='active'
                                    onClick={() => formik.setFieldValue('status_id', DOCUMENT_STATUS.IN_PROGRESS)}
                                    disabled={completed}/>
                            <Button label='Back' onClick={() => history.goBack()} primary color='gray'/>
                            <DeleteButton
                                query={DELETE_DOCUMENT}
                                variables={{id}}
                                onRemove={() => history.goBack()}
                                buttonProps={{
                                    label: 'Delete',
                                    primary: true,
                                    color: 'status-error',
                                    icon: undefined,
                                    disabled: completed,
                                    tip: completed ? 'Signed documents cannot be deleted' : undefined
                                }}
                            />
                        </Box>
                        {formik.status && <Notification
                            title={formik.status.message}
                            onClose={() => formik.setStatus(undefined)}
                            status={formik.status.status}
                            toast={{autoClose: true, position: 'bottom-right'}}
                        />}
                        <DocumentPrintPage formik={formik} ref={printRef} data={data} fieldsData={fieldsData} fields={fields} />
                    </Form>)}
            </Formik>
        </Box>
    );
}