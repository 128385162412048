import React from 'react'
import {colors} from "../../theme";

const Send = ({color, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 21 21" {...rest}>
        <title>Send</title>
        <path
            d="M.563.637A2.047 2.047 0 012.952.211l17.302 8.79c.458.232.746.702.746 1.216l-.002-.054a.692.692 0 01.002.053v.036c-.013.5-.299.953-.746 1.18L2.952 20.235a2.047 2.047 0 01-2.39-.426 2.054 2.054 0 01-.288-2.431l4.382-7.16L.26 3.044A2.047 2.047 0 01.453.763zm17.731 10.261H5.838l-4.387 7.168a.682.682 0 00.032.716l.069.086a.68.68 0 00.789.146l15.953-8.116zM2.348 1.435a.682.682 0 00-.796.142.676.676 0 00-.115.779l4.401 7.178h12.455z"
            fill={colors[color]}
            fillRule="nonzero"
        />
    </svg>
)

export default Send
