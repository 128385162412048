import React, {useEffect} from 'react'
import {useTable, usePagination, useSortBy} from 'react-table'
import {Box, Text} from "grommet";
import {Down, Up} from "grommet-icons";
import Spinner from "../Spinner";
import {Pagination} from "./Pagination";
import {userHasNecessaryRole} from "../../services/auth/service";

const showColumn = (role) => !role || userHasNecessaryRole(role);

export function Table({columns, data, fetchData, loading, boxProps, defaultPageSize=10,
                       pageCount: controlledPageCount, getRowProps = () => ({})}) {
    // Render the UI for your table

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        state: {pageIndex, pageSize, sortBy},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0, pageSize: defaultPageSize},
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount
        },
        // useBlockLayout,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        fetchData({pageIndex, pageSize, sortBy})
    }, [fetchData, pageIndex, pageSize, sortBy]);

    return (
        <Box gap='medium' {...boxProps} flex>
            <Box background='white' round='xsmall' elevation='xsmall'>
                <Box flex {...getTableProps()}>
                    <Box border={{side: 'bottom'}} pad={{horizontal: 'medium', vertical: 'small'}}>
                        {headerGroups.map(headerGroup => (
                            <Box {...headerGroup.getHeaderGroupProps()} className='tr' direction='row' justify='between'>
                                {headerGroup.headers
                                    .filter(({requiredRole}) => showColumn(requiredRole))
                                    .map(column => (
                                    <Box {...column.getHeaderProps(column.getSortByToggleProps())} fill className='th' align='center' direction='row' gap='xsmall'>
                                            {column.render('Header')}
                                            {column.isSorted ?
                                                column.isSortedDesc ?
                                                    <Down size='small'/> : <Up size='small'/> :
                                                null}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                    <Box {...getTableBodyProps()} pad={{horizontal: 'medium'}} overflow='auto' flex>
                        {page.length > 0 ? page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <Box {...row.getRowProps(getRowProps(row))}
                                     align='center'
                                     direction='row'
                                     pad={{vertical: 'small'}}
                                     className='tr'
                                     flex={false}
                                     justify='between'
                                     border={i === pageSize - 1 || i === data.length - 1 ? null : {side: 'bottom'}} // remove border from last row
                                >
                                    {row.cells.filter(({column: {requiredRole}}) => showColumn(requiredRole)).map(cell => {
                                        return <Box {...cell.getCellProps()} fill='horizontal' className='td'>{cell.render('Cell')}</Box>
                                    })}
                                </Box>
                            )
                        }) : <Box pad={{vertical: 'small'}}><Text size='16px'>No results.</Text></Box>}
                        {loading && <Spinner/>}
                    </Box>
                </Box>
            </Box>
            <Pagination pageCount={pageCount} pageSize={pageSize} gotoPage={gotoPage} pageIndex={pageIndex}
                        setPageSize={setPageSize} boxProps={{flex: false, pad: {bottom: 'small'}}}/>
        </Box>
    )
}
