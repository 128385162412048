import React from 'react';
import {Box, Text} from "grommet";
import RoutedButton from "../components/RoutedButton";
import GeneralError from "../components/icons/GeneralError";
import {useLocation} from "react-router-dom";
import {safeGet} from "../utils";


export const GeneralErrorPage = () => {
    const location = useLocation();


    return (
        <Box fill align="center" justify='center' gap='small'>
            <Box height='medium'>
                <GeneralError/>
            </Box>
            {safeGet( () => location.state.errorMessage, false) ?
                <Text>{location.state.errorMessage}</Text> :
                <Text>An unknown error occurred.</Text>
            }
            <Text color="sidebarInactive">Please try again later.</Text>
            <RoutedButton margin={{top: "small"}} label="Go to Dashboard" path='/'/>
        </Box>
    );
};
