import React, {Fragment, useMemo, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    Accordion,
    AccordionPanel,
    Box,
    Button,
    FormField,
    Heading,
    Notification,
    Spinner,
    Text,
    TextArea,
    TextInput
} from "grommet";
import {FieldArray, Form, Formik} from "formik";
import gql from "graphql-tag";
import {FormClose, FormDown, FormUp} from "grommet-icons";
import {TX_PLAN_REVIEW_STATUS} from "../../../lib/constants";
import {DeleteButton} from "../../../components/DeleteButton";
import {useClientSession} from "../hooks/useClientSession";
import SignaturePadBox from "./SignaturePadBox";
import {getUserIpAddress} from "../../../lib/helpers";
import moment from "moment";

const FETCH_DATA = gql`
    query ($id: Int!) {
        id @client
        tx_plan_review: tx_plan_review_by_pk(id: $id) {
            id
            created_at
            status_id
            problems
            signatures: tx_plan_review_signatures {
                id
                ip
                sign_path_data
                created_at
                user {
                    id
                    first_name
                    last_name
                }
            }
            tx_plan {
                id
                name
            }
        }
    }
`;

const DELETE_REVIEW = gql`
    mutation ($id: Int!) {
        document: delete_tx_plan_review_by_pk(id: $id) {
            id
        }
    }
`;

const UPDATE_SIGNATURE = gql`
    mutation ($id: Int! $data: tx_plan_review_signature_set_input!) {
        insert: update_tx_plan_review_signature_by_pk(pk_columns: {id: $id} _set: $data) {
            id
            ip
            sign_path_data
            created_at
            user {
                id
                first_name
                last_name
            }
        }
    }
`;

const UPDATE_REVIEW = gql`
    mutation ($id: Int! $data: jsonb! $status_id: Int!) {
        tx_plan_review: update_tx_plan_review_by_pk(pk_columns: {id: $id} _set: {problems: $data, status_id: $status_id}) {
            id
            problems
            status_id
        }
    }
`;

const PROBLEM_STUB = {
    name: '',
    description: ''
}

const renderPanelHeader = (value, name, handleChange, active, remove) => (
    <Box direction="row" align="center" pad="xsmall" justify='between' round='xsmall' background='light-2'
         margin={{top: 'small'}}>
        <FormField margin={{bottom: 'none'}}>
            <TextInput value={value} name={name} onChange={handleChange} placeholder='Problem'/>
        </FormField>
        <Box direction='row' gap='small'>
            {active ? <FormUp color='brand'/> : <FormDown color='brand'/>}
            <FormClose onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                remove();
            }}/>
        </Box>
    </Box>
);

export const TreatmentPlanReview = () => {
    const {review_id} = useParams();
    const {data, loading} = useQuery(FETCH_DATA, {variables: {id: review_id}});
    const [activeIndex, setActiveIndex] = useState([0]);
    const {active} = useClientSession();
    const history = useHistory();
    const [updateReview] = useMutation(UPDATE_REVIEW);
    const [updateSignature] = useMutation(UPDATE_SIGNATURE);
    const completed = useMemo(() => data?.tx_plan_review.status_id === TX_PLAN_REVIEW_STATUS.COMPLETED || !active, [data, active]);

    if (loading) {
        return <Spinner/>;
    }

    return (
        <Formik
            initialValues={{
                problems: data.tx_plan_review.problems || [],
                status_id: data.tx_plan_review.status_id,
                signatures: data.tx_plan_review.signatures || [],
            }}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                const {status_id, signatures, problems} = values;
                if (status_id === TX_PLAN_REVIEW_STATUS.COMPLETED) {
                    if (signatures.some(signature => !signature.sign_path_data)) {
                        actions.setStatus({status: 'critical', message: 'Please add required signatures!'});
                        return;
                    }
                    signatures.forEach(async (signature) => {
                        await updateSignature({
                            variables: { id: signature.id, data: {
                                    sign_path_data: signature.sign_path_data,
                                    ip,
                                    created_at: moment().toISOString()
                                }}
                        })
                    });
                }
                const ip = await getUserIpAddress();
                await updateReview({variables: {data: problems, id: review_id, status_id}});
                actions.setStatus({status: 'normal', message: 'Changes saved successfully!'});
            }}
        >
            {props => (
                <Form>
                    <Box pad='small' background='white' elevation='xsmall' round='xsmall'>
                        <Heading level={4} margin={{vertical: 'small'}}>Review for treatment
                            plan {data.tx_plan_review.tx_plan.name}</Heading>
                        <FieldArray name='problems'>
                            {(helpers) => (
                                <Fragment>
                                    <Accordion multiple activeIndex={activeIndex}
                                               onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}>
                                        {props.values.problems.map((problem, index) =>
                                            <AccordionPanel
                                                key={index}
                                                header={renderPanelHeader(props.values.problems[index].name, `problems.${index}.name`, props.handleChange, activeIndex.includes(index), () => helpers.remove(index))}
                                            ><Box pad='small'>
                                                <FormField>
                                                    <TextArea placeholder='Review of the problem...'
                                                              value={props.values.problems[index].description}
                                                              name={`problems.${index}.description`}
                                                              onChange={props.handleChange}/>
                                                </FormField>
                                            </Box>
                                            </AccordionPanel>)}
                                    </Accordion>
                                    <Button
                                        label='Add Problem'
                                        margin={{top: 'small'}}
                                        onClick={() => helpers.push(PROBLEM_STUB)}
                                        alignSelf='start'
                                    />
                                </Fragment>
                            )}
                        </FieldArray>
                    </Box>
                    <Box margin={{top: 'medium'}}>
                        <Text weight='bold'>Signatures</Text>
                        <Box direction='row' gap='medium' wrap>
                            {props.values.signatures.map((signature, index) =>
                                <Box key={signature.id} margin={{top: 'small'}} background='white' alignSelf='start' round='xsmall' elevation='xsmall' pad='small'>
                                    <SignaturePadBox disabled={completed} signature={signature} onChange={(path) => {
                                        let signatures = [...props.values.signatures];
                                        signatures[index] = {...signatures[index], sign_path_data: path};
                                        props.setFieldValue('signatures', signatures);
                                    }}/>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box direction='row' gap='small' margin={{top: 'medium'}}>
                        <Button label='Sign & Complete' type='submit'
                                onClick={() => props.setFieldValue('status_id', TX_PLAN_REVIEW_STATUS.COMPLETED)}
                                primary disabled={completed}/>
                        <Button label='Save' type='submit' primary color='active'
                                onClick={() => props.setFieldValue('status_id', TX_PLAN_REVIEW_STATUS.PENDING)}
                                disabled={completed}/>
                        <Button label='Back' onClick={() => history.goBack()} primary color='gray'/>
                        <DeleteButton
                            query={DELETE_REVIEW}
                            variables={{id: review_id}}
                            onRemove={() => history.goBack()}
                            buttonProps={{
                                label: 'Delete',
                                primary: true,
                                color: 'status-error',
                                icon: undefined,
                                disabled: completed,
                                tip: completed ? 'Signed reviews cannot be deleted' : undefined
                            }}
                        />
                    </Box>
                    {props.status && <Notification
                        title={props.status.message}
                        onClose={() => props.setStatus(undefined)}
                        status={props.status.status}
                        toast={{autoClose: true, position: 'bottom-right'}}
                    />}
                </Form>
            )}
        </Formik>
    );
}