import React from 'react';
import {Box, Text, ResponsiveContext, Grid} from "grommet";
import Page from "../../components/Page";
import RoutedButton from "../../components/RoutedButton";
import Staff from "../../components/icons/Staff";
import StaffImport from "../../components/icons/StaffImport";

export const CreateStaffPage = () => (
    <Page title="New Staff Member">
        <Box>
            <ResponsiveContext.Consumer>
                {(deviceSize) => (
                    <Grid
                        gap='medium'
                        fill
                        columns={deviceSize === 'small' ? 'auto' : {count: 'fit', size: 'small'}}
                    >
                        <Box background='white' fill align='center' round="xsmall" elevation="xsmall"
                             pad={{vertical: 'large'}}>
                            <Staff active style={{width: '50px', height: '50px'}}/>
                            <Text size='large' margin='small' weight='bold'>Invite Staff Members</Text>
                            <Text size='small' margin='xsmall'>Invite staff members by email.</Text>
                            <RoutedButton path='/staff/invite' label='Invite' primary margin='small'/>
                        </Box>
                        <Box background='white' fill align='center' round="xsmall" elevation="xsmall"
                             pad={{vertical: 'large'}}>
                            <StaffImport active style={{width: '50px', height: '50px'}}/>
                            <Text size='large' margin='small' weight='bold'>Import Staff Members</Text>
                            <Text size='small' margin='xsmall'>Import staff members directly from EHR/CRM system.</Text>
                            <RoutedButton path='/staff/import' label='Coming Soon' primary disabled margin='small'/>
                        </Box>
                    </Grid>
                )}
            </ResponsiveContext.Consumer>
        </Box>
    </Page>
);
