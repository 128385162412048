import React from 'react';
import {FormField, TextInput, DateInput, Text, Box} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import moment from "moment";
import {NA} from "../../../../lib/constants";

export const DateInputField = ({id, title, value, error, disabled, setFieldValue, preview}) => {
    return (
        <FormField label={title} error={error} flex={false}>
            {preview ? <Box pad='small' border round='xsmall'><Text>{value ? moment(value).format("mm/dd/yyyy") : NA}</Text></Box> : <DateInput
                format="mm/dd/yyyy"
                value={value || ''}
                onChange={({ value }) => {setFieldValue(id, value)}}
                disabled={disabled}
                name={id}
            />}
        </FormField>
    )
};

DateInputField.defaultSize = {
    w: 2,
    h: 2,
    minW: 2,
    minH: 2,
    maxH: 2
};

DateInputField.title = 'Date'

const DateInputSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
});

export const DateInputFieldProperties = ({title, updateField, id, item}) => {
    return (

        <Formik
            // same as onChange. Formik calls both validate and validationSchema.
            validate={(values) => {
                updateField(id, values)
            }}
            initialValues={{title}}
            validationSchema={DateInputSchema}
            enableReinitialize
            onSubmit={() => {
            }}
        >
            {({errors, values, handleChange}) => (
                <Form>
                    <FormField label="Title" error={errors.title}>
                        <TextInput
                            name="title"
                            value={values.title || ""}
                            onChange={handleChange}
                        />
                    </FormField>
                </Form>
            )
            }
        </Formik>
    )}