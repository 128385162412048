import React from 'react';
import {Box, FormField, RadioButton, Text} from "grommet";

const RadioButtonList = ({id, title, options, error, value, setFieldValue, disabled}) => (
    <Box gap="small">
        <Text color="lightgray">{title}</Text>
        <FormField error={error} className="surveyFormInput">
            {options.map((option) => {
                return (
                    <Box key={option.id} pad='xsmall'>
                        <RadioButton
                            key={option.id}
                            disabled={disabled}
                            name={`${id}[${option.id}]`}
                            label={option.content}
                            onChange={() => setFieldValue(id, option)}
                            checked={value && value.id === option.id}
                        />
                    </Box>
                )
            })}
        </FormField>
    </Box>
);

export default RadioButtonList;
