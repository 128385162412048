export const SESSION_STATUS = {
    ACTIVE: 1,
    PENDING: 2,
    COMPLETED: 3
}

export const TX_PLAN_REVIEW_STATUS = {
    ACTIVE: 1,
    PENDING: 2,
    COMPLETED: 3
}

export const TX_PLAN_STATUS = {
    COMPLETED: 5
}

export const SessionStatusColor = {
    1: {color: 'brand'},
    2: {color: 'yellow'},
    3: {color: 'gray'},
};

export const UserEngagement = {
    0: {color: 'lightred'},
    1: {color: 'green'}
};

export const SurveyResultStatus = {
    1: {color: 'statusPending'},
    2: {color: 'status-warning'},
    3: {color: 'status-ok'}
};

export const MeetingType = {
    ONLINE: 1,
    PHYSICAL: 2
}

export const BillStatus = {
    WAITING_PAYMENT: 1,
    PAID: 2,
}

export const MeetingTypeLabels = {
    [MeetingType.ONLINE]: 'Online',
    [MeetingType.PHYSICAL]: 'Physical'
}

export const MeetingTypeTags = {
    [MeetingType.ONLINE]: {color: '#6943F0'},
    [MeetingType.PHYSICAL]: {color: '#859CB3'},
};

export const BillStatusTags = {
    [BillStatus.WAITING_PAYMENT]: {color: 'status-warning'},
    [BillStatus.PAID]: {color: 'status-ok'},
}

export const TxPlanStatus = {
    1: {color: '#859CB3'},
    2: {color: '#6943F0'},
    3: {color: 'status-warning'},
    4: {color: '#4977F6'},
    5: {color: 'status-ok'},
}


export const ASSESSMENT = 1;
export const CONSENT = 2;
export const MEDICAL = 3;
export const DISCHARGE = 4;
export const NOTE = 5;

export const NA = 'N/A';

export const PROVIDER = 'Provider';
export const MANAGER = 'ProviderManager';
export const COMPANY_OWNER = 'CompanyOwner';

export const PROFESSIONAL_PLAN = 2;
export const STANDARD_PLAN = 1;

export const PROFESSIONAL_PLAN_MONTHLY = '$499/month';
export const STANDARD_PLAN_MONTHLY = '$399/month';

export const EHRS = ['ZenCharts', 'Paper', 'BestNotes', 'Netsmart', 'KIPU', 'Alleva', 'Lightning Step', 'Sunwave', 'Azzly', 'Other'];
export const DOCUMENT_STATUS = {
    NEW: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3
}

export const TIME_UNITS = {
    MINUTES: 'min',
    HOURS: 'hours',
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months'
};

export const INTERCOM_APP_ID = 'wav75rbw';


export const EMAIL_MASK = [
    {
        regexp: /^[\w\-_.]+$/,
        placeholder: "example"
    },
    {fixed: "@"},
    {
        regexp: /^[\w]+$/,
        placeholder: "my"
    },
    {fixed: "."},
    {
        regexp: /^[\w]+$/,
        placeholder: "com"
    }
];

export const PHONE_NUMBER_MASK = [
    {fixed: "("},
    {
        length: 1,
        regexp: /^[1-9]$/,
        placeholder: "x"
    },
    {
        length: 2,
        regexp: /^[0-9]{1,2}$/,
        placeholder: "xx"
    },
    {fixed: ")"},
    {fixed: " "},
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx"
    },
    {fixed: "-"},
    {
        length: 4,
        regexp: /^[0-9]{1,4}$/,
        placeholder: "xxxx"
    }
];

export const EXPIRATION_DATE_MASK = [
    {
        length: 2,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "mm"
    },
    {fixed: '/'},
    {
        length: 2,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "yy"
    },
]
