import React from 'react'
import {colors} from "../../theme";

function Check(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 45 46" {...props}>
            <g fillRule="nonzero" fill="none">
                <path
                    d="M21.14 28.156c.44 0 .873-.065 1.298-.197a3.81 3.81 0 001.166-.594c.147-.118.286-.235.418-.352.132-.118.257-.25.374-.396l20.281-24.33c.118-.146.206-.307.264-.483.059-.176.074-.352.044-.528a1.13 1.13 0 00-.132-.55 1.373 1.373 0 00-.352-.418 3.078 3.078 0 00-.44-.22 1.402 1.402 0 00-1.078.044 1.25 1.25 0 00-.461.352L22.24 24.813l-.11.11-.154.154c-.117.088-.25.154-.396.198a1.524 1.524 0 01-.44.066.362.362 0 01-.11-.022.487.487 0 00-.154-.022 1.35 1.35 0 01-.506-.198 1.7 1.7 0 01-.418-.374l-6.203-8.667a1.227 1.227 0 00-.484-.418 1.455 1.455 0 00-1.078-.088c-.132.044-.256.11-.374.198a1.23 1.23 0 00-.571.902c-.06.396.014.74.22 1.034l6.203 8.667c.322.469.726.85 1.21 1.144a4.27 4.27 0 001.561.572 2.968 2.968 0 00.704.087z"
                    fill={colors.green}
                />
                <path
                    d="M21.052 45.006c1.848 0 3.637-.234 5.368-.704 1.76-.44 3.41-1.092 4.949-1.957a21.09 21.09 0 004.246-3.146 20.536 20.536 0 003.431-4.18 23.006 23.006 0 002.156-4.75c.528-1.673.843-3.359.946-5.06a22.103 22.103 0 00-.286-5.104 21.79 21.79 0 00-1.584-4.97 1.357 1.357 0 00-.528-.617 1.388 1.388 0 00-.748-.22.975.975 0 00-.308.044c-.088.03-.19.059-.308.088a2.174 2.174 0 00-.418.33 1.167 1.167 0 00-.286.462c-.088.176-.124.36-.11.55.015.19.066.36.154.506a21.014 21.014 0 011.364 4.311c.264 1.496.345 2.985.242 4.466a19.52 19.52 0 01-.814 4.377 16.692 16.692 0 01-1.892 4.092 17.689 17.689 0 01-2.947 3.651 18.137 18.137 0 01-3.696 2.706 17.87 17.87 0 01-4.267 1.694c-1.496.41-3.05.616-4.664.616-1.7 0-3.365-.235-4.993-.704a18.667 18.667 0 01-4.597-2.024c-2.142-1.35-3.901-3.006-5.28-4.971-1.378-1.995-2.339-4.128-2.881-6.401-.543-2.274-.638-4.613-.286-7.018.352-2.375 1.202-4.634 2.551-6.775a17.689 17.689 0 012.948-3.651 18.407 18.407 0 017.963-4.444 18.807 18.807 0 014.663-.572c1.672 0 3.322.235 4.95.704 1.627.47 3.16 1.144 4.597 2.024.117.059.242.11.374.154s.257.066.374.066c.264 0 .499-.059.704-.176.205-.117.381-.279.528-.484.088-.176.147-.352.176-.528.03-.176.015-.352-.044-.528a1.202 1.202 0 00-.198-.528 1.097 1.097 0 00-.418-.352A21.583 21.583 0 0026.86 3.63a20.497 20.497 0 00-5.72-.814c-1.847 0-3.651.234-5.411.704a20.596 20.596 0 00-4.905 1.935 19.647 19.647 0 00-4.246 3.168 20.536 20.536 0 00-3.431 4.18C1.62 15.265.654 17.875.243 20.632c-.41 2.787-.3 5.492.33 8.117.63 2.625 1.738 5.082 3.321 7.37 1.584 2.287 3.623 4.194 6.116 5.719a20.737 20.737 0 005.301 2.354c1.892.542 3.806.813 5.741.813z"
                    fill={colors.brand}
                />
            </g>
        </svg>
    )
}

export default Check
