import React, {useEffect, useState} from 'react'
import Page from "../../components/Page";
import {Anchor, Box, Button, Grid, Paragraph, Text} from "grommet";
import {ExcelImport} from "../../components/ExcelImport";
import {useExcelToJson} from "../../lib/useExcelToJson";
import {ImportClientsPreview} from "./components/ImportClientsPreview";
import API from "../../services/api";
import HorizontalDivider from "../../components/HorizontalDivider";
import {ImportProgress, ImportResultsView, StartImportView} from "./components/ClientsImportSummaryStages";

const CLIENT_IMPORT_FIELDS = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "gender",
    "dateOfBirth",
    "mrNumber",
    "sessionStatus",
    "diagnoses",
    "recoveryDate"
];


const ClientsImportStages = {
    FILE_UPLOAD: 1,
    START_IMPORT: 2,
    IMPORTING: 3,
    FINISHED_IMPORTING: 4
};

const ImportClientsPage = () => {

    const [importStage, setImportStage] = useState(ClientsImportStages.FILE_UPLOAD);
    const [clientsImportFile, setClientsImportFile] = useState(undefined);
    const [clientsData, setClientsData] = useState(undefined);
    const [processedClientsCount, setProcessedClientsCount] = useState(0);
    const [failedClients, setFailedClients] = useState([]);

    // Skip the first row as it is the human-readable client import fields
    const {data, error: parseError} = useExcelToJson(clientsImportFile, CLIENT_IMPORT_FIELDS, 1);

    useEffect(() => {
        if (!!data) {
            const clients = data.map((client, idx) => {
                return {...client, rowNumber: idx + 1}
            });
            setClientsData(clients);
            // Move to next stage of the importing
            setImportStage(ClientsImportStages.START_IMPORT)
        }
    }, [data]);


    const importClients = async () => {
        setImportStage(ClientsImportStages.IMPORTING);
        // TODO: fiddle with the await to send the request concurrently
        for (const client of clientsData) {
            try {
                await API.post('/session/import', {
                    ...client,
                    recoveryDate: new Date(client.recoveryDate),
                    dateOfBirth: new Date(client.dateOfBirth)
                });
            } catch (e) {
                setFailedClients((failedSoFar => [...failedSoFar, client]));
            }
            setProcessedClientsCount(countSoFar => countSoFar + 1);
        }
        setImportStage(ClientsImportStages.FINISHED_IMPORTING);

    };

    const renderClientsImportSummary = () => {
        switch (importStage) {
            case ClientsImportStages.FILE_UPLOAD:
                return <ExcelImport setFile={setClientsImportFile} error={parseError}/>;
            case ClientsImportStages.START_IMPORT:
                return <StartImportView importClients={importClients}
                                        totalClients={clientsData.length}
                                        backToPreviousStage={() => setImportStage(ClientsImportStages.FILE_UPLOAD)}
                />;
            case ClientsImportStages.IMPORTING:
                return <ImportProgress currClientNumber={processedClientsCount} totalClients={clientsData.length}/>;
            case ClientsImportStages.FINISHED_IMPORTING:
                return <ImportResultsView failedClients={failedClients} totalClients={clientsData.length}/>;
            default:
                return <ExcelImport setFile={setClientsImportFile}/>;
        }
    };


    return (
        <Page title='Import Clients' boxProps={{pad: "large"}}>
            <Grid rows={['medium', 'auto']}
                  columns={['1/3', '2/3']}
                  areas={[
                      ['import', 'info'],
                      ['clients-preview', 'clients-preview']
                  ]}
                  gap="medium"
            >
                <Box gridArea='import' fill background='white' elevation='xsmall' round='xsmall'>
                    {renderClientsImportSummary()}
                </Box>
                <Box pad="large" gap="small" gridArea="info" fill background='white' elevation='xsmall' round='xsmall'>
                    <Box direction="row" gap="large" justify="between">
                        <Box gap="small">
                            <Text weight="bold">Use our Excel Template</Text>
                            <Paragraph color="grey">Please make sure to download the Import Template in <br/>
                                order to map the fields correctly.
                            </Paragraph>
                            <Anchor
                                href="https://storage.googleapis.com/unaffiliated-production.appspot.com/CO-Clients-Import-Template.xlsx"
                            >
                                <Button fill label="Download Template"/>
                            </Anchor>
                            <Box align='center'>
                                <Anchor justify="center" label="Client Import Documentation" target="_blank"
                                        href="https://storage.googleapis.com/unaffiliated-production.appspot.com/CO%20Client%20Import%20Documentation.pdf"
                                />
                            </Box>
                        </Box>
                        <Box gap="small">
                            <Text weight="bold">Attention: Duplicate Clients will be Ignored</Text>
                            <Paragraph color="grey">
                                If you import duplicate clients, they will be ignored by the import engine.
                                This logic may change in the future.
                            </Paragraph>

                            {/*TODO: The following code is to be implemented in the future: */}
                            {/*<Text weight="bold">Duplicate Clients Management</Text>*/}
                            {/*<Text color="grey">Choose how to handle importing duplicate clients </Text>*/}
                            {/*<Text color="grey">How to handle duplicates</Text>*/}
                            {/*<Select*/}
                            {/*    options={['Do not update', 'Update only new information', 'Update new and existing info']}*/}
                            {/*    disabled*/}
                            {/*    placeholder='Coming soon...'*/}
                            {/*/>*/}
                        </Box>
                    </Box>
                    <HorizontalDivider/>
                    <Box>
                        {/*TODO: make duplicate clients info component and show the info here*/}
                        <Text weight="bold">Please Import a Template to see more details.</Text>
                    </Box>

                </Box>
                <Box gridArea="clients-preview">
                    {clientsData && <ImportClientsPreview clientsData={clientsData}/>}
                </Box>
            </Grid>
        </Page>
    )
};

export default ImportClientsPage;
