import React from 'react'
// import {colors} from "../../theme";

const Gears = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M9.29 8.173l-.629.703a.556.556 0 01-.824-.003l-.624-.697a2.057 2.057 0 00-3.581 1.48l.047.934a.556.556 0 01-.583.584l-.935-.047a2.056 2.056 0 00-1.483 3.578l.696.628a.557.557 0 01-.001.826l-.694.626a2.056 2.056 0 001.482 3.579l.934-.047a.557.557 0 01.584.584l-.047.934a2.056 2.056 0 003.578 1.483l.63-.703a.556.556 0 01.824.003l.624.697a2.057 2.057 0 003.581-1.48l-.047-.934a.556.556 0 01.583-.584l.935.047a2.055 2.055 0 001.48-3.58l-.692-.626a.556.556 0 010-.825l.694-.627a2.055 2.055 0 00-1.483-3.579l-.933.047a.553.553 0 01-.584-.58l.047-.938a2.056 2.056 0 00-3.578-1.483zm1.739.865c.217.09.354.307.342.542l-.047.934a2.048 2.048 0 002.162 2.158l.93-.047a.555.555 0 01.401.967l-.695.627a2.056 2.056 0 000 3.053l.694.627a.555.555 0 01-.4.967l-.934-.047a2.054 2.054 0 00-2.158 2.159l.047.933a.556.556 0 01-.968.401l-.624-.697a2.06 2.06 0 00-3.054-.003l-.63.703a.555.555 0 01-.965-.404l.047-.934a2.057 2.057 0 00-2.158-2.158l-.934.047a.556.556 0 01-.401-.968l.694-.626a2.059 2.059 0 00.001-3.052l-.696-.628a.556.556 0 01.402-.967l.934.047a2.054 2.054 0 002.158-2.159L5.13 9.58a.556.556 0 01.968-.401l.624.697a2.06 2.06 0 003.054.003l.63-.703a.554.554 0 01.528-.167l.095.03z"
                fill="#3F75FF"
            />
            <path
                d="M8.25 13.126a2.625 2.625 0 100 5.25 2.625 2.625 0 000-5.25zm0 1.5a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25zM18.638 4.25l.123.007a1.125 1.125 0 11-.246 0l.123-.007z"
                fill="#28CC8B"
            />
            <path
                d="M17.214.065a1.499 1.499 0 00-1.093 1.222l-.212 1.36-1.363.212a1.499 1.499 0 00-.71 2.65l1.075.864-.5 1.286a1.5 1.5 0 001.942 1.942l1.285-.5.864 1.076a1.5 1.5 0 001.558.51l.14-.046a1.5 1.5 0 00.954-1.174l.211-1.364 1.364-.21a1.5 1.5 0 00.71-2.653l-1.075-.863.5-1.286a1.5 1.5 0 00-1.942-1.942l-1.286.5-.864-1.073a1.499 1.499 0 00-1.558-.51zm.178 2.812l.211-1.361.865 1.074a1.5 1.5 0 001.712.458l1.286-.501-.5 1.286a1.499 1.499 0 00.458 1.713l1.075.864-1.363.21a1.499 1.499 0 00-1.253 1.253l-.211 1.364-.864-1.075a1.501 1.501 0 00-1.711-.46l-1.288.501.5-1.286.047-.142a1.5 1.5 0 00-.505-1.57l-1.073-.864 1.36-.21a1.501 1.501 0 001.254-1.254z"
                fill="#3F75FF"
            />
        </g>
    </svg>
);

export default Gears
