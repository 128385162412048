import React from 'react';
import Page from "../../components/Page";
import {Form, Formik} from "formik";
import IField, {CARD_TYPE} from "react-cardknox-ifields";
import {PAYMENT_ACCOUNT, PAYMENT_OPTIONS} from "../../services/payment";
import {Box, FormField, MaskedInput, Text, TextInput} from "grommet";
import {EXPIRATION_DATE_MASK} from "../../lib/constants";
import './style.css';
import * as Yup from "yup";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {useHistory} from "react-router-dom";
import {LoadingButton} from "../../components/LoadingButton";

const validationSchema = Yup.object().shape({
    Name: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Too Long!')
        .required('Required'),
    Token: Yup.string().required('Required'),
    Exp: Yup.string().required('Required'),
    Zip: Yup.string().required('Required').test('len', 'Invalid', val => val?.length === 5),
    Street: Yup.string(),
    City: Yup.string(),
});

const MUTATION = gql`
    mutation($input: payment_method_input!) {
        create_payment_method(data: $input) {
            Result
            Error
            PaymentMethodId
        }
    }
`;

export const CreatePaymentMethod = () => {
    const cardNumberRef = React.useRef(null);
    const history = useHistory();
    const [create, {loading}] = useMutation(MUTATION);
    return (
        <Page title='Create Payment Method'>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    Token: '',
                    Exp: '',
                    Name: '',
                    Street: '',
                    Zip: '',
                    City: '',
                }} onSubmit={async (values, actions) => {
                    console.log('valu', values);
                    const {City, ...input} = values
                    actions.setStatus(null);
                    try {
                        await create({variables: {input}, refetchQueries: ['fetchPaymentData']});
                        history.push('/billing');
                    } catch (e) {
                        actions.setStatus('error');
                    }
                }}>
                {({setFieldValue, errors, values, handleChange, handleBlur, status}) => (
                    <Form>
                        <Box background="white" pad="medium" round="small" elevation="xsmall" width={{max: 'large'}}>
                            <FormField label="Cardholder Name (as it appears on the card)" error={errors.Name}>
                                <TextInput
                                    name="Name"
                                    value={values.Name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <Box direction="row-responsive" gap="small">
                                <FormField label="Card Number" error={errors.Token}>
                                    <IField
                                        onUpdate={() => cardNumberRef?.current?.getToken()}
                                        ref={cardNumberRef}
                                        onToken={(data) => setFieldValue('Token', data.xToken)}
                                        type={CARD_TYPE}
                                        account={PAYMENT_ACCOUNT}
                                        src={process.env.REACT_APP_IFIELDS_SRC}
                                        options={PAYMENT_OPTIONS}/>
                                    <TextInput type="hidden" name="Token" value={values.Token}/>
                                </FormField>
                                <FormField label="Expiration Date" error={errors.Exp} width="xsmall">
                                    <MaskedInput
                                        name='Exp'
                                        mask={EXPIRATION_DATE_MASK}
                                        value={values.Exp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormField>
                            </Box>
                            <Box direction="row-responsive" gap="small">
                                <FormField label="Address" error={errors.Street} width="medium">
                                    <TextInput
                                        name="Street"
                                        onBlur={handleBlur}
                                        value={values.Street}
                                        onChange={handleChange}
                                    />
                                </FormField>
                                <FormField label="City" error={errors.City}>
                                    <TextInput
                                        name="City"
                                        value={values.City}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormField>
                                <FormField label="ZIP" error={errors.Zip} width="xsmall">
                                    <TextInput
                                        name="Zip"
                                        value={values.Zip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormField>
                            </Box>
                            {status && <Text color="lightred">Something went wrong. Please try with another card.</Text>}
                        </Box>
                        <LoadingButton loading={loading} type="submit" primary label="Save" margin={{top: 'medium'}}/>
                    </Form>
                )}
            </Formik>
        </Page>
    )
};