import {Box, Text} from "grommet";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {IndividualMeasurementReportHeader} from "./IndividualMeasurementReportHeader";
import {safeGet} from "../../../utils";
import {Suggestion} from "./Suggestion";
import LineChart from "./LineChart";
import {Legend} from "./Legend";
import {PHQ9_TEMPLATE_ID} from "./constants";
import PHQ9DataTable from "./PHQ9DataTable";

export const result_to_severity = (result, legend) => {

    let severity = {};
    legend.forEach(row => {
        if (result >= row.min_score && result <= row.max_score) {
            severity = {
                label: row.severity,
                color: row.color
            };
        }
    });
    return severity;
};

const PHQ9 = ({session_id, printRef, queryData, status_id}) => {
    const template_id = PHQ9_TEMPLATE_ID;
    const variables = {
        session_id: session_id,
        template_id: template_id,
        status_id: status_id
    };
    const {data, loading} = useQuery(queryData, {variables: variables}); // TODO: Handle Error

    if (loading) {
        return <Spinner full/>;
    }

    const latestResult = data.survey_results[data.survey_results.length - 1];

    return (
        <Box gap="medium">
            <Box justify='evenly' pad={{bottom: 'medium'}} gap='medium' flex="grow">
                <IndividualMeasurementReportHeader reportName={data.template.title}
                                                   printRef={printRef}
                                                   session={data.session}
                                                   twilioChannelSid={data.session?.provider_session?.twilio_channel_sid}
                                                   latestResultOn={safeGet(() => latestResult.updated_at)}
                                                   severity={latestResult ?
                                  result_to_severity(latestResult.score, data.template.metadata.legend) : null}
                />
                {
                    latestResult &&
                    <Box gap='medium'>
                        {latestResult.suggestions.map((suggestion, index) => (
                            <Suggestion key={index} text={suggestion}/>
                        ))}
                        <Text weight="bold">{data.session.client.user.first_name}'s Report</Text>
                        <LineChart
                            data={data.survey_results}
                            colors={['#51DA5D']}
                            width='100%'
                            height='350'
                            categoricalYAxis
                            minTick={data.template.metadata.domain.min}
                            maxTick={data.template.metadata.domain.max}
                            stepSize={data.template.metadata.domain.step_size}
                        />
                    </Box>
                }
                <Text weight="bold">{data.session.client.user.first_name}'s Report Summary</Text>
                <PHQ9DataTable session_id={session_id} status_id={status_id}/>
                <Text weight="bold">{data.template.title} Management Summary</Text>
                <Legend data={data.template.metadata.legend}/>
            </Box>
        </Box>
    );
};

export default (PHQ9);
