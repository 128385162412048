import {colors} from "../theme";

export const PAYMENT_ACCOUNT = {
    xKey: process.env.REACT_APP_IFIELDS_KEY,
    xSoftwareVersion: '1.0.0',
    xSoftwareName: 'React',
}

export const PAYMENT_OPTIONS = {
    iFieldstyle: {
        border: '1px solid ' + colors.border,
        fontSize: '14px',
        padding: '5px',
        width: '350px',
        height: '32px',
        borderRadius: '5px'
    },
    enableLogging: false,
    autoSubmit: true,
};


export const PAYMENT_API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_PAYMENT_API_URL : process.env.REACT_APP_PROD_PAYMENT_API_URL;