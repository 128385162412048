import React, {useEffect, useState} from 'react';
import {Box, Text} from "grommet";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/react-hooks";
import dot from "dot-object";
import {castInput} from "../../../components/SelectClientsByAudiences";
import Spinner from "../../../components/Spinner";

const FETCH_DATA = gql`
    query {
        audience {
            id
            name
            filters {
                id
                filter_value
                operator {
                    id
                    name
                    expression
                }
                operand {
                    id
                    relation
                    options_table
                    type
                    column
                }
            }
        }
    }
`;

const compareValue = (value, filter) => {
    const input = castInput(filter.operand.type, filter.filter_value);
    switch (filter.operator.expression) {
        case '_eq':
            return input === value;
        case '_neq':
            return value !== undefined && input !== value;
        case '_lt':
            return value < input;
        case '_gt':
            return value > input;
        case 'is_null':
            return !value;
        default:
            return !!value;
    }
};

const getSessionAudiences = (audiences, session) =>
    audiences.filter(audience => {
        const filters = audience.filters.map(filter => {
            const relation = dot.pick(`${filter.operand.relation}`, session);
            const relationField = `${filter.operand.column}`;
            // handles has many relation
            if (Array.isArray(relation)) {
                return relation.some(e => compareValue(e[relationField], filter))
            }
            // handles has one relation
            else {
                return compareValue(dot.pick(relationField, relation), filter);
            }
        });

        // If this audience has no filters, don't show it
        if (filters.length === 0)
            return false;

        return filters.every(filter => filter === true);
    }).map(({id, name}) => ({id, name}));


export const ClientAudienceTags = ({session}) => {
    const [audiences, setAudiences] = useState([]);
    const {data, loading, error} = useQuery(FETCH_DATA);

    useEffect(() => {
        if (data) {
            setAudiences(getSessionAudiences(data.audience, session));
        }
    }, [session, data])

    if (loading) {
        return <Spinner full/>
    }

    if (error) {
        return <Text color="red">Can't load audiences for this client</Text>
    }

    return (
        <Box wrap gap="xsmall" direction="row">
            {audiences.length > 0 ? audiences.map(audience =>
                <Box
                    round="xxsmall"
                    border
                    flex={false}
                    direction="row"
                    margin={{bottom: "xsmall"}}
                    width={{max: "xsmall"}}
                    background="light-1"
                    key={audience.id}
                    pad={{horizontal: 'xsmall', vertical: "xxsmall"}}
                >
                    <Text alignSelf="center" weight="bold" color="gray" truncate>{audience.name}</Text>
                </Box>) : <Text color='gray'>No audiences</Text>}
        </Box>
    )
};
