import React from 'react';
import {Box, Text, Grid, ResponsiveContext} from "grommet";
import Page from "../../components/Page";
import RoutedButton from "../../components/RoutedButton";
import Clients from "../../components/icons/Clients";
import ClientsImport from "../../components/icons/ClientsImport";

export const NewClientPage = () => (
    <Page title="New Client">
        <Box>
            <ResponsiveContext.Consumer>
                {(deviceSize) => (
                    <Grid
                        gap='medium'
                        columns={deviceSize === 'small' ? 'auto' : {count: 'fit', size: 'small'}}
                    >
                        <Box background='white' align='center' round="xsmall" elevation="xsmall"
                             pad={{vertical: 'large'}}>
                            <Clients active style={{width: '50px', height: '50px'}}/>
                            <Text size='large' margin='small' weight='bold'>Invite Clients</Text>
                            <Text size='small' margin='xsmall'>Invite clients by email or SMS.</Text>
                            <RoutedButton path='/clients/invite' label='Invite' primary margin='small'/>
                        </Box>
                        <Box background='white' align='center' round="xsmall" elevation="xsmall"
                             pad={{vertical: 'large'}}>
                            <ClientsImport active style={{width: '50px', height: '50px'}}/>
                            <Text size='large' margin='small' weight='bold'>Import Clients</Text>
                            <Text size='small' margin='xsmall'>Import clients directly from EHR/CRM system.</Text>
                            <RoutedButton path='/clients/import' label='Import' primary margin='small'/>
                        </Box>
                    </Grid>
                )}
            </ResponsiveContext.Consumer>
        </Box>
    </Page>
);
