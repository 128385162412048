import React from 'react';
import {Box, Button, FormField, Text, TextInput} from "grommet";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import Spinner from "../../../components/Spinner";
import {useMutation, useQuery} from "@apollo/react-hooks";

const FETCH = gql`
    query ($id: Int!){
        id @client @export(as: "id")
        id @client
        provider: provider_by_pk(id: $id) {
            id
            appointments_gap
            appointment_duration
        }
    }
`;

const UPDATE = gql`
    mutation ($id: Int! $gap: Int! $duration: Int!) {
        update_provider_by_pk(pk_columns: {id: $id}, _set: {appointments_gap: $gap, appointment_duration: $duration}) {
            id
            appointments_gap
            appointment_duration
        }
    }`

const validationSchema = Yup.object().shape({
    duration: Yup.number().required('Please select a duration'),
    gap: Yup.number().required('Please select a gap'),
});


export const Timeslots = () => {
    const {data, loading} = useQuery(FETCH);
    const [update] = useMutation(UPDATE);
    if (loading) return <Spinner/>;
    return (
        <Box gap="small" pad="small">
            <Text weight="bold">Default Session Type & Duration</Text>
            <Formik validationSchema={validationSchema} initialValues={{
                duration: data?.provider?.appointment_duration,
                gap: data?.provider?.appointments_gap,
            }}
                    onSubmit={async (values, actions) => {
                        await update({variables: {...values, id: data?.id}});
                    }}>
                {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                    <Form>
                        <Box background="white" elevation="xsmall" round="xsmall" direction="row" gap="small"
                             alignSelf="start" pad="small">

                            <FormField label="Appointments duration (minutes)" error={errors.duration}>
                                <TextInput name="duration" onChange={handleChange} value={values.duration}/>
                            </FormField>
                            <FormField label="Gap between appointments (minutes)" error={errors.gap}>
                                <TextInput name="gap" onChange={handleChange} value={values.gap}/>
                            </FormField>
                        </Box>
                        <Button primary type="submit" label="Save" margin={{top: 'small'}}/>
                    </Form>
                )}

            </Formik>
        </Box>
    );
}