import React, {forwardRef} from 'react';
import {PrintPage} from "../PrintPage";
import {Box} from "grommet";
import SurveyReport from "./components/SurveyReport";

export const BasicReportPrintPage = forwardRef(({survey}, ref) => (
    <Box style={{ overflow: 'hidden', height: '0'}}>
        <PrintPage ref={ref} gap='small'>
            <SurveyReport
                template={survey.survey}
                answers={survey.data}
                surveysCount={survey.numberOfResults}
            />
        </PrintPage>
    </Box>
));