import React, {useContext} from 'react';
import {Box, Select, Text, ResponsiveContext, Grid, Button} from "grommet";
import {Print} from 'grommet-icons';
import ReactToPrint from "react-to-print";

const pageTitle = document.title;

export const ComparisonMeasurementReportHeader = ({templates, template, setTemplate, printRef, ...rest}) => {
    const deviceSize = useContext(ResponsiveContext);
    return (
        <Box {...rest}>
            <Grid
                align="start"
                columns={deviceSize === 'small' ? 'auto' : ["1/3", "2/3"]}
            >
                <Box direction='column' gap='xsmall'
                     border={deviceSize !== "small" ? [{side: "left"}, {side: "horizontal"}] : [{side: "top"}, {side: "vertical"}]}
                     fill
                     round={{size: "xsmall", corner: deviceSize === "small" ? "top" : "left"}}
                     pad={{horizontal: "medium", vertical: "small"}}
                >
                    <Text weight='bold' color='dark'>Choose Survey</Text>
                    <Select
                        options={templates}
                        value={template}
                        valueKey="id"
                        labelKey="title"
                        placeholder='Select survey template'
                        onChange={({option}) => setTemplate(option)}
                    />
                </Box>
                <Box direction='column'
                     border
                     round={{size: "xsmall", corner: deviceSize === "small" ? "bottom" : "right"}}
                     background='white'
                     pad={{horizontal: 'medium', vertical: 'medium'}}>
                    <Box direction='row' fill='vertical' justify='between'>
                        <Box direction='column' gap='medium'>
                            <Text weight='bold' color='dark'>Comparing Clients</Text>
                            <Text color='brand'>Comparing Multiple Clients Measurements</Text>
                        </Box>
                        <Box direction='column' align='center' justify='center' gap='medium'>
                            <ReactToPrint trigger={() => <Button plain icon={<Print/>}/>}
                                          content={() => printRef.current}
                                          onBeforePrint={() => {
                                              let date = new Date().toLocaleDateString('us-US');
                                              document.title = `${date}_${template.title}_comparison`
                                          }}
                                          onAfterPrint={() => {
                                              document.title = pageTitle;
                                          }}
                            />
                            {/*<Download color='gray' size='20px'/>*/}
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
};
