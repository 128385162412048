import React, {useMemo} from "react";
import {SelectClientsByAudiences} from "../../../components/SelectClientsByAudiences";
import {buildWhereClauseCaseload} from "../../../components/ClientsSelect";

// Returns all sessions that are assigned to provider with id 'id' and all unassigned sessions
const buildWhereClauseProvider = (id) =>
    ({_or: [{_not: {provider_session: {}}}, {provider_session: {provider_id: {_eq: id}}}]});

export const StaffCaseload = ({providerId: id, ...rest}) =>
    <SelectClientsByAudiences
        boxProps={{background: "white", pad: "medium", round: "xsmall", elevation: "xsmall", flex: false}}
        {...rest}
        fetchPolicy='network-only'
        staticFilter={useMemo(() => buildWhereClauseProvider(id), [id])}
        additionalFilter={useMemo(() => buildWhereClauseCaseload(id), [id])}
    />;