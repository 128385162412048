import React from 'react';
import {Cube} from "grommet-icons";
import {Box, Text} from "grommet";

export const DroppableField = ({label, type, icon = <Cube color='green'/>, onClick = () => {}}) => (
    <Box
        // className="droppable-element"
        draggable={true}
        unselectable="on"
        pad='medium'
        border
        gap='xsmall'
        round='xsmall'
        align='center'
        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        onDragStart={e => {e.dataTransfer.setData("text/plain", type); onClick(type)}}
    >
        {icon}
        <Text>{label}</Text>
    </Box>
)