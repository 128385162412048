import React, {useState} from 'react';
import MultiSelect from "../../../components/input/MultiSelect";
import {Box, Button, Grid, Text} from "grommet";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import Spinner from "../../../components/Spinner";
import {Checkmark, Close} from "grommet-icons";
import PropTypes from "prop-types";

const FETCH_DATA = gql`
    query {
        diagnoses: diagnosis {
            id
            name: icd10_code
        }
    }
`;

const SAVE_SESSION_DIAGNOSES = gql`
    mutation($sessionId: Int!, $diagnoses: [session_diagnosis_insert_input!]!) {
        delete_session_diagnosis(where: {session_id: {_eq: $sessionId}}) {
            affected_rows
        }
        insert_session_diagnosis(objects: $diagnoses) {
            affected_rows
        }
    }
`;

export const EditSessionDiagnosesField = ({sessionId, currentDiagnoses = [], onCancel = () => {}, onAccept = () => {}, refetchQueries = []}) => {

    const [diagnoses, setDiagnoses] = useState([]);
    const {data = {tags: []}, loading, error} = useQuery(FETCH_DATA);
    const [saveSessionDiagnoses, {loading: mutationLoading, error: mutationError}] = useMutation(SAVE_SESSION_DIAGNOSES, {
        refetchQueries, onCompleted: onAccept
    });
    if (loading || mutationLoading) {
        return <Spinner full/>
    }
    if (error) {
        return <Text color='red'>Error encountered when trying to get all diagnoses.</Text>
    }

    return (
        <Grid
            align='start'
            rows={['auto']}
            columns={['2/3', '1/3']}
            areas={[
                {name: 'select', start: [0, 0], end: [0, 0]},
                {name: 'actions', start: [1, 0], end: [1, 0]},
            ]}
            gap='small'
        >
            <Box gridArea='select'>
                <MultiSelect
                    options={data.diagnoses}
                    handleChange={setDiagnoses}
                    preselectedOptions={data.diagnoses.filter(diagnose => currentDiagnoses.includes(diagnose.id))}
                />
            </Box>
            <Box gridArea='actions' direction='row' gap='small' alignSelf='center'>
                <Button plain icon={<Checkmark/>} onClick={() => saveSessionDiagnoses({
                    variables: {
                        sessionId, diagnoses: diagnoses.map(diagnose => ({
                            session_id: sessionId,
                            diagnosis_id: diagnose.id
                        }))
                    }
                })}/>
                <Button plain icon={<Close/>} onClick={onCancel}/>
            </Box>
            {mutationError && <Text color='red'>Could not save the changes.</Text>}
        </Grid>
    )
};

EditSessionDiagnosesField.propTypes = {
    sessionId: PropTypes.number.isRequired,
    currentDiagnoses: PropTypes.arrayOf(PropTypes.number), // ids of the selected options
    refetchQueries: PropTypes.array,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
};

