import React, {Component} from "react";
import {Box, Button, Collapsible, Text} from "grommet";
import RoutedButton from "./RoutedButton";

export class MenuButton extends Component {

    render() {
        const {label, icon, submenuItemOpened, submenu, onButtonClick, ...rest} = this.props;
        const isActive = (submenuItemOpened === label);
        const border = isActive ? activeBorder : inactiveBorder;
        const color = isActive ? "brand" : "sidebarInactive";
        const Icon = icon;
        const SidebarButton = submenu ? Button : RoutedButton;

        return (
            <Box>
                <SidebarButton className="sidebarButton"
                               onClick={() => {
                                   onButtonClick(label)
                               }}
                               hoverIndicator={{color: 'accent'}}
                               {...rest}
                               active={false}
                >
                    <Box pad={{vertical: "small", left: "20px"}}
                         gap="20px"
                         border={border}
                         direction="row"
                         flex
                         justify="start"
                         alight="center"
                    >
                        {Icon && <Icon style={{alignSelf: 'center', width: '25px', height: '25px'}} active={isActive}/>}
                        <Text className={"sidebarButtonText" + (isActive ? ' active' : '')}
                              weight='bold' style={{lineHeight: '26px'}}
                              color={color}>{label}</Text>
                    </Box>
                </SidebarButton>
                {submenu && this.renderSubmenu(submenu, isActive)}
            </Box>
        );
    }

    renderSubmenu(items, isOpened) {
        return (
            <Collapsible open={isOpened}>
                {items.map(this.renderSubmenuItem)}
            </Collapsible>
        );
    }

    renderSubmenuItem({label, active, ...rest}) {
        const color = active ? "dark" : "gray";
        return (
            <RoutedButton hoverIndicator={{color: 'accent'}}
                          key={label}
                          {...rest}
            >
                <Box pad={{vertical: "small", left: "32.5px"}} // To align it with the text from the main category
                     margin={{left: "35px"}}
                     gap="20px"
                     border={{
                         color: 'divider',
                         size: 'xsmall',
                         side: 'left'
                     }}
                     flex
                     direction="row"
                     justify="start"
                     alight="center"
                >
                    <Text className="sidebarButtonText"
                          style={{fontWeight: 600}}
                          color={color}
                    >
                        {label}
                    </Text>
                </Box>
            </RoutedButton>
        );
    }
}

const activeBorder = {
    color: 'brand',
    size: 'medium',
    side: 'left'
};


const inactiveBorder = {
    color: "light-1",
    size: 'medium',
    side: 'left'
};
