import React, {useEffect, useMemo, useState} from 'react';
import {Anchor, Box, DropButton, InfiniteScroll, Stack, Text} from "grommet";
import Bell from "./icons/Bell";
import {Refresh} from "grommet-icons";
import Spinner from "./Spinner";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Link} from "react-router-dom";
import {NotificationRow} from "./NotificationRow";
import {GreenDot} from "./GreenDot";
import {DeleteButton} from "./DeleteButton";

const FETCH_NOTIFICATIONS = gql`
    query fetch_all_notifications ($limit: Int! $offset: Int!){
        notifications: provider_notification(order_by: {created_at: desc} limit: $limit offset: $offset) {
            id
            title
            body
            path
            created_at
            seen_at
            notification_type: provider_notification_type {
                id
                name
            }
        }
    }
`;

const SUBSCRIBE_NOTIFICATIONS = gql`
    subscription {
        notification: provider_notification(order_by: {created_at: desc} limit: 1) {
            id
            title
            body
            path
            created_at
            seen_at
            notification_type: provider_notification_type {
                id
                name
            }
        }
    }
`;

const MARK_ALL_READ = gql`
    mutation($date: timestamptz) {
        update_provider_notification(where: {} _set: {seen_at: $date}) {
            affected_rows
        }
    }
`;

const MARK_READ = gql`
    mutation($date: timestamptz $ids: [Int!]!) {
        update_provider_notification(where: {id: {_in: $ids}} _set: {seen_at: $date}) {
            returning {
                id
                title
                body
                path
                created_at
                seen_at
                notification_type: provider_notification_type {
                    id
                    name
                }
            }
        }
    }
`;

const DELETE_ALL_NOTIFICATIONS = gql`
    mutation {
        delete_provider_notification(where: {}) {
            affected_rows
        }
    }
`

const QUERY_LIMIT = 10;

export const GeneralNotifications = () => {
    const [showList, setShowList] = useState(false);
    const {data, loading, error, fetchMore, subscribeToMore} = useQuery(FETCH_NOTIFICATIONS, {
        // Initial offset can be 1 or 0 because the first record is also fetched with the subscription
        variables: {limit: QUERY_LIMIT, offset: 0}
    });
    const [markRead] = useMutation(MARK_READ);
    const [readMessages, setReadMessages] = useState([]);

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIBE_NOTIFICATIONS,
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data?.notification?.length || !prev) return prev;
                const newNotification = subscriptionData.data.notification[0];
                let notifications = [...prev.notifications];
                const existingNotification = notifications.findIndex(el => el.id === newNotification.id);
                if (existingNotification >= 0) {
                    notifications.splice(existingNotification, 1, newNotification)
                } else {
                    notifications = [newNotification, ...prev.notifications];
                }
                return Object.assign({}, prev, {
                    notifications
                })
            },
            onError: () => {} // The jwt expiration is handled globally but still throws an exception here if not handled
        });
    }, [subscribeToMore]);

    useEffect(() => {
        if (!showList && readMessages.length > 0) {
            markRead({variables: {ids: readMessages, date: new Date().toUTCString()}});
        }
    }, [readMessages, showList, markRead]);

    const [markAllRead] = useMutation(MARK_ALL_READ);
    const unseenNotifications = useMemo(() => data?.notifications?.some(notification =>
            !notification.seen_at),
        [data]);

    const renderList = () => (
        <Box round='medium' height={{max: 'medium'}} width={{max: 'medium'}} flex={false}>
            <Box overflow='auto' id='notifications'>
                <InfiniteScroll items={data?.notifications || []} replace step={QUERY_LIMIT} onMore={() => {
                    fetchMore({
                        variables: {offset: data?.notifications?.length || 0},
                        updateQuery: (prev, {fetchMoreResult}) => {
                            if (!fetchMoreResult) return prev;
                            return Object.assign({}, prev, {
                                notifications: [...prev.notifications, ...fetchMoreResult.notifications]
                            });
                        }
                    })
                }}>
                    {notification =>
                        <Link to={notification.path ?? '#'} key={notification.id} onClick={() => setShowList(false)}>
                            <NotificationRow {...notification} onRead={() => setReadMessages(messages => {
                                if (!messages.some(msg => msg === notification.id))
                                    return [...messages, notification.id];
                                return messages;
                            })}/>
                        </Link>
                    }
                </InfiniteScroll>
                {data?.notifications?.length === 0 && <Box pad='small' border={{side: 'bottom'}}><Text>No Notifications.</Text></Box>}
            </Box>
            <Box pad='small' justify="between" flex={false} direction='row' gap='medium'>
                <Anchor label='Mark all as read' onClick={async () => {
                    await markAllRead({variables: {date: new Date().toUTCString()}});
                    setShowList(false);
                }}/>
                <DeleteButton
                    query={DELETE_ALL_NOTIFICATIONS}
                    queriesToRefetch={['fetch_all_notifications']}
                    text='Are you sure you want to delete all notifications?'
                    onRemove={() => setShowList(false)}
                    buttonProps={{
                        icon: undefined,
                        plain: true,
                        color: 'status-error',
                        label: 'Delete all',
                    }}
                />
            </Box>
        </Box>
    );

    return (
        <Stack anchor="top-right">
            <DropButton
                label={<Bell style={{width: '30px', height: '30px'}}
                             active={unseenNotifications || showList}/>}
                focusIndicator={false}
                plain
                disabled={loading}
                onOpen={() => !error && setShowList(true)}
                open={showList}
                onClose={() => setShowList(false)}
                dropContent={renderList()}
                dropProps={{align: {top: "bottom"}}}
            />
            {error ?
                <Refresh color='brand'/> :
                loading ?
                    <Spinner width="18px" height="18px"/> :
                    unseenNotifications && <GreenDot/>
            }
        </Stack>
    );
};