import React, {useCallback, useState} from 'react';
import {Box, List, Stack, Text} from "grommet";
import {SearchField} from "../../../components/SearchField";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {useMutation} from "@apollo/react-hooks";
import Spinner from "../../../components/Spinner";
import {useHistory, useParams} from "react-router-dom";
import {AppointmentsModal} from "./AppointmentsModal";

const FETCH_DOCUMENT_TEMPLATES = gql`
    query ($limit: Int! $offset: Int! $filter: document_template_bool_exp) {
        id @client
        data: document_template(limit: $limit offset: $offset where: $filter) {
            id
            title
            body
            type_id
            provider_signature_required
            client_signature_required
            document_type {
                id
                name
            }
        }
    }
`;

const DOCUMENT_NOTE_TYPE_ID = 5;

const CREATE_DOCUMENT = gql`
    mutation ($data: document_insert_input!) {
        insert_document_one(object: $data) {
            id
            updated_at
            created_at
            session_id
            title
            type_id
            document_type {
                id
                name
            }
        }
    }
`;

const LIMIT = 20;

export const CreateDocument = ({closeModal}) => {
    const {session_id: sessionId, id: clientId} = useParams();
    const [openAppointmentModal, setOpenAppointmentModal] = useState(null);
    const [offset, setOffset] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [filter, setFilter] = useState({});
    const history = useHistory();
    // TODO: Update cache here so that we don't need to refetch the query
    const [createDocument, {loading}] = useMutation(CREATE_DOCUMENT, {refetchQueries: ['fetchSessionDocuments']});
    const {data, loading: fetchLoading} = useQuery(FETCH_DOCUMENT_TEMPLATES, {
        variables: {limit: LIMIT, offset, filter},
        fetchPolicy: 'no-cache',
        onCompleted: data => setDocuments(d => d.concat(data?.data || []))
    })
    const createSelectedDocument = useCallback(async (item, meeting = null) => {
        const documentSignatures = [];
        if (item.provider_signature_required) {
            documentSignatures.push({user_id: data.id});
        }
        if (item.client_signature_required) {
            documentSignatures.push({user_id: clientId})
        }
        await createDocument({
            variables: {
                data: {
                    body: item.body,
                    title: item.title,
                    meeting_id: meeting ?? null,
                    session_id: sessionId,
                    type_id: item.type_id,
                    document_signatures: {
                        data: documentSignatures
                    }
                }
            }
        });
    }, [createDocument, data, clientId, sessionId]);

    return (
        <Stack>
            <Box gap="small">
                <SearchField onSubmit={(term) => {
                    setDocuments([]);
                    setOffset(0);
                    setFilter({title: {_ilike: `%${term}%`}});
                }} textInputProps={{focusIndicator: false}} containerProps={{pad: {horizontal: 'small'}}}/>

                <Box overflow='auto' height={{max: 'medium'}}>
                    {documents.length > 0 ? <List
                        primaryKey="title"
                        secondaryKey={(item) => (<Text key={item.id}>{item.document_type.name}</Text>)}
                        data={documents}
                        onClickItem={async ({item}) => {
                            console.log(item, item.type_id, item.type_id === DOCUMENT_NOTE_TYPE_ID)
                            if (item.type_id === DOCUMENT_NOTE_TYPE_ID) {
                                setOpenAppointmentModal(item);
                                return;
                            }
                            await createSelectedDocument(item);
                            history.push(`/clients/${clientId}/${sessionId}/documents`);
                            closeModal();
                        }}
                        onMore={() => {
                            setOffset(o => o + LIMIT);
                        }}
                    /> : fetchLoading ? <Spinner/> :
                        <Text margin={{left: 'small', bottom: 'small'}}>No results found</Text>}

                </Box>
                {openAppointmentModal && <AppointmentsModal
                    onClose={() => setOpenAppointmentModal(null)}
                    onSelect={async (item) => {
                        await createSelectedDocument(openAppointmentModal, item);
                        history.push(`/clients/${clientId}/${sessionId}/documents`);
                        closeModal();
                    }}
                />}
            </Box>
            {loading && <Box round='xsmall' fill background={{color: 'white', opacity: 'strong'}}><Spinner fill/></Box>}
        </Stack>
    )
}