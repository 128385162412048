import React, {useEffect, useRef, useState} from 'react';
import {Box, Image, Text} from "grommet";
import {Chat, Local, MailOption, Refresh} from "grommet-icons";
import moment from "moment";
import Spinner from "../../../components/Spinner";

const now = moment();

export const MessagesList = ({channelSid, messages, loading, loadMore, isTyping, hasMore, client, ready}) => {
    const messagesList = useRef();
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => setScrollHeight(0), [channelSid]); // Reset scroll when chat channel changes

    useEffect(() => { // Scroll down on new message
        if (!ready) return;
        messagesList.current.scrollTop = messagesList.current.scrollHeight;
    }, [ready, messages]);

    useEffect(() => { // Keep scroll when loading previous messages
        if (!ready) return;
        const ref = messagesList.current;
        ref.scrollTop = ref.scrollHeight - scrollHeight;
    }, [loading, scrollHeight, ready]);

    const handleScroll = e => {
        let element = e.target;
        if (element.scrollTop === 0 && hasMore && !loading) {
            loadMore();
            setScrollHeight(e.target.scrollHeight);
        }
    };

    const getMessageDate = (timestamp, now) => {
        const messageTime = moment(timestamp);
        return now.diff(messageTime, 'days') > 1 ? messageTime.format('Do MMM, kk:mm') : messageTime.fromNow();
    };

    const renderLoading = () => (
        <Box direction='row' justify='center' pad='small'>
            <Refresh color='active'/>
            <Text color='active'>Loading Messages</Text>
        </Box>
    );

    const renderBeginningOfConversation = () => (
        <Box align='center' gap='medium' pad='medium' height={{min: 'small'}} justify='end'>
            <Box>
                <Image src='/Chat.svg' height={50}/>
                <Text margin={{top: 'small'}} color='lightgray'>This is the beginning of your messages
                    with {client.name}.</Text>
            </Box>
        </Box>
    );

    return (
        <React.Fragment>
            {!ready ?
                <Spinner full/> :
                <Box pad={{horizontal: 'medium'}} overflow='auto' flex onScroll={handleScroll}
                     ref={messagesList}>
                    {loading && renderLoading()}
                    {!hasMore && renderBeginningOfConversation()}
                    {messages.map(message => {
                        const timestamp = new Date(message.timestamp);
                        const author = message.author;
                        return (
                            <Box key={message.index}
                                 align={author === 'system' ? 'center' : author.charAt(0) === 'p' ? 'end' : 'start'}
                                 flex={false}
                                 animation='slideLeft'
                                 margin={{bottom: 'small'}}
                                 alignSelf={author === 'system' ? 'center' : author.charAt(0) === 'p' ? 'end' : 'start'}
                            >
                                <Box pad='small'
                                     round='xsmall'
                                     border={author === 'system' ? false : author.charAt(0) === 's'}
                                     background={author.charAt(0) === 'p' ? 'green' : 'white'}
                                     flex={false}
                                >
                                    <Text color={author === 'system' ? 'lightgray' : author.charAt(0) === 'p' ? 'white' : 'darkgray'}>
                                        {message.body}
                                    </Text>
                                </Box>
                                {author !== 'system' && <Box direction='row' gap='xsmall' pad='xsmall' align='center'>
                                    {message.sms && <Local color='lightgray' size='small'/>}
                                    {message.email && <MailOption color='lightgray' size='small'/>}
                                    <Chat size='small'/>
                                    <Text color='lightgray'>{getMessageDate(timestamp, now)}</Text>
                                </Box>}
                            </Box>
                        )
                    })}
                </Box>}
            {isTyping && <Box pad='medium'><Text>{client.name.split(' ')[0]} is typing...</Text></Box>}
        </React.Fragment>
    );
};
