import React, {Component} from 'react';
import {Form} from "formik";
import {Anchor, Box, Button, CheckBox, FormField, Text} from "grommet";
import {Lock, MailOption, Phone, User} from "grommet-icons";
import {TextField} from "./TextField";
import {TermsAndConditionsModal} from "./TermsAndConditionsModal";

export class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpened: false
        }
    }

    onOpen = () => this.setState({modalOpened: true});

    onClose = () => this.setState({modalOpened: false});

    render() {
        const {values, errors, handleChange, status, isSubmitting} = this.props;
        const {modalOpened} = this.state;
        return (
            <Form>
                <TextField name='email'
                           handleChange={handleChange}
                           value={values.email}
                           placeholder='Email'
                           icon={MailOption}
                           type='email'
                           inputProps={{disabled: true}}
                           error={errors.email}
                />

                <TextField name='firstName'
                           handleChange={handleChange}
                           value={values.firstName}
                           placeholder='First Name'
                           icon={User}
                           error={errors.firstName}
                />

                <TextField name='lastName'
                           handleChange={handleChange}
                           value={values.lastName}
                           placeholder='Last Name'
                           icon={User}
                           error={errors.lastName}
                />

                <TextField name='password'
                           handleChange={handleChange}
                           value={values.password}
                           placeholder='Password'
                           type='password'
                           icon={Lock}
                           error={errors.password}
                />

                <TextField name='passwordRepeat'
                           handleChange={handleChange}
                           value={values.passwordRepeat}
                           placeholder='Repeat Password'
                           type='password'
                           icon={Lock}
                           error={errors.passwordRepeat}
                />

                <TextField name='phoneNumber'
                           handleChange={handleChange}
                           value={values.phoneNumber}
                           placeholder='Phone Number'
                           icon={Phone}
                           error={errors.phoneNumber}
                />

                {/*<FormField*/}
                {/*    error={errors.phoneNumber}*/}
                {/*    margin={{bottom: errors.acceptTerms ? '0px' : 'medium'}}*/}
                {/*>*/}
                {/*    <Box*/}
                {/*        direction="row"*/}
                {/*        align="center"*/}
                {/*        round="xsmall"*/}
                {/*        border={{color: errors.phoneNumber ? 'lightred' : 'border'}}*/}
                {/*    >*/}
                {/*        <Box pad="small">*/}
                {/*            <Phone color="sidebarInactive" size="medium"/>*/}
                {/*        </Box>*/}
                {/*        <MaskedInput*/}
                {/*            name='phoneNumber'*/}
                {/*            mask={[*/}
                {/*                {fixed: "("},*/}
                {/*                {*/}
                {/*                    length: 3,*/}
                {/*                    regexp: /^[0-9]{1,3}$/,*/}
                {/*                    placeholder: "xxx"*/}
                {/*                },*/}
                {/*                {fixed: ")"},*/}
                {/*                {fixed: " "},*/}
                {/*                {*/}
                {/*                    length: 3,*/}
                {/*                    regexp: /^[0-9]{1,3}$/,*/}
                {/*                    placeholder: "xxx"*/}
                {/*                },*/}
                {/*                {fixed: "-"},*/}
                {/*                {*/}
                {/*                    length: 4,*/}
                {/*                    regexp: /^[0-9]{1,4}$/,*/}
                {/*                    placeholder: "xxxx"*/}
                {/*                }*/}
                {/*            ]}*/}
                {/*            value={values.phoneNumber}*/}
                {/*            onChange={handleChange}*/}
                {/*        />*/}
                {/*    </Box>*/}
                {/*</FormField>*/}

                <FormField
                    error={errors.acceptTerms}
                    margin={{bottom: errors.acceptTerms ? 'none' : 'medium'}}
                >
                    <Box
                        direction="row"
                        align="center"
                        round="xsmall"
                        gap='small'
                    >
                        <CheckBox
                            name='acceptTerms'
                            checked={values.acceptTerms || false}
                            onChange={handleChange}
                        />
                        <Box direction='row' gap='xxsmall'>
                            <Text>I accept the</Text>
                            <Anchor onClick={this.onOpen} size='medium' color='brand'>terms of use</Anchor>
                        </Box>
                    </Box>
                </FormField>

                {status && <Box margin={{bottom: "medium"}} width='small'>
                    <Text color="lightgray">{status.message}</Text>
                </Box>}

                <Box direction='row-responsive'>
                    <Button type="submit" fill primary label="Sign Up" disabled={isSubmitting}/>
                </Box>
                {modalOpened && (
                    <TermsAndConditionsModal
                        onClose={this.onClose}
                    />
                )}
            </Form>
        );
    }
}
