import React, {useState} from 'react';
import {Anchor, Box, Button, Layer, List, Text} from "grommet";
import {Close} from "grommet-icons";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import Spinner from "../../../components/Spinner";
import moment from "moment";


const FETCH_MEETINGS = gql`
    query ($limit: Int! $offset: Int! $filter: meeting_bool_exp) {
        data: meeting(limit: $limit, offset: $offset, order_by: {created_at: desc} where: $filter) {
            id
            start_at
            end_at
            approved
            name
            status
            type_id
            meeting_type {
                id
                name
            }
            meeting_participants {
                checked_in
            }
            description
        }
    }
`;

const LIMIT = 20;

export const AppointmentsModal = ({onClose, onSelect}) => {
    const [appointments, setAppointments] = useState([]);
    const [offset, setOffset] = useState(0);
    const {loading: fetchLoading} = useQuery(FETCH_MEETINGS, {
        variables: {limit: LIMIT, offset},
        fetchPolicy: 'no-cache',
        onCompleted: data => setAppointments(d => d.concat(data?.data || []))
    })
    return (
        <Layer onEsc={onClose}
               onClickOutside={onClose}>
            <Box width={{min: 'medium'}} gap='small'>
                <Box direction='row' justify='between' align='center'>
                    <Text weight='bold' margin={{left: 'small'}}>Do you want to associate this note with an appointment for that client?</Text>
                    <Button icon={<Close/>} onClick={onClose}/>
                </Box>
                <Text margin={{horizontal: 'medium'}}><Anchor label="Skip" onClick={() => onSelect(null)} /> or select an appointment from the list</Text>
                <Box overflow='auto' height={{max: 'medium'}}>
                    {appointments.length > 0 ? <List
                        primaryKey="name"
                        itemKey="id"
                        secondaryKey={(item) => (<Text key={item.id}>{moment(item.start_at).format('MMM D, h:mm a')}</Text>)}
                        data={appointments}
                        onClickItem={async ({item}) => {
                            await onSelect(item.id);
                        }}
                        onMore={() => {
                            setOffset(o => o + LIMIT);
                        }}
                    /> : fetchLoading ? <Spinner/> :
                        <Text margin={{left: 'small', bottom: 'small'}}>No results found</Text>}

                </Box>
            </Box>
        </Layer>
    )
}