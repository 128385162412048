import React from 'react';
import {Box, Text} from "grommet";


export const ActivityBox = ({color = 'active', header, text}) => (
    <Box
         background='white'
         round='xxsmall'
         pad={{vertical: 'small', horizontal: 'medium'}}
         elevation='xsmall'
         border={{side: 'left', color: color, size: 'medium'}}
    >
        {header === undefined || text === undefined ? <Text>Loading...</Text> :
            <Box gap='small'
                 direction='row'
            >
                <Text size='xxlarge' weight='bold'>{header}</Text>
                <Text size='small' alignSelf='end' weight='bold'>{text}</Text>
            </Box>}
    </Box>
);
