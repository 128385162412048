import React from 'react';
import {Box, FormField, Grid, Select, Text, TextInput} from "grommet";
import CitiesPerState from "./CitiesPerState";

export const AddressFields = ({handleBlur, handleChange, setFieldValue, errors, values, field, gridArea, heading = 'Address'}) => (
    <Grid
        gridArea={gridArea}
        gap='small'
        areas={
            [
                {name: 'address', start: [0, 0], end: [2, 0]},
                {name: 'state', start: [0, 1], end: [0, 1]},
                {name: 'city', start: [1, 1], end: [1, 1]},
                {name: 'zip', start: [2, 1], end: [2, 1]},
            ]}
        columns={['auto', 'auto', 'auto']}
        rows={['auto', 'auto']}
    >
        <Box gridArea='address' gap='small'>
            {heading && <Text>{heading}</Text>}
            <FormField label="Address" error={errors.address}>
                <TextInput
                    name={`${field}.address`}
                    onBlur={handleBlur}
                    value={values.address}
                    onChange={handleChange}
                />
            </FormField>
        </Box>
        <Box gridArea='state'>
            <FormField label="State" error={errors.state}>
                <Select
                    name={`${field}.state`}
                    options={Object.keys(CitiesPerState).sort()}
                    value={values.state}
                    onChange={({ option }) => setFieldValue(`${field}.state`, option)}
                />
            </FormField>
        </Box>
        <Box gridArea='city'>
            <FormField label="City" error={errors.city}>
                <TextInput
                    name={`${field}.city`}
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Box>
        <Box gridArea='zip'>
            <FormField label="ZIP" error={errors.zip}>
                <TextInput
                    name={`${field}.zip`}
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Box>
    </Grid>
);