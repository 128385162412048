import React, {useCallback, useState} from 'react';
import Camera from "../../components/icons/Camera";
import {Box, Image, Text} from "grommet";
import {useDropzone} from "react-dropzone";
import {useQuery} from "@apollo/react-hooks";
import {FETCH_BRAND} from "../../services/image";

export const CompanyImageBranding = ({onUpload}) => {
    const [preview, setPreview] = useState(null);
    useQuery(FETCH_BRAND, {onCompleted: (data) => {setPreview(data.brand.url)}});
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            onUpload(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreview(reader.result)
            };
        });

    }, [onUpload]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    return (
        <Box
            pad='large'
            round='xsmall'
            align='center'
            justify='center'
            background='#f8f8f8'
            height={{min: 'small'}}
            border={{color: 'lightborder'}}
            {...getRootProps()}
        >
            {preview ?<Image fill='horizontal'  fit='cover'  src={preview}/> : <Camera height='38px' width='38px'/>}
            {isDragActive && <Text color='gray'>Drop file here.</Text>}
            <input
                type="file"
                name="image"
                {...getInputProps()}
            />
        </Box>
    );
};
