import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    FormField,
    CheckBox, Text,
} from "grommet";

const CheckboxList = ({id, title, options, error, handleChange, value, disabled, ...rest}) => (
    <Box gap="small" {...rest}>
        <Text color="lightgray">{title}</Text>
        <FormField error={error} className="surveyFormInput">
            {options.map((option, index) => {
                return (
                    <Box key={option.id} pad='xsmall'>
                        <CheckBox
                            key={option.id}
                            disabled={disabled}
                            name={`${id}[${option.id}]`}
                            label={option.content}
                            onChange={handleChange}
                            checked={(value && value[option.id]) || false}
                        />
                    </Box>
                )
            })}
        </FormField>
    </Box>
);

CheckboxList.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    })),
    error: PropTypes.string,
    value: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
};

export default CheckboxList;
