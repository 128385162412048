import React, {useState} from 'react';
import {Box, Button, Layer, Paragraph, Text} from "grommet";
import {LoadingButton} from "../../components/LoadingButton";
import ExclamationMark from "../../components/icons/ExclamationMark";
import {Close} from "grommet-icons";

export const CancelSubscriptionModal = ({onClose, accept, visible}) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const doneLoading = () => setLoading(false);
    if (!visible) {
        return null;
    }
    return (
        <Layer position="center" modal onClickOutside={onClose} onEsc={onClose}>
            <Box pad="medium"  width="medium" align='center'>
                <Button onClick={onClose} alignSelf='end'>
                    <Close size='15px' color='sidebarInactive'/>
                </Button>
                <Box margin={{top: 'small'}}><ExclamationMark width='50px' height='50px'/></Box>
                <Text weight='bold' margin={{top: 'medium'}}>
                    Are you sure you want to cancel your Subscription?
                </Text>
                <Paragraph color='gray' size='small'>If you cancel your subscription, you will not be able to use our services and your account and data will be deleted 30 days after your subscription expires.</Paragraph>
                {error && <Text color='red'>The operation could not be performed.</Text>}
                <Box
                    as="footer"
                    gap="small"
                    direction="row"
                    margin={{top: 'small'}}
                    // justify="end"
                    pad={{bottom: "small"}}
                >
                    <LoadingButton
                        loading={loading}
                        label={
                            <Text color="white" weight='bold'>Cancel Subscription</Text>
                        }
                        onClick={async () => {
                            setLoading(true);
                            try {
                                await accept(doneLoading);
                            } catch (e) {
                                setError(true);
                                setLoading(false);
                            }
                        }}
                        primary
                        color="statusPending"
                    />
                    <Button label="Go Back" onClick={onClose} color="dark-3" primary/>
                </Box>
            </Box>
        </Layer>
    )
}