import React, {useMemo} from 'react';
import Page from "../../components/Page";
import {Box, ResponsiveContext, Tabs, Tab} from "grommet";
import {FacesheetTab} from "./components/Facesheet/FacesheetTab";
import {DocumentsTab} from "./components/ClientTabs/DocumentsTab";
import {ProfileSidebar} from "./components/ProfileSidebar";
import {SurveysTab} from "./components/ClientTabs/SurveysTab";
import {TreatmentPlansTab} from "./components/ClientTabs/TreatmentPlansTab";
import {Redirect, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {AppointmentsTab} from "./components/ClientTabs/AppointmentsTab";
import {PrivateRoute} from "../../routes";
import {CorrespondenceTab} from "./components/ClientTabs/CorrespondenceTab";
import {BillsTab} from "./components/ClientTabs/BillsTab";

const tabs = [
    {
        path: 'facesheet',
        component: FacesheetTab,
        label: 'FACESHEET'
    },
    {
        path: 'surveys',
        component: SurveysTab,
        label: 'SURVEYS'
    },
    {
        path: 'documents',
        component: DocumentsTab,
        label: 'DOCUMENTS'
    },
    {
        path: 'treatment-plans',
        component: TreatmentPlansTab,
        label: 'TREATMENT PLANS'
    },
    {
        path: 'appointments',
        component: AppointmentsTab,
        label: 'APPOINTMENTS'
    },
    {
        path: 'bills',
        component: BillsTab,
        label: 'BILLS'
    },
    {
        path: 'correspondence',
        component: CorrespondenceTab,
        label: 'CORRESPONDENCE'
    }
]

const ClientPage = () => {
    const {path, url} = useRouteMatch();
    const {id, session_id} = useParams();
    const location = useLocation();
    let history = useHistory();

    const tabIndex = useMemo(() => {
        const sections = location.pathname.split('/');
        if (sections.length > 4) {
            return tabs.findIndex(tab => tab.path === sections[4]);
        }
        return -1;
    }, [location]);

    return (
        <ResponsiveContext.Consumer>
            {(deviceSize) => (
                <Box fill direction={deviceSize === 'small' ? 'column-reverse' : 'row'}>
                    {/* TODO: Fix perform a React state update on an unmounted component */}
                    {tabIndex < 0 ? <Redirect to={`${url}/${tabs[0].path}`}/> :
                        <Page title="Client Information" boxProps={{overflow: 'none'}}>
                            <Tabs
                                alignControls='center'
                                alignSelf='stretch'
                                margin={{top: 'small'}}
                                activeIndex={tabIndex}
                                onActive={t => {
                                    history.push(`/clients/${id}/${session_id}/${tabs[t].path}`);
                                }}
                            >
                                {tabs.map(tab => <Tab key={tab.path} title={tab.label}/>)}
                            </Tabs>
                            <Box pad="medium" fill overflow="auto">
                                {tabs.map(tab =>
                                    <PrivateRoute
                                        key={tab.path}
                                        path={`${path}/${tab.path}`}
                                        component={tab.component}/>
                                )}
                            </Box>
                        </Page>}
                    <ProfileSidebar/>
                </Box>
            )}
        </ResponsiveContext.Consumer>
    )
};

export default ClientPage;
