import React, {useState, useRef, useEffect} from "react";

import {FormClose} from "grommet-icons";

import {Box, Button, CheckBox, Select, Text} from "grommet";
import PropTypes from "prop-types";

const MultiSelect = (props) => {
    const {
        options: allOptions,
        preselectedOptions = [],
        handleChange = () => {},
        disabled,
        placeholder,
        searchPlaceholder,
        emptySearchMessage,
        ...rest
    } = props
    const [selectedOptions, setSelectedOptions] = useState(preselectedOptions);
    const [options, setOptions] = useState(allOptions);
    const [searching, setSearching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const selectRef = useRef();

    const clearSelectedOptions = () => {
        setSelectedOptions([]);
    };

    useEffect(() => {
        const filteredOptions = allOptions.filter(
            s => s.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0,
        );

        setTimeout(() => {
            setSearching(false);
            setOptions(filteredOptions);
        }, 100);
    }, [searching, searchQuery, allOptions]);

    //TODO: We should refactor the code to pass a useCallback in the handleChange prop and add is as a dependency
    useEffect(() => {
        handleChange(selectedOptions)
        // eslint-disable-next-line
    }, [selectedOptions])

    const renderOption = ({name}) => (
        <Box direction="row" align="center" pad="small" flex={false}>
            <CheckBox
                tabIndex="-1"
                checked={selectedOptions.some(option => option.name === name)}
                label={<Text size="small">{name}</Text>}
                onChange={() => {
                }}
                disabled={disabled}
            />
        </Box>
    );

    const renderOptions = () => {
        return (
            <Box
                direction="row"
                gap="xsmall"
                pad={{left: 'small', vertical: 'small'}}
                align="center"
                fill
            >
                {
                    selectedOptions?.length > 0 &&
                    <React.Fragment>
                        <Box
                            background="brand"
                            round="medium"
                            align="center"
                            justify="center"
                            pad={{horizontal: 'xsmall'}}
                            style={{minWidth: '21px'}}
                        >
                            <Text size="small">{selectedOptions.length}</Text>
                        </Box>
                        <Box flex>
                            <Text size="small" truncate>
                                {selectedOptions.map(({name}) => name).join(', ')}
                            </Text>
                        </Box>
                    </React.Fragment>
                }
                <Button
                    href="#"
                    onFocus={event => event.stopPropagation()}
                    onClick={event => {
                        // Stop the event in to prevent the closing of the select menu
                        event.preventDefault();
                        event.stopPropagation();

                        clearSelectedOptions();
                        selectRef.current.focus();
                    }}
                    disabled={disabled}
                >
                    <Box background="gray" round="full">
                        <FormClose style={{width: '12px', height: '12px'}}/>
                    </Box>
                </Button>
            </Box>
        );
    }

    const sortOptions = selectedOptionsNames => {
        return (p1, p2) => {
            const p1Exists = selectedOptionsNames.includes(p1.name);
            const p2Exists = selectedOptionsNames.includes(p2.name);

            if (!p1Exists && p2Exists) {
                return 1;
            }
            if (p1Exists && !p2Exists) {
                return -1;
            }
            if (p1.name.toLowerCase() < p2.name.toLowerCase()) {
                return -1;
            }
            return 1;
        };
    };

    function reorderOptions(option) {
        const newSelectedOptions = [...selectedOptions];
        const chipIndex = newSelectedOptions
            .map(({name}) => name)
            .indexOf(option.name);
        if (chipIndex >= 0) {
            // not selected -> delete it from the selected options
            newSelectedOptions.splice(chipIndex, 1);
        } else {
            // selected -> add it to the selected options
            newSelectedOptions.push(option);
        }
        const selectedOptionsNames = newSelectedOptions.map(
            ({name}) => name,
        );
        const sortedOptions = [...allOptions].sort(
            sortOptions(selectedOptionsNames),
        );
        setSelectedOptions(newSelectedOptions);
        setOptions(sortedOptions);
    }


    return (
        <Select
            {...rest}
            ref={selectRef}
            closeOnChange={false}
            placeholder={placeholder}
            searchPlaceholder={searchPlaceholder}
            emptySearchMessage={emptySearchMessage}
            searching={searching}
            multiple
            value={renderOptions()}
            selected={selectedOptions.map(option =>
                options.indexOf(option),
            )}
            options={options}
            onChange={({option}) => {
                reorderOptions(option);
            }}
            onSearch={query => {
                setSearching(true);
                setSearchQuery(query);
            }}
            disabled={disabled}
        >
            {renderOption}
        </Select>
    );
};
MultiSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    preselectedOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })),
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
};

MultiSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,

    preselectedOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })),

    handleChange: PropTypes.func,

    emptySearchMessage: PropTypes.string,
    placeholder: PropTypes.string,
    searchPlaceholder: PropTypes.string,

    disabled: PropTypes.bool
};

export default MultiSelect
