import React from "react";
import {Box, Button, FormField, Text, TextInput} from "grommet";
import {FieldArray, Form, Formik, Field} from "formik";
import * as Yup from "yup";
import {Add, Subtract} from "grommet-icons";
import {newId} from "../../../../components/survey/Questions";

const TextInputSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Required'),
    options: Yup.array()
        .of(Yup.object().shape({
                id: Yup.string()
                    .required('Required'),
                content: Yup.string()
                    .min(2, 'Too Short!')
                    .max(255, 'Too Long!')
                    .required('Required')
            })
        )
        .required('Question should contain options.') // these constraints are shown if and only if inner constraints are satisfied
        .min(2, 'A minimum of 2 options is required.'),
});

export const MultipleChoiceEditor = ({title, options, updateQuestion, id}) => (
    <Formik
        // same as onChange. Formik calls both validate and validationSchema.
        validate={(values) => updateQuestion(id, values)}
        initialValues={{title, options}}
        validationSchema={TextInputSchema}
        enableReinitialize
        onSubmit={() => {
        }}
        render={({errors, values, handleChange}) => (
            <Form>
                <FormField label="Title" error={errors.title}>
                    <TextInput
                        name="title"
                        value={values.title || ""}
                        onChange={handleChange}
                    />
                </FormField>
                <FieldArray name="options">
                    {({push, remove}) => (
                        values.options.map((option, index) => (
                            <Field
                                key={option.id}
                                name={`options.${index}.content`}
                                render={({field, form: {handleChange, errors}}) => (
                                    <Box direction='row'>
                                        <FormField
                                            error={errors.options && errors.options[index] && errors.options[index].content}
                                        >
                                            <TextInput
                                                name={field.name}
                                                value={field.value || ''}
                                                onChange={handleChange}
                                            />
                                        </FormField>
                                        <Button icon={<Add/>}
                                                onClick={() => push({id: newId(), content: 'Option'})}/>
                                        {index !== 0 ?
                                            <Button icon={<Subtract/>} onClick={() => remove(index)}/>
                                            :
                                            null
                                        }
                                        {typeof errors.options === 'string' ?
                                            <Text color="status-critical">{errors.options}</Text> : null
                                        }
                                    </Box>
                                )}/>
                        ))
                    )}
                </FieldArray>
            </Form>
        )
        }
    />
);
