import React from 'react';
import {Copy} from "grommet-icons";
import {Button, Spinner} from "grommet";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";

const COPY_TEMPLATE = gql`
    mutation copyTemplate($data: document_template_insert_input!) {
        insert_document_template_one(object: $data) {
            id
        }
    }
`;

const FETCH_TEMPLATE = gql`
    query fetchTemplate($id: Int!) {
        template: document_template_by_pk(id: $id) {
            title
            body
            type_id
            provider_signature_required
            client_signature_required
        }
    }
`;

export const CopyButton = ({id}) => {
    const [copyTemplate, {loading: mutationLoading}] = useMutation(COPY_TEMPLATE, {refetchQueries: ['fetch_document_templates']});
    const [fetchTemplate, {loading}] = useLazyQuery(FETCH_TEMPLATE,  {onCompleted: async (data) => {
            const {__typename, ...template} = data.template;
            await copyTemplate({
                variables: {
                    data: {
                        ...template, title: template.title + ' (copy)',
                    }
                }
            });
        }});

    return (
        <Button
            plain
            icon={mutationLoading || loading ? <Spinner/> : <Copy color='sidebarInactive'/>}
            onClick={() => fetchTemplate({variables: {id}})
        }/>
    );
}