import React, {useEffect} from 'react';
import {Box, Heading, RadioButtonGroup, Text} from "grommet";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import {cardBrandIcons} from "../Billing/PaymentMethods";
import {Form, Formik} from "formik";
import {CardFieldsGrid} from "../../components/CardFieldsGrid";
import {PAYMENT_OPTIONS} from "../../services/payment";
import './styles.css';
import {LoadingButton} from "../../components/LoadingButton";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const API_BASE_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_CLIENT_API_URL : process.env.REACT_APP_PROD_CLIENT_API_URL;

function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
}

const CardRow = ({MaskedNumber, CardType, Exp}) => {
    const CardIcon = cardBrandIcons[CardType || 'Visa'];
    return (<Box direction='row' gap="small" align="center">
        <CardIcon color="plain"/>
        <Text size='16px'>{MaskedNumber}</Text>
        <Text size="16px">Exp: <strong>{Exp.slice(0, 2)}/{Exp.slice(2)}</strong></Text>
    </Box>)
}

export const PaymentSite = () => {
    const {id, amount} = useParams();
    const cardNumberRef = React.createRef();
    const cvvRef = React.createRef();
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [token, setToken] = React.useState(null);

    useEffect(() => {
        console.log('coming');
        if (!token) return;
        console.log('it is in');
        setLoading(true);
        axios.post(`${API_BASE_URL}/payment/customer`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(data => {
            console.log(data);
            if (data.data['Result'] === 'S') setPaymentMethods(data.data['PaymentMethods']);
            else setError(data.data['Error']);
        }).catch(error => console.log(error)).finally(() => setLoading(false));
    }, [token]);

    useEffect(() => {
        const listener = (event) => {
            if (event.data.toString().startsWith('jwt-token')) {
                setToken(event.data.slice(9));
            }
        };
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        }
    }, []);

    if (loading) {
        return <Spinner fill />;
    }

    if (error) {
        return <Text>Something went wrong, please try again with another card.</Text>
    }

    return (
        <Box align="center">
            <Heading>Bill #{addLeadingZeros(id, 6)}</Heading>
            <Text size="large" weight="bold">{formatter.format(amount)}</Text>
            <Box margin={{vertical: 'medium'}} border={{side: 'top'}} fill="horizontal" height="2px"/>
            <Formik
                initialValues={{paymentMethod: '', name: '', card_number_token: '', expiration_date: '', cvv_token: ''}}
                onSubmit={async (values) => {
                    const response = await axios.post(`${API_BASE_URL}/payment/pay-bill?id=${id}`, values, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    if (response.data['Result'] === 'S') {
                        history.push('success');
                    } else {
                        setError(response.data['Error']);
                    }
                }}>
                {(props) => (
                    <Form>
                        <Box align="center">
                            <Text size="large" textAlign="center">Choose a payment method</Text>
                            <RadioButtonGroup value={props.values.paymentMethod} onChange={(event) => props.setFieldValue('paymentMethod', event.target.value)} margin={{top: 'medium'}} name="paymentMethod" options={paymentMethods.map(method => ({
                                value: method['PaymentMethodId'],
                                id: method['PaymentMethodId'],
                                disabled: false,
                                label: <CardRow {...method}/>,
                            })).concat({
                                value: 'new',
                                id: 'new',
                                disabled: false,
                                label: 'Add a new card',
                            })} />
                        </Box>
                        {props.values.paymentMethod === 'new' && <CardFieldsGrid {...props} cvvRef={cvvRef} cardNumberRef={cardNumberRef} fieldOptions={{...PAYMENT_OPTIONS, iFieldstyle: {...PAYMENT_OPTIONS.iFieldstyle, width: '300px'}}} />}
                        <LoadingButton loading={props.isSubmitting} size="large" type="submit" primary label="Pay" alignSelf="center" margin={{top: 'medium'}} fill="horizontal"/>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}