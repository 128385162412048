import React, {useState} from 'react';
import {Box, Button} from "grommet";
import gql from "graphql-tag";
import {Modal} from "../../../components/Modal";
import {useMutation} from "@apollo/react-hooks";


const DELETE_TX_PLAN = gql`
    mutation($id: Int!){
        delete_tx_plan(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const DeleteTxPlanButton = ({queriesToRefetch, audienceId: txPlanId, onRemove = () => {}}) => {
    const id = txPlanId;
    const [removeModalVisible, setRemoveModalVisible] = useState(false);
    const [deleteTxPlan] = useMutation(DELETE_TX_PLAN, {refetchQueries: queriesToRefetch});

    return (
        <Box direction="row">
            <Modal
                visible={removeModalVisible}
                onClose={() => setRemoveModalVisible(false)}
                text='Are you sure you want to delete this tx plan?'
                acceptLabel='Remove'
                accept={async (doneLoading) => {
                    await deleteTxPlan({variables: {id}});
                    doneLoading();
                    setRemoveModalVisible(false);
                    onRemove();
                }}
            />
            <Button
                onClick={() => setRemoveModalVisible(true)}
                label='Delete'
                primary
                color='status-error'
            />
        </Box>
    );
};
