export const ACTION_TYPE = {
    SEND_EMAIL: 1,
    SEND_SURVEY:2,
    SEND_MESSAGE: 3,
    SEND_MEASUREMENT_SURVEY: 4
};

export const EVENT_TYPE = {
    CHANGE_OF_FIELD: 2
};
