import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import {QuestionDescriptions, questionIcons} from "../../../../components/survey/Questions";
import {Box, Grid} from "grommet";
import BigIconButton from "../../../../components/BigIconButton";

class QuestionListPanel extends Component {

    render() {
        const {selectQuestion} = this.props;
        const {deviceSize} = this.props;
        return (
            <Box pad="small">
                <Grid
                    align="start"
                    columns={deviceSize === "medium" ? ["fill"] : {count: 2, size: "xsmall"}}
                    gap="small"
                >
                    {QuestionDescriptions.map(question => {
                        const QuestionIcon = questionIcons[question.type];
                        return (
                            <BigIconButton key={question.text}
                                           label={question.text}
                                           icon={<QuestionIcon size="medium" color="brand"/>}
                                           onClick={() => selectQuestion(question.type)}
                            />
                        )
                    })}
                </Grid>
            </Box>
        );
    }
}

// QuestionListPanel.propTypes = {
//     questions: PropTypes.array.isRequired,
//     dispatch: PropTypes.func.isRequired
// };
QuestionListPanel.defaultProps = {};

export default QuestionListPanel;
