import React from 'react'
import {colors} from "../../theme";

const ClientsImport = ({active, ...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 38 38" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M29.688 38c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.834c0-.322-.118-.6-.353-.835a1.141 1.141 0 00-.834-.353c-.322 0-.6.118-.835.353a1.141 1.141 0 00-.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353H13.063c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.834c0-.322-.118-.6-.353-.835a1.141 1.141 0 00-.835-.353c-.321 0-.6.118-.834.353a1.141 1.141 0 00-.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.835.353h-4.75c-.99 0-1.83-.346-2.523-1.04C.346 36.269 0 35.428 0 34.438v-23.75c0-.99.346-1.83 1.04-2.523.692-.693 1.533-1.039 2.522-1.039h30.875c.99 0 1.831.346 2.524 1.04.693.692 1.039 1.533 1.039 2.523v23.75c0 .99-.346 1.83-1.04 2.523-.692.693-1.533 1.039-2.523 1.039h-4.75zm4.75-2.375c.321 0 .6-.118.834-.353.235-.235.353-.513.353-.834v-23.75c0-.322-.118-.6-.353-.835a1.141 1.141 0 00-.834-.353H3.561c-.321 0-.6.118-.834.353a1.141 1.141 0 00-.353.835v23.75c0 .321.118.6.353.834.235.235.513.353.834.353h3.786a3.567 3.567 0 011.28-1.707 3.391 3.391 0 012.06-.668c.766 0 1.453.223 2.06.668a3.567 3.567 0 011.28 1.707h9.908a3.807 3.807 0 011.317-1.707 3.391 3.391 0 012.06-.668c.766 0 1.453.223 2.06.668a3.807 3.807 0 011.316 1.707h3.748zM36.812 4.75c-.321 0-.6-.118-.834-.353a1.141 1.141 0 01-.353-.835c0-.321-.118-.6-.353-.834a1.141 1.141 0 00-.834-.353H3.561c-.321 0-.6.118-.834.353a1.141 1.141 0 00-.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353c-.322 0-.6-.118-.835-.353A1.141 1.141 0 010 3.562c0-.99.346-1.83 1.04-2.523C1.731.346 2.572 0 3.561 0h30.875c.99 0 1.831.346 2.524 1.04C37.654 1.731 38 2.572 38 3.561c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353z"
                fill={active ? colors.brand : colors.sidebarInactive}
            />
            <g fill={active ? colors.green : colors.sidebarInactive}>
                <path d="M12.117 14.625c1.485 0 2.746.52 3.785 1.559 1.04 1.039 1.559 2.3 1.559 3.785 0 .99-.247 1.892-.742 2.709a5.238 5.238 0 01-1.93 1.892c.94.347 1.775.841 2.505 1.485a8.218 8.218 0 011.8 2.263c.074.149.123.297.148.446a.914.914 0 01-.037.445c-.05.148-.124.284-.223.408a1.095 1.095 0 01-.37.297.825.825 0 01-.26.111 1.22 1.22 0 01-.297.038c-.223 0-.427-.056-.613-.167a1.105 1.105 0 01-.426-.464 5.578 5.578 0 00-1.373-1.67 5.674 5.674 0 00-1.893-1.04 6.373 6.373 0 00-.816-.166 6.036 6.036 0 00-.817-.056c-.445 0-.89.056-1.336.167a5.07 5.07 0 00-1.261.501 5.03 5.03 0 00-1.318.946 5.81 5.81 0 00-.983 1.318 1.105 1.105 0 01-.427.464c-.186.11-.39.167-.612.167a1.22 1.22 0 01-.297-.038.825.825 0 01-.26-.11 1.06 1.06 0 01-.575-.687 1.228 1.228 0 01.093-.91 7.937 7.937 0 011.391-1.874 7.937 7.937 0 011.874-1.391c.173-.1.347-.186.52-.26l.52-.223a5.238 5.238 0 01-1.93-1.892 5.126 5.126 0 01-.743-2.71c0-1.484.52-2.745 1.559-3.784 1.04-1.04 2.3-1.559 3.785-1.559zm0 2.375c-.816 0-1.515.29-2.096.872a2.859 2.859 0 00-.873 2.097c0 .816.291 1.515.873 2.096.58.582 1.28.873 2.096.873.817 0 1.515-.291 2.097-.873.581-.581.872-1.28.872-2.096 0-.817-.29-1.516-.872-2.097A2.859 2.859 0 0012.117 17zM22.563 19c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.834c0-.322.118-.6.353-.835s.513-.353.834-.353h7.125c.322 0 .6.118.835.353s.353.513.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353h-7.125zM22.563 23.75c-.322 0-.6-.118-.835-.353a1.141 1.141 0 01-.353-.834c0-.322.118-.6.353-.835s.513-.353.834-.353h9.5c.322 0 .6.118.835.353s.353.513.353.834c0 .322-.118.6-.353.835a1.141 1.141 0 01-.834.353h-9.5z" />
            </g>
        </g>
    </svg>
)

export default ClientsImport
