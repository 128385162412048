export const AUTH_TOKEN_KEY = 'authToken';
export const REFRESH_TOKEN = 'refreshToken';
export const CHAT_TOKEN_KEY = 'chatToken';

export const loadState = (key = 'state') => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
            return undefined;
        }
        return JSON.parse(serializedValue);
    } catch (e) {
        return undefined;
    }
};

export const saveState = (key = 'state', value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (e) {
        // Ignore write errors.
    }
};

export const removeState = (key = 'state') => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        // Ignore remove errors.
    }
};
