import React, {useState} from "react";
import {Image} from "grommet";
import {PROFESSIONAL_PLAN} from "../lib/constants";
import {useQuery} from "@apollo/react-hooks";
import {FETCH_BRAND} from "../services/image";
import gql from "graphql-tag";


//Suggestion: We can move the logic of fetching the brand in this component?

const FETCH_PLAN_ID = gql`
    query {
        planId @client
    }
`;

const Brand = () => {
    const [preview, setPreview] = useState(null);
    const [plan, setCompanyPlan] = useState(null);

    const {loading: planLoading} = useQuery(FETCH_PLAN_ID, {
        onCompleted: (data) => {
            setCompanyPlan(data.planId);
        }
    });

    const {loading: brandLoading} = useQuery(FETCH_BRAND, {
        onCompleted: (data) => {
            setPreview(data.brand.url)
        }
    });

    if (brandLoading || planLoading){
        return null;
    }

    if (preview && plan === PROFESSIONAL_PLAN) {
        return (
            // TODO: I don't like the hard-coding of the maxHeight and not specifying maxWidth
            <Image style={{'height': '100%', 'maxHeight': '60px'}} fit='contain' src={preview}/>
        )
    }
    else {
        return <Image src="/LogoHorizontal.svg"/>
    }
};

export default Brand;
