import React from 'react'
import {colors} from "../../theme";

const Padlock = (props) => (
    <svg width={34} height={45} viewBox="0 0 34 45" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M4.25 45c-1.18 0-2.184-.41-3.01-1.23C.413 42.95 0 41.953 0 40.78V21.094c0-1.172.413-2.168 1.24-2.989.826-.82 1.83-1.23 3.01-1.23h1.417V11.25c0-1.553.295-3.018.885-4.395a11.43 11.43 0 012.435-3.56A11.522 11.522 0 0112.573.88 11.26 11.26 0 0117 0c1.564 0 3.04.293 4.427.879a11.522 11.522 0 013.586 2.417 11.43 11.43 0 012.435 3.56c.59 1.376.885 2.841.885 4.394v5.625h1.417c1.18 0 2.184.41 3.01 1.23.827.82 1.24 1.817 1.24 2.989V40.78c0 1.172-.413 2.168-1.24 2.989-.826.82-1.83 1.23-3.01 1.23H4.25zm0-25.313c-.384 0-.716.14-.996.418-.28.278-.42.608-.42.989V40.78c0 .381.14.71.42.989.28.278.612.417.996.417h25.5c.384 0 .716-.139.996-.417.28-.278.42-.608.42-.989V21.094c0-.381-.14-.71-.42-.989a1.366 1.366 0 00-.996-.418H4.25zm21.25-2.812V11.25c0-2.314-.834-4.3-2.501-5.955C21.33 3.64 19.332 2.813 17 2.813s-4.331.827-5.999 2.482C9.334 6.951 8.5 8.935 8.5 11.25v5.625h17z"
                fill={colors.brand}
            />
            <path
                d="M17.5 25c1.518 0 2.814.537 3.889 1.611C22.463 27.686 23 28.981 23 30.5c0 1.518-.537 2.814-1.611 3.889C20.314 35.463 19.019 36 17.5 36c-1.518 0-2.814-.537-3.889-1.611C12.537 33.314 12 32.019 12 30.5c0-1.518.537-2.814 1.611-3.889C14.686 25.537 15.981 25 17.5 25zm0 2.75c-.745 0-1.39.272-1.934.816-.544.545-.816 1.19-.816 1.934 0 .745.272 1.39.816 1.934.545.544 1.19.816 1.934.816.745 0 1.39-.272 1.934-.816.544-.545.816-1.19.816-1.934 0-.745-.272-1.39-.816-1.934-.545-.544-1.19-.816-1.934-.816z"
                fill={colors.green}
            />
        </g>
    </svg>
);

export default Padlock
