import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Page from "../../components/Page";
import React from "react";
import Spinner from "../../components/Spinner";
import {transform} from 'json-to-json-transformer';
import {Box} from "grommet";
import {ErrorMessage, Form, Formik} from "formik";
import CampaignForm from "./components/CampaignForm";
import {ADDITIONAL_CAMPAIGN_FRAGMENT, CAMPAIGN_BY_ID_FRAGMENT} from "./constants/queries";
import {CampaignValidationSchema} from "./constants/validationSchemas";
import {prepareForSending, transformerTemplate} from "./constants/dataTransormations";
import API from "../../services/api";
import SagaErrorHandler from "../../errors/SagaErrorHandler";


const FETCH_DATA = gql`
    query fetchCampaignById($campaign_id: Int!) {
        ...campaign_fields
        ...additional_fields
    }
    ${CAMPAIGN_BY_ID_FRAGMENT}
    ${ADDITIONAL_CAMPAIGN_FRAGMENT}
`;

const CloneCampaignPage = ({match,history}) => {
    const id = (match.params && match.params.id) || 0;

    const {data, loading} = useQuery(FETCH_DATA, {variables: {campaign_id: id},fetchPolicy: 'cache-and-network'});

    if (loading) {
        return <Spinner full/>
    }

    let campaignData = transform(transformerTemplate, data.campaign);

    return (
        <Page title={`${data.campaign.name} Campaign`}>
            <Formik
                initialValues={campaignData}
                validationSchema={CampaignValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const payload = prepareForSending(values);
                    try {
                        await API.post('/campaign/create', payload);
                        history.push('/campaigns');
                    } catch (e) {
                        SagaErrorHandler.handle(e, actions);
                        actions.setFieldError('globalError', e.message);
                    }
                }}
                render={({errors, values, handleChange, setFieldValue, setFieldError}) => (
                    <Box>
                        <ErrorMessage name='globalError'/>
                        <Form>
                            <CampaignForm
                                values={values}
                                errors={errors}
                                setFieldError={setFieldError}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                audienceOptions={data.audience}
                                surveyOptions={data.survey_template}
                                measurementSurveyOptions={data.measurement_survey_template}
                                eventTypeOptions={data.event_type}
                                actionTypeOptions={data.action_type}
                                eventsChangeOfFieldOperands={data.trigger_operand}
                                eventsChangeOfFieldOperators={data.operator}
                                preselectedAudiences={campaignData.audiences}
                            />
                        </Form>
                    </Box>
                )}
            />
        </Page>
    )
};

export default CloneCampaignPage
