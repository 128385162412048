import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {requestPasswordReset} from '../../services/auth/service'
import {Box, Text} from "grommet";
import RoutedAnchor from "../../components/RoutedAnchor";
import {RequestPasswordResetForm} from "./components/RequestPasswordResetForm";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import {Layout} from "./Layout";
import {withRouter} from "react-router-dom";

const RequestPasswordResetSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

const RequestPasswordResetPage = withRouter(({history}) => (
    <Layout header='Request a password reset'>
        <Box align="center">
            <Box
                height={{min: '240px'}}
                background="white"
                round="small"
                border
                elevation='xsmall'
                pad="34px"
            >
                <Box margin={{bottom: "medium"}} width='small'>
                    <Text color="lightgray">Please enter your email to reset your
                        password.</Text>
                </Box>
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    validationSchema={RequestPasswordResetSchema}
                    onSubmit={async ({email}, actions) => {
                        try {
                            const message = await requestPasswordReset(email);
                            console.log(message, email);
                            actions.resetForm();
                            actions.setStatus(message);
                            history.push('/login');
                        } catch (e) {
                            SagaErrorHandler.handle(e, actions);
                        }
                    }}
                    render={props => <RequestPasswordResetForm {...props} />}
                />
            </Box>

            <Text margin={{top: "medium"}} color="lightgray">
                Try to login again?
                <RoutedAnchor path='/login' label=' Back to Login.'/>
            </Text>
        </Box>
    </Layout>
));

export default RequestPasswordResetPage
