import React from 'react';
import {Box, DataTable, Heading, Text} from "grommet";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {CreateContactModal} from "./CreateContactModal";
import {DeleteButton} from "../../../../components/DeleteButton";
import {useClientSession} from "../../hooks/useClientSession";

const FETCH_DATA = gql`
    query fetchSessionContacts($id: Int) {
        session_contact(where: {session_id: {_eq: $id}}) {
            id
            full_name
            contact_relationship {
                id
                name
            }
            email
            phone_number
        } 
    }
`;

const DELETE_MUTATION = gql`
    mutation($id:Int!) {
        delete_session_contact(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

export const Contacts = ({sessionId}) => {
    const {data, loading} = useQuery(FETCH_DATA, {variables: {id: sessionId}})
    const {active} = useClientSession();
    return (
        <Box border={{side: 'bottom'}} pad='medium'>
            <Heading level={6} margin={{bottom: 'small', top: 'none'}}>Contacts</Heading>
            <DataTable
                primaryKey='id'
                columns={[
                    {
                        property: 'full_name',
                        header: <Text>Name</Text>,
                        // primary: true,
                    },
                    {
                        property: 'contact_relationship.name',
                        header: <Text>Relationship</Text>,
                    },
                    {
                        property: 'phone_number',
                        header: <Text>Phone</Text>,
                    },
                    {
                        property: 'email',
                        header: <Text>Email</Text>,
                    },
                    active ? {
                        property: 'actions',
                        render: ({id}) =>
                            <DeleteButton
                                query={DELETE_MUTATION}
                                text='Are you sure you want to delete this contact?'
                                variables={{id}}
                                queriesToRefetch={['fetchSessionContacts']}
                            />
                    }: undefined,
                ].filter(column => !!column)}
                placeholder={loading ? 'Loading...' : undefined}
                data={data?.session_contact || []}
                border={{ body: 'bottom' }}
                pad={{ horizontal: 'large', vertical: 'small' }}
            />
            {active && <CreateContactModal sessionId={sessionId} refetchQueries={['fetchSessionContacts']}/>}
        </Box>
    )
};