import React, {forwardRef} from 'react';
import Avatar from "./Avatar";
import {Box, Text} from "grommet";


export const UserCard = forwardRef(({id, firstName, lastName, boxProps = {}, onClick = () => {}, button}, ref) => (
    <Box
        direction='row'
        width={{max: "small"}}
        round="xsmall"
        pad='xsmall'
        gap="xsmall"
        background='white'
        border
        onClick={onClick}
        align='center'
        ref={ref}
        {...boxProps}
    >
        <Avatar size='small' id={id} />
        <Text color="dark" size="small" truncate>
            {firstName} {lastName}
        </Text>
        {button}
    </Box>
));
