import React, {useMemo, useState} from 'react';
import {Box, Grid, Text} from "grommet";
import Page from "../../components/Page";
import {Form, Formik} from "formik";
import * as Yup from "yup";
// import {Invoices} from "./Invoices";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Spinner from "../../components/Spinner";
// import {LoadingButton} from "../../components/LoadingButton";
import {AccountSettings} from "./AccountSettings";
import {PaymentMethods} from "./PaymentMethods";
// import {WhyUpgrade} from "./WhyUpgrade";
import RoutedButton from "../../components/RoutedButton";
// import {STANDARD_PLAN} from "../../lib/constants";
import {CancelSubscriptionModal} from "./CancelSubscriptionModal";
// import {SorryToSeeYouGo} from "./SorryToSeeYouGo";
// import {SubscribeAgain} from "./SubscribeAgain";

const FETCH_PLAN = gql`
    query fetchCompanyData($companyId: Int!) {
        companyID @client @export(as: "companyId")
        company: company_by_pk(id: $companyId) {
            id
            plan_id
        }
        plan {
            id
            name
            price
        }
    }
`;

const FETCH_PAYMENT_DATA = gql`
    query fetchPaymentData {
        payment_methods {
            PaymentMethodId
            CardType
            MaskedNumber
            Exp
            Name
            Zip
            Revision
            CreatedDate
        }
        company_subscription {
            StartDate
            Amount
            LastTransactionStatus
            NextScheduledRunTime
        }
    }
`;

const CHANGE_PLAN = gql`
    mutation ($plan_id: Int!) {
        change_company_subscription_plan(plan_id: $plan_id) {
            status
        }
    }
`;

const CANCEL_SUBSCRIPTION = gql`
    mutation {
        cancel_company_subscription {
            cancel_at_period_end
        }
    }
`;

// const RESUBSCRIBE = gql`
// mutation ($plan_id: Int!) {
//     company_resubscribe(plan_id: $plan_id) {
//         cancel_at_period_end
//     }
// }
// `;
//
// const SUBSCRIBE = gql`
//     mutation ($plan_id: Int!) {
//         company_subscribe(plan_id: $plan_id) {
//             cancel_at_period_end
//         }
//     }
// `;

// TODO: Needs a lot of refactoring and implementation of new features (resubscribe, change plan, etc.)
const BillingPage = () => {
    const {data: planData, loading: planLoading} = useQuery(FETCH_PLAN);
    const {data: paymentData, loading: paymentLoading, error} = useQuery(FETCH_PAYMENT_DATA);
    const [changePlan] = useMutation(CHANGE_PLAN, {refetchQueries: ['fetchCompanyData']});
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);
    // const [resubscribe, {loading: resubscribeLoading}] = useMutation(RESUBSCRIBE);
    // const [subscribe, {loading: subscribeLoading}] = useMutation(SUBSCRIBE);
    const subscriptionActive = useMemo(() => true, []);
    console.log('planData', planData, paymentData);

    if (planLoading || paymentLoading) {
        return <Spinner full/>;
    }

    if (error || !paymentData?.company_subscription) {
        return <Page title="Billing Details">
            <Box align='center' justify='center' fill>
                <Text size="large" weight="bold">No billing information. If you think this is a mistake please contact
                    support.</Text>
            </Box>
        </Page>
    }

    return (
        <Page title="Billing Details">
            <CancelSubscriptionModal
                onClose={() => {
                    setSubscriptionModal(false)
                }}
                accept={async (doneLoading) => {
                    await cancelSubscription({refetchQueries: ['fetchPaymentData']});
                    doneLoading();
                    setSubscriptionModal(false);
                }}
                visible={subscriptionModal}
            />
            <Formik
                initialValues={{
                    plan_id: planData.company.plan_id
                }}
                validationSchema={Yup.object().shape({plan_id: Yup.number()})}
                onSubmit={async ({plan_id}, actions) => {
                    try {
                        await changePlan({variables: {plan_id}});
                        actions.setStatus({
                            type: 'success',
                            message: 'Your subscription plan was successfully changed.'
                        });

                    } catch (e) {
                        actions.setStatus({type: 'failure', message: 'Could not change subscription plan.'});

                    } finally {
                        actions.setSubmitting(false);
                    }
                }}
            >
                {props => (
                    <Form>
                        <Grid
                            gap='medium'
                            columns={{count: 'fit', size: '500px'}}
                        >
                            <AccountSettings {...props} subscription={paymentData.company_subscription}
                                             subscriptionActive={subscriptionActive} plans={planData.plan}/>
                            {/*{paymentData.company_subscription ? subscriptionActive ? planData.company.plan_id === STANDARD_PLAN && <WhyUpgrade/> : <SorryToSeeYouGo periodEnd={paymentData.company_subscription.current_period_end}/> : <SubscribeAgain/>}*/}
                            {/*<Invoices invoices={paymentData.invoices || []} subscription={paymentData.company_subscription}/>*/}
                            <PaymentMethods paymentMethods={paymentData.payment_methods}/>
                        </Grid>
                        <Box margin={{top: 'medium'}} direction='row' gap='small'>
                            {/*<LoadingButton loading={props.isSubmitting} type='submit' primary label='Update'/>*/}
                            <RoutedButton primary label='Back' color='lightgray' path='/'/>
                            {/*{paymentData.company_subscription ? subscriptionActive ?*/}
                            {/*    <Button label='Cancel Subscription' onClick={() => setSubscriptionModal(true)} primary color='statusPending'/> :*/}
                            {/*    <LoadingButton*/}
                            {/*        loading={resubscribeLoading}*/}
                            {/*        label={<Text color='white'>Resubscribe</Text>}*/}
                            {/*        onClick={async () => {*/}
                            {/*            try {*/}
                            {/*                await resubscribe({variables: props.values, refetchQueries: ['fetchPaymentData']})*/}
                            {/*            } catch (e) {*/}
                            {/*                console.log('Resubscribe error', e);*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*        primary*/}
                            {/*        color='green'*/}
                            {/*    /> :*/}
                            {/*    <LoadingButton*/}
                            {/*        loading={subscribeLoading}*/}
                            {/*        label={<Text color='white'>Subscribe</Text>}*/}
                            {/*        onClick={async () => {*/}
                            {/*            try {*/}
                            {/*                await subscribe({variables: props.values, refetchQueries: ['fetchPaymentData']})*/}
                            {/*            } catch (e) {*/}
                            {/*                console.log('Subscribe error', e);*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*        primary*/}
                            {/*        color='green'*/}
                            {/*    />*/}
                            {/*}*/}
                        </Box>
                    </Form>
                )}
            </Formik>
        </Page>
    )
};
export default BillingPage;
