import React from 'react';
import Page from "../../components/Page";
import {Formik} from "formik";
import * as Yup from "yup";
import API from "../../services/api";
import SagaErrorHandler from "../../errors/SagaErrorHandler";
import {MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS, useImageUpload} from "../../services/image";
import {InviteStaffForm} from "./InviteStaffForm";

const InviteMemberSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string(),
    avatar: Yup.mixed()
        .test('fileSize', "File is too large", value => !value.name || value.size <= MAX_FILE_SIZE)
        .test('fileType', "Unsupported file format", value => !value.name || SUPPORTED_IMAGE_FORMATS.includes(value.type)),
    isManager: Yup.bool().required('Required')
});

const InviteStaffPage = ({history}) => {
    const {uploadAvatar} = useImageUpload();
    return (
        <Page title="Invite Staff Member">
            <Formik
                initialValues={{
                    email: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    avatar: {},
                    isManager: false
                }}
                validationSchema={InviteMemberSchema}
                onSubmit={async (values, actions) => {
                    console.log("Submitting")
                    const {avatar, ...providerFields} = values;
                    try {
                        const response = await API.post('/provider/create', providerFields);
                        await uploadAvatar(values.avatar, response.data.id);
                        history.push('/staff');
                    } catch (e) {
                        SagaErrorHandler.handle(e, actions);
                    }
                }}
                render={props => <InviteStaffForm {...props} />}
            />
        </Page>
    );
};

export default InviteStaffPage;
